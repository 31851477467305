<template>
  <v-container fluid grid-list-md>
    <v-row no-gutters>

      <v-col xs="12" sm="10" md="10" lg="10" ex="10">
        <v-card ref="form">
          <v-card-text>

              <v-row no-gutters>
                <v-flex xs="12" class="py-0 px-2">                
                    <h2>{{lang.SalesAnalysis}} </h2>
                </v-flex>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.CompanyName}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.CompanyNameDatabase}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.BusinessYear}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.BusinessYear}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                  {{lang.User}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{task.First}} {{task.Last}}, {{task.email}}, {{task.UserId}}</span>
                </v-col>
              </v-row>
              <v-divider class="mt-1"></v-divider>
              <br>

          <v-row no-gutters>
             <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" >

              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </template>
 
                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1"  >
                  <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Mjesec"
                  clearable
                ></v-select>
              </v-col>

          </v-row>           

            <v-row no-gutters>
              <v-col xs="3" class="py-0 px-2">
                  {{lang.DocumentType}}
              </v-col>
              <v-col xs="9">
                <v-select :label="lang.DocumentType" :items="privitems" v-model="documentAna.documentName" value="documentAna.documentName" clearable></v-select>
                  <v-spacer></v-spacer>
              </v-col>
            </v-row>
             <v-row no-gutters v-if="documentAna.documentName === 'WorkingOrder'">
              <v-col xs="3" class="py-0 px-2">
           
              </v-col>
              <v-col xs="9">
                <v-select label="Status naloga" :items="productiontypes" v-model="selectPT" value="productiontypes.value" clearable></v-select>
                  <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="documentAna.documentName === 'WorkingOrder'">
              <v-col xs="3" class="py-0 px-2">
           
              </v-col>
              <v-col xs="9">
                <v-select label="Vrsta liste" :items="workOrderType" v-model="workOrder2Type" value="workOrder2Type" clearable></v-select>
                  <v-spacer></v-spacer>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="documentAna.documentName === 'InvoiceSales'">
              <v-col xs="3" class="py-0 px-2">
           
              </v-col>
              <v-col xs="9">
                <v-select label="Vrsta liste" :items="invoiceType" v-model="invoiceTypeSet" value="invoiceTypeSet" clearable></v-select>
                  <v-spacer></v-spacer>
              </v-col>
            </v-row>
             <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    {{lang.Partner}}
                  </v-col>
                  <v-col xs="4" class="py-0 px-2">
                    <v-autocomplete
                      :items="partners"
                      v-model="selectPa"
                      :label="lang.SelectPartner"
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                  </v-col>
              </v-row>

              <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-autocomplete
                    :items="itemsLoc"
                    v-model="selectIL"
                    :label="lang.ItemSelect"
                    item-text="itemByID"
                    item-value="itemID"
                    return-object
                    clearable
                  ></v-autocomplete>
                  </v-col>
              </v-row>
               <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-autocomplete
                    :items="privGroups"
                    v-model="selectGroup"
                    label="Odaberi grupu"
                    single-line
                    item-text="GroupName"
                    item-value="id"
                    return-object
                    persistent-hint
                    clearable
                  ></v-autocomplete>
                  </v-col>
              </v-row>

              <v-row no-gutters>
                  <v-col xs="3" class="py-4 px-2">
                    
                  </v-col>
                  <v-col xs="9" class="py-0 px-2">
                    <v-text-field
                      dense
                      :label="lang.CityName"
                      v-model="partnerPostcode"
                    ></v-text-field> 
                  </v-col>
              </v-row>


          </v-card-text>
          <v-divider class="mt-1"></v-divider>
          <v-card-actions>
             <v-btn
              color="green"
              @click="documentlist">
              {{langC.Back}}
            </v-btn>
            <v-btn color="green" @click="warehouseState" class="mr-1">
                {{langC.Warehouse}}
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="itemcard">
              {{lang.ItemCard}}
            </v-btn>

            <v-btn
              class="primary"
              @click="register">
              {{langC.Analysis}}
            </v-btn>

            

          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    
    <v-row>
       <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <v-progress-linear v-if="saving"
                indeterminate
                color="blue"
            ></v-progress-linear>
       </v-col> 
    </v-row>

    <v-row no-gutters>
      <v-col xs="12" sm="12" md="12" lg="12" ex="12">
          <div v-if="this.typeAna === 1">

              <v-row style="border-bottom:solid 1px;" class="mt-2">
                  <v-col xs="5">
                      <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                      {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                      {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                      {{lang.VATID}} {{comp.CompanyVATID}}
                  </v-col>
                  <v-col xs="3" v-if="this.typeAna === 1">
                    <h2>{{lang.SalesAnalysis}}</h2>
                    {{head2}} <br />
                    {{head3}} <br />
                    {{head4}} <br />
                    {{head5}} <br />
                    {{head6}}
                  </v-col>
                  <v-col xs="4">
                    <p class="text-md-left">
                        {{langC.PrintDate}} {{PrintDate}}<br />
                        email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                    </p>
                  </v-col>
              </v-row>
               <v-row>
                  <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9">
  
                  </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                       <v-btn
                        v-if="this.typeAna === 1"
                        text color="primary"
                        @click="downloadFile()">
                        excel
                      </v-btn>                      
                      <v-btn
                        text color="primary"
                        @click="printAna()">
                        {{langC.Print}}
                      </v-btn>
                    </v-col>
                </v-row>
          
            <v-row style="border-bottom:solid 1px; border-top:solid 1px;" class="mb-2">
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-md-left">
                   {{lang.documentId}} 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{lang.documentDate1}}
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left">
                    {{lang.partnerName}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    {{langC.UserId}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    {{lang.documentTotalAmountNetto}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left">
                    {{lang.documentTotalAmountVAT}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.documentTotalAmountBrutto}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!haveFilter">
                    {{lang.TotalDiscount}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!haveFilter">
                    {{lang.TotalDiff}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!haveFilter">
                    {{lang.TotalServices}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" v-if="!haveFilter">
                    {{lang.documentTotalAmountBruttoForeign}}
                  </v-col>
              </v-row>

                <v-row v-for="item in items" :key="item.id" dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{ item.documentId }}
                      {{localName(item.documentName)}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ formatDate(item.documentDate) }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ item.partnerName }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ item.email }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.documentTotalAmountNetto) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.documentTotalAmountVAT) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.documentTotalAmountBrutto) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                      {{ localMoney(item.Discount) }}
                    </v-col>
                     <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                      {{ localMoney(item.Diff) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                      {{ localMoney(item.Services) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                      {{ localMoney(item.documentTotalAmountBruttoForeign) }}
                    </v-col>                    
            </v-row>
            <v-row style="border-top:solid 1px;">
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                .
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="text-right" style="font-size:10px;"> 
                .
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                {{ localMoney(Total1) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                {{ localMoney(Total2) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                {{ localMoney(Total3) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;" v-if="!haveFilter">
                {{ localMoney(TotalFullDiscount) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                {{ localMoney(TotalFullDiff) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                {{ localMoney(TotalFullServices) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;" v-if="!haveFilter">
                {{ localMoney(Total6) }}
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="text-right" style="font-size:10px;"> 
                
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                Roba:
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;" v-if="!haveFilter">
                {{ localMoney(Total3 - TotalFullServices) }}
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">

              </v-col>
            </v-row>
           </div>

           <div v-if="this.typeAna === 2">
              <v-row style="border-bottom:solid 1px;">
                    <v-col xs="5">
                        <h4><b><i>{{comp.CompanyName}}</i></b></h4>
                        {{comp.CompanyStreet}} {{comp.CompanyBuildingNameNumber}}   <br />                
                        {{comp.CompanyPostcode}} {{comp.CompanyCity}}<br />
                        {{lang.VATID}} {{comp.CompanyVATID}}
                    </v-col>
                   <v-col xs="3">
                      <h2 >{{lang.ItemCard}}</h2>
                      {{head2}}<br />
                      {{itemID}}<br />
                      {{itemName}}
                    </v-col>
                    <v-col xs="4">
                      <p class="text-md-left">
                          {{langC.PrintDate}} {{PrintDate}}<br />
                          email: {{comp.Companyemail}}, {{comp.CompanyPhones}}<br />
                      </p>
                    </v-col>
              </v-row>
             <v-row style="border-bottom:solid 1px; border-top:solid 1px;">
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-md-left">
                   {{lang.documentId}} 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    {{lang.documentDate1}}
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left">
                    {{ lang.partnerName }}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                   {{lang.ItemPcsPlus}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemPcsMinus}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemSuppAmount}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemMargin}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemBaseAmountNoVAT}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemVAT1Percentage}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemAmountWithVAT}}
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right">
                    {{lang.ItemHomeCurrency}}
                  </v-col>
              </v-row>
              <v-row v-for="item in items" :key="item.id" dense>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-xs-left" style="font-size:10px;">
                      {{ item.documentId }}
                      {{localName(item.documentName)}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-left" style="font-size:10px;">
                      {{ formatDate(item.documentDate) }}
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="text-left" style="font-size:10px;">
                      {{ item.partnerNameDatabase }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      <span v-if="parseFloat(item.itemPcsPlus) !== 0">{{ item.itemPcsPlus }}</span>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      <span v-if="parseFloat(item.itemPcsMinus) !==0">{{ item.itemPcsMinus }}</span>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemSuppPrice) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemMargin) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemBaseAmountNoVAT) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.itemVAT1Percentage }}%
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(item.itemAmountWithVAT) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ item.HomeCurrency}}
                    </v-col>                    
                  </v-row>
                  <v-row style="border-top:solid 1px;">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      .
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-right" style="font-size:10px;"> 
                      {{lang.WarehousePlus}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total1) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
   
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total3) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total5) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total7) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      .
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total9) }}
                    </v-col>
                  </v-row>
                  <v-row >
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      .
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-right" style="font-size:10px;"> 
                      {{lang.WarehouseMinus}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">

                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total2) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total4) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total6) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total8) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      .
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total10) }}
                    </v-col>
                  </v-row>
                  <v-row style="border-top:solid 1px;">
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:10px;">
                      .
                    </v-col>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="text-right" style="font-size:10px;"> 
                      {{lang.WarehouseState}}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
                      {{ localMoney(Total21) }}
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
  
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">
       
                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">

                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">

                    </v-col>
                    <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="text-right" style="font-size:12px;">

                    </v-col>
                  </v-row>
             </div>

            <div v-if="this.statewhs">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <appDocumentWarehouseState :fromDate="startDate" :toDate="endDate"/>
                </v-col>
              </v-row>
            </div>
            <div v-if="workOrder">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <DocumentSalesAnaListWO :items="items" :head1="head1" :head2="head2" :head3="head3" :head4="head4" :head5="head5" :productiontypes="productiontypes" />
                </v-col>
              </v-row>
            </div>
            <div v-if="workOrder2">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <DocumentSalesAnaListWO_2 :allitems="itemDetails" :headitems="items" :head1="head1" :head2="head2" :head3="head3" :head4="head4" :head5="head5" :type="workOrder2Type" :productiontypes="productiontypes"/>
                </v-col>
              </v-row>
            </div>
            <div v-if="invoice2Set">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <DocumentSalesAnaListInv :allitems="itemDetails" :avgPrices="avgPrices" :head1="head1" :head2="head2" :head3="head3" :head4="head4" :head5="head5" :type="invoiceTypeSet"/>
                </v-col>
              </v-row>
            </div>

      </v-col>
    </v-row>
    <v-row>
     <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
        <DocumentSalesPrintAna ref="prnt" :all="all" />
      </v-col>
    </v-row>
     <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        bottom
        :color="color"
        >
        {{ text }}
      </v-snackbar>
  </v-container>
</template>

<script>
import UserService from '@/services/UserService'
import GroupService from '@/services/GroupService'
import DocumentService from '@/services/DocumentService'
import DocumentSalesPrintAna from './DocumentSalesPrintAna'
import DocumentSalesAnaListWO from './DocumentSalesAnaListWO'
import DocumentSalesAnaListWO_2 from './DocumentSalesAnaListWO_2'
import DocumentSalesAnaListInv from './DocumentSalesAnaListInv'
import DocumentDetailService from '@/services/DocumentDetailService'
//import ItemService from '@/services/ItemService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentWarehouseState from './DocumentWarehouseState'
//import {checkPartners} from '@/snippets/mainledgerHelper'
import { testLastUpdate } from '@/services/TestLastUpdate'
import {defDocumentDetail, defNewDocumentDetail} from './documentHelper'
import {parseNum} from '@/snippets/allSnippets'
import documentLocalName from '@/snippets/documentLocalName'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import xlsx from 'json-as-xlsx'
import dayjs from 'dayjs'

export default {
  name: 'documentsalesanalysis',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      itemsLoc: [],
      selectIL: {},
      productiontypes: [],
      selectPT: {},
      typeAna : 0,
      statewhs: false, 
      rules: {
        required: value => !!value || '*'
      },
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      task: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        EmployeeId: 0,
        Employeeemail: '',
        Employeehashtag: '',
        partnerId: 0,
        partnerName: '',
        partnerNameShort: '',
        taskTypeID: 0,
        taskTypeDescription: '',
        taskDepartmentID: 0,
        taskDepartmentDescription: '',
        taskShortDescription: '',
        taskDetailDescription: '',
        taskOpenDate: null,
        taskOpenTime: null,
        taskForDate: null,
        taskForTime: null,
        taskAlarmDate: null,
        taskAlarmTime: null,
        taskFor1UserId: 0,
        taskFor1Useremail: '',
        taskFor1UserSendEmail: false,
        taskFor2UserId: 0,
        taskFor2Useremail: '',
        taskFor2UserSendEmail: false,
        taskFor3UserId: 0,
        taskFor3Useremail: '',
        taskFor3UserSendEmail: false,
        taskFor4UserId: 0,
        taskFor4Useremail: '',
        taskFor4UserSendEmail: false,
        taskFor5UserId: 0,
        taskFor5Useremail: '',
        taskFor5UserSendEmail: false,
        taskClosedDate: null,
        taskClosedTime: null,
        taskClosedUserId: 0,
        taskClosed: false,
        taskClosedSendEmail: false,
        taskSpentHours: 0,
        taskChargeHours: 0,
        taskCharged: false,
        taskChargedByUserId: 0,
        taskChargedByUseremail: '',
        taskChargedDate: null,
        taskChargedTime: null,
        taskNotFinished: false,
        taskNotFinishedReason: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: '',
        LastUpdate: null
      },
      temptask: {},
      task2: {},
      taskAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        EmployeeId: 0,
        partnerId: 0,
        taskTypeID: 0,
        taskDepartmentID: 0,
        taskFor2UserId: 0,
        taskFromDate: null,
        taskToDate: null,
        taskClosed: false,
        taskCharged: false,
        taskNotFinished: false,
        Warehouse: 1
      },
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      comp: {},
      error: null,
      localError: false,
      newUser: {},
      confpassword: '',
      size: 'sm',
      privitems: [],
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      tasktypes: [],
      taskdepartments: [],
      users: [],
      items: [],
      itemDetails: [],
      preItemDetails: [],
      CompanyId: 0,
      selectPa: {},
      selectEm: {},
      selectTT: {},
      selectTD: {},
      selectUs: {},
      selectUs2: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      privGroups: [],
      selectGroup: {},
      havePath: '',
      Total1: 0,
      Total2: 0,
      Total3: 0,
      Total4: 0,
      Total5: 0,
      Total6: 0,
      Total7: 0,
      Total8: 0,
      Total9: 0,
      Total10: 0,
      Total21: 0,
      Total31: 0,
      Total41: 0,
      Total51: 0,
      Total61: 0,
      Total71: 0,
      TotalDiscount: 0,
      TotalServices: 0,
      TotalDiff: 0,
      TotalFullDiscount: 0,
      TotalFullServices: 0,
      TotalFullDiff: 0,
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      startDate: null,
      endDate: null,
      itemID: '',
      itemName: '',
      head1: '',
      head2: '',
      head3: '',
      head4: '',
      head5: '',
      head6: '',
      ana: {},
      all: 0,
      document: {},
      documentDet: {},
      documentDetails: [],
      documentID: 0,
      typePcs: 0,
      partnerPostcode: null,
      workOrder: false,
      workOrder2: false,
      workOrderType: [
        {text: 'Lista proizvedenog', value: 1},
        {text: 'Lista razduzenog', value: 2},
      ],
      workOrder2Type: null,
      invoiceType: [
        {text: 'Artikli zbrojeni po grupi', value: 1},
        {text: 'Artikli zbrojeni po mjeri', value: 2},
      ],
      invoiceTypeSet: null,
      invoiceSet: false,
      invoice2Set: false,
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      selectedMonth: null,
      avgPrices: [],
      haveFilter: false
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    // if (this.$store.state.itemStore) {
    //   this.itemsLoc = this.$store.state.itemStore
    // } else {
    //  this.itemsLoc = (await ItemService.index()).data.items
    // }
     if (this.$store.state.companyid) {
      this.CompanyId = await this.$store.state.companyid
      this.documentAna.CompanyId = await this.$store.state.companyid
      this.mainQuery.CompanyId = await this.$store.state.companyid
    }
    this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    this.privGroups = (await GroupService.choose(this.mainQuery)).data.groups

    this.privitems.push(
      { text: this.lang.InquirySales, value: 'InquirySales' },
      { text: this.lang.QuoteSales, value: 'QuoteSales' },
      { text: this.lang.OrderSales, value: 'OrderSales' },
      { text: this.lang.DeliveryNoteSales, value: 'DeliveryNoteSales' },
      { text: this.lang.InvoiceSales, value: 'InvoiceSales' },
      { text: this.lang.ContractSales, value: 'ContractSales' },
      { text: this.lang.WorkingOrder, value: 'WorkingOrder' },
      { text: this.lang.POS, value: 'POS' }
    )
    // need five arrays with partners, employes, tasktype, taskdepartment, users
   
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.users = (await UserService.choose(this.mainQuery)).data.users
    }
    if (this.$store.state.user.id) {
      this.task.UserId = this.$store.state.user.id
      this.documentAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.task.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.task.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.task.Last = this.$store.state.user.Last
    }
    if (this.$store.state.companyid) {
      this.task.CompanyId = this.$store.state.companyid
      this.documentAna.CompanyId = this.$store.state.companyid
    }
    if (this.$store.state.companyName) {
      this.task.CompanyNameDatabase = this.$store.state.companyName
    }
    if (this.$store.state.businessYear) {
      this.task.BusinessYear = this.$store.state.businessYear
      this.documentAna.BusinessYear = this.$store.state.businessYear
    }
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.task.taskOpenDate = dayjs().format('YYYY-MM-DD')
    this.startDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    this.productiontypes = this.$store.state.productiontype
  },
  computed: {
    formIsValid () {
      return this.documentAna.CompanyId !== null
    },
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localName (name) {
      return documentLocalName(name)
    },
    createHead(typeHead) {
      this.head1 = typeHead ? typeHead : ''
      this.head2 = 'Od: ' + dayjs(this.startDate).format('DD.MM.YYYY') + ' do: ' + dayjs(this.endDate).format('DD.MM.YYYY')
      this.head3 = this.documentAna.documentName ? 'Vrsta dok: ' + documentLocalName(this.documentAna.documentName) : ''
      this.head4 = this.selectPa && this.selectPa.partnerName ? 'Partner: ' + this.selectPa.partnerName : null
      this.head5 = this.selectIL && this.selectIL.itemName ? 'Sifra: ' + this.selectIL.itemName : null
      this.head6 = this.selectGroup && this.selectGroup.itemGroup ? 'Grupa:' + this.selectGroup.itemGroup : null
    },
    warehouseState() {
      this.statewhs = !this.statewhs
    },
    validate () {
      if (this.$refs.form.validate()) {
        //this.snackbar = true
        this.register()
      }
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.items ? this.items : []
      this.ana.head1 = this.head1
      this.ana.head2 = this.head2
      this.ana.head3 = this.head3
      this.ana.head4 = this.head4
      this.ana.head5 = this.head5
      this.ana.head6 = this.head6
      this.ana.TotalFullDiscount = this.TotalFullDiscount
      this.ana.TotalFullServices = this.TotalFullServices
      this.ana.TotalFullDiff = this.TotalFullDiff
      this.all = this.showSupp ? 1 : 2
      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
    setAna() {
        if (this.documentAna.partnerId) {
          delete this.documentAna.partnerId
        }
        if (this.documentAna.UserId) {
          delete this.documentAna.UserId
        }
        if (this.documentAna.docFromDate) {
          delete this.documentAna.docFromDate
        }
        if (this.documentAna.docToDate) {
          delete this.documentAna.docToDate
        }
        if (this.documentAna.partnerPostcode) {
          delete this.documentAna.partnerPostcode
        }
        if (this.documentAna.itemID) {
          delete this.documentAna.itemID
        }
        if (this.documentAna.itemGroup) {
          delete this.documentAna.itemGroup
        }
        this.typeAna = 0
        this.workOrder = false
        this.workOrder2 = false
        this.invoice2Set = false
        this.haveFilter = false

        // do analysis from database
        this.localError = false
        if (this.selectPa && this.selectPa.id) {
          this.documentAna.partnerId = this.selectPa.id
        }
        if (this.selectUs && this.selectUs.id) {
          this.documentAna.UserId = this.selectUs.id
        }
        if (this.startDate) {
          this.documentAna.docFromDate = dayjs(this.startDate).format('YYYY-MM-DD')
        }
        if (this.endDate) {
          this.documentAna.docToDate = dayjs(this.endDate).format('YYYY-MM-DD')
        }
        if (this.partnerPostcode) {
          this.documentAna.partnerPostcode = this.partnerPostcode
        }
        if (this.selectIL && this.selectIL.itemID) {
          this.documentAna.itemID = this.selectIL.itemID
        }
        if (this.selectGroup.id) {
          this.documentAna.itemGroup = this.selectGroup.id
        }
        this.createHead(this.lang.SalesAnalysis)
        this.typePcs = 2
        if (this.documentAna.documentName === 'POS' || this.documentAna.documentName === 'DeliveryNoteSales') {
          this.typePcs = 1
        }
        
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.Total7 = 0
        this.Total8 = 0
        this.Total9 = 0
        this.Total10 = 0
        this.Total21 = 0
        this.Total31 = 0
        this.Total41 = 0
        this.Total51 = 0
        this.Total61 = 0
        this.Total71 = 0
        this.TotalDiscount = 0
        this.TotalServices = 0
        this.TotalFullDiscount = 0
        this.TotalFullServices = 0
        this.TotalFullDiff = 0
        this.items.splice(0, this.items.length)
        this.itemDetails.splice(0, this.itemDetails.length)
        this.preItemDetails.splice(0, this.preItemDetails.length)
    },
    async register() {
      this.Total1 = 0
      this.Total2 = 0
      this.Total3 = 0
      this.Total4 = 0
      this.Total5 = 0
      this.Total6 = 0
      this.Total7 = 0
      this.Total8 = 0
      this.Total9 = 0
      this.Total10 = 0
      this.Total21 = 0
      this.Total31 = 0
      this.Total41 = 0
      this.Total51 = 0
      this.Total61 = 0
      this.Total71 = 0
      this.TotalDiscount = 0
      this.TotalServices = 0
      this.TotalFullDiscount = 0
      this.TotalFullServices = 0
      this.TotalFullDiff = 0
      this.saving = true
      console.log('this.saving', this.saving, this.selectGroup)
      this.setAna()
      let direction = 3
      console.log('this.selectPT', this.selectPT, this.workOrder2Type, this.invoiceTypeSet, this.selectIL, this.selectGroup)
       if (this.documentAna.documentName &&  Object.keys(this.selectPT).length === 0 && !this.workOrder2Type &&  !this.invoiceTypeSet && (this.selectIL || (this.selectGroup && Object.keys(this.selectGroup).length !== 0))) {
        direction = 2
      }
      if (this.documentAna.documentName && (Object.keys(this.selectPT).length !== 0 || this.workOrder2Type || this.invoiceTypeSet)) {
        direction = 3
      }
      console.log('direction ', direction )
      
      if (direction === 2) {
        await this.register2()
      }
      if (direction === 3 ){
        await this.register3()
      }
      this.saving = false
      console.log('this.saving', this.saving)
    },
    async register2() {
      // normal list - with subselections 
      // use details - cannot rely on date in document details, because row heads can have different dates, so use full period
      const fullPeriod = {...this.documentAna}
      fullPeriod.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      fullPeriod.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      delete fullPeriod.documentName 

      const res1 = await DocumentDetailService.salesana(fullPeriod)
      const preItems = res1.data.document ? res1.data.document : []

      // use heads
        const rowHeads = (await DocumentService.documentsalesana(this.documentAna)).data.document
        

        if (rowHeads) {
          
          rowHeads.map(item => {
            const docId = parseInt(item.id)
            const onlyForHead = preItems.filter(sitem => parseInt(sitem.databaseDocID) === docId)
            let netto = 0
            let vat = 0
            let brutto = 0
            let discount = 0
            let diff = 0
            let service = 0
            let bruttoF = 0 
             
            if (onlyForHead) {
              onlyForHead.map(ditem => {
                netto += ditem.itemBaseAmountNoVAT ? parseFloat(ditem.itemBaseAmountNoVAT) : 0
                brutto += ditem.itemAmountWithVAT ? parseFloat(ditem.itemAmountWithVAT) : 0
                vat += (ditem.itemAmountWithVAT ? parseFloat(ditem.itemAmountWithVAT) : 0) - (ditem.itemBaseAmountNoVAT ? parseFloat(ditem.itemBaseAmountNoVAT) : 0)
                discount += ditem.itemDiscountAmount1 ? parseFloat(ditem.itemDiscountAmount1) : 0
                // diff += ditem. ? parseFloat(ditem.) : 0
                // service += ditem. ? parseFloat(ditem.) : 0
                bruttoF += ditem.itemBaseForeAmountWithVAT ? parseFloat(ditem.itemBaseForeAmountWithVAT) : 0
              })
              this.Total1 += netto
              this.Total2 += vat
              this.Total3 += brutto
              this.Total4 += 0
              this.Total5 += 0
              this.Total6 += bruttoF        
            }

            const newItem = {}
            newItem.documentId = item.documentId 
            newItem.documentName = item.documentName ? item.documentName : ''
            newItem.documentDate = item.documentDate ? item.documentDate : ''
            newItem.partnerName = item.partnerName ? item.partnerName : ''
            newItem.email = item.email ? item.email : ''
            newItem.documentTotalAmountNetto = netto
            newItem.documentTotalAmountVAT = vat
            newItem.documentTotalAmountBrutto = brutto
            newItem.Discount = discount
            newItem.Diff = diff
            newItem.Services = service
            newItem.documentTotalAmountBruttoForeign = bruttoF

            // newItem.Discount = this.TotalDiscount
            // newItem.Services = this.TotalServices
            // newItem.Diff = this.TotalDiff
            // newItem.profitLoss = profitLoss
            // newItem.statusDoc2 = statusDoc2 && statusDoc2.text ? statusDoc2.text : ''
            this.items.push(newItem)
            this.TotalFullDiff += 0
          })
        }
        this.haveFilter = true
        this.typeAna = 1
    },

    // list with details for production
    async register3 () {
      try {
        this.items.splice(0, this.items.length)

        if (this.documentAna.documentName === 'InvoiceSales') {

          const fullPeriod = {...this.documentAna}
          fullPeriod.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
          fullPeriod.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
          delete fullPeriod.documentName 

          const res1 = await DocumentDetailService.salesana(fullPeriod)
          const sourceAvg = res1.data.document ? res1.data.document : []

          if (sourceAvg) {
            // let conditionItemID = null
            // let conditionSuppPrice = null

            sourceAvg.map(src => {
              if (src.itemID) {
                
                let checkIt = false
                if (src.documentName === 'WorkingOrder' && parseInt(src.FreeF6) === 33) {
                  checkIt = true
                }
                if (src.documentName === 'ReceiptPurchase') {
                  checkIt = true
                }

                if (checkIt){
                  const pcs = parseNum(src.itemPcsPlus,2)
                  const suppAm = parseNum(src.itemSuppPriceAmount,2)
                  if (suppAm > 0 ) {
                    // const fItem = itemsLoc.find(fitem => fitem.itemID === conditionItemID )
                    // if (fItem && fItem.itemSuppPrice && parseNum(fItem.itemSuppPrice, 2)> 0 && parseNum(fItem.itemSuppPrice, 2) < parseNum(fItem.itemPrice, 2)) {
                    //   conditionSuppPrice = parseNum(fItem.itemSuppPrice,2)
                    // }
                    // if (parseNum(src.itemSuppPrice,2) > conditionSuppPrice) {
                    //   conditionSuppPrice = parseNum(src.itemSuppPrice,2)
                    // }

                    const objIndx = this.avgPrices.findIndex(obj => obj.itemID === src.itemID)

                    if (objIndx !== -1) {
                      const haveSuppAm = this.avgPrices[objIndx].suppAm
                      const havePcs = this.avgPrices[objIndx].pcs
                      if (pcs !==0) {
                        this.avgPrices[objIndx].suppPriceAvg = (suppAm + haveSuppAm)/ (pcs + havePcs)
                        this.avgPrices[objIndx].pcs = pcs + havePcs
                        this.avgPrices[objIndx].suppAm = suppAm + haveSuppAm
                      }
                    }
                    if (objIndx === -1 && pcs !==0) {
                      const addAvg = {}
                      addAvg.itemID = src.itemID
                      addAvg.suppPriceAvg = suppAm / pcs
                      addAvg.pcs = pcs
                      addAvg.suppAm = suppAm
                      this.avgPrices.push(addAvg)
                    }
                  }
                }

              }
            })

          }

        }
        const allPeriod = {...this.documentAna}
        allPeriod.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        allPeriod.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')

        await DocumentDetailService.salesana(allPeriod)
        .then(res => {
          this.preItemDetails = res.data.document ? res.data.document : []
          if (this.preItemDetails) {
            this.preItemDetails.map(item=> {
              this.TotalFullDiscount += parseFloat(item.itemDiscountAmount1)
              if (item.itemService === true) {
                this.TotalFullServices += parseFloat(item.itemFullTotal)
              }
            })
          }
        })
        .catch((err) => {
          this.itemDetails.splice(0, this.itemDetails.length)
          // eslint-disable-next-line
          console.log(err)
        })

        const rowHeads = (await DocumentService.documentsalesana(this.documentAna)).data.document
        // this.saving = false
        let total3 = 0
        let total33 = 0
        let total333 = 0

        if (rowHeads) {
          
          rowHeads.map(item => {
            const docId = parseInt(item.id)
            const statusDoc2 = this.productiontypes.find(prtype => prtype.value === parseInt(item.productiontype))
            const services = this.preItemDetails.filter(sitem => parseInt(sitem.databaseDocID) === docId)
            this.TotalDiscount = 0
            this.TotalServices = 0
            this.TotalDiff = 0

            total3 = 0
            total33 = 0
            total333 = 0
             
            if (services) {
              services.map(service => {
                this.TotalDiscount += parseFloat(service.itemDiscountAmount1)
                if (service.itemService === true) {
                  this.TotalServices += parseFloat(service.itemFullTotal)
                }
                if (service.itemService === false) {
                  if (this.typePcs === 2) {
                    this.TotalDiff += parseFloat(service.itemBaseAmountNoVAT) - (parseFloat(service.itemPcsNeutral) * parseFloat(service.itemSuppPrice))
                  }
                  if (this.typePcs === 1) {
                    this.TotalDiff += parseFloat(service.itemBaseAmountNoVAT) - (parseFloat(service.itemPcsMinus) * parseFloat(service.itemSuppPrice))
                  }
                }
                if (parseInt(service.FreeF6) === 3) {
                  total3 += parseNum(service.itemSuppBasePriceAmount,2)
                }
                if (parseInt(service.FreeF6) === 33){
                  total33 += parseNum(service.itemSuppBasePriceAmount,2)
                  total333 += parseNum(service.itemBaseAmountNoVAT,2)
                }
                const newSubItem = {...service}
                
                newSubItem.statusDoc2 = statusDoc2 && statusDoc2.value ? statusDoc2.value : null
                this.itemDetails.push(newSubItem)
              })            
            }
            const razPro = parseNum(item.FreeF4,2) - parseNum(total333,2)
            if (razPro !== 0) {
            console.log(item.documentId, 'proiz glava = ', parseNum(item.FreeF4,2), ' stavke=', parseNum(total333,2), 'raz = ', razPro)
            }

            const razMat = parseNum(item.FreeF1,2) - parseNum(total3,2)
            if (razMat !== 0) {
            console.log(item.documentId, 'materijal glava = ', parseNum(item.FreeF1,2), ' stavke=', parseNum(total3,2), 'raz = ', razMat)}

            const profitLoss = (item.FreeF4 ? parseFloat(item.FreeF4) : 0) - (item.FreeF1 ? parseFloat(item.FreeF1) : 0) - (item.FreeF2 ? parseFloat(item.FreeF2) : 0) - (item.FreeF3 ? parseFloat(item.FreeF3) : 0)
            const newItem = {...item}
            newItem.Discount = this.TotalDiscount
            newItem.Services = this.TotalServices
            newItem.Diff = this.TotalDiff
            newItem.profitLoss = profitLoss
            newItem.statusDoc2 = statusDoc2 && statusDoc2.text ? statusDoc2.text : ''
            this.items.push(newItem)
            this.TotalFullDiff += this.TotalDiff
           
            this.Total1 += item.documentTotalAmountNetto ? parseFloat(item.documentTotalAmountNetto) : 0
            this.Total2 += item.documentTotalAmountVAT ? parseFloat(item.documentTotalAmountVAT) : 0
            this.Total3 += item.documentTotalAmountBrutto ? parseFloat(item.documentTotalAmountBrutto) : 0
            this.Total4 += item.documentTotalAmountNettoForeign ? parseFloat(item.documentTotalAmountNettoForeign) : 0
            this.Total5 += item.documentTotalAmountVATForeign ? parseFloat(item.documentTotalAmountVATForeign) : 0
            this.Total6 += item.documentTotalAmountBruttoForeign ? parseFloat(item.documentTotalAmountBruttoForeign) : 0
          })
    
        }
        console.log('total33', total33)
        let haveIT = false 
         if (this.documentAna.documentName === 'WorkingOrder') {
          if (this.workOrder2Type) {
            // disable main  work order list
            this.workOrder = false
            this.workOrder2 = true
          }
          if (!this.workOrder2Type) {
            // enable main work order list
            this.workOrder = true
          }
        }
        if (this.documentAna.documentName === 'InvoiceSales') {
          if (this.invoiceTypeSet) {
            this.invoice2Set = true
          }
          if (!this.invoiceTypeSet) {
            this.invoice2Set = false
          }
        }

        if (this.workOrder) {
          // if is work order then do not show anything from here
          this.typeAna = 0
          haveIT = true
        }
        if (this.workOrder2) {
          this.typeAna = 0
          haveIT = true
        }
        if (this.invoice2Set) {
          this.typeAna = 0
          haveIT = true
        }
        if (!haveIT){
          this.typeAna = 1
        }
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    async warehousestateOld () {
      try {
        // do analysis from database
        this.localError = false
        this.error = null
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.Total7 = 0
        this.Total8 = 0
        this.Total9 = 0
        this.Total10 = 0
        this.Total21 = 0
        this.Total31 = 0
        this.items = []
        if (this.$store.state.companyid) {
          this.documentAna.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.documentAna.BusinessYear = this.$store.state.businessYear
        }
        this.documentAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') :  dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.documentAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        // this.saving = true
        const tempItems = (await DocumentService.documentwarehousestate(this.documentAna)).data.document
        // this.saving = false
        let iid = 0
        if (tempItems) {
          tempItems.map(item => {
            const newEntry = {}
            newEntry.itemID = item.itemID ? item.itemID : ''
            newEntry.itemName = item.itemName ? item.itemName : ''
            newEntry.TotalPlus = parseFloat(item.TotalPlus ? item.TotalPlus : 0)
            newEntry.TotalMinus = parseFloat(item.TotalMinus ? item.TotalMinus : 0)
            newEntry.TotalPcs = parseFloat(item.TotalPlus ? item.TotalPlus : 0) - parseFloat(item.TotalMinus ? item.TotalMinus : 0)
            this.Total1 += parseFloat(item.TotalPlus ? item.TotalPlus : 0)
            this.Total2 += parseFloat(item.TotalMinus ? item.TotalMinus : 0)
            iid += 1
            newEntry.id = iid
            this.items.push(newEntry)
          })
        }
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }
    },
    async itemcard () {
      try {
        if (!this.selectIL.itemID) {
          alert('Error! Sifra nije odabrana. Item ID is not selected!')
          return
        }
        // do analysis from database
        this.localError = false
        this.error = null
        if (this.selectPa.id) {
          this.documentAna.partnerId = this.selectPa.id
        }
        if (this.selectUs.id) {
          this.documentAna.UserId = this.selectUs.id
        }
        if (this.selectIL.itemID) {
          this.documentAna.itemID = this.selectIL.itemID
        }
        if (this.$store.state.companyid) {
          this.documentAna.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.documentAna.BusinessYear = this.$store.state.businessYear
        }
        this.createHead(this.lang.ItemCard)
        this.Total1 = 0
        this.Total2 = 0
        this.Total3 = 0
        this.Total4 = 0
        this.Total5 = 0
        this.Total6 = 0
        this.Total7 = 0
        this.Total8 = 0
        this.Total9 = 0
        this.Total10 = 0
        this.Total21 = 0
        this.Total31 = 0
        this.documentAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') :  dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.documentAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.saving = true
        this.items = (await DocumentService.documentitemcard(this.documentAna)).data.document
        this.saving = false
        if (this.items) {
          this.items.map(item => {
            this.itemID = item.itemID ? item.itemID : ''
            this.itemName = item.itemName ? item.itemName : ''
            this.Total1 += parseFloat(item.itemPcsPlus ? item.itemPcsPlus : 0)
            this.Total2 += parseFloat(item.itemPcsMinus ? item.itemPcsMinus : 0)
            this.Total3 += parseFloat((item.itemSuppPrice * item.itemPcsPlus) ? (item.itemSuppPrice * item.itemPcsPlus) : 0)
            this.Total4 += parseFloat((item.itemSuppPrice * item.itemPcsMinus) ? (item.itemSuppPrice * item.itemPcsMinus) : 0)
            this.Total5 += parseFloat((item.itemMargin * item.itemPcsPlus) ? (item.itemMargin * item.itemPcsPlus) : 0)
            this.Total6 += parseFloat((item.itemMargin * item.itemPcsMinus) ? (item.itemMargin * item.itemPcsMinus) : 0)
            this.Total7 += parseFloat((item.itemPrice * item.itemPcsPlus) ? (item.itemPrice * item.itemPcsPlus) : 0)
            this.Total8 += parseFloat((item.itemPrice * item.itemPcsMinus) ? (item.itemPrice * item.itemPcsMinus) : 0)
            this.Total9 += parseFloat((item.itemPriceWithVAT * item.itemPcsPlus) ? (item.itemPriceWithVAT * item.itemPcsPlus) : 0)
            this.Total10 += parseFloat((item.itemPriceWithVAT * item.itemPcsMinus) ? (item.itemPriceWithVAT * item.itemPcsMinus) : 0)
          })
        }
        this.Total21 = (this.Total1 - this.Total2) ? parseFloat(this.Total1 - this.Total2).toFixed(2) : 0
        this.Total31 = (this.Total3 - this.Total4) ? parseFloat(this.Total3 - this.Total4).toFixed(2) : 0
        this.typeAna = 2
      } catch (error) {
        this.error = 'Greska u konekciji na bazu'
      }
    },
    close () {
      this.show = false
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    async documentlist () {
      try {
        this.havePath = this.$store.state.retPath
        this.$store.dispatch('setretPath', '')
        await this.$router.push({
          name: 'home'
        })
        // }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async priceChangeConfirm() {
      if (this.documentAna.documentName === 'POS' || this.documentAna.documentName === 'InvoiceSales') {
        if (confirm('Da li ste sigurni da želite kreirati zapisnik za odabrani period?') ) {
          this.priceChangeCreate()
        }
      }
    },
    async fillDoc() {
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.mainQuery.documentName = 'PriceChange'
      this.mainQuery.documentSide = 3
      this.docSide = 3
      // ??? this maybe shoul be for every varehouse separate ... 
      const side = this.$store.state.documentSide ? this.$store.state.documentSide : 0
      if (side === 1 || side === 2) {
        this.mainQuery.WareHouseId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1
      }
      const {data} = await DocumentService.choose(this.mainQuery)
      
      if (data && data.docNumber) {
        this.document.documentId = data.docNumber
      }
      //this.document.documentId = parseInt((await DocumentService.choose(this.mainQuery)).data.docNumber)
      this.document.documentIdGeneral = this.$store.state.companyActiv.CompanyNameDatabase + '-' + this.$store.state.businessYear + '-' + 'PriceChange'+ '-' + this.document.documentId + '-' + this.$store.state.warehousemarkid
      this.document.WareHouseId = (this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1)
      const whObj =  this.$store.state.warehouseState ? this.$store.state.warehouseState.find(whID => whID.WareHouseId === this.$store.state.warehousemarkid) : []
      this.document.WareHouseName = whObj && whObj.WareHouseName ? whObj.WareHouseName : ''
      this.document.documentName = 'PriceChange'
      this.document.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
      this.document.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
      this.document.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
      this.document.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
      this.document.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.document.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
      this.document.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.document.documentSide = 3
      this.document.partnerId =  this.$store.state.companyActiv.companyid
      this.document.partnerNameDatabase =  this.$store.state.companyActiv.CompanyNameDatabase

      let locDate1 = new Date()
      locDate1.getDate()
      locDate1.getMonth() + 1
      locDate1.getFullYear()
      this.document.documentDate = this.startDate ? this.startDate  : dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentTime = dayjs(new Date()).format('HH:mm:SS')
      this.document.documentInvoiceStartDate = this.startDate ? this.startDate  : dayjs(new Date()).format('YYYY-MM-DD')
      this.document.documentInvoiceEndDate = this.endDate ? this.endDate : dayjs(new Date()).add(15, 'days').format('YYYY-MM-DD')
      let docName = ''
      if (this.documentAna.documentName === 'POS') {
        docName = 'Kasa '
      }
      if (this.documentAna.documentName === 'InvoiceSales') {
        docName = 'Fakture '
      }
      this.document.documentDescription4 = docName + this.localDate(this.document.documentInvoiceStartDate) + ' - ' + this.localDate(this.document.documentInvoiceEndDate)

    },
    async priceChangeCreate() {
      if (this.$store.state.companyid) {
        this.documentAna.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.documentAna.BusinessYear = this.$store.state.businessYear
      }
      this.documentAna.docFromDate = this.startDates
      this.documentAna.docToDate = this.endDate
      this.documentDetails = []

      await DocumentDetailService.salesana(this.documentAna)
      .then(async(res) => {
        this.itemDetails = res.data.document ? res.data.document : []
        if (this.itemDetails) {
          await this.fillDoc()

          await DocumentService.post(this.document)
            .then(async (response) => {
              
              if (response.data.newDoc) {
                this.documentID = response.data.newDoc.id
                this.itemDetails.map(item=> {
                  if (item.itemDiscountAmount1 > 0) {
                    this.documentDet = defDocumentDetail()
                    this.documentDet = {...this.documentDet, ...defNewDocumentDetail()}
                    this.documentDet.databaseDocID = response.data.newDoc.id
                    this.documentDet.CompanyId = this.document.CompanyId
                    this.documentDet.CompanyNameDatabase = this.document.CompanyNameDatabase
                    this.documentDet.BusinessYear = this.document.BusinessYear
                    this.documentDet.UserId = this.document.UserId
                    this.documentDet.email = this.document.email
                    this.documentDet.documentName = this.document.documentName
                    this.documentDet.documentId = parseInt(this.document.documentId)
                    this.documentDet.documentIdGeneral = this.document.documentIdGeneral
                    this.documentDet.documentDate = this.document.documentDate
                    this.documentDet.documentAnalysis = 0
                    this.documentDet.partnerId = this.document.partnerId
                    this.documentDet.partnerNameDatabase = this.document.partnerNameDatabase
                    this.documentDet.HomeCurrency = this.document.documentHomeCurrency
                    this.documentDet.ForeignCurrency = this.document.documentForeignCurrency
                    this.documentDet.ExRate = parseFloat(this.document.documentExRate)
                    this.documentDet.ItemHomeCurrency = this.document.documentHomeCurrency
                    this.documentDet.ItemForeignCurrency = this.document.documentForeignCurrency
                    this.documentDet.ItemExRate = parseFloat(this.document.documentExRate)
                    this.documentDet.documentSide = this.document.documentSide
                    this.documentDet.documentAnalysis = this.document.documentAnalysis
                         
                    if (this.documentAna.documentName === 'POS') {
                      this.documentDet.itemPcsNeutral = item.itemPcsMinus
                    }
                    if (this.documentAna.documentName === 'InvoiceSales') {
                      this.documentDet.itemPcsNeutral = item.itemPcsNeutral
                    }
                    this.documentDet.itemDiscountPercentage1 = 0
                    this.documentDet.itemID = item.itemID
                    this.documentDet.itemName = item.itemName
                    this.documentDet.ItemNameShort = item.ItemNameShort
                    this.documentDet.ItemCode = item.ItemCode
                    this.documentDet.ItemEANCode = item.ItemEANCode
                    this.documentDet.itemGroup = item.itemGroup
                    this.documentDet.itemDescription = item.itemDescription
                    this.documentDet.itemUnit = item.itemUnit
                    this.documentDet.ItemSize = item.ItemSize

                    this.documentDet.itemDirectDuty1 = item.itemDirectDuty1 ? this.round(item.itemDirectDuty1, 2) : 0
                    this.documentDet.itemVAT1Percentage = item.itemVAT1Percentage ? this.round(item.itemVAT1Percentage, 2) : 0
                    this.documentDet.itemPrice = item.itemPrice ? this.round(item.itemPrice, 3) : 0
                    this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPcsNeutral * this.documentDet.itemPrice), 3)
                  
                    this.documentDet.itemBaseAmountNoVAT = this.documentDet.itemBaseAmount
                    this.documentDet.itemVAT1Amount = (this.documentDet.itemBaseAmount * (1 + (this.documentDet.itemVAT1Percentage / 100))) - this.documentDet.itemBaseAmount
                    this.documentDet.itemVAT1Amount = this.round(this.documentDet.itemVAT1Amount, 2)

                    this.documentDet.itemAmountWithVAT = this.round((this.documentDet.itemBaseAmount + this.documentDet.itemVAT1Amount), 2)
                    this.documentDet.itemPriceWithVAT = this.documentDet.itemPcsNeutral !== 0 ? this.round(this.documentDet.itemAmountWithVAT / this.documentDet.itemPcsNeutral , 3) : 0

                    this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1 * this.documentDet.itemPcsNeutral), 2)
                    this.documentDet.itemFullTotal = (this.documentDet.itemAmountWithVAT + this.documentDet.itemDirectDuty1Amount)
                    this.documentDet.itemFullTotal = this.round(this.documentDet.itemFullTotal, 2)

                    this.documentDet.itemDiscountAmount1 = this.round(item.itemDiscountAmount1 ,2)
                    

                    this.documentDet.newitemDirectDuty1 = item.itemDirectDuty1 ? this.round(item.itemDirectDuty1, 2) : 0
                    this.documentDet.newitemVAT1Percentage = item.itemVAT1Percentage ? this.round(item.itemVAT1Percentage, 2) : 0

                    if (this.documentAna.documentName === 'POS') {
                      this.documentDet.newitemBaseAmount = this.round(item.itemBaseAmountNoVAT, 2)
                      this.documentDet.newitemFullTotal = this.round(item.itemAmountWithVAT, 2)
                      this.documentDet.newitemVAT1Amount = this.round(this.documentDet.newitemFullTotal - this.documentDet.newitemBaseAmount, 2)
                      this.documentDet.newitemDirectDuty1 = this.documentDet.itemDirectDuty1 
                      this.documentDet.newitemPriceWithVAT = this.documentDet.itemPcsNeutral !== 0 ? this.round(this.documentDet.newitemAmountWithVAT / this.documentDet.itemPcsNeutral , 3) : 0
                      this.documentDet.newitemPrice = (this.documentDet.newitemPriceWithVAT * (1 + (this.documentDet.newitemVAT1Percentage / 100)))
                      this.documentDet.newitemPrice = this.round(this.documentDet.newitemPrice, 2)
                    }

                    if (this.documentAna.documentName === 'InvoiceSales') {
                      this.documentDet.newitemPrice = item.itemDiscountPrice ? this.round(item.itemDiscountPrice, 3) : 0

                      this.documentDet.newitemBaseAmount = this.round((this.documentDet.itemPcsNeutral * this.documentDet.newitemPrice), 3)
                    
                      this.documentDet.newitemVAT1Amount = (this.documentDet.newitemBaseAmount * (1 + (this.documentDet.newitemVAT1Percentage / 100))) - this.documentDet.newitemBaseAmount
                      this.documentDet.newitemVAT1Amount = this.round(this.documentDet.newitemVAT1Amount, 2)

                      this.documentDet.newitemAmountWithVAT = this.round((this.documentDet.newitemBaseAmount + this.documentDet.newitemVAT1Amount), 2)
                      this.documentDet.newitemPriceWithVAT = this.documentDet.itemPcsNeutral !== 0 ? this.round(this.documentDet.newitemAmountWithVAT / this.documentDet.itemPcsNeutral , 3) : 0

                      this.documentDet.newitemFullTotal = (this.documentDet.newitemAmountWithVAT) + this.round((item.newitemDirectDuty1 * this.documentDet.itemPcsNeutral), 2)
                      this.documentDet.newitemFullTotal = this.round(this.documentDet.newitemFullTotal, 2)
                    }

                    this.documentDetails.push(this.documentDet)
                  }
                })
              }
              if (this.documentDetails) {
                console.log("🚀 ~ file: DocumentSalesAnalysis.vue ~ line 1246 ~ .then ~ this.documentDetails", this.documentDetails)
                
                await DocumentDetailService.saveitems(this.documentDetails)
                this.makeSumPriceChange()
              }
            })          
            .catch(err => {
              // eslint-disable-next-line
              console.log(err)
            })
          
        }
      })
      .catch((err) => {
        this.itemDetails = []
        // eslint-disable-next-line
        console.log(err)
      })
    },
    async makeSumPriceChange() {
        const exRate = this.$store.getters.NeedDefExRate
        const updateSumObject = {
          id: this.documentID,
          documentDescription9: 0,
          documentDescription10: 0,
          documentTotalVAT1Per: 0,
          documentTotalVAT1Base: 0,
          documentTotalVAT1Amount: 0,
          documentTotalVAT2Per: 0,
          documentTotalVAT2Base: 0,
          documentTotalVAT2Amount: 0,
          documentTotalVAT3Per: 0,
          documentTotalVAT3Base: 0,
          documentTotalVAT3Amount: 0,
          documentTotalVAT4Per: 0,
          documentTotalVAT4Base: 0,
          documentTotalVAT4Amount: 0,
          documentTotalVAT5Per: 0,
          documentTotalVAT5Base: 0,
          documentTotalVAT5Amount: 0,
          documentTotalAmountNetto: 0,
          documentTotalAmountVAT: 0,
          documentTotalAmountBrutto: 0,
          documentTotalAmountPayInAdvance: 0,
          documentTotalAmountToPay: 0,
          documentTotalAmountNettoForeign: 0,
          documentTotalAmountVATForeign: 0,
          documentTotalAmountBruttoForeign: 0,
          documentTotalAmountPayInAdvanceForeign: 0,
          documentTotalAmountToPayForeign: 0,
          documentTotalAmountDirectD1: 0,
          documentTotalAmountDirectD2: 0,
          documentTotalAmountDirectD1Fore: 0,
          documentTotalAmountDirectD2Fore: 0,
          FreeF1: 0,
          FreeF2: 0,
          FreeF3: 0,
          FreeF4: 0,
          FreeF5: 0,
          FreeF6: 0,
          documentHomeCurrency: 'Kn',
          documentForeignCurrency: 'EUR',
          documentExRate: exRate,
          newBaseAmount: 0,
          newVAT1Amount: 0,
          newAmountWithVAT: 0,
          newDirectDuty1: 0,
          newFullTotal: 0,
        }
        
        const hrVat1 = 25
        const hrVat2 = 13
        const hrVat3 = 5
        const documentsDet = this.documentDetails ? this.documentDetails : []
        if (documentsDet) {
          documentsDet.map(documentDet => {
            if (documentDet.itemBaseAmount) {
              updateSumObject.documentDescription9 += parseFloat(documentDet.itemBaseAmount)
            }
            if (documentDet.itemBaseAmountNoVAT) {
              updateSumObject.documentTotalAmountNetto += parseFloat(documentDet.itemBaseAmountNoVAT)
            }
            if (documentDet.itemVAT1Amount) {
              updateSumObject.documentTotalAmountVAT += parseFloat(documentDet.itemVAT1Amount)
            }
            const dD1 = documentDet.itemDirectDuty1Amount ? parseFloat(documentDet.itemDirectDuty1Amount) : 0
            if (documentDet.itemAmountWithVAT) {
              updateSumObject.documentTotalAmountBrutto += parseFloat(documentDet.itemAmountWithVAT) + dD1
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat1) {
              updateSumObject.documentTotalVAT1Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat2) {
              updateSumObject.documentTotalVAT2Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (parseFloat(documentDet.itemVAT1Percentage) === hrVat3) {
              updateSumObject.documentTotalVAT3Amount += parseFloat(documentDet.itemVAT1Amount)
            }
            if (documentDet.itemBaseAmount) {
              if (parseInt(documentDet.FreeF1) === 1) {
                updateSumObject.FreeF1 += parseFloat(documentDet.itemBaseAmount)
              }
              if (parseInt(documentDet.FreeF1) === 2) {
                updateSumObject.FreeF2 += parseFloat(documentDet.itemBaseAmount)
                updateSumObject.FreeF5 += parseFloat(documentDet.empWrkHrs)
              }
              if (parseInt(documentDet.FreeF1) === 3) {
                updateSumObject.FreeF3 += parseFloat(documentDet.itemBaseAmount)
              }
            }
            if (documentDet.itemBaseAmountNoVAT) {
              if (parseInt(documentDet.FreeF1) === 4) {
                updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmountNoVAT)
              }
            }
            if (documentDet.itemDirectDuty1Amount) {
              updateSumObject.documentTotalAmountDirectD1+= parseFloat(documentDet.itemDirectDuty1Amount)
            }
            if (documentDet.newitemBaseAmount) {
              updateSumObject.newBaseAmount+= parseFloat(documentDet.newitemBaseAmount)
            }
            if (documentDet.newitemVAT1Amount) {
              updateSumObject.newVAT1Amount+= parseFloat(documentDet.newitemVAT1Amount)
            }
            if (documentDet.newitemAmountWithVAT) {
              updateSumObject.newAmountWithVAT+= parseFloat(documentDet.newitemAmountWithVAT)
            }
            if (documentDet.newitemDirectDuty1) {
              updateSumObject.newDirectDuty1+= parseFloat(documentDet.newitemDirectDuty1)
            }
            if (documentDet.newitemFullTotal) {
              updateSumObject.newFullTotal+= parseFloat(documentDet.newitemFullTotal)
            }
          })
        }
        updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
        updateSumObject.documentTotalAmountNetto = updateSumObject.documentTotalAmountNetto ? this.round(updateSumObject.documentTotalAmountNetto, 2) : 0
        updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalVAT1Amount = updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount, 2) : 0
        updateSumObject.documentTotalVAT2Amount = updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount, 2) : 0
        updateSumObject.documentTotalVAT3Amount = updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount, 2) : 0
        updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
        updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
        updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0
        updateSumObject.documentTotalVAT1Base = hrVat1 && updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount / (hrVat1/100), 2) : 0
        updateSumObject.documentTotalVAT2Base = hrVat2 && updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount / (hrVat2/100), 2) : 0
        updateSumObject.documentTotalVAT3Base = hrVat3 && updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount / (hrVat3/100), 2) : 0
        updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
        updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
        updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
        updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
        updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
        updateSumObject.documentDescription10 = updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto
        updateSumObject.documentTotalAmountPayInAdvance = 0
        updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
        updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / exRate), 2) : 0
        updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / exRate), 2) : 0
        updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountPayInAdvanceForeign = 0
        updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
        updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / exRate), 2) : 0
        updateSumObject.documentHomeCurrency = 'Kn'
        updateSumObject.documentForeignCurrency = 'EUR'
        updateSumObject.documentExRate =  this.$store.getters.NeedDefExRate
        updateSumObject.newBaseAmount = updateSumObject.newBaseAmount ? this.round(updateSumObject.newBaseAmount, 2) : 0
        updateSumObject.newVAT1Amount = updateSumObject.newVAT1Amount ? this.round(updateSumObject.newVAT1Amount, 2) : 0
        updateSumObject.newAmountWithVAT = updateSumObject.newAmountWithVAT ? this.round(updateSumObject.newAmountWithVAT, 2) : 0
        updateSumObject.newDirectDuty1 = updateSumObject.newDirectDuty1 ? this.round(updateSumObject.newDirectDuty1, 2) : 0
        updateSumObject.newFullTotal = updateSumObject.newFullTotal ? this.round(updateSumObject.newFullTotal, 2) : 0
        await DocumentService.put(updateSumObject)
        this.text = this.lang.pricechangeCreatedOK
        this.color = 'green'
        this.snackbar = true
    },
    downloadFile () {

      this.topHeader = this.documentAna.documentName + this.$store.state.businessYear + this.lang.ReportCreated + dayjs().format('DD.MM.YYYY HH:MM')

      const final2 = [...this.items]
      if (this.typeAna === 1) {
        const newObj = {}
        newObj.documentId = ''
        newObj.documentName = ''
        newObj.documentDate = ''
        newObj.partnerName = ''
        newObj.email = ''
        newObj.documentTotalAmountNetto = this.Total1
        newObj.documentTotalAmountVAT = this.Total2
        newObj.documentTotalAmountBrutto = this.Total3
        newObj.Discount = this.TotalFullDiscount
        newObj.Diff = this.TotalFullDiff
        newObj.Services = this.TotalFullServices
        newObj.documentTotalAmountBruttoForeign = this.Total6
        final2.push(newObj)
        const newObj2 = {}
        newObj2.documentId = ''
        newObj2.documentName = ''
        newObj2.documentDate = ''
        newObj2.partnerName = ''
        newObj2.email = 'Roba'
        newObj2.documentTotalAmountNetto = this.Total3 - this.TotalFullServices
        newObj2.documentTotalAmountVAT = ''
        newObj2.documentTotalAmountBrutto = ''
        newObj2.Discount = ''
        newObj2.Diff = ''
        newObj2.Services = ''
        newObj2.documentTotalAmountBruttoForeign = ''
        final2.push(newObj2)
      }

      let sheetNm = ''
      let fileNm = ''
      const currDate = dayjs().format('DD_MM_YYYY_HH_MM')
   
      sheetNm = this.documentAna.documentName.substr(0,14) + '_' + currDate 
      fileNm = this.documentAna.documentName + '_' + currDate 
      let data = []
      if (this.typeAna === 1) {
      const newIT = {}
          newIT.sheet = sheetNm,
          newIT.columns = [
            { label: this.lang.documentId, value: 'documentId'},  
            { label: this.lang.Document, value: 'documentName'},
            { label: this.lang.documentDate1, value: 'documentDate'},
            { label: this.lang.partnerName, value: 'partnerName' },
            { label: 'email', value: 'email' },
            { label: this.lang.documentTotalAmountNetto , value: 'documentTotalAmountNetto' },
            { label: this.lang.documentTotalAmountVAT, value: 'documentTotalAmountVAT' },
            { label: this.lang.documentTotalAmountBrutto, value: 'documentTotalAmountBrutto' },
            { label: this.lang.TotalDiscount, value: 'Discount' },
            { label: this.lang.TotalDiff, value: 'Diff' },
            { label: this.lang.TotalServices, value: 'Services' },
            { label: this.lang.documentTotalAmountBruttoForeign, value: 'documentTotalAmountBruttoForeign' },
          ],
          newIT.content =  final2
          data.push(newIT)
      }
      
      
      let settings = {
        fileName: fileNm, // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
      }

      xlsx(data, settings)

    },
    setNull() {
      this.typeAna = 0
      this.workOrder = false
      this.workOrder2 = false
      this.workOrder2Type = null
      this.invoiceSet = false
      this.invoice2Set = false
      this.haveFilter = false
    }
  },
  watch: {
    'selectIL.itemID' () {
      if (!this.selectIL) {
        this.setNull()
      }
    },
    'selectGroup' () {
      console.log('this.selectGroup', this.selectGroup)
      if (!this.selectGroup) {
        this.selectGroup = {}
        this.setNull()
      }
    },
   'workOrder2Type' () {
      if (!this.workOrder2Type) {
        this.setNull()
      }
   },
   'invoiceTypeSet' () {
      if (!this.invoiceTypeSet) {
        this.setNull()
      }
   },
   'documentAna.documentName' () {
      if (!this.documentAna.documentName) {
        this.setNull()
      }
   },
   'selectedMonth' () {
     if  (this.selectedMonth) {
       let y = this.$store.state.businessYear
       let m = this.selectedMonth - 1;
       let firstDay = new Date(y, m, 1);
       let lastDay = new Date(y, m + 1, 0);

      this.startDate = dayjs(firstDay).format('YYYY-MM-DD')
      this.endDate = dayjs(lastDay).format('YYYY-MM-DD')

     }
    if (!this.selectedMonth) {
      this.startDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
     }
   },
   'selectPT' (val) {
     console.log(val)
      if (val) {
        this.documentAna.productiontype = val ? val : null
      }      
      if (!val) {
        this.documentAna.productiontype = null
      } 
      console.log({...this.documentAna})
    },
  },
  components: {
    appDocumentWarehouseState: DocumentWarehouseState,
    DocumentSalesPrintAna,
    DocumentSalesAnaListWO,
    DocumentSalesAnaListWO_2,
    DocumentSalesAnaListInv
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>