<template>
<div>
  <v-container fluid grid-list-md>

  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="users"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="50"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Kase</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">Kasa</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row dense>
                    <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-1">
                     <v-text-field
                        dense
                        label="Kasa (broj) *"
                        v-model="editedItem.BranchId"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pr-1">
                      <v-text-field
                        dense
                        label="Naziv *"
                        v-model="editedItem.Objekat"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                     <v-text-field
                        dense
                        label="Ulica"
                        v-model="editedItem.ObjekatUlica"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                      <v-text-field
                        dense
                        label="Broj"
                        v-model="editedItem.ObjekatUlicaBroj"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                     <v-text-field
                        dense
                        label="Pošta"
                        v-model="editedItem.ObjekatPosta"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-text-field
                        dense
                        label="Grad"
                        v-model="editedItem.ObjekatGrad"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                   <v-row dense>
                    <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-1">
                     <v-text-field
                        dense
                        label="Država"
                        v-model="editedItem.ObjekatDrzava"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-text-field
                        dense
                        label="email"
                        v-model="editedItem.ObjekatEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-1">
                      <v-text-field
                        dense
                        label="Telefon"
                        v-model="editedItem.ObjekatTelefon"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Odustani</v-btn>
                    <v-btn color="success" text @click="save">Spremi</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>


    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      Nema definiranih kasa
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import BetonObjekatService from '@/services/BetonObjekatService'
import dateFormat from '@/snippets/dateFormat'

  export default {
    name: 'betonkorisnikCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      modal1: false,
      dialog1: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {},
      dialog: false,
      headers: [],
      users: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        CompanyId: 1,
        BranchId: 0,
        Objekat: '',
        ObjekatUlica: '',
        ObjekatUlicaBroj: '',
        ObjekatGrad: '',
        ObjekatPosta: '',
        ObjekatDrzava: '',
        ObjekatEmail: '',
        ObjekatTelefon: '',
        TvrtkaID: 0,
        Tvrtka: '',
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: '',
        FreeF6: '',
      },
      privitems: [],
      valid: true,
      tvrtke: [],
      selectTvrtka: {}
    }),

    computed: {

    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'selectTvrtka' () {
        if (this.selectTvrtka) {
          this.editedItem.TvrtkaID = this.selectTvrtka.TvrtkaID ? this.selectTvrtka.TvrtkaID : null
          this.editedItem.Tvrtka = this.selectTvrtka.Tvrtka ? this.selectTvrtka.Tvrtka : null
        }
        if (!this.selectTvrtka) {
          this.editedItem.TvrtkaID = null
          this.editedItem.Tvrtka = null
        }
      }
    },
    components: {

    },

    async mounted() {
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.headers.push(
        {text: 'Kasa ID', value: 'BranchId', width: '50px', sortable: true},
        {text: 'Objekat', value: 'Objekat', width: '160px'},
        {text: 'Ulica', value: 'ObjekatUlica', width: '130px', sortable: true},
        {text: 'Broj', value: 'ObjekatUlicaBroj', width: '80px', sortable: true},
        {text: 'Pošta', value: 'ObjekatPosta', width: '80px', sortable: true},
        {text: 'Grad', value: 'ObjekatGrad', width: '120px', sortable: true},
        {text: 'email', value: 'ObjekatEmail', width: '120px', sortable: true},
        {text: 'Telefon', value: 'ObjekatTelefon', width: '120px', sortable: true},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )
    },

    created () {
      this.initialize()
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
          const {data} = await BetonObjekatService.index(this.mainQuery)
          if (data && data.betonobjekat !== undefined){
            this.users = data.betonobjekat
          }
          this.saving = false
          this.errorMatch = false
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        } catch (err) {
          this.error = err
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.users.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      async deleteItem (item) {
        try {
          
          const index = this.users.indexOf(item)
          const warningMessage = item.Ime + ' ' + item.Prezime
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.users.splice(index, 1)) {
            await BetonObjekatService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
        }
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           if (!this.editedItem.BranchId) {
             alert('GREŠKA!! Nije upisan ID kase!')
             return
           }
           if (!this.editedItem.Objekat) {
             alert('GREŠKA!! Nije upisan naziv kase!')
             return
           }

           let doneOK = false
           this.editedItem.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
           this.editedItem.LastUpdate = new Date()
           console.log('this.editedIndex', this.editedIndex)
           if (this.editedIndex > -1) {
              await BetonObjekatService.put(this.editedItem)
              doneOK = true
            } else {
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
              await BetonObjekatService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      }
    },
  }
</script>