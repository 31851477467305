<template>
  <v-container fluid>
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          {{lang.itemCard}}:  {{itemName}}
          <v-btn
            text color="primary"
            @click="printAna()">
            {{langC.Print}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template v-slot:[`item.documentDate`]="{ item }">
              <span>{{ localDate(item.documentDate) }}</span>
          </template>
          <template v-slot:[`item.itemPcsMinus`]="{ item }">
              <span>{{ localMoney(item.itemPcsMinus) }}</span>
          </template>
          <template v-slot:[`item.itemPcsPlus`]="{ item }">
              <span>{{ localMoney(item.itemPcsPlus) }}</span>
          </template>
          <template v-slot:[`item.itemState`]="{ item }">
              <span>{{ localMoney(item.itemState) }}</span>
          </template>
           <template v-slot:[`item.itemPriceWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemPriceWithVAT) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
          <v-card-text>
            {{lang.itemTotalPlus}} {{totalPcsPlus}},{{lang.itemTotalMinus}} {{totalPcsMinus}}, {{lang.itemTotal}} {{totalPcs}} <br />
            <span v-if="totalSupp"> {{lang.ItemSuppPriceAmount}}: {{localMoney(totalSupp)}},</span>
            <span v-if="totalMargin"> {{lang.ItemMarginAmount}}: {{localMoney(totalMargin)}},</span>
            <span v-if="totalNetto"> {{lang.ItemBaseAmountNoVAT}}: {{localMoney(totalNetto)}},</span>
            <span v-if="totalVAT"> {{lang.ItemVAT1Amount}}: {{localMoney(totalVAT)}},</span> 
            <span v-if="totalTotal"> {{lang.ItemFullTotal}}: {{localMoney(totalTotal)}}</span> {{defCurr}}
          </v-card-text>
      </v-card>
      <v-row>
        <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10">
          <DocumentPrintItemCard ref="prnt" />
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
// import axios from 'axios'
import DocumentService from '@/services/DocumentService'
import DocumentPrintItemCard from './DocumentPrintItemCard'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import documentLocalName from '@/snippets/documentLocalName'
import documentCurrency from '@/snippets/documentCurrency'
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'
import dayjs from 'dayjs'

export default {
  name: 'documentitemcard',
  data () {    
    return {
      items: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      totalPcsPlus: 0,
      totalPcsMinus: 0,
      totalPcs: 0,
      totalSupp: 0,
      totalMargin: 0,
      totalNetto: 0,
      totalVAT: 0,
      totalDirect: 0, 
      totalTotal: 0,
      defCurr: 'Kn',
      itemState: 0,
      itemName: ''
    }
  },
  
  async mounted () {
    try {
        // do analysis from database
        if (this.$store.state.language === 'EN') {
          this.lang = langEn
          this.langC = commEn
        }
        if (this.$store.state.language === 'HR') {
          this.lang = langHr
          this.langC = commHr
        }
        this.defCurr = documentCurrency()
        this.headers.push(
          {text: this.lang.documentDate1, value: 'documentDate', width: '110px'},
          {text: this.lang.documentId, value: 'documentId', width: '80px'},
          {text: this.langC.DocumentName, value: 'documentName', width: '80px'},
          {text: this.lang.partnerName, value: 'partnerNameDatabase', width: '150px'},
          {text: this.lang.ItemPcsPlus, value: 'itemPcsPlus', type: 'decimal', width: '100px'},
          {text: this.lang.ItemPcsMinus, value: 'itemPcsMinus', type: 'decimal', width: '100px'},
          {text: this.lang.itemState, value: 'itemState', type: 'decimal', width: '100px'},
          {text: this.lang.ItemSuppBasePrice, value: 'itemSuppBasePrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemSuppDiscount, value: 'itemSuppDiscount', type: 'decimal', width: '60px'},
          {text: this.lang.ItemSuppPrice, value: 'itemSuppPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemMargin, value: 'itemMargin', type: 'decimal', width: '60px'},
          {text: this.lang.ItemPrice, value: 'itemPrice', type: 'decimal', width: '100px'},
          {text: this.lang.ItemPriceWithVAT, value: 'itemPriceWithVAT', type: 'decimal', width: '100px'},
          {text: this.lang.TotalPlus, value: 'TotalPlus', type: 'decimal', width: '100px'},
          {text: this.lang.id, value: 'id', width: '50px'}
        )
        
        // do analysis from database
        this.localError = false
        this.error = null
        this.items = []
        this.documentAna.CompanyId = this.$store.state.documentAna.CompanyId ? this.$store.state.documentAna.CompanyId : 0
        this.documentAna.BusinessYear = this.$store.state.documentAna.BusinessYear ? this.$store.state.documentAna.BusinessYear : 2020
        this.documentAna.itemID = this.$store.state.documentAna.itemID ? this.$store.state.documentAna.itemID : ''
        this.documentAna.docFromDate = this.$store.state.documentAna.docFromDate ? this.$store.state.documentAna.docFromDate : dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
        this.documentAna.docToDate = this.$store.state.documentAna.docToDate ? this.$store.state.documentAna.docToDate : dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
        this.saving = true
        const tempItems = (await DocumentService.documentitemcard(this.documentAna)).data.document
        this.saving = false
        let iid = 0
        this.totalPcs = 0
        this.totalSupp = 0
        this.totalMargin = 0
        this.totalNetto = 0
        this.totalVAT = 0
        this.totalDirect = 0
        this.totalTotal = 0

        if (tempItems) {
          tempItems.map(item => {
            const newEntry = {}
              newEntry.itemID = item.itemID ? item.itemID : ''
              newEntry.itemName = item.itemName ? item.itemName : ''
              this.itemName = newEntry.itemName
              newEntry.documentDate = item.documentDate ? item.documentDate : ''
              newEntry.documentName = item.documentName ? documentLocalName(item.documentName): ''
              newEntry.documentId = item.documentId ? item.documentId : ''
              newEntry.partnerNameDatabase = item.partnerNameDatabase ? item.partnerNameDatabase : ''
              newEntry.itemPcsPlus = item.itemPcsPlus ? item.itemPcsPlus : null
              newEntry.itemPcsMinus = item.itemPcsMinus ? item.itemPcsMinus : null
              newEntry.itemSuppBasePrice = item.itemSuppBasePrice ? item.itemSuppBasePrice : ''
              newEntry.itemSuppDiscount = item.itemSuppDiscount ? item.itemSuppDiscount : ''
              newEntry.itemSuppPrice = item.itemSuppPrice ? item.itemSuppPrice : ''
              newEntry.itemMargin = item.itemMargin ? item.itemMargin : ''
              newEntry.itemPrice = item.itemPrice ? item.itemPrice : ''
              newEntry.itemPriceWithVAT = item.itemPriceWithVAT ? item.itemPriceWithVAT : ''
              if (item.itemPcsPlus) {                
                this.totalPcsPlus += parseFloat(item.itemPcsPlus)
                newEntry.TotalPlus = this.totalPcsPlus
              } 
              if (item.itemPcsMinus) {                
                this.totalPcsMinus += parseFloat(item.itemPcsMinus)
                newEntry.TotalMinus = this.totalPcsMinus
              }
              newEntry.itemState = this.totalPcsPlus - this.totalPcsMinus    
              if (item.itemSuppPriceAmount) {
                this.totalSupp += parseFloat(item.itemSuppPriceAmount)
              }
              if (item.itemMarginAmount ) {
                this.totalMargin += parseFloat(item.itemMarginAmount)
              }
              if (item.itemBaseAmountNoVAT) {
                this.totalNetto += parseFloat(item.itemBaseAmountNoVAT)
              }
              if (item.itemVAT1Amount) {
                this.totalVAT += parseFloat(item.itemVAT1Amount)
              }
              if (item.itemFullTotal) {
                this.totalTotal += parseFloat(item.itemFullTotal)
              }
              iid += 1
              newEntry.id = iid
              this.items.push(newEntry)
            
          })
          this.totalPcs = this.totalPcsPlus - this.totalPcsMinus
        }
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }      
  },
  methods: {     
    navigateTo () {
    },
     round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
     async printAna() {
      const newObj = {}
      this.ana = {...newObj}
      this.ana = {...this.documentAna}
      this.ana.items = this.items ? this.items : []
      this.ana.head1 = this.lang.itemCard + ': ' + this.itemName
      this.ana.head2 = ''
      this.ana.head3 = ''
      this.ana.head4 = ''
      this.ana.head5 = ''
      this.ana.type = 1
      this.ana.Total1 = this.lang.itemTotalPlus + this.totalPcsPlus + ', ' +this.lang.itemTotalMinus + ':' + this.totalPcsMinus + ', ' +  this.lang.itemTotal + ': ' + this.totalPcs
      this.ana.Total2 = this.totalSupp ? this.lang.ItemSuppPriceAmount + ': ' + this.localMoney(this.totalSupp) : ''
      this.ana.Total3 = this.totalMargin ? this.lang.ItemMarginAmount + ': ' + this.localMoney(this.totalMargin) : ''
      this.ana.Total4 = this.totalNetto ? this.lang.ItemBaseAmountNoVAT + ': ' + this.localMoney(this.totalNetto) : ''
      this.ana.Total5 = this.totalVAT ? this.lang.ItemVAT1Amount + ': ' + this.localMoney(this.totalVAT)  : ''
      this.ana.Total6 = this.totalTotal ? this.lang.ItemFullTotal + ': ' + this.localMoney(this.totalTotal) + ' ' + this.defCurr : ''

      await this.$store.dispatch('setPrintObj', this.ana)
      await this.$refs.prnt.print()
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
    DocumentPrintItemCard
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


