<template>
  <v-container>
    <v-row dense>
      <v-flex xs12 sm12 md10 lg8 xl8>
        <v-card>
        <v-card-title>
          Select company
          <v-spacer></v-spacer>
          <v-text-field
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            v-model="search"
          ></v-text-field>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"    
            :mobile-breakpoint="0"        
          >
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
          <template v-slot:[`item.CompanyNameDatabase`]="{ item }">
            <span class="title indigo--text">{{item.CompanyNameDatabase }}</span>
          </template>
        </v-data-table>
      </v-card>

      <v-snackbar
        v-model="snackbar"
        bottom
        :color="color"
        >
        {{ text }}
      </v-snackbar>
       <v-alert type="warning" v-model="havemssgalert" dismissible close-text="Close Alert">
          {{havemssgalert}}
      </v-alert>

      </v-flex>
    </v-row>
     <div v-if="showDialog">
      <JustWait :message="message" :showDialog="showDialog" />
    </div>
  </v-container>
</template>

<script>
import CompService from '@/services/CompService'
import CompUserService from '@/services/CompUserService'
import CompBusinessYearService from '@/services/CompBusinessYearService'
import ItemService from '@/services/ItemService'
import GroupService from '@/services/GroupService'
import Account4Service from '@/services/Account4Service'
import EmployeeService from '@/services/EmployeeService'
import MainLedgerProjectTypeService from '@/services/MainLedgerProjectTypeService'
import PartnerService from '@/services/PartnerService'
import {dynamicSort} from '@/snippets/allSnippets'
import JustWait from '../../snippets/JustWait'

export default {
  name: 'companyselect',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      error: '',
      pagination: {},
      rowsPerPageItems: [50],
      columns: [],
      rows: [],
      headers: [],
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        email: ''
      },
      mainQuery2: {
        CompanyId: 0
      },
      defaultCompanyId: 0,
      currentYear: 0,
      companyBY: [],
      havemssgalert: false,
      message: '',
      showDialog: false
    }
  },
  async mounted () {
    try {
      if (this.$store.state.user.email) {
        this.mainQuery.email = this.$store.state.user.email
        this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        this.items = (await CompUserService.choose(this.mainQuery)).data.companyUser

        this.items.sort(dynamicSort("CompanyNameDatabase"))
        if (this.$store.state.language === 'EN') {
          this.message = 'Please wait...'
          this.headers.push(
            {text: 'Company name', value: 'CompanyNameDatabase', width: '300px'},
            {text: 'email', value: 'email', width: '150px'},
            {text: 'Company Id', value: 'CompanyId', type: 'number', width: '50px'},
            {text: 'DbId', value: 'id', type: 'number', width: '50px'}
          )
        }
        if (this.$store.state.language === 'HR') {
          this.message = 'Molim pričekajte...'
          // console.log(' radim push 2 hr naziva ', this.$store.state.language)
          this.headers.push(
            {text: 'Naziv firme', value: 'CompanyNameDatabase', width: '300px'},
            {text: 'email', value: 'email', width: '150px'},
            {text: 'ID broj firme', value: 'CompanyId', type: 'number', width: '50px'},
            {text: 'DbId', value: 'id', type: 'number', width: '50px'}
          )
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    async navigateTo (params) {
      try {
        this.showDialog = true
        await this.$store.dispatch('setDocumentActiv', null)
        await this.$store.dispatch('setDocumentActivLoc', null)
        await this.$store.dispatch('setDocumentSide', null)
        await this.$store.dispatch('setCompanyName', null)
        await this.$store.dispatch('setCompanyid', null)
        await this.$store.dispatch('setCompanyActiv', null)
        await this.$store.dispatch('setActivItems', null)
        await this.$store.dispatch('setActivGroups', null)
        await this.$store.dispatch('setActivEmployes', null)
        await this.$store.dispatch('setAccount4s', null)
        await this.$store.dispatch('setProjectTypes', null)

        const locCompSelect = (await CompService.show(params.CompanyId)).data.company
        this.$store.dispatch('setCompanyid', locCompSelect.id)
        this.$store.dispatch('setCompanyName', locCompSelect.CompanyNameDatabase)
        this.$store.dispatch('setCompanyActiv', locCompSelect)

        this.text = 'Selected company: ' + locCompSelect.CompanyNameDatabase
        this.color = 'green'
        this.snackbar = true
        this.timeout = 2000
        this.mainQuery2.CompanyId = locCompSelect.id
        this.currentYear = new Date().getFullYear()
        this.companyBY = (await CompBusinessYearService.choose(this.mainQuery2)).data.companyBY
        if (this.companyBY) {
          this.companyBY.forEach(compBY => {
            if (compBY.defaultBusinessYear) {
              this.currentYear = compBY.BusinessYear
              this.$store.dispatch('setBusinessYear', this.currentYear)
            }
          })
        } else {
          this.text = 'Business Year is NOT set for these company! Default current year!'
          this.color = 'red'
          this.snackbar = true          
        }
        await ItemService.index(this.mainQuery2).then(res => {
          if (res.data.items) {
            this.$store.dispatch('setDateItems', new Date())
            this.$store.dispatch('setActivItems', res.data.items)
          }
        })
        .catch(err => {
          this.havealert = true
          this.havemssgalert = 'Error on init Items ' + err
        })

        await GroupService.index(this.mainQuery2).then(res => {
          if (res.data.groups) {
            this.$store.dispatch('setActivGroups', res.data.groups)

          }
        })
        .catch(err => {
          // this.havealert = !this.havealert
          this.havealert = true
          this.havemssgalert = 'Error on init Groups ' + err
        })

        await Account4Service.index(this.mainQuery2).then(res => {
          if (res.data.account4s) {
            this.$store.dispatch('setDateAccounts', new Date())
            this.$store.dispatch('setAccount4s', res.data.account4s)
          }
        })
        .catch(err => {
          // eslint-disable-next-line
          console.log('Error on init account4s ' + err)
          this.havealert = true
          this.havemssgalert = 'Error on init account4s ' + err
        })

         await EmployeeService.index(this.mainQuery2).then(res => {
          if (res.data.employes) {
            this.$store.dispatch('setActivEmployes', res.data.employes)

          }
        })
        .catch(err => {
        //  this.havealert = !this.havealert
          this.havemssgalert = 'Error on init Employees ' + err
        })

        await MainLedgerProjectTypeService.index2(this.mainQuery2).then(res => {
            if (res.data.mainledgerprojecttype) {
              this.$store.dispatch('setProjectTypes', res.data.mainledgerprojecttype)
            }
          })
          .catch(err => {
            console.log('Error init MainLedgerProjectTypes ', err)
          })

          this.mainQuery2.noPageing = 0
          const privateP = "privatePartner" in this.$store.state.user ? this.$store.state.user.privatePartner : true
          const {data} = privateP ? (await PartnerService.index2(this.mainQuery2)) : (await PartnerService.index(this.mainQuery2))

          if (data && data.partners && data.partners.rows) {
            this.$store.dispatch('setDatePartners', new Date())
            this.$store.dispatch("setPartners", data.partners.rows)
          }

        this.showDialog = false
        if (this.$route.name !== 'home') {
          this.$router.push({
            name: 'home'
          })
        }
        // setTimeout(()=> {
        //   this.snackbar = false
        //   this.$router.push({
        //     name: 'home'
        //   })
        // }, 1000)
        
      } catch (error) {
        // eslint-disable-next-line
        console.log(error)
      }
    }
  },
  computed: {

  },
  watch: {

  },
  components: {
    JustWait
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
