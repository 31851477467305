<template>
      <v-container fluid  class="grey lighten-5">
        <v-row class="mt-2">
          <v-col sm="2">
            <h1>{{documentNameLoc}}</h1>
          </v-col>
          <v-col sm="4">
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="documentEdit" class="mr-1 pa-0">
                  {{langC.Edit}}
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 5">
                <v-btn  color="primary" @click="documentCopy" class="mr-1 pa-0">
                  KOPI
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="documentPrevious" class="mr-1 pa-0">
                 PRETH.
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="warehouseState" class="mr-1 pa-0">
                  {{langC.Warehouse2}}
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel == 1">
                <v-btn text color="red" @click="documentDelete" class="mr-1">
                  {{langC.Delete}}
                </v-btn>
            </span>
          </v-col>
          <v-col sm="1" v-if="documentName === 'DeliveryNoteSales'">
            <v-checkbox
              dense 
              v-model="printCustom"
              :label="lang.PrintCustom"
            ></v-checkbox>
          </v-col>
          <v-col sm="1" v-if="documentName === 'InvoiceSales'">
            <v-checkbox
              dense 
              v-model="printID"
              :label="lang.printItemID"
            ></v-checkbox>
          </v-col>
          <v-col sm="1" v-if="documentName === 'QuoteSales'">
            <v-checkbox
              dense 
              v-model="noTotal"
              :label="lang.noTotal"
            ></v-checkbox>
          </v-col>
          <v-col sm="1">
            <v-checkbox
            dense 
              v-model="hidePrice"
              :label="langC.HidePrice"
            ></v-checkbox>
          </v-col>
          <v-col sm="1" v-if="documentName !== 'ServiceNotes'">
            <v-checkbox
              dense
              v-model="useMemo"
              :label="langC.useMemo"
            ></v-checkbox>
          </v-col>
          <v-col sm="1" v-if="documentName === 'ServiceNotes' && !serviceVehicle">
            <v-checkbox
              dense
              v-model="smallPrint"
              :label="langC.SmallPrint"
            ></v-checkbox>
          </v-col>
          <v-col sm="2" v-if="documentName === 'ServiceNotes' && serviceVehicle">
            <v-select
              v-model="vehiclePrintSelect"
              :items="vehiclePrintType"
              item-text="text"
              item-value="value"
              label="Vrsta ispisa"
              persistent-hint
              return-object
              single-line
              clearable 
            ></v-select>
          </v-col>

          <v-col sm="3">
            <v-btn color="green" @click="documentprint" class="mr-1 pa-0">
                  {{langC.Print}}
                </v-btn>                
              <span v-if="this.$store.state.user.AccessLevel <= 5">
                <v-btn color="green" @click="documentSave" class="mr-1 pa-0">
                   {{langC.Save}}
                </v-btn>
                <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
                ></v-progress-circular>
            </span>
            <v-btn color="green" @click="documentlist" class="mr-1">
                  {{langC.Back}}
                </v-btn>
          </v-col>
          
        </v-row> 

        <v-row no-gutters>
          <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
            
          </v-col>
        </v-row>

        <v-row no-gutters>
            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
              <appDocumentViewCard1></appDocumentViewCard1>
              </v-card>
            </v-col>

            <v-col  sm="4">
              <v-card
                class="pa-2"
                outlined
                tile
              >
               <appDocumentViewCard2 />
              </v-card>
              <DigitalDocShortList />
            </v-col>

            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <appDocumentViewCard3 v-if="!pricechange"></appDocumentViewCard3>
                <appDocumentViewCard5 v-if="pricechange"></appDocumentViewCard5>
              </v-card>
            </v-col>
          </v-row>

          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            bottom
            :color="color"
            >
            {{ text }}
          </v-snackbar>

         

          <div v-if="!this.pricechange">
            <app-document-detail-list13 :key="componentKey"></app-document-detail-list13>
          </div>
          <div v-if="this.pricechange">
            <DocumentPriceChangeCRUD></DocumentPriceChangeCRUD>
          </div>
          <app-document-print-sales ref="prnt" :useMemo="useMemo" :printID="printID" :noTotal="noTotal"></app-document-print-sales>
          <app-document-print-purchase ref="prntPls"></app-document-print-purchase>
          <document-print-sales-service ref="printVehicle" />
          <div v-if="this.statewhs">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <appDocumentWarehouseState></appDocumentWarehouseState>
                </v-col>
              </v-row>
          </div>
          <div v-if="this.previous">
              <v-row class="pt-2">
                  <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                    <appDocumentDetailListPrevious></appDocumentDetailListPrevious>
                </v-col>
              </v-row>
          </div>
   </v-container>

</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import ItemService from '@/services/ItemService'
import DocumentViewCard1 from './DocumentViewCard1'
import DocumentViewCard2 from './DocumentViewCard2'
import DocumentViewCard3 from './DocumentViewCard3'
import DocumentViewCard5 from './DocumentViewCard5'
import DocumentDetaillist13 from './DocumentDetailList13'
import DocumentPrintSales from './DocumentPrintSales'
import DocumentPrintPurchase from './DocumentPrintPurchase'
import DocumentPrintSalesService from './DocumentPrintSalesService'
import DocumentDetailListPrevious from './DocumentDetailListPrevious'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import DocumentWarehouseState from './DocumentWarehouseState'
import DigitalDocShortList from '../DigitalDoc/DigitalDocShortList.vue'
import WarehouseService from '@/services/WarehouseService'
import DocumentPriceChangeCRUD from './DocumentPriceChangeCRUD'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'documentview',
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      msg: '',
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      typeAna : 1,
      hidePrice: false,
      smallPrint: false,
      useMemo: false,
      itemsState: [],
      itemsUpdate: [],
      itemUpdate: {},
      document: {
      },
      loctaskOpenDate: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      loctaskForDate: '',
      loctaskClosedDate: '',
      loctaskAlarmDate: '',
      haveRight: 4,
      documentActivData: {},
      documentNameLoc: '',
      documentName: '',
      itemsLoc: [],
      locdocumentDate: '',
      docSide: 0,
      mainQuery2: {},
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        items: []
      },
      previous: false,
      statewhs: false, 
      suppcard: false,
      componentKey: 0,
      pricechange: false,
      printCustom: false,
      printID: false,
      serviceVehicle: false,
      vehiclePrintType: [],
      vehiclePrintSelect: '',
      noTotal: false
    }
  },
  async mounted () {
    try {
      if (this.$store.state.language === 'EN') {
        this.langC = commEn
        this.lang = langEn
      }
      if (this.$store.state.language === 'HR') {
        this.lang = langHr
        this.langC = commHr
      }
      this.$store.dispatch('settypePOSPrint', 0)
      this.vehiclePrintType.push(
        {text: 'Potvrda primitka POS', value: 'confirmPOS'},
        {text: 'Potvrda primitka A6', value: 'confirmA6', width: '90px'},
        {text: 'Potvrda primitka A5', value: 'confirmA5', width: '90px'},
        {text: 'Predračun POS', value: 'proformaPOS', width: '150px'},
        {text: 'Predračun A6', value: 'proformaA6', width: '150px'},
        {text: 'Predračun A5', value: 'proformaA5', width: '150px'},
        {text: 'Nalog za servisera POS', value: 'mechanicPOS', width: '150px'},
        {text: 'Nalog za servisera A6', value: 'mechanicA6', width: '150px'},
        {text: 'Nalog za servisera A5', value: 'mechanicA5', width: '150px'},
      )
      if (this.$store.state.documentid) {
      this.documentNameLoc = this.$store.state.documentActivLoc
      this.documentName = this.$store.state.documentActiv
      this.pricechange = this.documentName === 'PriceChange' ? true : false
      this.docSide = this.$store.state.documentSide ? this.$store.state.documentSide : 3
      this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
      this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
      this.$store.dispatch("setGroupid", null)
      //this.$store.dispatch("setPartnerid", this.$store.state.documentActivHead.partnerId ? parseInt(this.$store.state.documentActivHead.partnerId): null)

      this.itemsState = []
      if (this.$store.state.companyActiv.CompanyShowWarehouseState) {
        this.typeAna = 1
        this.itemsState = await WarehouseService.whstate(this.mainQuery.CompanyId, this.mainQuery.businessYear, 1)
        this.typeAna = 4
      }
       if (this.documentName === 'ServiceNotes') {
        if (this.$store.state.user && this.$store.state.user.vehicles && this.$store.state.user.vehicles === true) {
          this.serviceVehicle = true
        }
      }
      //console.log('DocumentView Monuted this.$store.state.documentActivHead ', this.$store.state.documentActivHead.partnerVATID )
    }
    } catch(err) {
      // eslint-disable-next-line
      console.log(err)
    }    
  },
  methods: {
    
    forceRerender() {
      this.componentKey += 1;  
    },
    documentPrevious() {
      this.previous = !this.previous
    },
    warehouseState() {
      this.statewhs = !this.statewhs
    },
    async documentlist () {
      try {
        await this.documentSave()
        this.$store.dispatch('setDocumentActivHead', {})
        this.$store.dispatch('setDocumentActivItems', [])
        await this.$router.push({
          name: 'document'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentCopy () {
      try {
        await this.$router.push({
          name: 'documentcopy'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentDelete () {
      try {
        await this.$router.push({
          name: 'documentdelete'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentprint() {
      if (this.smallPrint) {
         await this.$store.dispatch('setprintSmall', true)
      } else {
         await this.$store.dispatch('setprintSmall', false)
      }
      if (this.hidePrice) {
        await this.$store.dispatch('setprintNoPrice', true)
      } else {
        await this.$store.dispatch('setprintNoPrice', false)
      }
      // if print service 
      if (this.documentName === 'ServiceNotes') {
        this.$store.dispatch('settypePOSPrint', 1)
      }
      // if print custom DeliveryNoteSales
      if (this.documentName === 'DeliveryNoteSales' && this.printCustom) {
        this.$store.dispatch('settypePOSPrint', 100)
      }
      if (!this.vehiclePrintSelect) {
        if (this.$store.state.documentSide === 1 || this.pricechange) {
          this.$refs.prntPls.print()
        }
        if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
          this.$refs.prnt.print()
        }
      }
      if (this.$store.state.documentActiv === 'ServiceNotes' && this.serviceVehicle && this.vehiclePrintSelect){
        if (this.vehiclePrintSelect) {
          this.$store.dispatch('settypePOSName', this.vehiclePrintSelect.value)
          this.$refs.printVehicle.print()
        }
      }
      
    },
    async documentEdit () {
      try {
        await this.documentSave()
        this.$store.dispatch('seteditItem', 1)
        if (this.$store.state.user && this.$store.state.user.vehicles && this.$store.state.user.vehicles === true && this.$store.state.documentActiv === 'ServiceNotes') {
          await this.$router.push({
            name: 'documentcreateservice'
          })
        } else {
          await this.$router.push({
            name: 'documentcreate'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentDetails () {
      try {
        this.documentActivData = this.document
        this.$store.dispatch('setDocumentActivData', this.documentActivData)
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'documentdetail'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentSave () {
      try {
        if (this.$store.state.documentActivHead) {
          this.saving = true
          const docID = this.$store.state.documentActivHead.id ? this.$store.state.documentActivHead.id : 0
          if (this.$store.state.documentActivHead) {
            const newHead = {...this.$store.state.documentActivHead}
            newHead.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : ''
            newHead.LastUpdate = new Date()
            //console.log('Document Save this.$store.state.documentActivHead.partnerVATID', this.$store.state.documentActivHead.partnerVATID, newHead)
            await DocumentService.put(newHead)
          }          
          
          let newItems = []
          const itemsUpd = []
          if (this.$store.state.documentActivItems) {
            this.$store.state.documentActivItems.map(item => {
              let newObject = JSON.parse(JSON.stringify(item))
              let updateItem = {}
              let suppDesc = ''
              delete newObject.id
              newItems.push(newObject)
              if (this.$store.state.documentSide === 1 && this.$store.state.documentActiv === 'ReceiptPurchase') {
                updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
                updateItem.itemID = item.itemID
                updateItem.ItemSupppartnerName = this.$store.state.documentActivHead.partnerNameDatabase ? this.$store.state.documentActivHead.partnerNameDatabase : 0
                updateItem.ItemSupppartnerVATID = this.$store.state.documentActivHead.partnerVATID ? this.$store.state.documentActivHead.partnerVATID : 0
                updateItem.ItemSuppDate = this.$store.state.documentActivHead.documentDate ? this.$store.state.documentActivHead.documentDate : 0
                updateItem.ItemSuppDoc = parseInt(this.$store.state.documentActivHead.documentId)? parseInt(this.$store.state.documentActivHead.documentId): 0
                updateItem.ItemSuppDate = this.$store.state.documentActivHead.documentDate ? this.$store.state.documentActivHead.documentDate : null
                updateItem.itemSuppPrice = item.itemSuppPrice ? item.itemSuppPrice : 0
                updateItem.itemSuppBasePrice = item.itemSuppBasePrice ? item.itemSuppBasePrice : 0
                updateItem.itemSuppDiscount = item.itemSuppDiscount ? item.itemSuppDiscount : 0                
                updateItem.itemMargin = item.itemMargin ? item.itemMargin : 0
                updateItem.itemMaxDiscount = item.itemMaxDiscount ? item.itemMaxDiscount : 0
                updateItem.itemPrice = item.itemPrice ? item.itemPrice : 0
                updateItem.itemVAT1Percentage = item.itemVAT1Percentage ? item.itemVAT1Percentage : 0
                updateItem.itemVAT1Amount = item.itemVAT1Amount ? item.itemVAT1Amount : 0
                updateItem.itemDirectDuty1 = item.itemDirectDuty1 ? item.itemDirectDuty1 : 0
                updateItem.itemDirectDuty1 = item.itemDirectDuty1 ? item.itemDirectDuty1 : 0
                updateItem.itemPriceWithVAT= item.itemPriceWithVAT ? item.itemPriceWithVAT : 0
                suppDesc = this.$store.state.documentActivHead.documentName ? this.$store.state.documentActivHead.documentName : ''
                suppDesc += ', ' + (parseInt(this.$store.state.documentActivHead.documentId) ?  parseInt(this.$store.state.documentActivHead.documentId) : '')
                suppDesc += ', ' + (this.$store.state.documentActivHead.documentDate ? dayjs(this.$store.state.documentActivHead.documentDate).format('DD.MM.YYYY') : '')
                suppDesc += ', ' + (this.$store.state.documentActivHead.partnerNameDatabase ? this.$store.state.documentActivHead.partnerNameDatabase : '')
                updateItem.ItemSuppCode = suppDesc
                updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
                itemsUpd.push(updateItem)
              }
            })
          }
          // if delete all items from document
          if (newItems.length === 0 && docID > 0 ) {
            let newObject = {
              databaseDocID : docID
            }
            newItems.push(newObject)
          }

          if (newItems) {
            await DocumentDetailService.saveitems(newItems).then(res => {
              this.text = this.langC.DocumentSaved + (res.data.message)
              this.color = 'green'
              this.snackbar = true
            }).catch(err => {
              this.text = this.langC.DocumentSaveError + (err.data)
              this.color = 'red'
              this.snackbar = true
            })
          }
          if (itemsUpd && this.$store.state.documentActiv === 'ReceiptPurchase') {
            await ItemService.updateprices(itemsUpd).then(async(res) => {
            console.log(' update prices done ', res && res.data ? res.data : '')
            await newUpdates(1)
            await testLastUpdate()
            }
            )
          }

          this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
          this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
          this.mainQuery.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : null
          this.itemsLoc = []
          this.itemsLoc = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
          if (this.itemsLoc) {
            this.$store.dispatch('setDocumentActivItems', this.itemsLoc)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          this.forceRerender()
          this.saving = false
        }

      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  },
  components: {
    appDocumentViewCard1: DocumentViewCard1,
    appDocumentViewCard2: DocumentViewCard2,
    appDocumentViewCard3: DocumentViewCard3,
    appDocumentViewCard5: DocumentViewCard5,
    appDocumentDetailList13: DocumentDetaillist13,
    appDocumentPrintSales: DocumentPrintSales,
    appDocumentPrintPurchase: DocumentPrintPurchase,
    appDocumentWarehouseState: DocumentWarehouseState,
    appDocumentDetailListPrevious: DocumentDetailListPrevious,
    DocumentPriceChangeCRUD,
    DigitalDocShortList,
    DocumentPrintSalesService
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
