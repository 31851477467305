<template>
    <v-card>
        <v-card-title>
            <v-toolbar color="green darken-2" dark> 
              Proizvedeno/obavljena usluga
              <v-spacer></v-spacer>                          
              <v-btn class="mx-2" fab dark color="green darken-3" @click='newMaterial'>
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>        
            </v-toolbar>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="10"
            @click:row="navigateTo"
            :mobile-breakpoint="0"
          >
          <template v-slot:[`item.itemPrice`]="{ item }">
              <span>{{ localMoney(item.itemPrice) }}</span>
          </template>
          <template v-slot:[`item.itemPcsPlus`]="{ item }">
              <span>{{ localMoney(item.itemPcsPlus) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmount`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmount) }}</span>
          </template>
          <template v-slot:[`item.itemBaseAmountNoVAT`]="{ item }">
              <span>{{ localMoney(item.itemBaseAmountNoVAT) }}</span>
          </template>
          <template v-slot:[`item.itemAmountWithVAT`]="{ item }">
              <span>{{ localMoney(item.itemAmountWithVAT) }}</span>
          </template>
          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>
        </v-data-table>
      </v-card>

</template>

<script>
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'documentdetaillist3',
  data () {
    return {
      msg: '',
      items: [],
      max25chars: (v) => v.length <= 25 || 'Input too long!',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0,
        FreeF1: 4
      }
    }
  },
  async mounted () {
    try {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }
      if (this.$store.state.documentActiv) {
        this.mainQuery.documentName = this.$store.state.documentActiv
      }
      if (this.$store.state.databaseDocID) {
        this.mainQuery.databaseDocID = this.$store.state.databaseDocID
      }
      this.mainQuery.FreeF1 = 4
      this.items = this.$store.state.documentActivItems.filter(item => {
            return parseInt(item.FreeF6)  === 33
          })
      if (this.$store.state.language === 'EN') {
        this.headers.push(
          {
            text: 'item Name',
            align: 'left',
            sortable: true,
            value: 'itemName'
          },
          { text: 'Price ', align: 'left', value: 'itemPrice' },
          { text: 'Pcs', align: 'left', value: 'itemPcsPlus' },
          { text: 'Base Amount', align: 'right', sortable: true, value: 'itemBaseAmount' },
          { text: 'Discount %', align: 'left', value: 'itemDiscountPercentage1' },
          { text: 'Base Amount with Disc.', align: 'left', value: 'itemBaseAmountNoVAT' },
          { text: 'VAT %', align: 'right', sortable: true, value: 'itemVAT1Percentage' },
          { text: 'Amount With VAT', align: 'center', value: 'itemAmountWithVAT' },
          { text: 'DbId', value: 'id' }
       )
      }
      if (this.$store.state.language === 'HR') {
        this.headers.push(
          {
            text: 'Artikl/usluga',
            align: 'left',
            sortable: true,
            value: 'itemName'
          },
          { text: 'Cijena ', align: 'left', value: 'itemPrice' },
          { text: 'Kolicina', align: 'left', value: 'itemPcsPlus' },
          { text: 'Iznos VPC', align: 'right', sortable: true, value: 'itemBaseAmount' },
          { text: 'Popust %', align: 'left', value: 'itemDiscountPercentage1' },
          { text: 'Iznos VPC sa popustom.', align: 'left', value: 'itemBaseAmountNoVAT' },
          { text: 'PDV %', align: 'right', sortable: true, value: 'itemVAT1Percentage' },
          { text: 'Iznos sa PDVom', align: 'center', value: 'itemAmountWithVAT' },
          { text: 'Id baze', value: 'id' }
        )
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    navigateTo (item) {
      this.$store.dispatch('seteditItem', 1)
      this.$store.dispatch('setDocumentDetailid', item.id)
      this.$store.dispatch('settypePOS', 33)
      // warehouse plus
      this.$store.dispatch('setDocumentSide', 1) 
      this.$router.push({
        name: 'documentdetailcreateedit3'
      })
    },
    newMaterial () {
      if (this.items.length === 1) {
        alert('Samo jedna stavka moze biti u proizvedenom! Only one item is allowed in made!')
      } else {
        this.$store.dispatch('seteditItem', 0)
        this.$store.dispatch('settypePOS', 33)
        // warehouse plus
        this.$store.dispatch('setDocumentSide', 1)
        this.$router.push({
          name: 'documentdetailcreateedit3'
        })
      }
    }
  },
  computed: {

  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
