<template>
  <div class="companycreate">
    <h2>Company create</h2>
    <v-container grid-list-md text-xs-center>
      <v-row>


       <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
        
          <h5 class="text-xs-left">Basic data</h5>
           <v-row no-gutters>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12"  lg="12" xl="12">
                <v-text-field
                    label="Company name:"
                    required
                    :rules="[required]"
                    v-model="company.CompanyName"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    label="Company name short:"
                    required
                    :rules="[required]"
                    v-model="company.CompanyNameShort"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                    label="Company name NO SPACES:"
                    required
                    :rules="[required]"
                    v-model="company.CompanyNameDatabase"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="10"  lg="10" xl="10" class="pr-3">
                <v-text-field
                  label="Adress street:"
                  v-model="company.CompanyStreet"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Adress building number:"
                  v-model="company.CompanyBuildingNameNumber"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="Adress postcode:"
                  v-model="company.CompanyPostcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="5"  lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Adress City:"
                  v-model="company.CompanyCity"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <v-text-field
                  label="Adress County/Region:"
                  v-model="company.CompanyCountyRegion"
                ></v-text-field>
              </v-col>
          </v-row>
          
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="Adress State:"
                v-model="company.CompanyState"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="VAT ID:"
                required
                :rules="[required]"
                v-model="company.CompanyVATID"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="Company email:"
                required
                :rules="[required]"
                v-model="company.Companyemail"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pr-3">
              <v-text-field
                label="Company phone:"
                v-model="company.CompanyPhones"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Company Business Space:"
                  v-model="company.CompanyBusinessSpace"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-text-field
                    label="Company Business Device:"
                    v-model="company.CompanyBusinessDevice"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-checkbox
                  label="Obrt"
                  v-model="company.showMainBook"
                ></v-checkbox>
            </v-col>
          </v-row>


          <br>
          <div class="danger-alert" v-html="error" />
          <br>
          <v-row no-gutters>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
              <v-btn
                dark
                class="blue mr-2"
                :disabled="!formIsValid"
                @click="register">
                Save
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
              <v-btn
                color="green"
                @click="compList">
                Back
              </v-btn>
            </v-col>
          </v-row>
      </v-col>

      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
          <h5 class="text-xs-left">Company Director data</h5>
          <v-row no-gutters>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Title:"
                  v-model="company.CompanyDirectorTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="First:"
                  v-model="company.CompanyDirectorFirst"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Last:"
                  v-model="company.CompanyDirectorLast"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Former:"
                  v-model="company.CompanyDirectorFormer"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="State of Residence:"
                  v-model="company.CompanyDirectorStateResidence"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Nationality:"
                  v-model="company.CompanyDirectorNationality"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                 <v-text-field
                    label="Date Of Birth:"
                    v-model="company.CompanyDirectorDateOfBirth"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-text-field
            label="Business Occupation:"
            v-model="company.CompanyDirectorBusinessOccupation"
          ></v-text-field>


           <v-row no-gutters>
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-3">
                <v-text-field
                  label="Adress street:"
                  v-model="company.CompanyDirectorAdressStreet"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Adress building number:"
                  v-model="company.CompanyDirectorAdressBuildingNameNumber"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="Adress postcode:"
                  v-model="company.CompanyDirectorPostcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Adress City:"
                  v-model="company.CompanyDirectorCity"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                <v-text-field
                  label="Adress County/Region:"
                  v-model="company.CompanyDirectorCountyRegion"
                ></v-text-field>
              </v-col>
          </v-row>


          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Email:"
                  v-model="company.CompanyDirectorEmail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Phone:"
                  v-model="company.CompanyDirectorPhone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Contact Option:"
                  v-model="company.CompanyDirectorContactOption"
                ></v-text-field>
              </v-col>
          </v-row>          

          
          
          
          <h5 class="text-xs-left">Company Contact person</h5>

            <v-row no-gutters>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Title:"
                  v-model="company.CompanySecretaryTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="First:"
                  v-model="company.CompanySecretaryFirst"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Last:"
                  v-model="company.CompanySecretaryLast"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Former:"
                  v-model="company.CompanySecretaryFormer"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="State of Residence:"
                  v-model="company.CompanySecretaryStateResidence"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Nationality:"
                  v-model="company.CompanySecretaryNationality"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                 <v-text-field
                    label="Date Of Birth:"
                    v-model="company.CompanySecretaryDateOfBirth"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-text-field
            label="Business Occupation:"
            v-model="company.CompanySecretaryBusinessOccupation"
          ></v-text-field>


           <v-row no-gutters>
              <v-col cols="12" sm="10" md="10" xs="10" lg="10" xl="10" class="pr-3">
                <v-text-field
                  label="Adress street:"
                  v-model="company.CompanySecretaryAdressStreet"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Adress building number:"
                  v-model="company.CompanySecretaryAdressBuildingNameNumber"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="pr-3">
                <v-text-field
                  label="Adress postcode:"
                  v-model="company.CompanySecretaryPostcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5" class="pr-3">
                <v-text-field
                  label="Adress City:"
                  v-model="company.CompanySecretaryCity"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                <v-text-field
                  label="Adress County/Region:"
                  v-model="company.CompanySecretaryCountyRegion"
                ></v-text-field>
              </v-col>
          </v-row>


          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Email:"
                  v-model="company.CompanySecretaryEmail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-text-field
                  label="Phone:"
                  v-model="company.CompanySecretaryPhone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Contact Option:"
                  v-model="company.CompanySecretaryContactOption"
                ></v-text-field>
              </v-col>
          </v-row> 

        </v-col>

        <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4" class="pr-3">
          <h5 class="text-xs-left">Company settings</h5>
          <v-textarea
            label="Invoice Memo Text:"
            v-model="company.CompanyInvoiceMemo"
          ></v-textarea>
          <v-textarea
            label="Invoice Foregin Memo Text:"
            v-model="company.InvoiceForeginMemo"
          ></v-textarea>
          <v-textarea
            label="Delivery Note Memo Text:"
            v-model="company.CompanyDeliveryNoteMemo"
          ></v-textarea>
          <v-textarea
            label="Delivery Note Foregin Memo Text:"
            v-model="company.CompanyDeliveryNoteForeginMemo"
          ></v-textarea>
           <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Invoice Default Days for Payment:"
                  v-model="company.CompanyInvoiceDefDays"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                 <v-text-field
                  label="Invoice Place:"
                  v-model="company.CompanyInvoicePlace"
                ></v-text-field>
              </v-col>
          </v-row>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Area of Activities:"
                  v-model="company.CompanyInvoiceType"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-text-field
                    label="Activity code:"
                    v-model="company.CompanyInvoiceHint"
                  ></v-text-field>
              </v-col>
          </v-row>

           <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Invoice Speed():"
                  v-model="company.CompanyInvoiceSpeed"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-checkbox
                    label="Use Retail Price:"
                    v-model="company.CompanyUseRetailPrice"
                  ></v-checkbox>
              </v-col>
          </v-row>
          
         
          
         <v-row no-gutters>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-checkbox
                  label="Show Last Sale Buyer:"
                  v-model="company.CompanyShowLastSaleBuyer"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-checkbox
                  label="Show Warehouse State:"
                  v-model="company.CompanyShowWarehouseState"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4">
                <v-checkbox
                  label="Show Input Price:"
                  v-model="company.CompanyShowInputPrice"
                ></v-checkbox>
              </v-col>
          </v-row> 
          
           <v-row no-gutters>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-checkbox
                  label="VATonPay:"
                  v-model="company.CompanyVATonPay"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pr-3">
                <v-checkbox
                  label="Retail:"
                  v-model="company.CompanyRetail"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pr-3">
                <v-text-field
                  label="Default Warehouse:"
                  v-model="company.CompanyDefaultWarehouse"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                <v-text-field
                  label="Printer Size:"
                  v-model="company.CompanyPrinterSize"
                  type="number"
                ></v-text-field>
              </v-col>
          </v-row> 
          
          
           <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Input Files Path:"
                  v-model="company.CompanyInputFilesPath"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                   <v-text-field
                    label="Output Files Path:"
                    v-model="company.CompanyOutputFilesPath"
                  ></v-text-field>
              </v-col>
          </v-row>
          
          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Start Invoice Number:"
                  v-model="company.CompanyStartInvoiceNumber"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                   <v-text-field
                    label="Start Bill Number:"
                    v-model="company.CompanyStartBillNumber"
                    type="number"
                  ></v-text-field>
              </v-col>
          </v-row>
          
          
          <v-text-field
            label="Company Description Up:"
            v-model="company.CompanyDescriptionUp"
          ></v-text-field>
          <v-text-field
            label="Company Description Down:"
            v-model="company.CompanyDescriptionDown"
          ></v-text-field>

          <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Company Business Space Retail:"
                  v-model="company.CompanyBusinessSpaceRetail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-text-field
                    label="Company Business Device Retail:"
                    v-model="company.CompanyBusinessDeviceRetail"
                  ></v-text-field>
              </v-col>
          </v-row>

           <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-text-field
                  label="Type:"
                  v-model="company.CompanyType"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-text-field
                    label="Retail space description:"
                    v-model="company.CompanyLogo5"
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Company default currency" :items="currencyType" v-model="company.currencyType" value="company.currencyType"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-checkbox
                  label="Hide foot.lines:"
                  v-model="company.showFooter1"
                ></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto URA ukupno:" :items="account4s" v-model="company.InAllaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto IRA ukupno:" :items="account4s" v-model="company.OutAllaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto URA 25% PDV:" :items="account4s" v-model="company.InVATaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto IRA 25% PDV:" :items="account4s" v-model="company.OutVATaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto URA osn.:" :items="account4s" v-model="company.InBaseaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
                 <v-select label="Def.Konto IRA osn.:" :items="account4s" v-model="company.OutBaseaccount4" item-text="account4" item-value="account4" clearable></v-select>
            </v-col>
          </v-row>

          <v-layout row>
            <v-col xs="4" class="pr-3">
                <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"                   
                    width="290px"
                    :return-value.sync="company.StartFrom"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="company.StartFrom"
                      label="Activ from Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                    <v-date-picker v-model="company.StartFrom" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(company.StartFrom)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
              </v-col>

           </v-layout>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                <v-checkbox
                  label="Send email with task:"
                  v-model="company.CompanySendEmailTask"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6" class="pr-3">
                  <v-checkbox
                    label="Activ:"
                    v-model="company.Activ"
                  ></v-checkbox>
              </v-col>
          </v-row>

        </v-col>

      </v-row>

       <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
    </v-container>

  </div>
</template>

<script>
import CompService from '@/services/CompService'

export default {
  name: 'companycreate',
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      modal: false,
      modal1: false,
      dialog1: false,
      msg: '',
      newCompany: {},
      company: {
        CompanyName: '',
        CompanyNameShort: '',
        CompanyNameDatabase: '',
        CompanyBuildingNameNumber: '',
        CompanyStreet: '',
        CompanyCity: '',
        CompanyPostcode: '',
        CompanyCountyRegion: '',
        CompanyState: '',
        CompanyVATID: '',
        Companyemail: '',
        CompanyPhones: '',
        CompanyBankName: '',
        CompanyBankAddress: '',
        CompanyBankIBAN: '',
        CompanyBankSWIFT: '',
        CompanyDirectorTitle: '',
        CompanyDirectorFirst: '',
        CompanyDirectorLast: '',
        CompanyDirectorFormer: '',
        CompanyDirectorStateResidence: '',
        CompanyDirectorNationality: '',
        CompanyDirectorDateOfBirth: '',
        CompanyDirectorBusinessOccupation: '',
        CompanyDirectorAdressBuildingNameNumber: '',
        CompanyDirectorAdressStreet: '',
        CompanyDirectorCity: '',
        CompanyDirectorPostcode: '',
        CompanyDirectorCountyRegion: '',
        CompanyDirectorEmail: null,
        CompanyDirectorPhone: '',
        CompanyDirectorContactOption: '',
        CompanySecretaryTitle: '',
        CompanySecretaryFirst: '',
        CompanySecretaryLast: '',
        CompanySecretaryFormer: '',
        CompanySecretaryStateResidence: '',
        CompanySecretaryNationality: '',
        CompanySecretaryDateOfBirth: '',
        CompanySecretaryBusinessOccupation: '',
        CompanySecretaryAdressBuildingNameNumber: '',
        CompanySecretaryAdressStreet: '',
        CompanySecretaryCity: '',
        CompanySecretaryPostcode: '',
        CompanySecretaryCountyRegion: '',
        CompanySecretaryEmail: null,
        CompanySecretaryPhone: '',
        CompanySecretaryContactOption: '',
        CompanyInvoiceMemo: '',
        CompanyInvoiceForeginMemo: '',
        CompanyDeliveryNoteMemo: '',
        CompanyDeliveryNoteForeginMemo: '',
        CompanyInvoiceDefDays: 15,
        CompanyInvoicePlace: '',
        CompanyInvoiceType: '',
        CompanyInvoiceHint: '',
        CompanyInvoiceSpeed: 1,
        CompanyUseRetailPrice: false,
        CompanyShowLastSaleBuyer: false,
        CompanyShowWarehouseState: false,
        CompanyShowInputPrice: false,
        CompanyVATonPay: false,
        CompanyRetail: false,
        CompanyDefaultWarehouse: 1,
        CompanyPrinterSize: 1,
        CompanyInputFilesPath: '',
        CompanyOutputFilesPath: '',
        CompanyTransferDataFilesPath: '',
        CompanyStartInvoiceNumber: 1,
        CompanyStartBillNumber: 1,
        CompanyType: 1,
        CompanyDescriptionUp: '',
        CompanyDescriptionDown: '',
        CompanyBusinessSpace: '',
        CompanyBusinessDevice: '',
        CompanyBusinessSpaceRetail: '',
        CompanyBusinessDeviceRetail: '',
        CompanySendEmailTask: false,
        CompanyLogo5: '',
        currencyType: 'EUR',
        InAllaccount4: null,
        InVATaccount4: null,
        InBaseaccount4: null,
        OutAllaccount4: null,
        OutVATaccount4: null,
        OutBaseaccount4: null,
        StartFrom: null,
        Activ: false,
        LastUpdate: null,
        showFooter1: false
      },
      required: (value) => !!value || 'Required.',
      error: '',
      currencyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      account4s: [],
    }
  },
  async mounted () {
    try {
      if (this.$store.state.editItem === 1) {
        const locComp = await CompService.show(this.$store.state.companyid)
        this.company = locComp.data.company
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log('greska kod monut dokumenta', error)
    }
    this.account4s = this.$store.state.account4s ? this.$store.state.account4s : []
  },
  methods: {
    async register () {
      try {
        if (this.$store.state.editItem === 1) {
          await CompService.put(this.company)
          this.text = 'Company updated'
          this.color = 'green'
          this.snackbar = true
        }
        if (this.$store.state.editItem === 0) {
          await CompService.post(this.company)
          this.text = 'New company saved to database'
          this.color = 'green'
          this.snackbar = true
        }
        // this.$store.dispatch('seteditItem', 0)
        // this.$router.push({
        //   name: 'company'
        // })
      } catch (error) {
        this.error = error.response.data.error
        this.text = 'Error on save'
        this.color = 'red'
        this.snackbar = true
      }
    },
    async compList () {
      try {
        await this.$router.push({
          name: 'company'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
    formIsValid () {
      return this.company.CompanyName !== '' &&
        this.company.CompanyNameShort !== '' &&
        this.company.CompanyNameDatabase !== '' &&
        this.company.CompanyVATID !== '' &&
        this.company.Companyemail !== ''
    }
  },
  watch: {
    'company.CompanyName' () {
      if (this.company.CompanyName) {
        this.company.CompanyNameDatabase =  this.company.CompanyName.replace(/[\W_]/g, '')
        this.company.CompanyNameShort = this.company.CompanyName
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
