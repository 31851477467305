<template>
  <v-container fluid grid-list-md>
    <v-row>
      <v-col cols="12" sm="6" md="6" xs="6" lg="6" xl="6">
        <h2>{{head1}}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
        <POSindex />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">

      </v-col>
    </v-row>
  </v-container>

</template>

<script>

import POSindex from './POS/PosIndex'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"

export default {
  name: 'home',
  data () {
    return {
      head1: '',
      langC: {},
      haveIt: null
    }
  },
  components: {
    POSindex
  },
  async mounted () {
    try {
      if (this.$store.state.language === "EN") {
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.langC = commHr;
      }

      this.head1 = this.$store.state.companyActiv && this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ' '
      this.head1 = this.head1 + (this.$store.state.businessYear ? ', ' + this.$store.state.businessYear : '')

    }
    catch(err) {
      console.log(err)
    }
  },
  methods: {

    localDate(dateToFormat) {
      return dateToFormat.toLocaleDateString('de-DE', {year: 'numeric', month: '2-digit', day:'2-digit'});
      //return this.$moment(dateToFormat).format('DD.MM.YYYY')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#dateheader {
  color:aliceblue;
  text-align: center;
  font-weight: bold;
}
#calendarcolum {
  padding-left: 2px;
  padding-right: 3px;
}
#fullhome {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
