<template>
  <div>

      <v-row justify="center">
        <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">
          <v-row>
            <v-col xs="6" sm="6" md="6" lg="6" xl="6">
              <v-btn block  @click="enableDates">
                Odaberi period
              </v-btn>
            </v-col>
            <v-col xs="6" sm="6" md="6" lg="6" xl="6">
              <v-btn block @click="refreshPeriod">
                Refresh
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="settingDate">
            <v-col cols="12" class="pr-3">
              Od datuma: <input type="date" ref="docdate"  v-model="odDatuma" />
            </v-col>
            <v-col cols="12" class="pr-3">
              Do datuma: <input type="date" ref="docdate"  v-model="doDatuma" />
            </v-col>
          </v-row>

          <v-row v-if="settingDate">
            <v-col cols="12">
              <v-autocomplete
                :items="svekase"
                v-model="odabranaKasa"
                label="Sve kase"
                single-line
                item-text="Objekat"
                item-value="BranchId"
                return-object
                persistent-hint
                clearable
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>


      <v-row justify="center" v-if="!settingDate">
        <v-col cols="12">
           <POStotal :total="total" :odDatuma="odDatuma" :doDatuma="doDatuma" />
        </v-col>
      </v-row>
      <div v-if ="!settingDate" class="mt-2">
        <POSkartica
          v-for="(kasa, index) in kase"
          :kasaid="kasa.KASAID"
          :iznos="kasa.IZNOS"
          :datum="kasa.DATUM"
          :user="kasa.USER"
          :stavke="kasa.Stavke"
          :index="index"
          :key="kasa.id"
        />
      </div>
  </div>
</template>

<script>

import dayjs from 'dayjs'
import POSService from '@/services/POSService'
import BetonObjekatService from '@/services/BetonObjekatService'
import POStotal from './PosTotal'
import POSkartica from './POSkartica'

export default {
  name: 'pos',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      saving: false,
      postNew: false,
      settingDate: false,
      query: {},
      odDatuma: null,
      doDatuma: null,
      svekase: [],
      kase: [],
      total: 0,
      odabranaKasa: {}
    }
  },
  async mounted () {
    this.odDatuma = dayjs(new Date()).format('YYYY-MM-DD')
    this.doDatuma = dayjs(new Date()).format('YYYY-MM-DD')
    this.query.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    await this.init()
   // this.query.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2024
  },
  methods: {

    enableDates() {
      this.settingDate = !this.settingDate
    },
    async refreshPeriod() {
      await this.init()
      this.enableDates()
    },
    async init() {
      this.total = 0
      this.kase.length = 0
      this.svekase.length = 0
      this.query.fromDate = this.odDatuma
      this.query.toDate = this.doDatuma
      this.query.BranchId = this.$store.state.warehousemarkid ? this.$store.state.warehousemarkid : 1

      await BetonObjekatService.index(this.query)
      .then(res=> {
        if (res && res.data && res.data.betonobjekat && res.data.betonobjekat.length > 0) {
          this.svekase = res.data.betonobjekat
        }
      })
      .catch(err => {
        console.log('Error', err)
      })

      await POSService.choose(this.query)
      .then(res=> {
        if (res && res.data && res.data.pos && res.data.pos.length > 0) {
          this.kase = res.data.pos
          if (this.kase && this.kase.length > 0) {
            this.kase.map(ks => {
              this.total += ks.IZNOS ? parseFloat(ks.IZNOS) : 0
            })
          }
        }
      })
      .catch(err => {
        console.log('Error', err)
      })

    }
  },
  computed: {

  },
  watch: {
    odabranaKasa () {
      console.log('this.odabranaKasa', this.odabranaKasa)
      if (this.odabranaKasa && this.odabranaKasa.BranchId) {
        this.$store.dispatch('setWareHouseMarkid', this.odabranaKasa.BranchId)
      }
      if (!this.odabranaKasa) {
        this.$store.dispatch('setWareHouseMarkid', 1)
      }
      this.init()
    }
  },
  components: {
    POStotal,
    POSkartica
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
