<template>
<div>
  <v-container fluid grid-list-md>
  <v-row>
      <appAppSettings></appAppSettings>
    </v-row>
  <v-progress-circular
      v-if="this.saving"
      indeterminate
      color="green"
    ></v-progress-circular>
  <v-data-table
    dense
    :headers="headers"
    :items="warehouseUsers"
    sort-by="id"
    class="elevation-1"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }"
    :items-per-page="20"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{lang.Users}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">

          <template v-slot:activator="{ on }">
            <v-btn fab dark class="indigo" v-on="on">
               <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>


          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-text-field
                        v-model="editedItem.CompanyId"
                        :label="lang.CompanyId"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                      <v-text-field
                        v-model="editedItem.CompanyNameDatabase"
                        :label="lang.CompanyNameDatabase"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        :items="locWareHouses"
                        v-model="select2"
                        :label="lang.SelectWarehouse"
                        item-text="WareHouseName"
                        item-value="id"
                        return-object
                        persistent-hint
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" lg="7" xl="7">
                      <v-text-field
                        v-model="editedItem.WareHouseName"
                        :label="lang.WareHouseName"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                      <v-select
                        :items="locusers"
                        v-model="select"
                        :label="lang.SelectUser"
                        item-text="email"
                        item-value="id"
                        return-object
                        persistent-hint
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                        <v-select :label="lang.AccessLevel" :items="privitems" v-model="editedItem.useridright"></v-select>
                    </v-col>
                  </v-row>

                  <v-row v-if="errorMatch">                    
                      <div class="red" v-html="errorMatch" />
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">{{langC.Cancel}}</v-btn>
                    <v-btn :disabled="!valid" color="success" text @click="save">{{langC.Save}}</v-btn>  
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
 
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.action="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can
      </v-icon>
    </template>
    <template v-slot:no-data>
      {{ lang.warehouseUsersEmpty }}
    </template>
  </v-data-table>
  </v-container>
  </div>
</template>

<script>
import WareHouseUserService from '@/services/WareHouseUserService'
import WareHouseMarkService from '@/services/WareHouseMarkService'
import UserService from '@/services/UserService'
import langEn from './warehouseDescEn'
import langHr from './warehouseDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import AppSettings from '../AppSettings'

  export default {
    name: 'userCRUD',
    data: () => ({
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal2: false,
      menu2: false,
      lang: {},
      langC: {},
      saving: false,
      error: null,
      errorMatch: null,
      modal: false,
      show1: false,
      show2: false,
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: "",
        databaseDocID: 0
      },
      dialog: false,
      headers: [
        
      ],
      warehouseUsers: [],
      editedIndex: -1,
      editedItem: { },
      defaultItem: {
        WareHouseId: 0,
        WareHouseName: '',
        CompanyId: 1,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        useridright: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: null,
        LastUpdate: null
      },
      confpassword: '',
      privitems: [ ],
      valid: true,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 6) || 'Password must be 6 or more characters',
      ],
      locusers: [],
      select: { },
      locWareHouses: [],
      select2: { },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.lang.NewWareHouseUser: this.lang.EditWareHouseUser
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      'select' () {
        this.choosedUser(this.select.id)
      },
      'select2' () {
        this.choosedWareHouse(this.select2.id)
      },
    },

    async mounted() {
      if (this.$store.state.language === "EN") {
        this.lang = langEn
        this.langC = commEn;
      }
      if (this.$store.state.language === "HR") {
        this.lang = langHr
        this.langC = commHr;
      }
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
        this.defaultItem.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.companyName) {
        this.defaultItem.CompanyNameDatabase = this.$store.state.companyName
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.businessYear = this.$store.state.businessYear
      }

      this.privitems.push(
        { text: this.lang.Priv1, value: 1 },
        { text: this.lang.Priv2, value: 2 },
        { text: this.lang.Priv3, value: 3 },
        { text: this.lang.Priv4, value: 4 },
        { text: this.lang.Priv5, value: 5 }
      )
      this.headers.push(
        {text: this.lang.CompanyId, value: 'CompanyId', width: '50px'},
        {text: this.lang.CompanyNameDatabase, value: 'CompanyNameDatabase', width: '200px'},
        {text: this.lang.WareHouseId, value: 'WareHouseId', width: '50px'},
        {text: this.lang.WareHouseName, value: 'WareHouseName', width: '200px'},
        {text: this.lang.UserId, value: 'UserId', width: '150px'},
        {text: 'email', value: 'email', width: '200px'},
        {text: this.lang.UserIdRight, value: 'useridright', type: 'number', width: '50px'},
        {text: 'DbId', value: 'id', type: 'number', width: '50px'},
        {text: this.lang.Actions, value: 'action', sortable: false },
      )

     this.defaultItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
     this.defaultItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
     this.defaultItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''

     this.locusers = (await UserService.choose(this.mainQuery)).data.users
     this.locWareHouses = (await WareHouseMarkService.index()).data.wareHouseMarks
    },

    created () {
      this.initialize()
    },
    components: {
      appAppSettings: AppSettings
    },

    methods: {
      validate () {
        if (this.$refs.form.validate()) {
          this.snackbar = true
        }
      },
      async initialize () {
        try {
          this.saving = true
          this.warehouseUsers = (await WareHouseUserService.index()).data.wareHouseUsers   
          this.saving = false
          this.errorMatch = false
          this.editedItem = this.defaultItem
          this.select = {}
          this.select2 = {}
        } catch (err) {
          this.error = err
         
        }
      },
      editItem (item) {
        this.errorMatch = false
        this.editedIndex = this.warehouseUsers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        this.select = this.editedItem.UserId
        this.select2 = this.editedItem.WareHouseId
      },

      async deleteItem (item) {
        try {
          const index = this.warehouseUsers.indexOf(item)
          const warningMessage = item.email
          if (confirm(this.langC.AreYouShureToDelete + ' ' + warningMessage + '!') && this.warehouseUsers.splice(index, 1)) {
            await WareHouseUserService.delete(item.id)
            this.initialize()
          }
        } catch(err) {
          this.error = err
          
        }
        
      },

      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      async save () {
        try {
           let doneOK = false
           if (this.editedIndex > -1) {
              await WareHouseUserService.put(this.editedItem)
              doneOK = true
            } else {
              //this.editedItem.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
              //this.editedItem.email = this.$store.state.user.email ? this.$store.state.user.email : ''
              this.editedItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : ''
              this.editedItem.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
              this.editedItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : ''
              await WareHouseUserService.post(this.editedItem)
              doneOK = true
            }
            if (doneOK) {
              this.initialize()
              this.close()
            }
        } catch(err) {
          this.error = err
          
        }
       
      },
      localDate(dateToFormat) {
       return dateFormat(dateToFormat)
      },
      choosedUser (choosedId) {
        if (this.locusers) {
          this.locusers.forEach(element => {
            if (element.id === choosedId) {
              this.editedItem.UserId = element.id
              this.editedItem.email = element.email
              this.editedItem.useridright = Number(element.AccessLevel)
            }
          })
        }
      },
      choosedWareHouse (choosedId) {
        if (this.locWareHouses) {
          this.locWareHouses.forEach(element => {
            if (element.id === choosedId) {
              this.editedItem.WareHouseId = element.id
              this.editedItem.WareHouseName = element.WareHouseName
            }
          })
        }
      }
    },
  }
</script>