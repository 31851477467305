import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menushow: false,
    databaseDocID: 0,
    user: null,
    userid: 0,
    token: null,
    isUserLoggedIn: false,
    language: 'HR',
    companyName: '',
    companyid: 0,
    companyuserid: 0,
    companybusinessyearid: 0,
    companyActiv: {},
    useridright: 1,
    employeeid: 0,
    empldayid: 0,
    employeeActiv: {},
    onPage: {},
    workhourid: 0,
    businessYear: 2022,
    defExRate: 7.5345,
    oldCurrency : 'Kn',
    newCurrency : '€',
    pcs: 0,
    price: 0,
    amo1: 0,
    vat: 0,
    editItem: 0,
    editSubItem: 0,
    newItem: 0,
    newItemAround: false,
    groupid: 0,
    itemid: 0,
    selectedItemId: 0,
    partnerid: 0,
    partnerIBAN: '',
    partnerVATID: '',
    itemStore: [],
    groupStore: [],
    partnerStore: [],
    employeeStore: [],
    bankStore: [],
    bankActiv: {},
    userStore: [],
    documentid: 0,
    documentdetailid: 0,
    documentTypeid: 0,
    documentTypeName: '',
    documentActiv: '',
    documentActivLoc: '',
    documentActivData: {},
    documentActivHead: {},
    documentActivItems: [],
    documentAna: {},
    documentSide: 0,
    documentAnalysis: 0,
    warehousemarkid: 1,
    warehouseuserid: 0,
    warehouseState: [],
    taskid: 0,
    tasktypeid: 0,
    taskdepartmentid: 0,
    taskviewtype: 0,
    bankaccountid: 0,
    account1id: 0,
    account1: '',
    account2id: 0,
    account2: '',
    account3id: 0,
    account3: '',
    account4id: 0,
    account4: '',
    account1s: [],
    account2s: [],
    account3s: [],
    account4s: [],
    mainledgerid: 0,
    mainledgerdetailid: 0,
    mainledgerActiv: {},
    mainledgerStatus: 0,
    mainledgerTotals: 0,
    mainledgerItems: [],
    mainledgerErrorItems: [],
    mainledgerItem: {},
    mainledgerSideItems: [],
    mainledgerDetailItems: [],
    mainledgerSideItem: {},
    printSmall: false,
    printNoPrice: false,
    dontGo: false,
    itemsPOS: [],
    typePOS: 0,
    typePOSPrint: 0,
    typePOSName: '',
    retPath: '',
    pathAgain: '',
    ConnName: '',
    ConnId: 0,
    resetList: 0,
    bookTypeSelected: null,
    dialogState: false,
    searchState: {},
    projectTypes: [],
    printObj: {},
    stickers: [],
    actionType: 0,
    vehicleid: 0,
    dateItems: null,
    datePartners: null,
    dateAccounts: null,
    tikTok: 0,
    colors: [
      {text: 'red',
      value: 'red darken-1'},
      {text: 'pink',
      value: 'pink darken-1'},
      {text: 'purple',
      value: 'purple darken-1'},
      {text: 'deep-purple',
      value: 'deep-purple darken-1'},
      {text: 'indigo',
      value: 'indigo darken-1'},
      {text: 'blue',
      value: 'blue darken-1'},
      {text: 'light-blue',
      value: 'light-blue darken-1'},
      {text: 'cyan',
      value: 'cyan darken-1'},
      {text: 'teal',
      value: 'teal darken-1'},
      {text: 'green',
      value: 'green darken-1'},
      {text: 'light-green',
      value: 'light-green darken-1'},
      {text: 'lime',
      value: 'lime darken-1'},
      {text: 'yellow',
      value: 'yellow darken-1'},
      {text: 'amber',
      value: 'amber darken-1'},
      {text: 'orange',
      value: 'orange darken-1'},
      {text: 'deep-orange',
      value: 'deep-orange darken-1'},
      {text: 'brown',
      value: 'brown darken-1'},
      {text: 'blue-grey',
      value: 'blue-grey darken-1'},
      {text: 'grey',
      value: 'grey darken-1'},
    ],
    banksHR: [
      {text: 'Addiko Bank d.d., Zagreb', vatid: '14036333877'},
      {text: 'Agram banka d.d., Zagreb', vatid: '70663193635'},
      {text: 'Banka Kovanica d.d., Varaždin', vatid: '33039197637'},
      {text: 'Croatia banka d.d., Zagreb', vatid: '32247795989'},
      {text: 'Erste&Steiermärkische Bank d.d., Rijeka', vatid: '23057039320'},
      {text: 'Hrvatska poštanska banka d. d., Zagreb', vatid: '87939104217'},
      {text: 'Imex banka d.d., Split', vatid: '99326633206'},
      {text: 'Istarska kreditna banka Umag d.d., Umag', vatid: '65723536010'},
      {text: 'J&T banka d.d., Varaždin', vatid: '38182927268'},
      {text: 'Karlovačka banka d.d., Karlovac', vatid: '08106331075'},
      {text: 'KentBank d.d., Zagreb', vatid: '73656725926'},
      {text: 'OTP banka d.d., Split', vatid: '52508873833'},
      {text: 'Partner banka d.d., Zagreb', vatid: '71221608291'},
      {text: 'Podravska banka d.d., Koprivnica', vatid: '97326283154'},
      {text: 'Privredna banka Zagreb d.d., Zagreb', vatid: '02535697732'},
      {text: 'Raiffeisenbank Austria d.d., Zagreb', vatid: '53056966535'},
      {text: 'Samoborska banka d.d., Samobor', vatid: '13806526186'},
      {text: 'Nova hrvatska banka d.d., Zagreb', vatid: '78427478595'},
      {text: 'Slatinska banka d.d., Slatina', vatid: '42252496579'},
      {text: 'Zagrebačka banka d.d., Zagreb', vatid: '92963223473'},
    ],
    booksubtype: [
      {text: 'IRA_NOTAX_RH', value: '1', descHR: 'ISPORUKE U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_DRUGIM', value: '2', descHR: 'ISPORUKE DOBARA OBAVLJENE U DRUGIM DRŽAVAMA ČLANICAMA', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_DOB_EU', value: '3', descHR: 'ISPORUKE DOBARA UNUTAR EU', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_USL_EU', value: '4', descHR: 'OBAVLJENE USLUGE UNUTAR EU', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_BEZSJEDISTARH', value: '5', descHR: 'OBAVLJENE ISPORUKE OSOBAMA BEZ SJEDIŠTA U RH', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_RAD_U_EU', value: '6', descHR: 'SASTAVLJANJE I POSTAVLJANJE DOBARA U DRUGOJ DRŽAVI ČLANICI EU', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_NOVA_VOZILA', value: '7', descHR: 'ISPORUKE NOVIH PRIJEVOZNIH SREDSTAVA U EU', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_TUZEMNE', value: '8', descHR: 'TUZEMNE ISPORUKE', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_IZVOZ', value: '9', descHR: 'IZVOZNE ISPORUKE', descEN: '',  side: 2},
      {text: 'IRA_NOTAX_OSTALO', value: '10', descHR: 'OSTALA OSLOBOĐENJA', descEN: '',  side: 2},
    ],
    children: [
      {text: 'One_child', value: 1, calc: 0.7, calc2024: 0.5, amount: 232.27, amount2024: 280, descHR: 'Jedno dijete 0.7', descEN: 'One child'},
      {text: 'Two_child', value: 2, calc: 1.0, calc2024: 0.7, amount: 331.81, amount2024: 392, descHR: 'Dvoje djece 1.0', descEN: 'Two childs'},
      {text: 'Three_child', value: 3, calc: 1.4, calc2024: 1, amount: 464.53, amount2024: 560, descHR: 'Troje djece 1.4', descEN: 'Three childs'},
      {text: 'Four_child', value: 4, calc: 1.9, calc2024: 1.4, amount: 630.44, amount2024: 784, descHR: 'Četvero djece 1.9', descEN: 'Four childs'},
      {text: 'Five_child', value: 5, calc: 2.5, calc2024: 1.9, amount: 829.53, amount2024: 1064, descHR: 'Petero djece 2.5', descEN: 'Five childs'},
      {text: 'Six_child', value: 6, calc: 3.2, calc2024: 2.5, amount: 1061.79, amount2024: 1400, descHR: 'Šestero djece 3.2', descEN: 'Six childs'},
      {text: 'Seven_child', value: 7, calc: 4.0, calc2024: 3.2, amount: 1327.24,amount2024: 1792, descHR: 'Sedmero djece 4.0', descEN: 'Seven childs'},
      {text: 'Eight_child', value: 8, calc: 4.9, calc2024: 4, amount: 1625.87,amount2024: 2240, descHR: 'Osmero djece 4.9', descEN: 'Eight childs'},
      {text: 'Nine_child', value: 9, calc: 5.9, calc2024: 4.9, amount: 1957.68, amount2024: 2744, descHR: 'Devetero djece 5.9', descEN: 'Nine childs'},
    ],
    familysupport: [
      {text: 'One', value: 1, calc: 0.5, amount: 280, descHR: 'Jedan član', descEN: 'One'},
      {text: 'Two', value: 2, calc: 2, amount: 464.53, descHR: 'Dva člana', descEN: 'Two'},
      {text: 'Three', value: 3, calc: 3, amount: 696.79, descHR: 'Tri člana', descEN: 'Three'},
      {text: 'Four', value: 4, calc: 4, amount: 796.34, descHR: 'Četiri člana', descEN: 'Four'},
      {text: 'Five', value: 5, calc: 5, amount: 1028.60, descHR: 'Pet člana', descEN: 'Five'},
    ],
    invalidtype: [
      {text: 'One', value: 1, calc: 0.3, amount: 168, descHR: 'Djelomična invalidnost', descEN: 'Part invalidity'},
      {text: 'Two', value: 2, calc: 1.5, amount: 497.72, descHR: '100% invalidnost', descEN: '100% invalidity'},
    ],
    citytax: [
      {text: '1%', value: 1},
      {text: '2%', value: 2, },
      {text: '3%', value: 3,},
      {text: '4%', value: 4},
      {text: '5%', value: 5,},
      {text: '6%', value: 6,},
      {text: '7%', value: 7, },
      {text: '7.5%', value: 7.5, },
      {text: '8%', value: 8, },
      {text: '9%', value: 9, },
      {text: '10%', value: 10, },
      {text: '12%', value: 12, },
      {text: '13%', value: 13, },
      {text: '15%', value: 15, },
      {text: '18%', value: 18, },
    ],
    typeOfPayment: [
      {text: 'Nema isplate', value: 0, default: false},
      {text: 'Isplata na tekući račun', value: 1, default: true},
      {text: 'Isplata na žiro račun', value: 2,},
      {text: 'Ostale bezgotovinske isplate na račune sukladno posebnim propisima', value: 3, default: false},
      {text: 'Isplata u gotovini', value: 4,default: false},
      {text: 'Davanje u naravi', value: 5,default: false},
      {text: 'Naplata primitka u ovršnom postupku', value: 6, default: false},
      {text: 'Isplate putem obračunskih plaćanja', value: 7, default: false}
    ],
    salariesType: [
      {text: 'Obračun plaće za', value: 0, default: true},
      {text: 'Ugovor o djelu', value: 1, default: false},
      {text: 'Autorski ugovor', value: 2, default: false},
      {text: 'Umjetničko djelo', value: 3, default: false},
    ],
    salariesFrom: [
      {text: 'MIOIstup', value: 1, per: 15, descHR: 'Doprinos za mirovinsko osiguranje 15%', descEN: 'Pension 1 degree', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8168-'},
      {text: 'MIOIIstup', value: 2, per: 5, descHR: 'Doprinos za MIO na temelju indiv. kapital. štednje 5%', descEN: 'Pension 2 degree savings', bank: 'HR7610010051700036001', bank01: 'HR68', bank02: '2283-'},
      {text: 'MIOIstup', value: 3, per: 20, descHR: 'Doprinos za MIOI stup-gener.solidar. 20%', descEN: 'Pension 1 degree 20%', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8168-'},
      {text: 'MIOIstupP', value: 4, per: 15, descHR: 'Doprinos za MIO I stup poduzetnik 15%', descEN: 'Pension 1 degree ENT', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8184-'},
      {text: 'MIOIIstupP', value: 5, per: 5, descHR: 'Doprinos za MIO II stup poduzetnik 5%', descEN: 'Pension 2 degree savings ENT', bank: 'HR7610010051700036001', bank01: 'HR68', bank02: '2305-'},
      {text: 'MIOIstupP', value: 6, per: 20, descHR: 'Doprinos za MIOI I stup poduzetnik 20%', descEN: 'Pension 1 degree 20% ENT', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8184-'},
      {text: 'MIOI2D', value: 7, per: 7.5, descHR: 'Doprinos za MIO I stup 2D 7.5%', descEN: 'Pension 1 degree 2D', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8176-'},
      {text: 'MIOII2D', value: 8, per: 2.5, descHR: 'Doprinos za MIO II stup 2D  2.5%', descEN: 'Pension 2 degree savings 2D', bank: 'HR7610010051700036001', bank01: 'HR68', bank02: '2291-'},
      {text: 'MIOI2D', value: 9, per: 10, descHR: 'Doprinos za MIOI I stup 2D 10%', descEN: 'Pension 1 degree 10%', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8176-'},
    ],
    salariesTo: [
      {text: 'HEALTH1', value: 1, per: 16.5, descHR: 'Doprinos za zdravstveno osiguranje - 16,5%', descEN: 'Health Ins 16.5', bank: 'HR6510010051550100001', bank01: 'HR68', bank02: '8486-'},
      {text: 'HEALTH2', value: 2, per: 12.38, descHR: 'Doprinos za zdravstveno osiguranje - 12,38%', descEN: 'Pension 2 degree savings', bank: 'HR6510010051550100001', bank01: 'HR68', bank02: '8486-'},
      {text: 'HEALTH3', value: 3, per: 7.5, descHR: 'Doprinos za zdravstveno osiguranje - 2D', descEN: 'Pension 1 degree 20%', bank: 'HR6510010051550100001', bank01: 'HR68', bank02: '8540-'},
      {text: 'HEALTH4', value: 4, per: 0.5, descHR: 'Doprinos zaštita zdravlja na radu - 0.5%', descEN: 'Pension 2 degree savings', bank: 'HR6510010051550100001', bank01: 'HR68', bank02: '8630-'},
      {text: 'MIOIstupPT', value: 5, per: 3.61, descHR: 'Doprinos za MIO I stup poveć.tr. 12 za 14mj  3.61%', descEN: 'Pension 1 degree ENT', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8192-'},
      {text: 'MIOIIstupPT', value: 6, per: 1.25, descHR: 'Doprinos za MIO II stup poveć.tr. 12 za 14mj  1.25%', descEN: 'Pension 2 degree savings ENT', bank: 'HR7610010051700036001', bank01: 'HR68', bank02: '2321-'},
      {text: 'MIOIstupPT', value: 7, per: 4.86, descHR: 'Doprinos za MIOI I stup poveć.tr. 12 za 14mj 4.86%', descEN: 'Pension 1 degree 20% ENT', bank: 'HR1210010051863000160', bank01: 'HR68', bank02: '8192-'},
    ],
    notaxaddition: [
      {text: '100 - Osobno primanje isplaćeno u cijelosti', value: 100, protected: false},
      {text: '110 - Isplata dijela osobnog primanja - zaštićeni dio', value: 110, protected: true},
      {text: '120 - Osobno primanje umanjeno za zaštićeni dio', value: 120,protected: false},
      {text: '130 - Ugovor o djelu – zaštićeni dio', value: 130, protected: true},
      {text: '140 - Rad za vrijeme školovanja – zaštićeni dio', value: 140,protected: true},
      {text: '150 - Isplata dividende', value: 150, protected: false},
      {text: '160 - Naknada članova Upravnog vijeća, Skupština, Nadzornih odbora', value: 160, protected: false },
      {text: '170 - Primanja od iznajmljivanja turističkih kapaciteta', value: 170, protected: false},
      {text: '180 - Najam', value: 180, protected: false},
      {text: '190 - Prijevoz – zaštićeni dio', value: 190, protected: true},
      {text: '191 - Topli obrok, do propisanih iznosa do kojih se ne smatraju oporezivim primicima', value: 191, protected: true},
      {text: '200 - Službeni put – zaštićeni dio', value: 200, protected: true},
      {text: '210 - Terenski dodatak, dnevnice u tuzemstvu i i inozemstvu, dnevnice za rad na terenu u tuzemstvu i inozemstvu i dnevnice za službena putovanja - zaštićeni dio', value: 210, protected: true},
      {text: '220 - Naknada za odvojeni život', value: 220, protected: true},
      {text: '230 - Naknada za bolovanje', value: 230, protected: true},
      {text: '240 - Naknada za korištenje privatnog automobila u službene svrhe', value: 240, protected: false},
      {text: '250 - Naknada za prekovremeni rad, bonusi, stimulacije, ostale nagrade , do propisanih iznosa do kojih se ne smatraju oporezivim primicima ', value: 250, protected: true},
      {text: '260 - Regres, do propisanih iznosa do kojih se ne smatraju oporezivim primicima ', value: 260, protected: true},
      {text: '270 - Božićnica, uskrsnica , do propisanih iznosa do kojih se ne smatraju oporezivim primicima', value: 270, protected: true},
      {text: '280 - Dječji dar – zaštićeni dio', value: 280, protected: true},
      {text: '290 - Stipendije, nagrade, pomoć studentima/učenicima za opremu, knjige i ostalo', value: 290, protected: true},
      {text: '300 - Pomoć u slučaju stupanja u brak', value: 300, protected: false},
      {text: '310 - Pomoć u slučaju rođenja djeteta – zaštićeni dio', value: 310, protected: true},
      {text: '320 - Otpremnina', value: 320, protected: false},
      {text: '330 - Pomoć u slučaju smrti zaposlenika/člana obitelji zaposlenika', value: 330, protected: true},
      {text: '340 - Naknada za bolovanje – zaštićeni dio', value: 340, protected: true},
      {text: '350 - Zakonsko uzdržavanje i naknade štete', value: 350, protected: true},
      {text: '360 - Primanja po osnovi socijalne skrbi', value: 360, protected: true},
      {text: '361 - Sindikalne socijalne potpore', value: 361, protected: true},
      {text: '370 - Naknada za nezaposlene', value: 370, protected: true},
      {text: '380 - Doplatak za djecu', value: 380, protected: true},
      {text: '390 - Naknada za rad osuđenika', value: 390, protected: true},
      {text: '400 - Rodiljne i roditeljske novčane potpore', value: 400, protected: true},
      {text: '410 - Sportske stipendije za sportaše s invaliditetom', value: 410, protected: true},
      {text: '420 - Naknada za saniranje posljedica štete od katastrofa i elementarnih nepogoda', value: 420, protected: true},
      {text: '431 - Primanja po osnovi naknade zbog tjelesnoga oštećenja prema propisima o invalidskom osiguranju', value: 431, protected: true},
      {text: '432 - Potpore zbog invalidnosti radnika i neprekidnog bolovanja radnika duljeg od 90 dana, potpore za slučaj smrti radnika i smrti člana uže obitelji radnika, do propisanih iznosa do kojih se ne smatraju oporezivim primicima po osnovi od nesamostalnog rada,', value: 432, protected: true},
      {text: '433 - Novčane naknada žrtvama kaznenih djela nasilja počinjenih s namjerom', value: 433, },
      {text: '440 - Primanja po osnovi odličja i priznanja', value: 440, protected: true},
      {text: '441 - Naknada za pričuvnike pozvane na izvršenje vojne obveze', value: 441, protected: true},
      {text: '450 - Uplate Agencije za plaćanja u poljoprivredi, ribarstvu i ruralnom razvoju', value: 450, protected: true},
      {text: '451 - Pomorski dodatak I pomorski dodatak na brodovima međunarodne plovidbe do propisanih iznosa do kojih se ne smatraju oporezivim primicima', value: 451, protected: true},
      {text: '500 - Krediti', value: 500, protected: false},
      {text: '510 - Uzdržavanje djeteta (alimentacija)', value: 510, protected: true},
      {text: '600 - Mirovina isplaćena u cijelosti', value: 600, protected: false},
      {text: '610 - Mirovina - nezaštićeni dio', value: 610, protected: false},
      {text: '620 - Mirovina - zaštićeni dio', value: 620, protected: true},
      {text: '621 - Nacionalna naknada za starije osobe', value: 621, protected: true},
      {text: '640 - Potpore zbog invalidnosti – zaštićeni dio', value: 640, protected: true},
      {text: '650 - Doplatak za tuđu njegu i pomoć', value: 650, protected: true},
      {text: '660 - Isplata stambenog kredita', value: 660, protected: true},
      {text: '690 - Ostala primanja izuzeta od ovrhe', value: 690, protected: true},
      {text: '600 - Ostala osobna primanja koja nisu izuzeta od ovrhe', value: 699, protected: false },

    ],
    joppd: [
      {text: '2. Šifra općine/grada prebivališta /boravišta', value: 2},
      {text: '3. Šifra općine/grada rada', value: 3, },
      {text: '4. OIB stjecatelja/osiguranika', value: 4,},
      {text: '5. Ime i prezime stjecatelja/osiguranika', value: 5},
      {text: '6.1 Oznaka stjecatelja/ osiguranika', value: 6.1,},
      {text: '6.2 Oznaka primitka/obveze doprinosa', value: 6.2,},
      {text: '7.1 Obveza dodatnog doprinosa za MO za staž s povećanim trajanjem', value: 7.1, },
      {text: '7.2 Obveza posebnog doprinosa za poticanje zapošljavanja osoba s invaliditetom', value: 7.2, },
      {text: '8. Oznaka prvog/zadnjeg mjeseca u osiguranju po istoj osnovi', value: 8., },
      {text: '9. Oznaka punog/ nepunog radnog vremena ili rada s polovicom radnog vremena', value: 9., },
      {text: '10. Ukupni sati rada prema kojima se radi obračun', value: 10, },
      {text: '10.0 Ukupni neodrađeni sati rada (10.- odrađeni sati rada)', value: 10.01, },
      {text: '10.1 Razdoblje obračuna od', value: 10.1, },
      {text: '10.2 Razdoblje obračuna do', value: 10.2, },
      {text: '11. Iznos primitka (oporezivi)', value: 11, },
      {text: '12. Osnovica za obračun doprinosa', value: 12, },
      {text: '12.1 Doprinos za mirovinsko osiguranje', value: 12.1, },
      {text: '12.2 Doprinos za mirovinsko osiguranje - II STUP', value: 12.2, },
      {text: '12.3 Doprinos za zdravstveno osiguranje', value: 12.3, },
      {text: '12.4 Doprinos za zaštitu zdravlja na radu', value: 12.4, },
      {text: '12.5 Doprinos za zapošljavanje', value: 12.5, },
      {text: '12.6 Dodatni doprinos za mirovinsko osig. za staž osiguranja koji se računa s povećanim trajanjem', value: 12.6, },
      {text: '12.7 Dodatni doprinos za mirovinsko osiguranje za staž osiguranja koji se računa s povećanim trajanjem - II STUP', value: 12.7, },
      {text: '12.8 Poseban doprinos za korištenje zdravstvene zaštite u inozemstvu', value: 12.8, },
      {text: '12.9 Poseban doprinos za zapošljavanje osoba s invaliditetom', value: 12.9, },
      {text: '13.1 Izdatak', value: 13.1, },
      {text: '13.2 Izdatak - uplaćeni doprinos za mirovinsko osiguranje', value: 13.2, },
      {text: '13.3 Dohodak', value: 13.3, },
      {text: '13.4 Osobni odbitak', value: 13.4, },
      {text: '13.5 Porezna osnovica', value: 13.5, },
      {text: '14.1 Iznos obračunanog poreza na dohodak', value: 14.1, },
      {text: '14.2 Iznos obračunanog prireza porezu na dohodak', value: 14.2, },
      {text: '15.1 Oznaka neoporezivog primitka', value: 15.1, },
      {text: '15.2 Iznos neoporezivog primitka', value: 15.2, },
      {text: '16.1 Oznaka načina isplate', value: 16.1, },
      {text: '16.2 Iznos za isplatu', value: 16.2, },
      {text: '17. Obračunani primitak od nesam. rada (plaća)', value: 17, },
    ],
    joppdNotax: [
      {text: '01 Primici od kamata po obveznicama koji su obračunati nakon 1. siječnja 2016. i primici po osnovi ugovora životnog osiguranja', value: 1},
      {text: '02 Primici po osnovi kapitalnih dobitaka od otuđenja financijske imovine ako to nije djelatnost poreznog obveznika koje ne podliježu oporezivanju ...', value: 2},
      {text: '03 Novčani dodaci uz mirovinu koje umirovljenicima isplaćuju jedinice lokalne i područne (regionalne) samouprave, na temelju svojih općih akata..', value: 3},
      {text: '04 Obiteljske mirovine i invalidnine koje djeca ostvaruju nakon smrti roditelja prema zakonu ...', value: 4},
      {text: '07 Socijalne potpore', value: 7},
      {text: '08 Doplatak za djecu i novčani primici za opremu novorođenog djeteta i primici po osnovi potpora za novorođenče,...', value: 8},
      {text: '09 Potpore zbog zbrinjavanja ratnih invalida i članova obitelji smrtno stradalih, zatočenih ili nestalih hrvatskih branitelja...', value: 8},
      {text: '10 Potpore koje djeci u slučaju smrti roditelja isplaćuju ili daju jedinice lokalne i područne (regionalne) samouprave...', value: 10},
      {text: '11 Naknada razlike plaće za vrijeme vojne službe u Oružanim snagama Republike Hrvatske te naknada razlike plaće pričuvniku pozvanom...', value: 11},
      {text: '12 Naknada plaće za vrijeme spriječenosti za rad isplaćena na teret sredstava obveznih osiguranja', value: 12},
      {text: '13 Nagrade učenicima za vrijeme praktičnog rada i naukovanja te naknade učenicima za vrijeme dualnog obrazovanja, do propisanog iznosa', value: 13},
      {text: '15 Primici učenika i studenata na školovanju za rad preko učeničkih i studentskih udruga, po posebnim propisima, do propisanog iznosa', value: 15},
      {text: '16 Naknade štete zbog posljedica nesreće na radu prema odluci suda ili nagodbi u tijeku sudskog postupka...', value: 16},
      {text: '17 Dnevnice za službena putovanja i naknade za korištenje privatnog automobila u službene svrhe, do propisanog iznosa isplaćene po osnovi službenih putovanja', value: 17},
      {text: '18 Naknade za korištenje privatnog automobila u službene svrhe, do propisanog iznosa', value: 18},
      {text: '19 Naknade troškova prijevoza na posao i s posla mjesnim i međumjesnim javnim prijevozom', value: 19},
      {text: '20 Potpore zbog invalidnosti radnika do propisanog iznosa, potpore za slučaj smrti radnika,...', value: 20},
      {text: '21 Dar djetetu do 15 godina starosti, do propisanog iznosa i potpore za novorođenče, do propisanog iznosa', value: 21},
      {text: '22 Prigodne nagrade, do propisanog iznosa (božićnica, naknada za godišnji odmor i sl.) za tekuće porezno razdoblje', value: 22},
      {text: '23 Dnevnica za rad na terenu, do propisanog iznosa', value: 23},
      {text: '24 Pomorski dodatak, do propisanog iznosa', value: 24},
      {text: '25 Naknade za odvojeni život od obitelji, do propisanog iznosa', value: 25},
      {text: '26 Otpremnine, do propisanog iznosa', value: 26},
      {text: '28 Stipendije i potpora djetetu za školovanje do 15. godine života odnosno do završetka osnovnoškolskog obrazovanja', value: 28},
      {text: '29 Nagrade za sportska ostvarenja i naknade sportašima amaterima prema posebnim propisima', value: 29},
      {text: '30 Pomoći i potpore koje neprofitne organizacije isplaćuju svim svojim članovima...', value: 30},
      {text: '31 Primici koji se kao bespovratna sredstva isplaćuju iz fondova i programa Europske unije putem tijela akreditiranih u skladu s pravilima Europske komisije ..', value: 31},
      {text: '32 Primici koje isplaćuje Hrvatski zavod za mirovinsko osiguranje, a koji se ne smatraju primicima od nesamostalnog rada', value: 32},
      {text: '34 Primici po osnovi dividendi i udjela u dobiti iskorišteni za uvećanje temeljnog kapitala društva', value: 34},
      {text: '35 Obiteljske mirovine odnosno novčane naknade u visini obiteljske mirovine ostvarene prema Zakonu o pravima hrvatskih branitelja..', value: 35},
      {text: '38 Primici fizičkih osoba po osnovi izravnih plaćanja u poljoprivredi..', value: 38},
      {text: '40 Povrat isplaćene naknade plaće za vrijeme spriječenosti za rad isplaćena na teret sredstava obveznih osiguranja..', value: 40},
      {text: '41 Ostale nenavedene naknade plaća koje se isplaćuju na teret državnog proračuna..', value: 41},
      {text: '42 Primici po osnovi dividendi i udjela u dobiti koji ne podliježu oporezivanju', value: 42},
      {text: '52 Dnevnice za službena putovanja i naknade za korištenje privatnog automobila u službene svrhe...', value: 52},
      {text: '54 Novčana pomoć polaznicima stručnog osposobljavanja za rad bez zasnivanja radnog odnosa...', value: 54},
      {text: '55 Primici u visini razlike između primljenih bespovratnih sredstava isplaćenih iz fondova EU ...', value: 55},
      {text: '56 Športske stipendije, do propisanog iznosa', value: 56},
      {text: '57 Primici po osnovi mirovina koje HZMO isplaćuju nerezidentima Republike Hrvatske...', value: 57},
      {text: '58 Primici koji se smatraju neoporezivim primicima/primicima prema kojima se ne utvrđuju doprinosi...', value: 58},
      {text: '59 Obračunani porez na dohodak koji se iskazuje kod naknadne isplate plaće/ostalog primitak uz plaću', value: 59},
      {text: '60 Nagrade radnicima za navršene godine staža, do propisanih iznosa', value: 60},
      {text: '61 Prigodne nagrade, do propisanog iznosa (božićnica, naknada za godišnji odmor i sl.) za prethodna porezna razdoblja (naknadne isplate)', value: 61},
      {text: '62 Trošak prehrane i smještaja sezonskih radnika iz članka 7. stavka 35. Pravilnika o porezu na dohodak', value: 62},
      {text: '63 Novčane nagrade za radne rezultate i drugi oblici dodatnog nagrađivanja radnika (dodatna plaća, dodatak uz mjesečnu plaću i sl.)', value: 63},
      {text: '64 Troškovi smještaja i prehrane osoba koje obavljaju privremene odnosno povremene sezonske poslove u poljoprivredi članka 6. stavka 10. Pravilnika o porezu na dohodak', value: 64},
      {text: '65 Novčane paušalne naknade za podmirivanje troškova prehrane radnika do propisanog iznosa', value: 65},
      {text: '66 Troškovi prehrane radnika ... propisanog iznosa', value: 66},
      {text: '67 Troškovi smještaja radnika ... podmiruju bezgotovinskim putem', value: 67},
      {text: '68 Troškovi smještaja radnika ... podmiruju na račun radnika', value: 68},
      {text: '69 Naknade za podmirivanje troškova ugostiteljskih, turističkih i drugih usluga namijenjenih odmoru radnika', value: 69},
      {text: '70 Naknade za troškove redovne skrbi djece radnika  ... vrtic', value: 70},
      {text: '71 Premije dodatnog i dopunskog zdravstvenog osiguranja koje poslodavac uplaćuje ...', value: 71},
      {text: '72 Nacionalna naknada za starije osobe prema posebnim propisima', value: 72},
      {text: '73 Novčane paušalne naknade za rad na izdvojenom radnom mjestu', value: 72},
      {text: '99 Razlika po godišnjem obračunu poreza', value: 99},
    ],

    // joppdStjecatelji: [
    // {text: '0000 Neoporezivo', value: '0000'},
    // {text: '0001 Radnik/osiguranik po osnovi radnog odnosa', value: '0001'},
    // {text: '0002 Radnik/osiguranik po osnovi radnog odnosa – osoba koja se prvi put zapošljava prema odredbama Zakona o doprinosima', value: ''},
    // {text: '0004 Fizička osoba izaslana na rad u Republiku Hrvatsku po nalogu inozemnog poslodavca ili poslodavca iz druge države članice Europske unije u tuzemna društva za rad u tim društvima', value: ''},
    // {text: '0005 Radnik/osiguranik koji je temeljem radnog odnosa izaslan na rad u inozemstvo', value: ''},
    // {text: '0006 Radnik/osiguranik koji je temeljem radnog odnosa izaslan na rad u inozemstvo ili drugu državu članicu Europske unije – osoba koja se prvi put zapošljava prema odredbama Zakona o doprinosima', value: ''},
    // {text: '0007 Radnik/osiguranik koji je temeljem radnog odnosa izaslan na rad u inozemstvo ili drugu državu članicu Europske unije – novozaposlena osoba prema odredbama Zakona o tržištu rada', value: ''},
    // {text: '0008 Članovi predstavničkih i izvršnih tijela državne vlasti i jedinica lokalne i područne (regionalne) samouprave (izabrane i imenovane osobe) po osnovi primitaka (plaće) koji im se isplaćuju za rad u tim tijelima i jedinicama', value: ''},
    // {text: '0009 Ostali stjecatelji/osiguranici po osnovi radnog odnosa/plaće', value: ''},
    // {text: '0010 Radnik/osiguranik po osnovi radnog odnosa – mlada osoba prema odredbama Zakona o doprinosima', value: ''},
    // {text: '0011 Radnik/osiguranik koji je temeljem radnog odnosa izaslan na rad u inozemstvo – mlada osoba prema odredbama Zakona o doprinosima', value: ''},
    // {text: '0012 Radnik/osiguranik po osnovi radnog odnosa - dijete smrtno stradalog hrvatskog branitelja iz Domovinskog rata ili dijete nestalog hrvatskog branitelja iz Domovinskog rata prema odredbama Zakona o hrvatskim braniteljima iz Domovinskog rata i članovima njihovih obitelji', value: ''},
    // {text: '0021 Radnik/osiguranik po osnovi radnog odnosa–rad u kućanstvu', value: ''},
    // {text: '0022 Radnik/osiguranik po osnovi radnog odnosa–rad u kućanstvu koji se prvi put zapošljava prema odredbama Zakona o doprinosima', value: ''},
    // {text: '0023 Radnik/osiguranik po osnovi radnog odnosa–rad u kućanstvu koji se prvi put zapošljava prema odredbama Zakona o tržištu rada', value: ''},
    // {text: '0024 Ostali stjecatelji/osiguranici po osnovi radnog odnosa–rad u kućanstvu', value: ''},
    // {text: '0025 Radnik/osiguranik po osnovi radnog odnosa–rad u kućanstvu mlada osoba premaodredbama Zakona o doprinosima', value: ''},
    // {text: '0031 Stjecatelj/osiguranik–trgovac pojedinac ', value: ''},
    // {text: '0032 Ostali stjecatelji/osiguranici koji obavljaju samostalnu djelatnost i po toj osnovi obveznici su poreza na dobit', value: ''},
    // {text: '0033 Stjecatelji/osiguranici koji ostvaruju primitke od imovine i imovinskih prava od kojih,prema propisima o porezu na dohodak, utvrđuju dohodak od imovine i imovinskih prava, ali', value: ''},
    // {text: '0041 Stjecatelj primitka/osiguranik po osnovi obavljanja samostalne djelatnosti obrta ', value: ''},
    // {text: '0042 Stjecatelj primitka/osiguranik po osnovi obavljanja samostalne djelatnosti slobodnog zanimanja (profesionalne djelatnosti) koji obavlja djelatnosti medicinske sestre, zubotehničara, fizioterapeuta, filmskog radnika, novinara i predstavnik obiteljskog doma', value: ''},
    // {text: '0043 Ostali nenavedeni stjecatelji primitka/osiguranici po osnovi obavljanja samostalne djelatnosti slobodnog zanimanja i sportaši (profesionalne djelatnosti)', value: ''},
    // {text: '0044 Stjecatelj primitka/osiguranik po osnovi obavljanja samostalne djelatnosti poljoprivrede i šumarstva ', value: ''},
    // {text: '0045 Stjecatelji/osiguranici koji ostvaruju primitke od imovine i imovinskih prava od kojih, prema propisima o porezu na dohodak, utvrđuju dohodak od imovine i imovinskih prava, ali su promijenili način utvrđivanja dohotka, pa prema tim primicima utvrđuju dohodak od samostalne djelatnosti (članak 7. točka 5.3. Zakona o doprinosima)', value: ''},
    // {text: '0046 Ostali stjecatelj primitka/osiguranik po osnovi obavljanja ostalih samostalnih djelatnosti (članak 7. točka 5.1.,5.2. i 5.4. Zakona o doprinosima)', value: ''},
    // {text: '0101 Umirovljenik korisnik starosne mirovine', value: ''},
    // {text: '0102 Stjecatelj obiteljske mirovine koja podliježe oporezivanju', value: ''},
    // {text: '0103 Stjecatelj obiteljske mirovine koja ne podliježe oporezivanju, ali po osnovi koje postoji obveza doprinosa', value: ''},
    // {text: '0104 Umirovljenik korisnik invalidske mirovine', value: ''},
    // {text: '0105 Stjecatelj invalidske mirovine zbog profesionalne nesposobnosti za rad koja se isplaćuje umirovljenicima obvezno osiguranim po osnovi radnog odnosa ili obavljanja samostalne djelatnosti', value: ''},
    // {text: '0106 Umirovljenik korisnik mirovine prema Zakonu o pravima hrvatskih branitelja iz Domovinskog rata i članova njihovih obitelji', value: ''},
    // {text: '0107 Ostali stjecatelji mirovine prema posebnom propisu', value: ''},
    // {text: '0108 Stjecatelji ostalih mirovina', value: ''},
    // {text: '0121 Stjecatelji mirovine-ostali isplatitelji', value: ''},
    // {text: '0201 Stjecatelj primitka od nesamostalnog rada koji se prema propisima o porezu na dohodak oporezuje kao dohodak od nesamostalnog rada, a prema propisima o doprinosima kao drugi dohodak', value: ''},
    // {text: '1001 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala', value: ''},
    // {text: '1002 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala, a pri obračunu primjenjuje se poreza stopa propisana odredbama ugovora o izbjegavanju dvostrukog oporezivanja', value: ''},
    // {text: '1003 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala ostvaren iz inozemstva ili druge države članice Europske unije', value: ''},
    // {text: '1004 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala ostvaren izravno iz inozemstva ili druge države članice Europske unije, a pri obračunu primjenjuje se  poreza stopa propisana odredbama ugovora o izbjegavanju dvostrukog    oporezivanja', value: ''},
    // {text: '1101 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala po osnovi prihoda od kamata', value: ''},
    // {text: '1102 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala po osnovi prihoda od kamata, a pri obračunu primjenjuje se poreza stopa propisana odredbama ugovora o izbjegavanju dvostrukog oporezivanja', value: ''},
    // {text: '1103 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala po osnovi prihoda od kamata ostvaren iz inozemstva ili druge države članice Europske unije', value: ''},
    // {text: '1104 Stjecatelj primitka od kojega se utvrđuje dohodak od kapitala po osnovi prihoda od kamata ostvaren izravno iz inozemstva ili druge države članice Europske unije, a pri obračunu primjenjuje se poreza stopi izbjegavanju dvostrukog oporezivanja', value: ''},
    // {text: '2001 Stjecatelj primitka od kojega se utvrđuje dohodak od imovinskih prava', value: ''},
    // {text: '2002 Stjecatelj primitka od kojega se utvrđuje dohodak od imovinskih prava, a pri obračunu primjenjuje se poreza stopa propisana odredbama ugovora o izbjegavanju dvostrukog oporezivanja', value: ''},
    // {text: '2003 Stjecatelj primitka od kojega se utvrđuje dohodak od imovinskih prava ostvaren iz inozemstva ili druge države članice Europske unije', value: ''},
    // {text: '2004 Stjecatelj primitka od kojega se utvrđuje dohodak od imovinskih prava ostvaren izravno iz inozemstva ili druge države članice Europske unije, a pri obračunu', value: ''},
    // {text: '2005 Stjecatelj primitka od kojega se utvrđuje dohodak od posebnih vrsta imovine', value: ''},
    // {text: '4001 Osiguranik/stjecatelj primitka od kojega se utvrđuje drugi dohodak, a po osnovi kojeg ne postoji obveza doprinosa', value: ''},
    // {text: '4002 Osiguranik/stjecatelj primitka od kojega se utvrđuje drugi dohodak, a po osnovi kojeg postoji obveza doprinosa', value: ''},
    // {text: '5001 Osiguranik na radu ili stručnom usavršavanju, odnosno obrazovanju u inozemstvu duže od 30 dana', value: ''},
    // {text: '5002 Osiguranik upućen na službeni put u inozemstvo u trajanju do 30 dana', value: ''},
    // {text: '5101 Osiguranik po osnovi korisnika invalidske mirovine zbog profesionalne nesposobnosti za rad', value: ''},
    // {text: '5102 Osiguranik po osnovi roditelja koji obavlja roditeljske dužnosti', value: ''},
    // {text: '5103 Osiguranik koji naknadu plaće ostvaruje na teret sredstava mirovinskog osiguranja na temelju generacijske solidarnosti', value: ''},
    // {text: '5104 Osiguranik–osoba s  invaliditetom kojoj se staž mirovinskog osiguranja računa s povećanim trajanjem', value: ''},
    // {text: '5201 Osiguranik koji naknadu plaće za vrijeme bolovanja ostvaruje na teret sredstava nositelja obveznoga zdravstvenog osiguranja - općenito', value: ''},
    // {text: '5202 Osiguranik koji naknadu plaće odnosno novčanu naknadu za vrijeme bolovanja ostvaruje na teret sredstava državnog proračuna – općenito', value: ''},
    // {text: '5203 Osiguranik koji naknadu plaće ostvaruje za vrijeme korištenja rodiljnog dopusta i dopusta radi smrti djeteta tijekom rodiljnog dopusta, a kojem se naknada plaće isplaćuje na teret HZZO-a', value: ''},
    // {text: '5204 Osiguranik koji naknadu plaće ostvaruje za vrijeme korištenja roditeljskog dopusta, posvojiteljskog dopusta, očinskog dopusta, skraćenog radnog vremena radi pojačane njege djeteta, stanke za dojenje, a kojemu se naknada isplaćuje na teret državnog proračuna', value: ''},
    // {text: '5205 Osiguranik koji naknadu plaće ostvaruje nakon prestanka radnog odnosa, a kojemu se naknada plaće isplaćuje na teret HZZO-a', value: ''},
    // {text: '5206 Osiguranik koji ostvaruje naknadu plaće nakon prestanka radnog odnosa, a kojemu se naknada plaće isplaćuje na teret državnog proračuna', value: ''},
    // {text: '5207 Osiguranik koji ostvaruje naknadu plaće za vrijeme komplikacija u trudnoći, a kojemu se naknada isplaćuje na teret HZZO-a', value: ''},
    // {text: '5208 Osiguranik koji ostvaruje naknadu plaće za vrijeme privremene nesposobnosti za rad zbog rane, ozljede ili bolesti koja je neposredna posljedica sudjelovanja u Domovinskom ratu, a kojemu se naknada isplaćuje na teret HZZO-a', value: ''},
    // {text: '5209 Osiguranik koji ostvaruje naknadu plaće za vrijeme bolovanja, koje je posljedica ozljede na radu ili profesionalne bolesti, a kojemu se naknada isplaćuje na teret HZZO-a', value: ''},
    // {text: '5210 Osiguranik koji ostvaruje naknadu plaće po osnovi roditelja djeteta s težim smetnjama u razvoju koji ima pravo na dopust za njegu djeteta do navršene osme godine djetetova života, a kojemu se naknada plaće isplaćuje na teret državnog proračuna', value: ''},
    // {text: '5211 Osiguranik koji ostvaruje naknadu plaće po osnovi roditelja djeteta s težim smetnjama u razvoju koji ima pravo na rad s polovicom radnog vremena zbog njege djeteta kojem se naknada plaće isplaćuje na teret državnog proračuna', value: ''},
    // {text: '5212 Ostali osiguranici prema kojima HZZO ima obvezu obračuna doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje na minimalnu osnovicu za obračun doprinosa na teret državnog proračuna', value: ''},
    // {text: '5213 Ostali osiguranici prema kojima HZZO ima obvezu obračuna doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje po osnovi naknade plaće na teret državnog proračuna', value: ''},
    // {text: '5301 Osiguranik po osnovi nezaposlena osoba (za obvezu doprinosa za mirovinsko osiguranje na temelju individualne kapitalizirane štednje)', value: ''},
    // {text: '5302 Osiguranici po osnovi nezaposlene osobe za vrijeme stručnog osposobljavanja ili profesionalne rehabilitacije na koje ih je uputila nadležna služba zapošljavanja', value: ''},
    // {text: '5401 Osiguranik po osnovi roditelja–njegovatelja, ministarstvo mjerodavno za socijalnu skrb ili drugo tijelo sukladno posebnom propisu', value: ''},
    // {text: '5402 Osiguranik po osnovi produženog mirovinskog osiguranja – bračnog druga profesionalnog odnosno ugovornog diplomata i osiguranik posebnog doprinosa zakorištenje zdravstvene zaštite u inozemstvu za članove obitelji profesionalnog odnosno ugovornog diplomata', value: ''},
    // {text: '5403 Osiguranik koji ostvaruje naknadu plaće po osnovi pružanja njege i pomoći hrvatskim ratnim vojnim invalidima iz Domovinskog rata I. skupine', value: ''},
    // {text: '5404 Osiguranik po osnovi udomitelja, ministarstvo mjerodavno za socijalnu skrb ili drugo tijelo sukladno posebnom propisu', value: ''},
    // {text: '5501 Osiguranik/stjecatelj primitka po osnovi učenika i redovitog studenta za vrijeme rada preko posrednika pri zapošljavanju', value: ''},
    // {text: '5601 Osiguranik po osnovi osobe koja je prekinula rad, a bivši ju je poslodavac uputio na obrazovanje ili stručno usavršavanje', value: ''},
    // {text: '5602 Osiguranik po osnovi osobe koju je pravna ili fizička osoba, prije stupanja u radni odnos, uputila na praktični rad', value: ''},
    // {text: '5603 Osiguranik po osnovi osobe upućene u inozemstvo u sklopu međunarodne tehničko-prosvjetne i kulturne suradnje', value: ''},
    // {text: '5604 Ostali osiguranici mirovinskog osiguranja u određenim okolnostima i zdravstvenog  osiguranja zaštite zdravlja na radu za slučaj ozljede na radu i profesionalne bolesti', value: ''},
    // {text: '5605 Osiguranik po osnovi stranca za kojega troškove zdravstvene zaštite snosi davatelj stipendije', value: ''},
    // {text: '5608 Osiguranik po osnovi slobodnog zanimanja–samostalnog umjetnika kojemu se doprinosi plaćaju iz državnog proračuna', value: ''},
    // {text: '5701 Osiguranik po osnovi stručnog osposobljavanja za rad bez zasnivanja radnogo dnosa prema Zakonu o radu', value: ''},
    // {text: '5702 Osiguranik po osnovi stručnog osposobljavanja za rad bez zasnivanja radnog odnosa prema odredbama Zakona o tržištu rada', value: ''},
    // ],
    
// 0001
// 0002
// 0003
// 0004
// 0005 
// 0006 
// 0007 
// 0008 
// 0009
// 0010 
// 0011 
// 0012 
// 0013
// 0021
// 0022
// 0023 
// 0024  
// 0025
// 0026 
// 0027 
// 0028 
// 0029
// 0030
// 0031
// 0032
// 0033
// 0034
// 0041
// 0042
// 0043
// 0044
// 0045
// 0046
// 0047
// 0048
// 0049 
// 0050 
// 0051
// 0052 
// 0053
// 0061
// 0062
// 0063
// 0064
// 0065
// 0066
// 0067
// 0068
// 0071 
// 0072 
// 0073 
// 0081 
// 0082 
// 0083 
// 0084 
// 0086 
// 0087 
// 0088 
// 0090 
// 0091
// 0092 
// 0093 
// 0094 
// 0095 
// 0096 
// 0097 
// 0098 
// 0101
// 0102 
// 0103 

    booktype: [{
        text: 'URA_TU',
        value: 1,
        descHR: 'PRETPOREZ OD PRIMLJENIH ISPORUKA U TUZEMSTVU',
        descEN: '',
        side: 1
      },
      {
        text: 'URA_PPO',
        value: 2,
        descHR: 'PRETPOREZ OD PRIMLJENIH ISPORUKA U RH ZA KOJE PDV OBRAČUNAVA PRIMATELJ (tuzemni prijenos porezne obveze)',
        descEN: '',
        side: 1
      },
      {
        text: 'URA_SD_EU',
        value: 3,
        descHR: 'PRETPOREZ OD STJECANJA DOBARA UNUTAR EU',
        descEN: '',
        side: 1
      },
      {
        text: 'URA_SU_EU',
        value: 4,
        descHR: 'PRETPOREZ OD PRIMLJENIH USLUGA IZ EU ',
        descEN: '',
        side: 1
      },
      {
        text: 'URA_SDU_NORH',
        value: 5,
        descHR: 'PRETPOREZ OD PRIMLJENIH ISPORUKA DOBARA I USLUGA OD POREZNIH OBVEZNIKA BEZ SJEDIŠTA U RH',
        descEN: '',
        side: 1
      },
      {
        text: 'URA_PDV_UVOZ',
        value: 6,
        descHR: 'PRETPOREZ PRI UVOZU',
        descEN: '',
        side: 1
      },
      {
        text: 'IRA_TU',
        value: 10,
        descHR: '',
        descEN: '',
        side: 2
      },
      {
        text: 'IRA_EU',
        value: 11,
        descHR: '',
        descEN: '',
        side: 2
      },
      {
        text: 'IRA_INO',
        value: 12,
        descHR: '',
        descEN: '',
        side: 2
      },
      {
        text: 'IRA_OST',
        value: 13,
        descHR: '',
        descEN: '',
        side: 2
      },
      {
        text: 'TEM',
        value: 20,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'PS',
        value: 21,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'OS',
        value: 22,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Sitni',
        value: 23,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Placa',
        value: 24,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Komp',
        value: 25,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Putni',
        value: 26,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Ost',
        value: 27,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Maloprodaja',
        value: 28,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'Zatvaranje',
        value: 29,
        descHR: '',
        descEN: '',
        side: 3
      },
      {
        text: 'PSB',
        value: 30,
        descHR: '',
        descEN: '',
        side: 3
      }
    ],
    cardtype: [{
      text: 'MasterCard',
      id: 1,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Visa',
      id: 2,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'DinersClub',
      id: 3,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Apple Card',
      id: 4,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Ostalo',
      id: 5,
      descHR: '',
      descEN: '',
      side: 1
    }
    ],
    productiontype: [{
      text: 'Priprema',
      value: 1,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Za proizvodnju',
      value: 2,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'U tijeku',
      value: 3,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Proizvedeno',
      value: 4,
      descHR: '',
      descEN: '',
      side: 1
    },
    {
      text: 'Otpremljeno',
      value: 5,
      descHR: '',
      descEN: '',
      side: 1
    }
    ]
  },
  getters: {
    NeedTikTok(state) {
      return state.tikTok
    },
    NeedMenuShow(state) {
      return state.menushow
    },
    NeedDatabaseDocID(state) {
      return state.databaseDocID
    },
    NeedonPage(state) {
      return state.onPage
    },
    NeedUserID(state) {
      let idR = 0
      if (state.user) {
        idR = state.user.id
      } else {
        idR = ''
      }
      return idR
    },
    NeedUserIdRight(state) {
      return state.useridright
    },
    NeedCompanyID(state) {
      return state.companyid
    },
    NeedDefExRate(state) {
      let exRate = 1
      if (state.businessYear <= 2022) {
        exRate = 7.5345
      }
      return exRate
    },
    NeedUseremail(state) {
      let idR = ''
      if (state.user) {
        idR = state.user.email
      } else {
        idR = ''
      }
      return idR
    },
    NeedTypePOS(state) {
      return state.typePOS
    },
    NeedToken(state) {
      return state.token
    },
    NeedReturnPath(state) {
      return state.retPath
    },
    NeedLanguage(state) {
      return state.language
    },
    NeededitItem(state) {
      return state.editItem
    },
    NeededitSubItem(state) {
      return state.editSubItem
    },
    NeedFullUser(state) {
      if (!state.user) {
        return ''
      } else {
        return state.user.First + ' ' + state.user.Last
      }
    },
    NeedBusinessYear(state) {
      return state.businessYear
    },
    NeedDocumentActiv(state) {
      return state.documentActiv
    },
    NeedDocumentSide(state) {
      return state.documentSide
    },
    NeedDocumentActivHead(state) {
      return state.documentActivHead
    },
    NeedEmployeeActiv(state) {
      return state.employeeActiv
    },
    NeedMainLedgerStatus(state) {
      return state.mainledgerStatus
    },
    NeedMainLedgerTotals(state) {
      return state.mainledgerTotals
    },
    NeedMainLedgerItems(state) {
      return state.mainledgerItems
    },
    NeedMainLedgerErrorItems(state) {
      return state.mainledgerErrorItems
    },
    NeedMainLedgerSideItems(state) {
      return state.mainledgerSideItems
    },
    NeedMainLedgerDetailItems(state) {
      return state.mainledgerDetailItems
    },
    NeedMainLedgerItem(state) {
      return state.mainledgerItem
    },
    NeedMainLedgerActiv(state) {
      return state.mainledgerActiv
    },
    NeedMainLedgerBookTypeSelected(state) {
      return state.bookTypeSelected
    },
    NeedPartners(state) {
      return state.partnerStore
    },
    NeedPartnerid(state) {
      return state.partnerid
    },
    NeedPartnerVATID(state) {
      return state.partnerVATID
    },
    NeedPartnerIBAN(state) {
      return state.partnerIBAN
    },
    NeedAccount4id(state) {
      return state.account4id
    },
    NeedSelectedItemId(state) {
      return state.selectedItemId
    },
    NeedResetList(state) {
      return state.resetList
    },
    NeedProductionType(state) {
      return state.productiontype
    },
    NeedDialogState(state) {
      return state.dialogState
    },
    NeedStickersState(state) {
      return state.stickers
    },
    NeedVehicleid(state) {
      return state.vehicleid
    },
    NeedWareHouseMarkId(state) {
      return state.warehousemarkid
    }
  },
  mutations: {
    setDatabaseDocID(state, databaseDocID) {
      state.databaseDocID = databaseDocID
    },
    setTikTok(state, tikTok) {
      state.tikTok = tikTok
    },
    setMenuShow(state, menushow) {
      state.menushow = menushow
    },
    setToken(state, token) {
      state.token = token
      if (token) {
        state.isUserLoggedIn = true
      } else {
        state.isUserLoggedIn = false
      }
    },
    setUser(state, user) {
      state.user = user
    },
    setUserId(state, userid) {
      state.userid = userid
    },
    setUserIdRight(state, useridright) {
      state.useridright = useridright
    },
    seteditItem(state, editItem) {
      state.editItem = editItem
    },
    seteditSubItem(state, editSubItem) {
      state.editSubItem = editSubItem
    },
    setnewItem(state, newItem) {
      state.newItem = newItem
    },
    setnewItemAround(state, newItem) {
      state.newItemAround = newItem
    },
    setCompanyid(state, companyid) {
      state.companyid = companyid
    },
    setCompanyUserid(state, companyuserid) {
      state.companyuserid = companyuserid
    },
    setCompanyBusinessYearid(state, companybusinessyearid) {
      state.companybusinessyearid = companybusinessyearid
    },
    setCompanyActiv(state, companyActiv) {
      state.companyActiv = companyActiv
    },
    setEmployeeid(state, employeeid) {
      state.employeeid = employeeid
    },
    setEmpldayid(state, empldayid) {
      state.empldayid = empldayid
    },
    setEmployeeActiv(state, employeeActivData) {
      state.employeeActiv = employeeActivData
    },
    setPcs(state, pcs) {
      state.pcs = pcs
    },
    setWorkHourid(state, workhourid) {
      state.workhourid = workhourid
    },
    setCompanyName(state, companyname) {
      state.companyName = companyname
    },
    setBusinessYear(state, businessyear) {
      state.businessYear = businessyear
    },
    setGroupid(state, groupid) {
      state.groupid = groupid
    },
    setItemid(state, itemid) {
      state.itemid = itemid
    },
    setSelectedItemId(state, itemid) {
      state.selectedItemId = itemid
    },
    setDocumentid(state, documentid) {
      state.documentid = documentid
    },
    setDocumentDetailid(state, documentdetailid) {
      state.documentdetailid = documentdetailid
    },
    setDocumentTypeid(state, documentTypeid) {
      state.documentTypeid = documentTypeid
    },
    setDocumentTypeName(state, documentTypeName) {
      state.documentTypeName = documentTypeName
    },
    setDocumentActiv(state, docName) {
      state.documentActiv = docName
    },
    setDocumentActivLoc(state, docNameLoc) {
      state.documentActivLoc = docNameLoc
    },
    setDocumentActivData(state, documentActivData) {
      state.documentActivData = documentActivData
    },
    setDocumentActivHead(state, documentActivHead) {
      state.documentActivHead = documentActivHead
    },
    setDocumentActivItems(state, documentActivItems) {
      state.documentActivItems = documentActivItems
    },
    setDocumentAna(state, documentAna) {
      state.documentAna = documentAna
    },
    setActivItems(state, items) {
      state.itemStore = items
    },
    setActivGroups(state, groups) {
      state.groupStore = groups
    },
    setActivEmployes(state, employes) {
      state.employeeStore = employes
    },
    setDocumentSide(state, sidetype) {
      state.documentSide = sidetype
    },
    setDocumentAnalysis(state, anatype) {
      state.documentAnalysis = anatype
    },
    setPartnerid(state, partnerid) {
      state.partnerid = partnerid
    },
    setPartnerIBAN(state, partnerIBAN) {
      state.partnerIBAN = partnerIBAN
    },
    setPartnerVATID(state, partnerVATID) {
      state.partnerVATID = partnerVATID
    },
    setWareHouseMarkid(state, warehousemarkid) {
      state.warehousemarkid = warehousemarkid
    },
    setWareHouseUserid(state, warehouseuserid) {
      state.warehouseuserid = warehouseuserid
    },
    setWarehouseState(state, warehouseState) {
      state.warehouseState = warehouseState
    },
    setTaskid(state, taskid) {
      state.taskid = taskid
    },
    setTaskTypeid(state, tasktypeid) {
      state.tasktypeid = tasktypeid
    },
    setTaskViewType(state, taskviewtype) {
      state.taskviewtype = taskviewtype
    },
    setTaskDepartmentid(state, taskdepartmentid) {
      state.taskdepartmentid = taskdepartmentid
    },
    setBankAccountid(state, bankaccountid) {
      state.bankaccountid = bankaccountid
    },
    setAccount1id(state, account1id) {
      state.account1id = account1id
    },
    setAccount1(state, account1) {
      state.account1 = account1
    },
    setAccount2id(state, account2id) {
      state.account2id = account2id
    },
    setAccount2(state, account2) {
      state.account2 = account2
    },
    setAccount3id(state, account3id) {
      state.account3id = account3id
    },
    setAccount3(state, account3) {
      state.account3 = account3
    },
    setAccount4id(state, account4id) {
      state.account4id = account4id
    },
    setAccount4(state, account4) {
      state.account4 = account4
    },
    setAccount1s(state, account1s) {
      state.account1s = account1s
    },
    setAccount2s(state, account2s) {
      state.account2s = account2s
    },
    setAccount3s(state, account3s) {
      state.account3s = account3s
    },
    setAccount4s(state, account4s) {
      state.account4s = account4s
    },
    setLanguage(state, lang) {
      state.language = lang
    },
    setretPath(state, retPath) {
      state.retPath = retPath
    },
    setMainLedgerid(state, mainledgerid) {
      state.mainledgerid = mainledgerid
    },
    setMainLedgerDetailid(state, mainledgerdetailid) {
      state.mainledgerdetailid = mainledgerdetailid
    },
    setMainLedgerActiv(state, mainledgerActiv) {
      state.mainledgerActiv = mainledgerActiv
    },
    setMainLedgerStatus(state, mainledgerStatus) {
      state.mainledgerStatus = mainledgerStatus
    },
    setMainLedgerTotals(state, mainledgerTotals) {
      state.mainledgerTotals = mainledgerTotals
    },
    setMainLedgerItems(state, mainledgerItems) {
      state.mainledgerItems = mainledgerItems
    },
    setMainLedgerErrorItems(state, mainledgerErrorItems) {
      state.mainledgerErrorItems = mainledgerErrorItems
    },
    setMainLedgerItem(state, mainledgerItem) {
      state.mainledgerItem = mainledgerItem
    },
    setMainLedgerSideItems(state, mainledgerSideItems) {
      state.mainledgerSideItems = mainledgerSideItems
    },
    setMainLedgerDetailItems(state, mainledgerDetailItems) {
      state.mainledgerDetailItems = mainledgerDetailItems
    },
    setMainLedgerSideItem(state, mainledgerSideItem) {
      state.mainledgerSideItem = mainledgerSideItem
    },
    setpathAgain(state, pathAgain) {
      state.pathAgain = pathAgain
    },
    setprintSmall(state, printSmall) {
      state.printSmall = printSmall
    },
    setprintNoPrice(state, printNoPrice) {
      state.printNoPrice = printNoPrice
    },
    setitemsPOS(state, itemsPOS) {
      state.itemsPOS = itemsPOS
    },
    settypePOS(state, typePOS) {
      state.typePOS = typePOS
    },
    settypePOSPrint(state, typePOSPrint) {
      state.typePOSPrint = typePOSPrint
    },
    settypePOSName(state, typePOSName) {
      state.typePOSName = typePOSName
    },
    setdontGo(state, dontGo) {
      state.dontGo = dontGo
    },
    setbookTypeSelected(state, bookTypeSelected) {
      state.bookTypeSelected = bookTypeSelected
    },
    setPartners(state, partners) {
      state.partnerStore = partners
    },
    setConnName(state, connName) {
      state.connName = connName
    },
    setConnId(state, connId) {
      state.connId = connId
    },
    setResetList(state, resetList) {
      state.resetList = resetList
    },
    setDialogState(state, dialogState) {
      state.dialogState = dialogState
    },
    setSearchState(state, searchState) {
      state.searchState = searchState
    },
    setProjectTypes(state, projectTypes) {
      state.projectTypes = projectTypes
    },
    setPrintObj(state, printObj) {
      state.printObj = printObj
    },
    setStickers(state, stickers) {
      state.stickers = stickers
    },
    setBankActiv(state, bankActiv) {
      state.bankActiv = bankActiv
    },
    setOnPage(state, onPage) {
      state.onPage = onPage
    },
    setActionType(state, actionType) {
      state.actionType = actionType
    },
    setVehicleid(state, vehicleid) {
      state.vehicleid = vehicleid
    },
    setDateItems(state, dateItems) {
      state.dateItems = dateItems
    },
    setDatePartners(state, datePartners) {
      state.datePartners = datePartners
    },
    setDateAccounts(state, dateAccounts) {
      state.dateAccounts = dateAccounts
    },
  },
  actions: {
    setDatabaseDocID({
      commit
    }, databaseDocID) {
      commit('setDatabaseDocID', databaseDocID)
    },
    setTikTok({
      commit
    }, tikTok) {
      commit('setTikTok', tikTok)
    },
    setMenuShow({
      commit
    }, menushow) {
      commit('setMenuShow', menushow)
    },
    setToken({
      commit
    }, token) {
      commit('setToken', token)
    },
    setUser({
      commit
    }, user) {
      commit('setUser', user)
    },
    setUserId({
      commit
    }, userid) {
      commit('setUserId', userid)
    },
    setUserIdRight({
      commit
    }, useridright) {
      commit('setUserIdRIght', useridright)
    },
    seteditItem({
      commit
    }, editItem) {
      commit('seteditItem', editItem)
    },
    seteditSubItem({
      commit
    }, editSubItem) {
      commit('seteditSubItem', editSubItem)
    },
    setnewItem({
      commit
    }, newItem) {
      commit('setnewItem', newItem)
    },
    setnewItemAround({
      commit
    }, newItem) {
      commit('setnewItemAround', newItem)
    },
    setCompanyid({
      commit
    }, companyid) {
      commit('setCompanyid', companyid)
    },
    setCompanyUserid({
      commit
    }, companyuserid) {
      commit('setCompanyUserid', companyuserid)
    },
    setCompanyBusinessYearid({
      commit
    }, companybusinessyearid) {
      commit('setCompanyBusinessYearid', companybusinessyearid)
    },
    setCompanyActiv({
      commit
    }, companyActiv) {
      commit('setCompanyActiv', companyActiv)
    },
    setEmployeeid({
      commit
    }, employeeid) {
      commit('setEmployeeid', employeeid)
    },
    setEmpldayid({
      commit
    }, empldayid) {
      commit('setEmpldayid', empldayid)
    },
    setEmployeeActiv({
      commit
    }, employeeActivData) {
      commit('setEmployeeActiv', employeeActivData)
    },
    setPcs({
      commit
    }, pcs) {
      commit('setPcs', pcs)
    },
    setWorkHourid({
      commit
    }, workhourid) {
      commit('setWorkHouridWorkid', workhourid)
    },
    setCompanyName({
      commit
    }, companyname) {
      commit('setCompanyName', companyname)
    },
    setBusinessYear({
      commit
    }, businessyear) {
      commit('setBusinessYear', businessyear)
    },
    setGroupid({
      commit
    }, groupid) {
      commit('setGroupid', groupid)
    },
    setItemid({
      commit
    }, itemid) {
      commit('setItemid', itemid)
    },
    setSelectedItemId({
      commit
    }, itemid) {
      commit('setSelectedItemId', itemid)
    },
    setDocumentid({
      commit
    }, documentid) {
      commit('setDocumentid', documentid)
    },
    setDocumentDetailid({
      commit
    }, documentdetailid) {
      commit('setDocumentDetailid', documentdetailid)
    },
    setDocumentTypeid({
      commit
    }, documentTypeid) {
      commit('setDocumentTypeid', documentTypeid)
    },
    setDocumentTypeName({
      commit
    }, documentTypeName) {
      commit('setDocumentTypeName', documentTypeName)
    },
    setDocumentActiv({
      commit
    }, docName) {
      commit('setDocumentActiv', docName)
    },
    setDocumentActivHead({
      commit
    }, docHead) {
      commit('setDocumentActivHead', docHead)
    },
    setDocumentActivItems({
      commit
    }, docItems) {
      commit('setDocumentActivItems', docItems)
    },
    setActivItems({
      commit
    }, items) {
      commit('setActivItems', items)
    },
    setActivGroups({
      commit
    }, groups) {
      commit('setActivGroups', groups)
    },
    setActivEmployes({
      commit
    }, employes) {
      commit('setActivEmployes', employes)
    },
    setDocumentActivLoc({
      commit
    }, docNameLoc) {
      commit('setDocumentActivLoc', docNameLoc)
    },
    setDocumentActivData({
      commit
    }, documentActivData) {
      commit('setDocumentActivData', documentActivData)
    },
    setDocumentAna({
      commit
    }, documentAna) {
      commit('setDocumentAna', documentAna)
    },
    setDocumentSide({
      commit
    }, sidetype) {
      commit('setDocumentSide', sidetype)
    },
    setDocumentAnalysis({
      commit
    }, anatype) {
      commit('setDocumentAnalysis', anatype)
    },
    setPartnerid({
      commit
    }, partnerid) {
      commit('setPartnerid', partnerid)
    },
    setPartnerIBAN({
      commit
    }, partnerIBAN) {
      commit('setPartnerIBAN', partnerIBAN)
    },
    setPartnerVATID({
      commit
    }, partnerVATID) {
      commit('setPartnerVATID', partnerVATID)
    },
    setWareHouseMarkid({
      commit
    }, warehousemarkid) {
      commit('setWareHouseMarkid', warehousemarkid)
    },
    setWareHouseUserid({
      commit
    }, warehouseuserid) {
      commit('setWareHouseUserid', warehouseuserid)
    },
    setWarehouseState({
      commit
    }, warehouseState) {
      commit('setWarehouseState', warehouseState)
    },
    setTaskid({
      commit
    }, taskid) {
      commit('setTaskid', taskid)
    },
    setTaskTypeid({
      commit
    }, tasktypeid) {
      commit('setTaskTypeid', tasktypeid)
    },
    setTaskViewType({
      commit
    }, taskviewtype) {
      commit('setTaskViewType', taskviewtype)
    },
    setTaskDepartmentid({
      commit
    }, taskdepartmentid) {
      commit('setTaskDepartmentid', taskdepartmentid)
    },
    setBankAccountid({
      commit
    }, bankaccountid) {
      commit('setBankAccountid', bankaccountid)
    },
    setAccount1id({
      commit
    }, account1id) {
      commit('setAccount1id', account1id)
    },
    setAccount1({
      commit
    }, account1) {
      commit('setAccount1', account1)
    },
    setAccount2id({
      commit
    }, account2id) {
      commit('setAccount2id', account2id)
    },
    setAccount2({
      commit
    }, account2) {
      commit('setAccount2', account2)
    },
    setAccount3id({
      commit
    }, account3id) {
      commit('setAccount3id', account3id)
    },
    setAccount3({
      commit
    }, account3) {
      commit('setAccount3', account3)
    },
    setAccount4id({
      commit
    }, account4id) {
      commit('setAccount4id', account4id)
    },
    setAccount4({
      commit
    }, account4) {
      commit('setAccount4', account4)
    },
    setAccount1s({
      commit
    }, account1s) {
      commit('setAccount1s', account1s)
    },
    setAccount2s({
      commit
    }, account2s) {
      commit('setAccount2s', account2s)
    },
    setAccount3s({
      commit
    }, account3s) {
      commit('setAccount3s', account3s)
    },
    setAccount4s({
      commit
    }, account4s) {
      commit('setAccount4s', account4s)
    },
    setLanguage({
      commit
    }, lang) {
      commit('setLanguage', lang)
    },
    setretPath({
      commit
    }, retPath) {
      commit('setretPath', retPath)
    },
    setMainLedgerid({
      commit
    }, mainledgerid) {
      commit('setMainLedgerid', mainledgerid)
    },
    setMainLedgerDetailid({
      commit
    }, mainledgerdetailid) {
      commit('setMainLedgerDetailid', mainledgerdetailid)
    },
    setMainLedgerActiv({
      commit
    }, mainledgerActiv) {
      commit('setMainLedgerActiv', mainledgerActiv)
    },
    setMainLedgerStatus({
      commit
    }, mainledgerStatus) {
      commit('setMainLedgerStatus', mainledgerStatus)
    },
    setMainLedgerTotals({
      commit
    }, mainledgerTotals) {
      commit('setMainLedgerTotals', mainledgerTotals)
    },
    setMainLedgerItems({
      commit
    }, mainledgerItems) {
      commit('setMainLedgerItems', mainledgerItems)
    },
    setMainLedgerErrorItems({
      commit
    }, mainledgerErrorItems) {
      commit('setMainLedgerErrorItems', mainledgerErrorItems)
    },
    setMainLedgerItem({
      commit
    }, mainledgerItem) {
      commit('setMainLedgerItem', mainledgerItem)
    },
    setMainLedgerSideItems({
      commit
    }, mainledgerSideItems) {
      commit('setMainLedgerSideItems', mainledgerSideItems)
    },
    setMainLedgerDetailItems({
      commit
    }, mainledgerDetailItems) {
      commit('setMainLedgerDetailItems', mainledgerDetailItems)
    },
    setMainLedgerSideItem({
      commit
    }, mainledgerSideItem) {
      commit('setMainLedgerSideItem', mainledgerSideItem)
    },
    setpathAgain({
      commit
    }, pathAgain) {
      commit('setpathAgain', pathAgain)
    },
    setprintSmall({
      commit
    }, printSmall) {
      commit('setprintSmall', printSmall)
    },
    setprintNoPrice({
      commit
    }, printNoPrice) {
      commit('setprintNoPrice', printNoPrice)
    },
    setitemsPOS({
      commit
    }, itemsPOS) {
      commit('setitemsPOS', itemsPOS)
    },
    settypePOS({
      commit
    }, typePOS) {
      commit('settypePOS', typePOS)
    },
    settypePOSPrint({
      commit
    }, typePOSPrint) {
      commit('settypePOSPrint', typePOSPrint)
    },
    settypePOSName({
      commit
    }, typePOSName) {
      commit('settypePOSName', typePOSName)
    },
    setdontGo({
      commit
    }, dontGo) {
      commit('setdontGo', dontGo)
    },
    setbookTypeSelected({
      commit
    }, bookTypeSelected) {
      commit('setbookTypeSelected', bookTypeSelected)
    },
    setPartners({
      commit
    }, partners) {
      commit('setPartners', partners)
    },
    setConnName({
      commit
    }, connName) {
      commit('setConnName', connName)
    },
    setConnId({
      commit
    }, connId) {
      commit('setConnId', connId)
    },
    setResetList({
      commit
    }, resetList) {
      commit('setResetList', resetList)
    },
    setDialogState({
      commit
    }, dialogState) {
      commit('setDialogState', dialogState)
    },
    setSearchState({
      commit
    }, searchState) {
      commit('setSearchState', searchState)
    },
    setProjectTypes({
      commit
    }, projectTypes) {
      commit('setProjectTypes', projectTypes)
    },
    setPrintObj({
      commit
    }, printObj) {
      commit('setPrintObj', printObj)
    },
    setStickers({
      commit
    }, stickers) {
      commit('setStickers', stickers)
    },
    setBankActiv({
      commit
    }, bankActiv) {
      commit('setBankActiv', bankActiv)
    },
    setOnPage({
      commit
    }, onPage) {
      commit('setOnPage', onPage)
    },
    setActionType({
      commit
    }, actionType) {
      commit('setActionType', actionType)
    },
    setVehicleid({
      commit
    }, vehicleid) {
      commit('setVehicleid', vehicleid)
    },
    setDateItems({
      commit
    }, dateItems) {
      commit('setDateItems', dateItems)
    },
    setDatePartners({
      commit
    }, datePartners) {
      commit('setDatePartners', datePartners)
    },
    setDateAccounts({
      commit
    }, dateAccounts) {
      commit('setDateAccounts', dateAccounts)
    },
  }
})