<template>
  <v-row no-gutters class="pt-2 pb-2">
    <v-col cols="12">
    <div class="blue lighten-5 rounded " @click="showstavke">
      <v-row no-gutters class="ma-0 pa-0">
        <v-col cols="5">
          <span class="text-h7 font-weight-bold ml-2">{{(iznos ? 'Iznos:'+ localMoney(iznos) + ' €' : '')}}</span>
        </v-col>
        <v-col cols="7" class="text-right">
          <span class="text-h8  mr-2">Račun: {{kasaid}}, {{localDate(datum)}}, {{user}}</span>
        </v-col>
      </v-row>

    </div>
    <div v-if="sstavke">
        <v-row  dense>
          <v-col cols="7" style="font-size:10px font-weight:bold">
            Naziv
          </v-col>
          <v-col cols="1"    style="font-size:10px font-weight:bold">
            Kol
          </v-col>
          <v-col cols="2" class="text-center" style="font-size:10px font-weight:bold">
            Cijena
          </v-col>
          <v-col cols="2" class="text-right"  style="font-size:10px font-weight:bold">
            Iznos
          </v-col>
      </v-row>
      <v-row v-for="item in stavke" :key="item.id" dense>
          <v-col cols="7" style="font-size:10px;">
            {{ item.NAZIV }}
          </v-col>
          <v-col cols="1"    style="font-size:12px;">
            {{ localMoney(item.KOLICINA) }}
          </v-col>
          <v-col cols="2" class="text-center"  style="font-size:12px;">
            {{ localMoney(item.MPC) }}
          </v-col>
          <v-col cols="2" class="text-right"  style="font-size:12px;">
            {{ localMoney(item.IZNOS) }}
          </v-col>
      </v-row>
    </div>
    </v-col>
  </v-row>
</template>

<script>
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'

export default {
  name: 'poskartica',
  props: ['kasaid','iznos','datum', 'user', 'stavke'],
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      saving: false,
      postNew: false,
      query: {},
      sstavke: false
    }
  },
  mounted () {

  },
  methods: {
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    showstavke() {
      this.sstavke = !this.sstavke
    },

  },
  computed: {

  },
  watch: {

  },
  components: {


  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
