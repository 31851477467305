<template>
  <v-form> 
  <v-container class="pa-0">
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">
            <v-row>
                <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
                  <h4 v-if="typePOS === 31">
                     {{lang.EmployeeCost}} -  {{ documentNameLoc }}
                  </h4>
                  <h4 v-if="typePOS !== 31">
                     {{lang.ItemFor}}  {{ documentNameLoc }}
                  </h4>
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" v-if="typePOS !== 31">
                  <v-select
                      :items="privGroups"
                      v-model="selectGroup"
                      :label="lang.selectGroup"
                      single-line
                      item-text="GroupName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                      dense
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="9" md="9" xs="9" class="right">
                  <v-btn text small color="primary" @click="warehouseState" class="mr-1" v-if="typePOS !== 31">
                    {{langC.Warehouse}}
                  </v-btn>
                  <v-btn text small color="primary" @click="itemCard" class="mr-1" v-if="typePOS !== 31">
                    {{lang.ItemCardPurchase}}
                  </v-btn>
                  <v-btn text small color="primary" @click="itemCardMinus" class="mr-1" v-if="typePOS !== 31">
                    {{lang.ItemCardSales}}
                  </v-btn>
                  <v-btn text small color="primary" @click="itemCardSell" class="mr-1" v-if="typePOS !== 31"> 
                    {{lang.ItemCardInv}}
                  </v-btn>
                  <v-btn text small color="red darken-1" @click="deleteDD2">
                    {{langC.Delete}}
                  </v-btn>
                </v-col>
            </v-row>

            <v-divider class="mt-1"></v-divider>

          <div v-if="typePOS === 31">

             <v-row class="pt-3">
              <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                <v-dialog
                    ref="dialog1"
                    persistent
                    v-model="modal1"
                    width="290px"
                    :return-value.sync="documentDet.empWorkDay"
                  >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="documentDet.empWorkDay"
                      :label="langC.Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>

                    <v-date-picker v-model="documentDet.empWorkDay" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="modal = false">{{langC.Cancel}}</v-btn>
                      <v-btn color="primary" @click="$refs.dialog1.save(documentDet.empWorkDay)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
              </v-col>
            </v-row>

            <v-row >
                <v-col cols="12" sm="3" md="3" xs="12" lg="3" xl="3" class="mr-3 pa-0">
                  <v-autocomplete
                    :items="employes"
                    v-model="selectEm"
                    :label="lang.SelectEmployee"
                    item-text="hashtag"
                    item-value="id"
                    return-object
                    persistent-hint
                  ></v-autocomplete>
                </v-col>
              </v-row>

             <v-row>
                <v-col cols="12" sm="6" md="6" xs="12" lg="6" xl="6" class="mr-3 pa-0">
                  <v-textarea
                      :label="lang.Description"
                      v-model="documentDet.itemDescription"
                    ></v-textarea>    
                </v-col>
              </v-row>

           
             <v-row>              
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                  <v-text-field
                    dense
                    :label="lang.WorkHours"
                    v-model="documentDet.empWrkHrs"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                  <v-text-field
                    dense
                    :label="lang.WorkHourPrice"
                    v-model="documentDet.empWrkExpHrs"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                  <v-text-field
                    dense
                    :label="lang.TravelCost"
                    v-model="documentDet.empExp1"
                    type="number"
                  ></v-text-field>
                </v-col>

            </v-row>

            <v-row >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                  <v-text-field
                    dense
                    :label="lang.TravelKm"
                    v-model="documentDet.empExp2"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                <v-text-field
                  dense
                  :label="lang.ExpensesTotal"
                  v-model="documentDet.itemBaseAmount"
                  type="number"
                  disabled
                ></v-text-field>

              </v-col>
            </v-row>

          </div>


           <div v-if="typePOS === 3 || typePOS === 32 || typePOS === 33">

                <v-row class="pt-6">
                  <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                    <v-autocomplete
                      :items="itemsLoc"
                      v-model="selectIL"
                      :label="lang.ItemSelect"
                      item-text="itemByID"
                      item-value="itemID"
                      clearable
                      return-object
                    ></v-autocomplete>
                  </v-col>
              </v-row>

              <v-row class="pa-0">
                  <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemName"
                        v-model="documentDet.itemName"
                      ></v-text-field>    
                  </v-col>
              </v-row>

              <v-row class="pa-0">
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemNameShort"
                        v-model="documentDet.itemNameShort"
                      ></v-text-field>    
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemGroup"
                        v-model="documentDet.itemBaseGroup"
                        type="number"
                        disabled
                      ></v-text-field>                   
                  </v-col>
              </v-row>

              <v-row class="pa-0">
                  <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pa-0">
                    <v-textarea
                        dense
                        :label="lang.ItemDescription"
                        v-model="documentDet.itemDescription"
                      ></v-textarea>
                  </v-col>
              </v-row>

              <v-row class="pa-0">
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3 pa-0">
                    <v-text-field
                        :label="lang.ItemUnit"
                        v-model="documentDet.itemUnit"
                      ></v-text-field>    
                  </v-col>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mr-3 pa-0">
                    <v-text-field
                        :label="lang.ItemEANCode"
                        v-model="documentDet.ItemEANCode"
                      ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pa-0">
                      <v-text-field
                        :label="lang.ItemSerialNr"
                        v-model="documentDet.itemSerialNr"
                      ></v-text-field>
                  </v-col>
              </v-row>

              <v-row v-if=" this.$store.state.documentSide === 2" >

                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0 mr-2">
                    
                    <span v-if="this.$store.state.documentSide == 2">
                        <v-text-field
                          dense
                          :label="lang.ItemPcsMinus"
                          v-model="documentDet.itemPcsMinus"
                          type="number"
                        ></v-text-field>
                    </span>
                  </v-col>

                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0 mr-2">
                    <v-text-field
                        dense
                        :label="lang.ItemSuppBasePrice"
                        v-model="documentDet.itemSuppBasePrice"
                        type="number"
                      ></v-text-field>
    
                  </v-col>

                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0 mr-1">
                      <v-text-field
                        dense
                        :label="lang.ItemSuppPriceAmount"
                        v-model="this.documentDet.itemSuppBasePriceAmount"
                        type="number"
                        disabled
                      ></v-text-field>                      
                  </v-col>

                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                      <v-btn text small color="primary" @click="checkSupplierPrice" class="mr-1">
                        Dob.Provjeri
                      </v-btn>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                              
                  </v-col>
              </v-row>


              <v-row v-if="this.$store.state.documentSide == 1 || this.$store.state.documentSide === 3">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                      <span v-if="this.$store.state.documentSide == 1">
                        <v-text-field
                          dense
                          :label="lang.ItemPcsPlus"
                          v-model="documentDet.itemPcsPlus"
                          type="number"
                        ></v-text-field>
                    </span>
                    
                      <span v-if="this.$store.state.documentSide == 3" class="pa-0">
                        <v-text-field
                          dense
                          :label="lang.ItemPcsNeutral"
                          v-model="documentDet.itemPcsNeutral"
                          type="number"
                        ></v-text-field>
                    </span> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemPrice"
                        v-model="documentDet.itemPrice"
                        type="number"
                        :disabled="!fullpriceChange"
                      ></v-text-field>    
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemBaseAmount"
                        v-model="documentDet.itemBaseAmount"
                        type="number"
                        disabled
                      ></v-text-field>                   
                  </v-col>
              </v-row>


              <v-row v-if="this.$store.state.documentSide !== 2">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                        <v-text-field
                        dense
                        :label="lang.ItemPriceWithVAT"
                        v-model="documentDet.itemPriceWithVAT"
                        type="number"
                        :disabled="fullpriceChange"
                      ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                        <v-icon
                            @click="fullpriceSwitch"
                          >
                            {{fullpriceChange ? 'mdi-pencil' : 'mdi-close'}}
                          </v-icon>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0 mr-1">
                        <v-text-field
                        dense
                        :label="lang.ItemVAT1Percentage"
                        v-model="documentDet.itemVAT1Percentage"
                        type="number"
                        disabled
                      ></v-text-field>
                  </v-col>                 
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemVAT1Amount"
                        v-model="documentDet.itemVAT1Amount"
                        type="number"
                        disabled
                      ></v-text-field> 
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemAmountWithVAT"
                        v-model="documentDet.itemAmountWithVAT"
                        type="number"
                        disabled
                      ></v-text-field>           
                  </v-col>
              </v-row>

              <v-row v-if="this.$store.state.documentSide !== 2">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                      <v-text-field
                      dense
                      :label="lang.ItemDirectDuty1"
                      v-model="documentDet.itemDirectDuty1"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemDirectDuty1Amount"
                        v-model="documentDet.itemDirectDuty1Amount"
                        type="number"
                        disabled
                      ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
  
                  </v-col>
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="pa-0">
                    <v-text-field
                        dense
                        :label="lang.ItemFullTotal"
                        v-model="documentDet.itemFullTotal"
                        type="number"
                        disabled
                      ></v-text-field>                   
                  </v-col>
              </v-row>

              <v-checkbox
                v-if="this.$store.state.language == 'EN'"
                v-model="documentDet.itemService"
                label="Service"
              ></v-checkbox>

              <v-checkbox
                v-if="this.$store.state.language == 'HR'"
                v-model="documentDet.itemService"
                label="Usluga"
              ></v-checkbox>

           </div>
             
          <div class="pink darken-2" v-html="error" />
          <v-btn
            class="primary mr-1"
             :disabled="!formIsValid"
            @click="register">
            {{lang.SaveItem}} {{ documentNameLoc }} 
          </v-btn>
          <v-btn
            color="green"
            @click="documentdetaillist">
            {{langC.Back}}
          </v-btn>

      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">
        <div v-if="this.statewhs">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentWarehouseState></appDocumentWarehouseState>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">
        <div v-if="this.suppcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCard></appDocumentItemCard>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">
        <div v-if="this.outcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCardMinus></appDocumentItemCardMinus>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="6" lg="9" xs="12" sm="12" md="6" class="pa-0">
        <div v-if="this.sellcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCardSales></appDocumentItemCardSales>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
  </v-form>
</template>

<script>
// import _ from 'lodash'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentWarehouseState from './DocumentWarehouseState'
import DocumentItemCard from './DocumentItemCard'
import DocumentItemCardMinus from './DocumentItemCardMinus'
import DocumentItemCardSales from './DocumentItemCardSales'
import ItemService from '@/services/ItemService'
import {parseNum} from '@/snippets/allSnippets'
import dayjs from 'dayjs'

export default {
  name: 'documentdetailcreateedit3',
  $validates: true,
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      numberValue: 0,
      lang: {},
      langC: {},
      docdetailitems: [],
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      dialog1: false,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      documentDet: {
        id: 0,
        databaseDocID: 0,
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        EmployeeId: null,
        hashtag: '',
        eemail: '',
        First: '',
        Last: '',
        empWorkDay: null,
        empStartTime: null,
        empEndTime: null,
        empWrkHrs: 0,
        empWrkExpHrs: 0,
        empExp1: 0,
        empExp2: 0,
        documentName: '',
        documentSide: 0,
        documentAnalysis: 0,
        documentId: 0,
        documentIdGeneral: '',
        documentDate: '',
        partnerId: '',
        partnerNameDatabase: '',
        HomeCurrency: '',
        ForeignCurrency: '',
        ExRate: 0,
        itemID: '',
        itemName: '',
        ItemNameShort: '',
        ItemCode: '',
        ItemEANCode: '',
        itemGroup: '',
        itemGroupName: '',
        itemDescription: '',
        itemUnit: '',
        ItemSize: '',
        ItemWeight: 0,
        ItemWeightMeasure: '',
        ItemDimMeasure: '',
        ItemDimLenght: 0,
        ItemDimHeight: 0,
        ItemDimDepth: 0,
        ItemWarehouse: '',
        ItemWarehouseRow: '',
        ItemWarehouseShelf: '',
        ItemWahrehouseFloor: '',
        ItemPicture1: '',
        ItemPicture2: '',
        ItemPicture3: '',
        ItemPicture4: '',
        ItemPicture5: '',
        ItemPicture6: '',
        ItemPicture7: '',
        ItemBelongsToId: 0,
        itemService: false,
        itemWeb: false,
        itemNotActiv: false,
        itemHideForever: false,
        ItemHomeCurrency: '',
        ItemForeignCurrency: '',
        ItemExRate: 0,
        itemSuppBasePrice: 0,
        itemSuppBasePriceAmount: 0,
        itemSuppDiscount: 0,
        itemSuppDiscountAmount: 0,
        itemSuppPrice: 0,
        itemSuppPriceAmount: 0,
        ItemSuppCode: '',
        itemMargin: 0,
        itemMarginAmount: 0,
        itemMaxDiscount: 0,
        itemPrice: 0,
        itemVAT1Percetage: 0,
        itemVAT1Amount: 0,
        itemVAT2Percentge: 0,
        itemVAT2Amount: 0,
        itemDirectDuty1: 0,
        itemDirectDuty2: 0,
        itemPriceWithVAT: 0,
        itemForePrice: 0,
        itemForeVAT1Percentage: 0,
        itemForeVAT1Amount: 0,
        itemForeVAT2Percentage: 0,
        itemForeVAT2Amount: 0,
        itemForeDirectDuty1: 0,
        itemForeDirectDuty2: 0,
        itemForePriceWithVAT: 0,
        itemBaseAmount: 0,
        itemBaseForeAmount: 0,
        itemDiscountAmount1: 0,
        itemDiscountPercentage1: 0,
        itemDiscountAmount2: 0,
        itemDiscountPercentage2: 0,
        itemDiscountAmount3: 0,
        itemDiscountPercentage4: 0,
        itemDiscountPrice: 0,
        itemSerialNr: '',
        itemPcsPlus: 0,
        itemPcsMinus: 0,
        itemPcsNeutral: 0,
        itemBaseAmountNoVAT: 0,
        itemBaseForeAmountNoVAT: 0,
        itemBaseAmountWithVAT: 0,
        itemBaseForeAmountWithVAT: 0,
        itemAmountWithVAT: 0,
        itemFullTotal: 0,
        itemFullTotalFore: 0,
        itemForeAmountWithVAT: 0,
        itemDirectDuty1Amount: 0,
        itemDirectDuty2Amount: 0,
        itemDirectDuty1AmountFore: 0,
        itemDirectDuty2AmountFore: 0,
        EnteredInDatabase: null,
        newitemPrice: 0,
        newitemBaseAmount: 0,
        newitemVAT1Percentage: 0,
        newitemVAT1Amount: 0,
        newitemAmountWithVAT: 0,
        newitemDirectDuty1: 0,
        newitemFullTotal: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
        LastUpdateBy: ''
      },
      itemTotalPrice: 0,
      error: null,
      localError: false,
      newDocument: {},
      size: 'sm',
      required: (value) => !!value || 'Required.',
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      sumDoc: {
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0
      },
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      mainQuery2: {
        CompanyId: 1,
        itemID: ''
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      documentUpdated: {},
      price: 7.500,
      docSide: 0,
      pcsLoc: 0,
      suppAmount: 0,
      suppNetoAmount: 0,
      maxDisc: 0,
      maxDiscPer: 0,
      haveEdit: false,
      noCalculate: false,
      haveNotice: '',
      firstUpdate: 0,
      workOn: false,
      fullpriceChange: true,
      fullpriceBtn: 'Change',
      itemIndex: 0,
      activItems: [],
      calcVersion: 1,
      statewhs: false, 
      suppcard: false,
      outcard: false,
      sellcard: false,
      groupcard: false,
      privGroups: [],
      selectGroup: {},
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      typePOS: 3,
      employes: [],
      selectEm: {},
      employee: {}
    }
  },
  components: {
    appDocumentWarehouseState: DocumentWarehouseState,
    appDocumentItemCard: DocumentItemCard,
    appDocumentItemCardMinus: DocumentItemCardMinus,
    appDocumentItemCardSales: DocumentItemCardSales,
  },
  async created () {
 
  },
  mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.employes = this.$store.state.employeeStore ? this.$store.state.employeeStore : []

    // if NEW item
    if (this.$store.state.editItem === 0 ) {
      this.firstUpdate = 3
      this.workOn = true
      this.documentDet.CompanyId = this.$store.state.documentActivHead.CompanyId
      this.documentDet.CompanyNameDatabase = this.$store.state.documentActivHead.CompanyNameDatabase
      this.documentDet.BusinessYear = this.$store.state.documentActivHead.BusinessYear
      this.documentDet.UserId = this.$store.state.documentActivHead.UserId
      this.documentDet.email = this.$store.state.documentActivHead.email
      this.documentDet.documentName = this.$store.state.documentActivHead.documentName
      this.documentDet.documentId = parseInt(this.$store.state.documentActivHead.documentId)
      this.documentDet.documentIdGeneral = this.$store.state.documentActivHead.documentIdGeneral
      this.documentDet.documentDate = this.$store.state.documentActivHead.documentDate
      this.documentDet.partnerId = this.$store.state.documentActivHead.partnerId
      this.documentDet.partnerNameDatabase = this.$store.state.documentActivHead.partnerNameDatabase
      this.documentDet.HomeCurrency = this.$store.state.documentActivHead.documentHomeCurrency
      this.documentDet.ForeignCurrency = this.$store.state.documentActivHead.documentForeignCurrency
      this.documentDet.ExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
      this.documentDet.ItemHomeCurrency = this.$store.state.documentActivHead.documentHomeCurrency
      this.documentDet.ItemForeignCurrency = this.$store.state.documentActivHead.documentForeignCurrency
      this.documentDet.ItemExRate = parseFloat(this.$store.state.documentActivHead.documentExRate)
      this.documentDet.FreeF6 = this.$store.state.typePOS ? this.$store.state.typePOS : 3
      this.typePOS = this.$store.state.typePOS ? this.$store.state.typePOS : 3
      if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
        this.documentDet.documentSide = this.$store.state.documentSide
      }
      if (this.$store.state.documentAnalysis) {
        this.documentDet.documentAnalysis = this.$store.state.documentAnalysis
      }          
      this.documentDet.databaseDocID = this.$store.state.databaseDocID
      if (this.$store.state.documentSide === 1) {
        this.documentDet.itemPcsPlus = 1
      }
      if (this.$store.state.documentSide === 2) {
        this.documentDet.itemPcsMinus = 1
      }
      if (this.$store.state.documentSide === 3) {
        this.documentDet.itemPcsNeutral = 1
      }
      // there is no item.id - new item in document
      const newId = this.getRndInteger(1000000000, 9000000000)
      this.documentDet.id = newId
      this.$store.dispatch('setDocumentDetailid', newId)
    }
    // if edit item

    if (this.$store.state.editItem === 1 && this.$store.state.documentdetailid) {
      this.typePOS = this.$store.state.typePOS ? this.$store.state.typePOS : 3
      const itemOnly = this.$store.state.documentActivItems.find(item => {
        return item.id === this.$store.state.documentdetailid
      })
      Object.assign(this.documentDet, itemOnly)
      this.itemIndex = this.$store.state.documentActivItems.indexOf(itemOnly)
      if (this.typePOS !== 31) {
        if (this.documentDet.itemID) {
          this.selectIL =
          {
            itemByID: (this.documentDet.itemID + ' ' + this.documentDet.itemName),
            itemID: this.documentDet.itemID
          }
        }
      }
      if (this.typePOS === 31) {
        if (this.documentDet.hashtag) {
          this.selectEm =
          {
            id: this.documentDet.EmployeeId,
            hashtag: this.documentDet.hashtag,
            eemail: this.documentDet.eemail
          }
        }
      }
    }
    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    if (this.$store.state.itemStore) {
      this.itemsLoc = this.$store.state.itemStore
    }
    if (this.$store.state.groupStore) {
      //this.privGroups = (await GroupService.choose(this.mainQuery)).data.groups
      this.privGroups = this.$store.state.groupStore
    }
    console.log('Prvi, ', this.documentDet)
    this.doParse()
    console.log('Drugi, ', this.documentDet)
  },
  updated () {
    this.firstUpdate += 1
    if (parseInt(this.firstUpdate) >= 1) {
      this.workOn = true
    }
  },
  computed: {
    formIsValid () {
      return this.documentDet.itemID !== '' ||
      this.selectEm.id
    }
  },
  methods: {
    async checkSupplierPrice() {
      if (this.documentDet.itemID) {
        if (!this.documentDet.Service) {
          this.mainQuery2 = {...this.emptyObj}
          this.mainQuery2.CompanyId = this.$store.state.companyid
          this.mainQuery2.itemID = this.documentDet.itemID ? this.documentDet.itemID : null
          await ItemService.choose(this.mainQuery2)
          .then((res) => {
            if (res.data && res.data.item !== undefined) {
              this.documentDet.itemSuppBasePrice = parseNum(res.data.item.itemSuppPrice, 2)
              this.calculatePrice()
            }
            this.text = 'OK.'
            this.color = 'green'
            this.snackbar = true
          })
          .catch(err => {
            console.log(' Error items ', err)
            this.text = this.lang.UpdateItemNameUpdatedError
            this.color = 'red'
            this.snackbar = true
          })
        }
        if (this.iService){
          this.calculateMarginSale()
        }
      }
    },
    warehouseState() {
      this.statewhs = !this.statewhs
      this.$store.dispatch('setdontGo', false)
      this.$store.dispatch("setGroupid", null)
      if (this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
      }
    },
    async initAna() {
      if (this.$store.state.companyid) {
        this.documentAna.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.documentAna.BusinessYear = this.$store.state.businessYear
      }
      if (this.selectIL.itemID) {
        this.documentAna.itemID = this.selectIL.itemID
      }
      this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.documentAna.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      await this.$store.dispatch('setDocumentAna', this.documentAna)
    },
    itemCard() {
      if (this.selectIL.itemID) {
        this.initAna()  
        this.suppcard = !this.suppcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardMinus() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.outcard = !this.outcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardSell() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.sellcard = !this.sellcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    fullpriceSwitch() {
      this.fullpriceChange = !this.fullpriceChange
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    doParse () {
      this.documentDet.ItemWeight = this.documentDet.ItemWeight ? parseFloat(this.documentDet.ItemWeight) : 0
      this.documentDet.ItemDimLenght = this.documentDet.ItemDimLenght ? parseFloat(this.documentDet.ItemDimLenght) : 0
      this.documentDet.ItemDimHeight = this.documentDet.ItemDimHeight ? parseFloat(this.documentDet.ItemDimHeight) : 0
      this.documentDet.ItemDimDepth = this.documentDet.ItemDimDepth ? parseFloat(this.documentDet.ItemDimDepth) : 0
      this.documentDet.ItemBelongsToId = this.documentDet.ItemBelongsToId ? parseFloat(this.documentDet.ItemBelongsToId) : 0
      this.documentDet.itemSuppBasePrice = this.documentDet.itemSuppBasePrice ? parseFloat(this.documentDet.itemSuppBasePrice) : 0
      this.documentDet.itemSuppBasePriceAmount = this.documentDet.itemSuppBasePriceAmount ? parseFloat(this.documentDet.itemSuppBasePriceAmount) : 0
      this.documentDet.itemSuppDiscunt = this.documentDet.itemSuppDiscunt ? parseFloat(this.documentDet.itemSuppDiscunt) : 0
      this.documentDet.itemSuppDiscuntAmount = this.documentDet.itemSuppDiscuntAmount ? parseFloat(this.documentDet.itemSuppDiscuntAmount) : 0
      this.documentDet.itemSuppPrice = this.documentDet.itemSuppPrice ? parseFloat(this.documentDet.itemSuppPrice) : 0
      this.documentDet.itemSuppPriceAmount = this.documentDet.itemSuppPriceAmount ? parseFloat(this.documentDet.itemSuppPriceAmount) : 0
      this.documentDet.ItemSuppCode = this.documentDet.ItemSuppCode ? parseFloat(this.documentDet.ItemSuppCode) : 0
      this.documentDet.itemMargin = this.documentDet.itemMargin ? parseFloat(this.documentDet.itemMargin) : 0
      this.documentDet.itemMarginAmount = this.documentDet.itemMarginAmount ? parseFloat(this.documentDet.itemMarginAmount) : 0
      this.documentDet.itemMaxDiscount = this.documentDet.itemMaxDiscount ? parseFloat(this.documentDet.itemMaxDiscount) : 0
      this.documentDet.itemPrice = this.documentDet.itemPrice ? parseFloat(this.documentDet.itemPrice) : 0
      this.documentDet.itemVAT1Percetage = this.documentDet.itemVAT1Percetage ? parseFloat(this.documentDet.itemVAT1Percetage) : 0
      this.documentDet.itemVAT1Amount = this.documentDet.itemVAT1Amount ? parseFloat(this.documentDet.itemVAT1Amount) : 0
      this.documentDet.itemVAT2Percentge =this.documentDet.itemVAT2Percentge ? parseFloat(this.documentDet.itemVAT2Percentge) : 0
      this.documentDet.itemVAT2Amount = this.documentDet.itemVAT2Amount ? parseFloat(this.documentDet.itemVAT2Amount) : 0
      this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? parseFloat(this.documentDet.itemDirectDuty1) : 0
      this.documentDet.itemDirectDuty2 = this.documentDet.itemDirectDuty2 ? parseFloat(this.documentDet.itemDirectDuty2) : 0
      this.documentDet.itemPriceWithVAT = this.documentDet.itemPriceWithVAT ? parseFloat(this.documentDet.itemPriceWithVAT) : 0
      this.documentDet.itemForePrice = this.documentDet.itemForePrice ? parseFloat(this.documentDet.itemForePrice) : 0
      this.documentDet.itemForeVAT1Percentage = this.documentDet.itemForeVAT1Percentage ? parseFloat(this.documentDet.itemForeVAT1Percentage) : 0
      this.documentDet.itemForeVAT1Amount = this.documentDet.itemForeVAT1Amount ? parseFloat(this.documentDet.itemForeVAT1Amount) : 0
      this.documentDet.itemForeVAT2Percentage = this.documentDet.itemForeVAT2Percentage ? parseFloat(this.documentDet.itemForeVAT2Percentage) : 0
      this.documentDet.itemForeVAT2Amount = this.documentDet.itemForeVAT2Amount ? parseFloat(this.documentDet.itemForeVAT2Amount) : 0
      this.documentDet.itemForeDirectDuty1 = this.documentDet.itemForeDirectDuty1 ? parseFloat(this.documentDet.itemForeDirectDuty1) : 0
      this.documentDet.itemForeDirectDuty2 = this.documentDet.itemForeDirectDuty2 ? parseFloat(this.documentDet.itemForeDirectDuty2) : 0
      this.documentDet.itemForePriceWithVAT = this.documentDet.itemForePriceWithVAT ? parseFloat(this.documentDet.itemForePriceWithVAT) : 0
      this.documentDet.itemPcsNeutral = this.documentDet.itemPcsNeutral ? parseFloat(this.documentDet.itemPcsNeutral) : 0
      this.documentDet.itemPcsMinus = this.documentDet.itemPcsMinus ? parseFloat(this.documentDet.itemPcsMinus) : 0
      this.documentDet.itemFullTotal = this.documentDet.itemFullTotal ? parseFloat(this.documentDet.itemFullTotal) : 0
      this.documentDet.itemFullTotalFore = this.documentDet.itemFullTotalFore ? parseFloat(this.documentDet.itemFullTotalFore) : 0
      this.documentDet.itemDirectDuty1Amount = this.documentDet.itemDirectDuty1Amount ? parseFloat(this.documentDet.itemDirectDuty1Amount) : 0
      this.documentDet.itemDirectDuty2Amount = this.documentDet.itemDirectDuty2Amount ? parseFloat(this.documentDet.itemDirectDuty2Amount) : 0
      this.documentDet.itemDirectDuty1AmountFore = this.documentDet.itemDirectDuty1AmountFore ? parseFloat(this.documentDet.itemDirectDuty1AmountFore) : 0
      this.documentDet.itemDirectDuty2AmountFore = this.documentDet.itemDirectDuty2AmountFore ? parseFloat(this.documentDet.itemDirectDuty2AmountFore) : 0
      this.documentDet.FreeF1 = this.documentDet.FreeF1 ? parseFloat(this.documentDet.FreeF1) : 0
      this.documentDet.FreeF2 = this.documentDet.FreeF2 ? parseFloat(this.documentDet.FreeF2) : 0
      this.documentDet.FreeF3 = this.documentDet.FreeF3 ? parseFloat(this.documentDet.FreeF3) : 0
      this.documentDet.FreeF4 = this.documentDet.FreeF4 ? parseFloat(this.documentDet.FreeF4) : 0
      this.documentDet.FreeF5 = this.documentDet.FreeF5 ? parseFloat(this.documentDet.FreeF5) : 0
    },
    async register () {
      try {
        this.localError = false
        this.documentDet.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : ''
        if (this.$store.state.editItem === 1) {
          const activItems = this.$store.state.documentActivItems
          activItems.splice(this.itemIndex, 1, this.documentDet)
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          await this.$store.dispatch('seteditItem', 0)

          if (this.$store.state.language === 'EN') {
            this.haveNotice = 'Document item is updated.'
          }
          if (this.$store.state.language === 'HR') {
            this.haveNotice = 'Stavka na dokumentu je izmijenjena.'
          }
        } else {
          const newItem = this.documentDet
          this.activItems = this.$store.state.documentActivItems
          this.activItems.push(newItem)
          if (this.activItems) {
            this.$store.dispatch('setDocumentActivItems', this.activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          if (this.$store.state.language === 'EN') {
            this.haveNotice = 'Document item has added'
          }
          if (this.$store.state.language === 'HR') {
            this.haveNotice = 'Dodana je nova stavka na dokument'
          }
        }
        console.log('Register, ', this.documentDet)
        this.makeSum()
        this.$router.push({
          name: 'documentview3'
        })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error
      }
    },
    close () {
      this.show = false
    },
    async deleteDD2 () {
      try {
        if (this.$store.state.documentdetailid) {
          const activItems = this.$store.state.documentActivItems.filter(item => {
            return item.id !== this.$store.state.documentdetailid
          })
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          // make sum local head
          this.makeSum()
  
          await this.$router.push({
            name: 'documentview3'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentdetaillist () {
      try {
        await this.$router.push({
          name: 'documentview3'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    initPcs() {
      this.pcsLoc = 0
      if (this.$store.state.documentSide === 1) {
        this.pcsLoc = this.documentDet.itemPcsPlus ? this.documentDet.itemPcsPlus : 0
      }
      if (this.$store.state.documentSide === 2) {
        this.pcsLoc = this.documentDet.itemPcsMinus ? this.documentDet.itemPcsMinus : 0
      }
      if (this.$store.state.documentSide === 3) {
        this.pcsLoc = this.documentDet.itemPcsNeutral ? this.documentDet.itemPcsNeutral : 0
      }
      this.pcsLoc = this.round(this.pcsLoc, 3)
    },

    calculatePriceWrk () {
      if (this.typePOS === 31) {
        console.log('Racunam radne sate')
        this.initPcs()
        this.pcsLoc = 0
        this.pcsLoc = this.documentDet.empWrkHrs ? parseFloat(this.documentDet.empWrkHrs) : 0
        this.documentDet.empWrkExpHrs =  this.documentDet.empWrkExpHrs ? parseFloat(this.documentDet.empWrkExpHrs) : 0
        this.documentDet.itemBaseAmount = this.documentDet.empWrkExpHrs * this.pcsLoc
        this.documentDet.itemBaseAmount += this.documentDet.empExp1 ? parseFloat(this.documentDet.empExp1) : 0
        this.documentDet.itemBaseAmount += this.documentDet.empExp2 ? parseFloat(this.documentDet.empExp2) : 0
        this.documentDet.itemBaseAmountNoVAT = this.documentDet.itemBaseAmount
        this.documentDet.itemVAT1Amount = 0
        this.documentDet.itemAmountWithVAT = this.documentDet.itemBaseAmount

        this.documentDet.itemSuppBasePrice = this.documentDet.itemBaseAmount
        this.documentDet.itemSuppDiscount = 0
        this.documentDet.itemSuppBasePriceAmount  = this.documentDet.itemBaseAmount
        this.documentDet.itemSuppDiscountAmount = 0
        this.documentDet.itemSuppPriceAmount = this.documentDet.itemSuppBasePriceAmount
        
        this.documentDet.itemMargin = 0
        this.documentDet.itemMarginAmount = 0
        // no discount in any case
        this.documentDet.itemDiscountPercentage1 = 0
        this.documentDet.itemDiscountPrice = this.documentDet.itemPrice
        this.maxDiscPer = 0
        this.documentDet.itemMaxDiscount = 0
      }

      // this.documentDet.itemPriceWithVAT = parseFloat((this.documentDet.itemPrice + (this.documentDet.itemPrice * (this.documentDet.itemVAT1Percentage / 100))).toFixed(2))
    },

    calculatePrice () {
      this.initPcs()
      if (this.typePOS === 3 || this.typePOS === 31 || this.typePOS === 32) {
          // no margin, no discount only supp price 
          this.documentDet.itemSuppBasePrice = this.documentDet.itemSuppBasePrice ? this.documentDet.itemSuppBasePrice : 0
          this.documentDet.itemSuppPrice = this.documentDet.itemSuppBasePrice
          this.documentDet.itemSuppDiscount = 0
          this.documentDet.itemSuppBasePriceAmount  = (this.documentDet.itemSuppBasePrice * this.pcsLoc) ? this.round((this.documentDet.itemSuppBasePrice * this.pcsLoc),2) : 0
          this.documentDet.itemSuppDiscountAmount = 0
          this.documentDet.itemSuppPriceAmount = this.documentDet.itemSuppBasePriceAmount
          this.documentDet.itemPrice = this.documentDet.itemSuppBasePrice
          this.documentDet.itemDiscountPercentage1 = 0
          this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? this.round(this.documentDet.itemDirectDuty1, 2) : 0
          this.documentDet.itemVAT1Percentage = this.documentDet.itemVAT1Percentage ? this.round(this.documentDet.itemVAT1Percentage, 2) : 0
          this.documentDet.itemPrice = this.documentDet.itemPrice ? this.round(this.documentDet.itemPrice, 3) : 0
          
          this.documentDet.itemMargin = 0
          this.documentDet.itemMarginAmount = 0
          // no discount in any case
          this.documentDet.itemDiscountPercentage1 = 0
          this.documentDet.itemDiscountPrice = this.documentDet.itemPrice
          this.maxDiscPer = 0
          this.documentDet.itemMaxDiscount = 0
          console.log('calculate ', this.documentDet)
      }
      if (this.typePOS === 33) {
        this.documentDet.itemSuppBasePrice = 0
        this.documentDet.itemSuppDiscount = 0
        this.documentDet.itemSuppBasePriceAmount  =  0
        this.documentDet.itemSuppDiscountAmount = 0
        this.documentDet.itemSuppPriceAmount = 0
        this.documentDet.itemSuppPrice = 0
        this.documentDet.itemDiscountPercentage1 = 0
       // this.documentDet.itemPrice = this.documentDet.itemSuppPrice
      }
      // all cases have these
      this.documentDet.itemBaseAmount = this.round((this.documentDet.itemPrice * this.pcsLoc), 2)
      this.documentDet.itemBaseAmountNoVAT = this.round(this.documentDet.itemBaseAmount, 2)

      this.documentDet.itemVAT1Amount = ((this.documentDet.itemBaseAmountNoVAT * (this.documentDet.itemVAT1Percentage / 100)))
      this.documentDet.itemVAT1Amount = this.round(this.documentDet.itemVAT1Amount, 2)
      this.documentDet.itemAmountWithVAT = (parseFloat(this.documentDet.itemBaseAmountNoVAT) + parseFloat(this.documentDet.itemVAT1Amount))
      this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemAmountWithVAT, 2)
      
      if (this.pcsLoc !== 0) {
        this.documentDet.itemPriceWithVAT = ((this.documentDet.itemAmountWithVAT / this.pcsLoc))
        this.documentDet.itemPriceWithVAT = this.round(this.documentDet.itemPriceWithVAT, 2)
      }
      this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1 * this.pcsLoc), 2)
      this.documentDet.itemFullTotal = (this.documentDet.itemBaseAmountNoVAT + this.documentDet.itemVAT1Amount + this.documentDet.itemDirectDuty1Amount)
      this.documentDet.itemFullTotal = this.round(this.documentDet.itemFullTotal, 2)

      if (this.typePOS === 33) {       
        this.documentDet.itemMarginAmount = this.documentDet.itemBaseAmount - this.documentDet.itemSuppPriceAmount
        if (this.documentDet.itemMarginAmount < 0) {
          this.documentDet.itemMarginAmount = 0
        }
        const per2 = this.documentDet.itemSuppPriceAmount / 100
        
        this.documentDet.itemMargin = per2 !== 0 ? this.round(this.documentDet.itemMarginAmount / per2, 2) : 0

        const maxDiscPer = (this.documentDet.itemBaseAmountNoVAT / 100) ? this.round((this.documentDet.itemBaseAmountNoVAT / 100),3) : 0
        this.documentDet.itemMaxDiscount = (this.documentDet.itemMargin/ maxDiscPer) ? this.round((this.documentDet.itemMargin/ maxDiscPer),2) : 0
      }

    },
    async findWrk() {
      try {
        this.employee = await this.employes.find(emp => emp.hashtag === this.selectEm.hashtag)
        if (this.employee) {                    
            this.documentDet.EmployeeId = this.employee.id
            this.documentDet.hashtag = this.employee.hashtag
            this.documentDet.eemail = this.employee.eemail
            this.documentDet.First = this.employee.First
            this.documentDet.Last = this.employee.Last
          }
      } catch (error) {
        // eslint-disable-next-line
        console.log('Error cant find employee ', error)
      }
    },
    async findItem () {
      try {
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.selectIL.itemID
        // const myObjStr = JSON.stringify(this.mainQuery2)
        this.itemLoc = await this.itemsLoc.find(itemM => itemM.itemID === this.mainQuery2.itemID)
        if (this.itemLoc) {                    
            this.documentDet.itemID = this.itemLoc.itemID
            this.documentDet.itemName = this.itemLoc.itemName
            this.documentDet.ItemNameShort = this.itemLoc.ItemNameShort
            this.documentDet.ItemCode = this.itemLoc.ItemCode
            this.documentDet.ItemEANCode = this.itemLoc.ItemEANCode
            this.documentDet.itemGroup = this.itemLoc.itemGroup
            this.documentDet.itemGroupName = this.itemLoc.GroupName
            this.documentDet.itemDescription = this.itemLoc.itemDescription
            this.documentDet.itemUnit = this.itemLoc.itemUnit
            this.documentDet.ItemSize = this.itemLoc.ItemSize
            this.documentDet.ItemWeight = this.itemLoc.ItemWeight
            this.documentDet.ItemWeightMeasure = this.itemLoc.ItemWeightMeasure
            this.documentDet.ItemDimMeasure = this.itemLoc.ItemDimMeasure
            this.documentDet.ItemDimLenght = this.itemLoc.ItemDimLenght
            this.documentDet.ItemDimHeight = this.itemLoc.ItemDimHeight
            this.documentDet.ItemDimDepth = this.itemLoc.ItemDimDepth
            this.documentDet.ItemWarehouse = this.itemLoc.ItemWarehouse
            this.documentDet.ItemWarehouseRow = this.itemLoc.ItemWarehouseRow
            this.documentDet.ItemWarehouseShelf = this.itemLoc.ItemWarehouseShelf
            this.documentDet.ItemWahrehouseFloor = this.itemLoc.ItemWahrehouseFloor
            this.documentDet.ItemPicture1 = this.itemLoc.ItemPicture1
            this.documentDet.ItemPicture2 = this.itemLoc.ItemPicture2
            this.documentDet.ItemPicture3 = this.itemLoc.ItemPicture3
            this.documentDet.ItemPicture4 = this.itemLoc.ItemPicture4
            this.documentDet.ItemPicture5 = this.itemLoc.ItemPicture5
            this.documentDet.ItemPicture6 = this.itemLoc.ItemPicture6
            this.documentDet.ItemPicture7 = this.itemLoc.ItemPicture7
            this.documentDet.ItemBelongsToId = this.itemLoc.ItemBelongsToId
            this.documentDet.itemService = this.itemLoc.itemService
            this.documentDet.itemWeb = this.itemLoc.itemWeb
            this.documentDet.itemNotActiv = this.itemLoc.itemNotActiv
            this.documentDet.itemHideForever = this.itemLoc.itemHideForever
            this.documentDet.itemSuppBasePrice = this.itemLoc.itemSuppBasePrice
            this.documentDet.itemSuppDiscunt = this.itemLoc.itemSuppDiscunt
            this.documentDet.itemSuppPrice = this.itemLoc.itemSuppPrice
            this.documentDet.ItemSuppCode = this.itemLoc.ItemSuppCode
            this.documentDet.itemMargin = this.itemLoc.itemMargin
            this.documentDet.itemMaxDiscount = this.itemLoc.itemMaxDiscount
            this.documentDet.itemPrice = this.round(this.itemLoc.itemPrice, 3)
            this.documentDet.itemVAT1Percentage = this.round(this.itemLoc.itemVAT1Percentage, 2)
            this.documentDet.itemVAT1Amount = this.itemLoc.itemVAT1Amount
            this.documentDet.itemVAT2Percentge = this.itemLoc.itemVAT2Percentge
            this.documentDet.itemVAT2Amount = this.itemLoc.itemVAT2Amount
            this.documentDet.itemDirectDuty1 = this.round(this.itemLoc.itemDirectDuty1, 2)
            this.documentDet.itemDirectDuty2 = this.itemLoc.itemDirectDuty2
            this.documentDet.itemPriceWithVAT = this.itemLoc.itemPriceWithVAT
            this.documentDet.itemForePrice = this.itemLoc.itemForePrice
            this.documentDet.itemForeVAT1Percentage = this.itemLoc.itemForeVAT1Percentage
            this.documentDet.itemForeVAT1Amount = this.itemLoc.itemForeVAT1Amount
            this.documentDet.itemForeVAT2Percentage = this.itemLoc.itemForeVAT2Percentage
            this.documentDet.itemForeVAT2Amount = this.itemLoc.itemForeVAT2Amount
            this.documentDet.itemForeDirectDuty1 = this.itemLoc.itemForeDirectDuty1
            this.documentDet.itemForeDirectDuty2 = this.itemLoc.itemForeDirectDuty2
            this.documentDet.itemForePriceWithVAT = this.itemLoc.itemForePriceWithVAT
          }
          this.calculatePrice()
      } catch (error) {
        // eslint-disable-next-line
        console.log(' greska Ne mogu naci artikla', error)
      }
    },
    makeSum() {
      const updateSumObject = {
        documentDescription9: 0,
        documentDescription10: 0,
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0,
        supplierAmount: 0,
        supplierNetoAmount: 0,
        supplierDiscount: 0,
        supplierMargin: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
      }
      if (this.$store.state.documentSide) {
        this.docSide = this.$store.state.documentSide
      }
      const exRate = this.$store.getters.NeedDefExRate
      const hrVat1 = 25
      const hrVat2 = 13
      const hrVat3 = 5
      const documentsDet = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []

      if (documentsDet) {
        documentsDet.map(documentDet => {
          this.pcsLoc = 0
          if (documentDet.documentSide === 1 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsPlus) ? parseFloat(documentDet.itemPcsPlus)  : 0
          }
          if (documentDet.documentSide === 2 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsMinus) ? parseFloat(documentDet.itemPcsMinus)  : 0
          }
          if (documentDet.documentSide === 3 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsNeutral) ? parseFloat(documentDet.itemPcsNeutral)  : 0
          }
          this.pcsLoc = this.round(this.pcsLoc, 3)

            
          if ((documentDet.itemSuppBasePriceAmount)) {
            updateSumObject.supplierAmount += this.round((documentDet.itemSuppBasePriceAmount),2)
          }
          if (documentDet.itemSuppDiscountAmount) {
            updateSumObject.supplierDiscount += this.round(documentDet.itemSuppDiscountAmount,2)
          }
          if (documentDet.itemSuppPriceAmount) {
            updateSumObject.supplierNetoAmount += this.round(documentDet.itemSuppPriceAmount,2)
          }
          if (documentDet.itemBaseAmount) {
            updateSumObject.documentDescription9 += parseFloat(documentDet.itemBaseAmount)
          }
          if (documentDet.itemBaseAmountNoVAT) {
            updateSumObject.documentTotalAmountNetto += parseFloat(documentDet.itemBaseAmountNoVAT)
          }
          if (documentDet.itemVAT1Amount) {
            updateSumObject.documentTotalAmountVAT += parseFloat(documentDet.itemVAT1Amount)
          }
          const dD1 = documentDet.itemDirectDuty1Amount ? parseFloat(documentDet.itemDirectDuty1Amount) : 0
          if (documentDet.itemAmountWithVAT) {
            updateSumObject.documentTotalAmountBrutto += parseFloat(documentDet.itemAmountWithVAT) + dD1
          }
          if (parseFloat(documentDet.itemVAT1Percentage) === hrVat1) {
            updateSumObject.documentTotalVAT1Amount += parseFloat(documentDet.itemVAT1Amount)
          }
          if (parseFloat(documentDet.itemVAT1Percentage) === hrVat2) {
            updateSumObject.documentTotalVAT2Amount += parseFloat(documentDet.itemVAT1Amount)
          }
          if (parseFloat(documentDet.itemVAT1Percentage) === hrVat3) {
            updateSumObject.documentTotalVAT3Amount += parseFloat(documentDet.itemVAT1Amount)
          }

          if (documentDet.itemBaseAmount) {
            if (parseInt(documentDet.FreeF6) === 3) {
              updateSumObject.FreeF1 += parseFloat(documentDet.itemBaseAmount)
            }
            if (parseInt(documentDet.FreeF6) === 31) {
              updateSumObject.FreeF2 += parseFloat(documentDet.itemBaseAmount)
              updateSumObject.FreeF5 += parseFloat(documentDet.empWrkHrs)
            }
            if (parseInt(documentDet.FreeF6) === 32) {
              updateSumObject.FreeF3 += parseFloat(documentDet.itemBaseAmount)
            }
            if (parseInt(documentDet.FreeF6) === 33) {
              updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmount)
            }
          }
        //   if (documentDet.itemBaseAmountNoVAT) {
        //  //   if (parseInt(documentDet.itemVAT1Amount) === 0) {
        //       updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmountNoVAT)
        //  //   }
        //   }
          if (documentDet.itemDirectDuty1Amount) {
            updateSumObject.documentTotalAmountDirectD1+= parseFloat(documentDet.itemDirectDuty1Amount)
          }
        })


      }
      updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
      updateSumObject.supplierNetoAmount = updateSumObject.supplierNetoAmount ? this.round(updateSumObject.supplierNetoAmount, 2) : 0
      updateSumObject.documentTotalAmountNetto = updateSumObject.documentTotalAmountNetto ? this.round(updateSumObject.documentTotalAmountNetto, 2) : 0
      updateSumObject.supplierMargin = this.round(updateSumObject.documentTotalAmountNetto - updateSumObject.supplierNetoAmount, 2)
      updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
      updateSumObject.documentTotalVAT1Amount = updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount, 2) : 0
      updateSumObject.documentTotalVAT2Amount = updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount, 2) : 0
      updateSumObject.documentTotalVAT3Amount = updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount, 2) : 0
      updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
      updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
      updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0
      updateSumObject.documentTotalVAT1Base = hrVat1 && updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount / (hrVat1/100), 2) : 0
      updateSumObject.documentTotalVAT2Base = hrVat2 && updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount / (hrVat2/100), 2) : 0
      updateSumObject.documentTotalVAT3Base = hrVat3 && updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount / (hrVat3/100), 2) : 0
      updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
      updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
      updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
      updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
      updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
      updateSumObject.documentDescription10 = updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto
      updateSumObject.documentTotalAmountPayInAdvance = 0
      updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? this.round(updateSumObject.documentTotalAmountBrutto, 2) : 0
      updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / exRate), 2) : 0
      updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / exRate), 2) : 0
      updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
      updateSumObject.documentTotalAmountPayInAdvanceForeign = 0
      updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / exRate), 2) : 0
      updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / exRate), 2) : 0

      const newHead = {...this.$store.state.documentActivHead, ...updateSumObject}
      if (newHead) {
          this.$store.dispatch('setDocumentActivHead', newHead)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
    }
  },
  watch: {
    'documentDet.itemPrice' () {
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
          this.calculatePrice()
        }
      }
    },
    // 'documentDet.itemDiscountPercentage1' () {
    //   if (this.workOn === true) {
    //       if (this.fullpriceChange === true) {
    //         this.calculatePrice()
    //       }
    //   }
    // },
    'documentDet.itemPcsNeutral' () {
      if (isNaN(this.documentDet.itemPcsNeutral)) {
        this.documentDet.itemPcsNeutral = 1
      }
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
         this.calculatePrice()
        }
      }
    },
    'documentDet.itemPcsMinus' () {
      if (isNaN(this.documentDet.itemPcsMinus)) {
        this.documentDet.itemPcsMinus = 1
      }
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
          this.calculatePrice()
        }
      }
    },
    'documentDet.itemPcsPlus' () {
      if (isNaN(this.documentDet.itemPcsPlus)) {
        this.documentDet.itemPcsPlus = 1
      }
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
         // this.calculateSupp()
          this.calculatePrice()
        }
      }
    },
    // 'documentDet.itemVAT1Percentage' () {
    //   if (this.workOn === true) {
    //     if (this.fullpriceChange === true) {
    //       this.calculatePrice()
    //     }
    //   }
    // },
    // 'documentDet.itemDirectDuty1' () {
    //   if (this.workOn === true) {
    //     if (this.fullpriceChange === true) {
    //       this.calculatePrice()
    //     }
    //   }
    // },
    'documentDet.itemSuppBasePrice' () {
      if (this.workOn === true) {
        if (this.fullpriceChange === true) {
          //this.calculateSupp()
          this.calculatePrice()
        }
      }
    },


    'documentDet.itemMargin' () {

    },

    'documentDet.itemPriceWithVAT' () {
      
      // if (this.workOn === true) {
      //     if (this.fullpriceChange === false) {
      //       this.initPcs()
            
      //       this.documentDet.itemDiscountAmount1 = 0
      //       this.documentDet.itemDiscountPercentage1 = 0
      //       this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? this.round(this.documentDet.itemDirectDuty1, 2) : 0
      //       this.documentDet.itemVAT1Percentage = this.documentDet.itemVAT1Percentage ? this.round(this.documentDet.itemVAT1Percentage, 2) : 0
      //       this.documentDet.itemPriceWithVAT = this.documentDet.itemPriceWithVAT ? this.round(this.documentDet.itemPriceWithVAT, 2) : 0

      //       this.documentDet.itemAmountWithVAT = this.documentDet.itemPriceWithVAT * this.pcsLoc ? this.documentDet.itemPriceWithVAT * this.pcsLoc : 0
      //       this.documentDet.itemAmountWithVAT = this.round(this.documentDet.itemAmountWithVAT, 2)

      //       this.documentDet.itemBaseAmountNoVAT= this.documentDet.itemAmountWithVAT / (1 + (this.documentDet.itemVAT1Percentage / 100))
      //       this.documentDet.itemBaseAmount = this.documentDet.itemBaseAmountNoVAT

      //       this.documentDet.itemVAT1Amount = this.documentDet.itemAmountWithVAT - this.documentDet.itemBaseAmountNoVAT
            
      //        if (this.pcsLoc !== 0) {
      //         this.documentDet.itemDiscountPrice = (this.documentDet.itemBaseAmountNoVAT / this.pcsLoc)
      //         this.documentDet.itemDiscountPrice = this.round(this.documentDet.itemDiscountPrice, 2)
      //         this.documentDet.itemPrice = this.round(this.documentDet.itemDiscountPrice, 2)
      //       }
      //       // calc from price with VAT, discount - fake discount
      //       // this.documentDet.itemBaseAmount = (this.documentDet.itemPrice * this.pcsLoc)
      //       // this.documentDet.itemDiscountAmount1 =  this.round((this.documentDet.itemBaseAmount - this.documentDet.itemBaseAmountNoVAT),2)
      //       // this.documentDet.itemDiscountPercentage1 = this.round((this.documentDet.itemDiscountAmount1 / (this.documentDet.itemBaseAmount / 100)),2)

      //       this.documentDet.itemDirectDuty1Amount = this.round((this.documentDet.itemDirectDuty1 * this.pcsLoc), 2)
      //       this.documentDet.itemFullTotal = (this.documentDet.itemBaseAmountNoVAT + this.documentDet.itemVAT1Amount + this.documentDet.itemDirectDuty1Amount)
      //       this.documentDet.itemFullTotal = this.round(this.documentDet.itemFullTotal, 2)
      //       if (this.$store.state.documentSide === 1) {
      //         this.initSupp()
      //         this.calculateSupp()
      //       }
          
      //   }
 
      // }
    },
    'selectIL.itemID' () {
      if (this.workOn === true) {
        this.findItem()
      }
    },
    'selectEm.hashtag' () {
      this.findWrk()
    },
    'documentDet.empWrkHrs' () {
      this.calculatePriceWrk()
    },
    'documentDet.empWrkExpHrs' () {
      this.calculatePriceWrk()
    },
    'documentDet.empExp1' () {
      this.calculatePriceWrk()
    },
    'documentDet.empExp2' () {
      this.calculatePriceWrk()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
