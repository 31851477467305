<template>
  <v-form> 
  <v-container class="my-0 pa-0">
    <v-row no-gutters justify="center">
      <v-col xl="9" lg="9" xs="12" sm="12" md="12">
            <v-row v-if="error">
              <v-alert 
                :type="typeError"
                v-model="alert"
                dismissible>
               {{error}}
              </v-alert>
            </v-row>
            <v-row>
                <v-col cols="12" sm="5" md="5" xs="5" lg="5" xl="5">
                  <h2>
                     {{ documentNameLoc }}
                  </h2>
                </v-col>
            </v-row>
          <v-card
            class="pa-2 ma-1"
                outlined
              >
              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6 pb-2">
                    <v-btn text color="primary" @click="optionsOn()" class="pb-3 pl-0">Opcije za artikal/uslugu <v-icon v-if="!showOptions">mdi-chevron-down</v-icon><v-icon v-if="showOptions">mdi-chevron-up</v-icon></v-btn>
                  </div>
                </v-col>
              </v-row>
            <div v-if="showOptions">
              <v-row>
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-select
                        :items="privGroups"
                        v-model="selectGroup"
                        :label="lang.selectGroup"
                        single-line
                        item-text="GroupName"
                        item-value="id"
                        return-object
                        persistent-hint
                        clearable
                        dense
                      ></v-select>
                  </v-col>
                  <v-col cols="12" sm="9" md="9" xs="9" class="right">
                  <v-btn text small color="primary" @click="showGroup" class="mr-1">
                      {{lang.Items}}
                    </v-btn>
                    <v-btn text small color="primary" @click="warehouseState" class="mr-1">
                      {{lang.WarehouseState2}},{{$store.state.warehousemarkid}}
                    </v-btn>
                    <v-btn text small color="primary" @click="itemCard" class="mr-1">
                      {{lang.ItemCard}}
                    </v-btn>
                    <v-btn text small color="primary" @click="itemCardSell" class="mr-1">
                      {{lang.ItemCardInv}}
                    </v-btn>
                    <v-btn text small color="primary" @click="createNewItem" class="mr-1">
                      Novi artikal
                    </v-btn>
                    <v-btn text small color="red darken-1" @click="deleteDD2">
                      {{langC.Delete}}
                    </v-btn>
                  </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card
            class="pa-2 ma-1"
                outlined
              >

              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6">
                    <span>{{lang.ItemFor}}</span>
                  </div>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                  <v-autocomplete
                    :items="itemsLoc"
                    v-model="selectIL"
                    :label="lang.ItemSelect"
                    item-text="itemByID"
                    item-value="itemID"
                    clearable
                    return-object
                    class="pa-0 my-0"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="pt-0">
                  <v-btn text small color="primary" @click="showItemCardd" class="mt-3" v-if="haveItemSelected">
                    {{lang.showItemCardd}}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pt-0">
                  <v-text-field
                      dense
                      :label="lang.ItemName"
                      v-model="iName"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="4" lg="4" xl="4" class="pt-0">
                  <v-btn text small color="primary" @click="updateItemName" class="pa-0 my-0 mr-1" v-if="haveItemSelected">
                    {{lang.UpdateItemName}}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" xs="12" sm="12" md="4"  lg="4" xl="4" class="pr-2 pt-0">
                  <v-text-field
                      dense
                      :label="lang.ItemNameShort"
                      v-model="iNameShort"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" xs="4" sm="4" md="1"  lg="1" xl="1" class="pr-2 pt-0">
                  <v-text-field
                      dense
                      :label="lang.ItemGroup"
                      v-model="iGroup"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" xs="8" sm="8" md="3"  lg="3" xl="3" class="pt-0">
                  <v-text-field
                      dense
                      :label="lang.ItemGroup"
                      v-model="iGroupName"
                      disabled
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" xs="4" sm="4" md="2"  lg="2" xl="2" class="pr-2 pt-0" v-if="haveItemSelected">
                  <v-checkbox
                    v-model="iForceNewPrintPage"
                    :label="lang.forceNewPrintPage"
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
            </v-row>

             <v-row no-gutters>
                <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pt-0">
                  <v-btn text small color="primary" @click="addDesc()" class="pb-3 pl-0"> Opis <v-icon v-if="!showDesc">mdi-chevron-down</v-icon><v-icon v-if="showDesc">mdi-chevron-up</v-icon></v-btn>
                </v-col>
            </v-row>
            <v-row no-gutters v-if="showDesc">
                <v-col cols="12" sm="8" md="8" xs="10" lg="8" xl="8" class="pt-0">
                  <v-textarea
                      dense
                      :label="lang.ItemDescription"
                      v-model="iDescription"
                    ></v-textarea>
                </v-col>
            </v-row>

            <v-row no-gutters>
               <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3">
                  <v-text-field
                      dense
                      :label="lang.ItemUnit"
                      v-model="iUnit"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3">
                  <v-text-field
                      dense
                      :label="lang.FreeF3"
                      v-model.number="iSize"
                      type="number"
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3">
                  <v-text-field
                      dense
                      :label="lang.FreeF4"
                      v-model.number="iFreeF4"
                      type="number"
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-3">
                  <v-text-field
                      dense
                      :label="lang.ItemEANCode"
                      v-model="iEANCode"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                    <v-text-field
                      dense
                      :label="lang.ItemSerialNr"
                      v-model="iSerialNr"
                    ></v-text-field>
                </v-col>
            </v-row>
            
          </v-card>
          <v-card
            class="pa-2 ma-1"
                outlined
              >

              <v-row no-gutters>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12" class="gray">
                  <div class="text-h6 pb-2">
                    <span>Cijena</span>
                  </div>
                </v-col>
              </v-row>
            <v-row v-if="this.$store.state.documentSide === 1 && exportDoc" no-gutters>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                    <v-text-field
                      dense
                      :label="lang.itemSuppBaseForePrice"
                      v-model.number="iSuppBaseForePrice"
                      type="number"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
 
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
                  <v-text-field
                      dense
                      :label="lang.ItemExRate"
                      v-model.number="ExRate"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                    <v-text-field
                      dense
                      :label="lang.itemSuppBasePercentageExp"
                      v-model.number="iSuppBasePercentageExp"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>

                
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                    <v-text-field
                      dense
                      :label="lang.itemDependablesAmount"
                      v-model.number="iDependablesAmount"
                      type="number"
                      disabled
                    ></v-text-field>          
                </v-col>
            </v-row>


             <v-row v-if="this.$store.state.documentSide === 1" no-gutters>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <span v-if="this.$store.state.documentSide === 1">
                      <v-text-field
                        dense
                        :label="lang.ItemPcsPlus"
                        v-model.number="iPcsPlus"
                        type="number"
                      ></v-text-field>
                   </span>
                </v-col>

                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
 
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemSuppBasePrice"
                      v-model.number="iSuppBasePrice"
                      type="number"
                      :disabled="!fullpriceChange || exportDoc"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
                  <v-text-field
                      dense
                      :label="lang.ItemSuppDiscount"
                      v-model.number="iSuppDiscount"
                      type="number"
                      :disabled="!fullpriceChange"
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                    <v-text-field
                      dense
                      :label="lang.ItemSuppPriceAmount"
                      v-model.number="iSuppPriceAmount"
                      type="number"
                      disabled
                    ></v-text-field>          
                </v-col>
            </v-row>

             <v-row v-if="this.$store.state.documentSide === 1" no-gutters>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemSuppPrice"
                      v-model.number="iSuppPrice"
                      type="number"
                      disabled
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
  
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemMargin"
                      v-model.number="iMargin"
                      type="number"
                      :disabled="!fullpriceChange"
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.FreeF2"
                      v-model.number="iFreeF2"
                      type="number"
                      disabled
                    ></v-text-field>                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemMarginAmount"
                      v-model.number="iMarginAmount"
                      type="number"
                      disabled
                    ></v-text-field>    
                </v-col>                
            </v-row>

            <v-row v-if="!hidePurchase && (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3)" no-gutters>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemSuppPrice"
                      v-model.number="iSuppPrice"
                      type="number"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                   
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                        
                </v-col>
                  
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemMargin"
                      v-model.number="iMargin"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemMarginAmount"
                      v-model.number="iMarginAmount"
                      type="number"
                      disabled
                    ></v-text-field>    
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-btn text small color="primary" @click="checkSupplierPrice" class="mr-1">
                    Dob.Provjeri
                  </v-btn>
                </v-col>
            </v-row>

             <v-row v-if="this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3" no-gutters>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                    <v-text-field
                      dense
                      :label="lang.itemPcsMinus"
                      v-model.number="iPcsMinus"
                      type="number"
                      v-if="this.$store.state.documentSide === 2"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="lang.ItemPcsNeutral"
                      v-model.number="iPcsNeutral"
                      type="number"
                      v-if="this.$store.state.documentSide === 3"
                    ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-3">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemPrice"
                      v-model.number="iPrice"
                      type="number"
                      v-if="this.showExport === false"
                      :disabled="!fullpriceChange"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="langItemPrice"
                      v-model.number="iForePrice"
                      type="number"
                      v-if="this.showExport === true"
                      :disabled="!fullpriceChange"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-2">
                  <span v-if="this.$store.state.documentSide === 1">
                    <v-text-field
                      dense
                      :label="lang.ItemMaxDiscount"
                      v-model.number="iMaxDiscount"
                      type="number"
                      disabled
                    ></v-text-field>
                  </span>
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemBaseAmount"
                      v-model.number="iBaseAmount"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="langItemBaseAmount"
                      v-model.number="iBaseForeAmount"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === true"
                    ></v-text-field>                
                </v-col>
            </v-row>

             <v-row v-if="this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3" class="pa-0 mt-0 mb-0">
                
                 
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemDiscountPercentage1"
                      v-model.number="iDiscountPercentage1"
                      type="number"
                      :disabled="!fullpriceChange"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemMaxDiscount"
                      v-model.number="iMaxDiscount"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemDiscountPrice"
                      v-model.number="iDiscountPrice"
                      type="number"
                      disabled
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="lang.ItemDiscountPrice"
                      v-model.number="iDiscountForePrice"
                      type="number"
                      disabled
                      v-if="this.showExport === true"
                    ></v-text-field>  
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
 
                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="langItemBaseAmountNoVAT"
                      v-model.number="iBaseAmountNoVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="langItemBaseAmountNoVAT"
                      v-model.number="iBaseForeAmountNoVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === true"
                    ></v-text-field>                  
                </v-col>
            </v-row>


            <v-row  v-if="this.$store.state.documentSide === 1 && !doRetail"  class="pa-0 mt-0 mb-0">

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-text-field
                      dense
                      :label="lang.ItemPrice"
                      v-model.number="iPrice"
                      type="number"
                      :disabled="fullpriceChange"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
                      <v-icon
                          @click="fullpriceSwitch"
                        >
                          {{fullpriceChange ? 'mdi-pencil' : 'mdi-close'}}
                        </v-icon>
                </v-col>
               
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemVAT1Percentage"
                      v-model.number="iVAT1Percentage"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>                 
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-text-field
                      dense
                      :label="lang.ItemPriceWithVAT"
                      v-model.number="iPriceWithVAT"
                      type="number"
                      disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
 
                </v-col>
                 
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-text-field
                      dense
                      :label="langItemAmountWithVAT"
                      v-model.number="iAmountWithVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                    ></v-text-field>       
                </v-col>
            </v-row>

            <v-row  v-if="this.$store.state.documentSide === 1 && doRetail"  class="pa-0 mt-0 mb-0">

              <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemPrice"
                      v-model.number="iPrice"
                      type="number"
                      disabled
                    ></v-text-field>
              </v-col>
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
 
              </v-col>

              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemVAT1Percentage"
                      v-model.number="iVAT1Percentage"
                      type="number"
                      disabled
                    ></v-text-field>
              </v-col>                 
              <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemPriceWithVAT"
                      v-model.number="iPriceWithVAT"
                      type="number"
                      :disabled="fullpriceChange"
                    ></v-text-field>
               </v-col>
               <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-icon
                          @click="fullpriceSwitch"
                        >
                          {{fullpriceChange ? 'mdi-pencil' : 'mdi-close'}}
                        </v-icon>
                </v-col>
                
                 
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-text-field
                      dense
                      :label="langItemAmountWithVAT"
                      v-model.number="iAmountWithVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === false"
                    ></v-text-field>       
                </v-col>
            </v-row>


            <v-row  v-if="(this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3)" class="pa-0 mt-0 mb-0">
               
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemVAT1Percentage"
                      v-model.number="iVAT1Percentage"
                      type="number"
                      disabled
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="lang.ItemVAT1Percentage"
                      v-model.number="iForeVAT1Percentage"
                      type="number"
                      disabled
                      v-if="this.showExport === true"
                    ></v-text-field>
                </v-col>                 
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemVAT1Amount"
                      v-model.number="iVAT1Amount"
                      type="number"
                      disabled
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="lang.ItemVAT1Amount"
                      v-model.number="iForeVAT1Amount"
                      type="number"
                      disabled
                      v-if="this.showExport === true"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" >
 
                </v-col>
                 <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mr-1">
                      <v-text-field
                      dense
                      :label="lang.ItemPriceWithVAT"
                      v-model.number="iPriceWithVAT"
                      type="number"
                      :disabled="fullpriceChange"
                      v-if="this.showExport === false"
                    ></v-text-field>
                     <v-text-field
                      dense
                      :label="lang.ItemPriceWithVAT"
                      v-model.number="iForePriceWithVAT"
                      type="number"
                      :disabled="fullpriceChange"
                      v-if="this.showExport === true"
                    ></v-text-field>
                </v-col>

                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                      <v-icon
                          @click="fullpriceSwitch"
                        >
                          {{fullpriceChange ? 'mdi-pencil' : 'mdi-check'}}
                        </v-icon>
                </v-col>
                
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-text-field
                      dense
                      :label="langItemAmountWithVAT"
                      v-model.number="iAmountWithVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="langItemAmountWithVAT"
                      v-model.number="iForeAmountWithVAT"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === true"
                    ></v-text-field>         
                </v-col>
            </v-row>

            <v-row class="pa-0 mt-0 mb-0">
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                    <v-text-field
                    dense
                    :label="lang.ItemDirectDuty1"
                    v-model.number="iDirectDuty1"
                    type="number"
                    v-if="this.showExport === false"
                  ></v-text-field>
                  <v-text-field
                    dense
                    :label="lang.ItemDirectDuty1"
                    v-model.number="iForeDirectDuty1"
                    type="number"
                    v-if="this.showExport === true"
                  ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
                  <v-text-field
                      dense
                      :label="lang.ItemDirectDuty1Amount"
                      v-model.number="iDirectDuty1Amount"
                      type="number"
                      disabled
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="lang.ItemDirectDuty1Amount"
                      v-model.number="iDirectDuty1AmountFore"
                      type="number"
                      disabled
                      v-if="this.showExport === true"
                    ></v-text-field>
                </v-col>
                 <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="mr-1">
 
                </v-col>
               
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-checkbox
                    v-model="iService"
                    :label="lang.Service"
                    class="pa-0 ma-0"
                  ></v-checkbox>
                </v-col>
                 <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                    <div v-if="showPT">
                      <v-select
                        :label="lang.MainLedgerProjectTypeName"
                        :items="projectTypes"
                        v-model="selectPT"
                        item-text="TypeName"
                        item-value="id"
                        return-object
                        @change="choosedProjectType(selectPT)"
                        clearable
                        class="pt-0 pb-0 pl-0 pr-2 ma-0"></v-select>
                    </div>
                  </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" >
                  <v-text-field
                      dense
                      :label="langItemFullTotal"
                      v-model.number="iFullTotal"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === false"
                    ></v-text-field>
                    <v-text-field
                      dense
                      :label="langItemFullTotal"
                      v-model.number="iFullTotalFore"
                      type="number"
                      disabled
                      style="font-weight:bold"
                      v-if="this.showExport === true"
                    ></v-text-field>                
                </v-col>
            </v-row>

            <v-row>
                
            </v-row>
            
          </v-card>
          <v-btn
            class="primary mr-1"
             :disabled="!formIsValid"
            @click="register">
            {{lang.SaveItem}} {{ documentNameLoc }} 
          </v-btn>
          <v-btn
            color="green"
            @click="documentdetaillist">
            {{langC.Back}}
          </v-btn>

      </v-col>
    </v-row>

    <!-- <div v-if="this.showItemCard"> -->
      <v-dialog
        v-model="showItemCard"
        activator="parent"
        width="auto"
      >
        <v-row class="pt-2">
            <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
              <ItemViewCard2 />
          </v-col>
        </v-row>
     </v-dialog>
    <!-- </div> -->

    <!-- <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.statewhs">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentWarehouseState></appDocumentWarehouseState>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row> -->

    <v-dialog
        v-model="statewhs"
        activator="parent"
        width="auto"
      >
        <appDocumentWarehouseState/>
      </v-dialog>

      <v-dialog
        v-model="suppcard"
        activator="parent"
        width="auto"
      >
        <appDocumentItemCard />
       </v-dialog>

    <!-- <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.suppcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCard />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row> -->

    <v-dialog
        v-model="sellcard"
        activator="parent"
        width="auto"
      >
        <appDocumentItemCardSales></appDocumentItemCardSales>
    </v-dialog>

    <!-- <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.sellcard">
            <v-row class="pt-2">
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <appDocumentItemCardSales></appDocumentItemCardSales>
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row> -->
    <v-dialog
        v-model="newItemCreate"
        activator="parent"
        width="auto"
      >
        <ItemCreate />
    </v-dialog>

     <!-- <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="10" lg="10" xs="12" sm="12" md="10" class="pa-0">
        <div v-if="this.newItemCreate">
            <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <ItemCreate />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row> -->

    <v-dialog
        v-model="showConditionGroup"
        activator="parent"
        width="auto"
      >
        <ItemListFast :conditionGroup="conditionGroup" />
    </v-dialog>

    <!-- <v-row no-gutters justify="center" class="pa-0">
      <v-col xl="12" lg="12" xs="12" sm="12" md="12" class="pa-0">
        <div v-if="this.showConditionGroup">
            <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
                  <ItemListFast :conditionGroup="conditionGroup" />
              </v-col>
            </v-row>
        </div>
      </v-col>
    </v-row> -->

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>

  </v-container>
  </v-form>
</template>

<script>
// import _ from 'lodash'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import ItemService from '@/services/ItemService'
import DocumentWarehouseState from './DocumentWarehouseState'
import DocumentItemCard from './DocumentItemCard'
import DocumentItemCardSales from './DocumentItemCardSales'
import ItemCreate from '../Item/ItemCreate'
import ItemViewCard2 from '../Item/ItemViewCard2'
import ItemListFast from '../Item/ItemListFast'
import {defDocumentDetail, defNewDocumentDetail} from './documentHelper'
import { mapGetters } from 'vuex'
import {parseNum} from '@/snippets/allSnippets'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'documentdetailcreate2',
  $validates: true,
  data () {
    return {
      snackbar: false,
      alert: true,
      text: '',
      timeout: 2000,
      color: 'green',
      numberValue: 0,
      lang: {},
      langC: {},
      docdetailitems: [],
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      documentName: '',
      documentNameLoc: '',
      documentDet: {
      },
      itemTotalPrice: 0,
      error: null,
      typeError: null,
      localError: false,
      newDocument: {},
      size: 'sm',
      required: (value) => !!value || 'Required.',
      itemsLoc: [],
      itemLoc: {},
      CompanyId: 0,
      selectIL: {},
      sumDoc: {
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0
      },
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      mainQuery2: {
        CompanyId: 1,
        itemID: ''
      },
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      documentUpdated: {},
      price: 1,
      pcsLoc: 0,
      suppAmount: 0,
      suppNetoAmount: 0,
      maxDisc: 0,
      maxDiscPer: 0,
      haveEdit: false,
      noCalculate: false,
      haveNotice: '',
      firstUpdate: 0,
      workOn: false,
      fullpriceChange: true,
      fullpriceBtn: 'Change',
      itemIndex: 0,
      activItems: [],
      calcVersion: 1,
      statewhs: false, 
      suppcard: false,
      outcard: false,
      sellcard: false,
      groupcard: false,
      newItemCreate: false,
      privGroups: [],
      selectGroup: {},
      documentAna: {
        CompanyId: 0,
        BusinessYear: 0,
        UserId: 0,
        partnerId: 0,
        documentName: '',
        docFromDate: null,
        docToDate: null,
        itemID: null,
        warehouseID: null
      },
      payInAdvance: 0,
      exportDoc: false,
      ppo:false,
      langItemPrice: '',
      langItemBaseAmount: '',
      langItemBaseAmountNoVAT: '',
      langItemAmountWithVAT: '',
      langItemFullTotal: '',
      langItemSuppPrice: '',
      langItemMargin: '',
      langItemMarginAmount: '',
      ExRate: this.$store.getters.NeedDefExRate,
      homeDiscount : 0,
      exportDiscount: 0,
      homePriceWithVAT : 0,
      itemPrice: 0,
      peaces: 1,
      margin: 0,
      suppPrice: 0,
      suppDiscount: 0,
      emptyObj: {},
      ePrice: 0,
      ePcs: 0,
      showConditionGroup: false,
      conditionGroup: null,
      projectTypes: [],
      selectPT: {},
      showPT: false,
      haveItemSelected: false,
      iID: '',
      iName: '',
      iNameShort: '',
      iCode: '',
      iEANCode: '',
      iGroup: '',
      iGroupName: '',
      iForceNewPrintPage: false,
      iDescription: '',
      iUnit: '',
      iSize: '',
      iWeight: 0,
      iWeightMeasure: '',
      iDimMeasure: '',
      iDimLenght: 0,
      iDimHeight: 0,
      iDimDepth: 0,
      iWarehouse: '',
      iWarehouseRow: '',
      iWarehouseShelf: '',
      iWahrehouseFloor: '',
      iPicture1: '',
      iPicture2: '',
      iPicture3: '',
      iPicture4: '',
      iPicture5: '',
      iPicture6: '',
      iPicture7: '',
      iBelongsToId: 0,
      iService: false,
      iWeb: false,
      iNotActiv: false,
      iHideForever: false,
      iHomeCurrency: '',
      iForeignCurrency: '',
      iExRate: 0,
      iTransport: 0,
      iTransportAmount: 0,
      iCustom: 0,
      iCustomAmount: 0,
      iDependables: 0,
      iDependablesAmount: 0,
      iSuppBaseForePrice: 0,
      iSuppBasePercentageExp: 0,
      iSuppBaseForePrice2: 0,
      iSuppBaseForeAmount: 0,
      iSuppBasePrice: 0,
      iSuppBasePriceAmount: 0,
      iSuppDiscount: 0,
      iSuppDiscountAmount: 0,
      iSuppPrice: 0,
      iSuppPriceAmount: 0,
      iSuppCode: '',
      iMargin: 0,
      iMarginAmount: 0,
      iMaxDiscount: 0,
      iPrice: 0,
      iVAT1Percentage: 0,
      iVAT1Amount: 0,
      iVAT2Percentge: 0,
      iVAT2Amount: 0,
      iDirectDuty1: 0,
      iDirectDuty2: 0,
      iPriceWithVAT: 0,
      iForePrice: 0,
      iForeVAT1Percentage: 0,
      iForeVAT1Amount: 0,
      iForeVAT2Percentage: 0,
      iForeVAT2Amount: 0,
      iForeDirectDuty1: 0,
      iForeDirectDuty2: 0,
      iForePriceWithVAT: 0,
      iBaseAmount: 0,
      iBaseForeAmount: 0,
      iDiscountAmount1: 0,
      iDiscountForeAmount1: 0,
      iDiscountPercentage1: 0,
      iDiscountAmount2: 0,
      iDiscountPercentage2: 0,
      iDiscountAmount3: 0,
      iDiscountPercentage4: 0,
      iDiscountPrice: 0,
      iDiscountForePrice: 0,
      iSerialNr: '',
      iPcsPlus: 0,
      iPcsMinus: 0,
      iPcsNeutral: 0,
      iBaseAmountNoVAT: 0,
      iBaseForeAmountNoVAT: 0,
      iBaseAmountWithVAT: 0,
      iBaseForeAmountWithVAT: 0,
      iAmountWithVAT: 0,
      iFullTotal: 0,
      iFullTotalFore: 0,
      iForeAmountWithVAT: 0,
      iDirectDuty1Amount: 0,
      iDirectDuty2Amount: 0,
      iDirectDuty1AmountFore: 0,
      iDirectDuty2AmountFore: 0,
      niPrice: 0,
      niBaseAmount: 0,
      niVAT1Percentage: 0,
      niVAT1Amount: 0,
      niAmountWithVAT: 0,
      niDirectDuty1: 0,
      niFullTotal: 0,
      iexportDoc: false,
      iitemPriceOriginal: 0,
      iitemFullPriceOriginal: 0,
      iFreeF1: 0,
      iFreeF2: 0,
      iFreeF3: 0,
      iFreeF4: 0,
      iFreeF5: 0,
      iFreeF6: 0,
      iTypeID: 0,
      iTypeName: '',
      showExport: false,
      showDesc: false,
      doRetail: false,
      marginIsZero: false,
      showItemCard: false,
      hidePurchase: false,
      showOptions: false, 
      showItemDetails: false
      // infoSuppBaseForePrice: 0,
      // infoSuppBasePrice: 0,
      // infoSuppDiscount: 0,
      // infoSuppPrice: 0,
      // infoMargin: 0,
    }
  },
  components: {
    appDocumentWarehouseState: DocumentWarehouseState,
    appDocumentItemCard: DocumentItemCard,
    appDocumentItemCardSales: DocumentItemCardSales,
    ItemCreate,
    ItemListFast,
    ItemViewCard2
  },
  async created () {
 
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.exportDoc = this.$store.state.documentActivHead.exportDoc
    this.ppo = this.$store.state.documentActivHead.ppo
    if (this.exportDoc && this.$store.state.documentActiv === 'ReceiptPurchase') {
      this.showExport = false
    }
    if (this.exportDoc && this.$store.state.documentActiv !== 'ReceiptPurchase') {
      this.showExport = true
    }
    this.doRetail = this.$store.state.companyActiv.CompanyUseRetailPrice

    this.hidePurchase = this.$store.state.user && this.$store.state.user.sales2 ? this.$store.state.user.sales2  : false

    let haveCurrency = ''
    if (this.exportDoc === false) {
      haveCurrency = this.$store.state.documentActivHead.documentHomeCurrency ? (' ' + this.$store.state.documentActivHead.documentHomeCurrency) : ''
    } else {
      if (this.showExport) {haveCurrency = this.$store.state.documentActivHead.documentForeignCurrency ? (' ' + this.$store.state.documentActivHead.documentForeignCurrency) : ''}
      if (!this.showExport) {haveCurrency = this.$store.state.documentActivHead.documentHomeCurrency ? (' ' + this.$store.state.documentActivHead.documentHomeCurrency) : ''}
    }
    this.langItemPrice = this.lang.ItemPrice + haveCurrency
    this.langItemBaseAmount = this.lang.ItemBaseAmount + haveCurrency
    this.langItemBaseAmountNoVAT = this.lang.ItemBaseAmountNoVAT + haveCurrency
    this.langItemAmountWithVAT = this.lang.ItemAmountWithVAT + haveCurrency
    this.langItemFullTotal = this.lang.ItemFullTotal + haveCurrency
    this.langItemSuppPrice = this.lang.ItemSuppPrice + (this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR')
    this.langItemMargin = this.lang.ItemMargin
    this.langItemMarginAmount = this.lang.ItemMarginAmount + (this.$store.state.businessYear <= 2022 ? 'Kn' : 'EUR')

    
    this.ExRate = this.$store.state.documentActivHead.documentExRate && parseFloat(this.$store.state.documentActivHead.documentExRate) > 1 ? parseFloat(this.$store.state.documentActivHead.documentExRate) : 1
    this.iExRate = this.ExRate
    this.iSuppBasePercentageExp = this.$store.state.documentActivHead.expenesPercent && parseFloat(this.$store.state.documentActivHead.expenesPercent) > 0 ? parseFloat(this.$store.state.documentActivHead.expenesPercent) : 0

    this.projectTypes = this.$store.state.projectTypes ? this.$store.state.projectTypes : []
    const docName = this.$store.state.documentActiv
    if (docName === 'InvoiceSales' || docName === 'DeliveryNoteSales' || docName === 'InternalDeliveryNotes') {
      this.showPT = true
    }
    
    // if add new item in items
    this.$store.dispatch('setnewItemAround', false)
    // if NEW item
    if (this.$store.state.editItem === 0 ) {
      this.newItem = true
      this.firstUpdate = 3
      await this.makeItNew()
    }
    // if edit item
    if (this.$store.state.editItem === 1 && this.$store.state.documentdetailid) {
      const itemOnly = this.$store.state.documentActivItems.find(item => {
        return item.id === this.$store.state.documentdetailid
      })
      await Object.assign(this.documentDet, itemOnly)
      await this.assignIt(itemOnly, 0)
      this.itemIndex = this.$store.state.documentActivItems.indexOf(itemOnly)
      if (this.documentDet.itemID) {
        this.selectIL =
        {
          itemByID: (this.documentDet.itemID + ' ' + this.documentDet.itemName),
          itemID: this.documentDet.itemID
        }
        this.haveItemSelected = true
      }
      this.showDesc = this.iDescription && this.iDescription.trim().length > 0 ? true : false

      await this.initPcs()
      this.suppDiscount = this.iSuppDiscount
      this.selectPT = this.defaultItem && this.defaultItem.TypeID ? this.projectTypes.find(element => element.id === this.defaultItem.TypeID) : null
    }

    this.documentName = this.$store.state.documentActiv
    this.documentNameLoc = this.$store.state.documentActivLoc
    this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
    this.privGroups = this.$store.state.groupStore ? this.$store.state.groupStore : []
    this.workOn = true
  },
  computed: {
    formIsValid () {
      return this.iID !== ''
    },
    ...mapGetters({
      mySelectedItemId: 'NeedSelectedItemId'
    }),
  },
  methods: {
    optionsOn () {
      this.showOptions = !this.showOptions
    },
    itemDetailsOn () {
      this.showItemDetails = !this.showItemDetails
    },
    addDesc () {
      this.showDesc = !this.showDesc
    },
    showItemCardd () {
      this.showItemCard = !this.showItemCard
    },
    async checkSupplierPrice() {
      if (this.iID) {
        if (!this.iService) {
          this.mainQuery2 = {...this.emptyObj}
          this.mainQuery2.CompanyId = this.$store.state.companyid
          this.mainQuery2.itemID = this.iID ? this.iID : null
          await ItemService.choose(this.mainQuery2)
          .then((res) => {
            if (res.data && res.data.item !== undefined) {
              this.initPcs()
              this.iSuppBasePrice = parseNum(res.data.item.itemSuppPrice, 2)
              this.iSuppPrice = parseNum(res.data.item.itemSuppPrice, 2)
              this.iSuppDiscount = 0
              this.iSuppDiscountAmount = 0
              this.iSuppBasePriceAmount  = (this.iSuppBasePrice * this.pcsLoc) ? this.round((this.iSuppBasePrice * this.pcsLoc),2) : 0
              this.iSuppPriceAmount = this.iSuppBasePriceAmount
              this.calculateMarginSale()
            }
            this.text = 'OK.'
            this.color = 'green'
            this.snackbar = true
          })
          .catch(err => {
            console.log(' Error items ', err)
            this.text = this.lang.UpdateItemNameUpdatedError
            this.color = 'red'
            this.snackbar = true
          })
        }
        if (this.iService){
          this.calculateMarginSale()
        }
      }
    },
    warehouseState() {
      this.statewhs = !this.statewhs
      this.$store.dispatch('setdontGo', false)
      this.$store.dispatch("setGroupid", null)
      if (this.selectGroup && this.selectGroup.id) {
        this.$store.dispatch("setGroupid", this.selectGroup.id)
      }
    },
    showGroup() {
      this.conditionGroup = null
      if (this.selectGroup && this.selectGroup.id) {
        this.conditionGroup = this.selectGroup.id
      }
      this.showConditionGroup = !this.showConditionGroup
    },
    choosedProjectType (choosedPT) {
      if (choosedPT) {
        const result = this.projectTypes.filter(element => element.id === choosedPT.id)
        if (result[0]) {
          const res = result[0]
          this.documentDet.TypeID = res.id
          this.documentDet.TypeName = res.TypeName
          this.selectPT = res
        } else {
          this.text = this.lang.MainLedgerProjectTypeEmpty
          this.color = 'red'
          this.snackbar = true
        }
      } else {
        this.documentDet.TypeID = null
        this.documentDet.TypeName = null
      }     
    },
    createNewItem() {
      this.$store.dispatch('setnewItemAround', true)
      this.newItemCreate = !this.newItemCreate
    },
    async initAna() {
      if (this.$store.state.companyid) {
        this.documentAna.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.documentAna.BusinessYear = this.$store.state.businessYear
      }
      if (this.selectIL.itemID) {
        this.documentAna.itemID = this.selectIL.itemID
      }
      this.documentAna.docFromDate = dayjs([this.documentAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.documentAna.docToDate = dayjs([this.documentAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
      await this.$store.dispatch('setDocumentAna', this.documentAna)
    },
    itemCard() {
      if (this.selectIL.itemID) {
        this.initAna()  
        this.suppcard = !this.suppcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardMinus() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.outcard = !this.outcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    itemCardSell() {
      if (this.selectIL.itemID) {
        this.initAna()
        this.sellcard = !this.sellcard
      } else {
        this.text = this.lang.itemNotSelected
        this.color = 'red'
        this.snackbar = true
      }
    },
    fullpriceSwitch() {
      this.fullpriceChange = !this.fullpriceChange
      this.initPcs()
    },
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    doParse () {
      this.documentDet.ItemWeight = this.documentDet.ItemWeight ? parseFloat(this.documentDet.ItemWeight) : 0
      this.documentDet.ItemDimLenght = this.documentDet.ItemDimLenght ? parseFloat(this.documentDet.ItemDimLenght) : 0
      this.documentDet.ItemDimHeight = this.documentDet.ItemDimHeight ? parseFloat(this.documentDet.ItemDimHeight) : 0
      this.documentDet.ItemDimDepth = this.documentDet.ItemDimDepth ? parseFloat(this.documentDet.ItemDimDepth) : 0
      this.documentDet.ItemBelongsToId = this.documentDet.ItemBelongsToId ? parseFloat(this.documentDet.ItemBelongsToId) : 0
      this.documentDet.itemSuppBasePrice = this.documentDet.itemSuppBasePrice ? parseFloat(this.documentDet.itemSuppBasePrice) : 0
      this.documentDet.itemSuppBasePriceAmount = this.documentDet.itemSuppBasePriceAmount ? parseFloat(this.documentDet.itemSuppBasePriceAmount) : 0
      this.documentDet.itemSuppDiscunt = this.documentDet.itemSuppDiscunt ? parseFloat(this.documentDet.itemSuppDiscunt) : 0
      this.documentDet.itemSuppDiscuntAmount = this.documentDet.itemSuppDiscuntAmount ? parseFloat(this.documentDet.itemSuppDiscuntAmount) : 0
      this.documentDet.itemSuppPrice = this.documentDet.itemSuppPrice ? parseFloat(this.documentDet.itemSuppPrice) : 0
      this.documentDet.itemSuppPriceAmount = this.documentDet.itemSuppPriceAmount ? parseFloat(this.documentDet.itemSuppPriceAmount) : 0
      this.documentDet.ItemSuppCode = this.documentDet.ItemSuppCode ? parseFloat(this.documentDet.ItemSuppCode) : 0
      this.documentDet.itemMargin = this.documentDet.itemMargin ? parseFloat(this.documentDet.itemMargin) : 0
      this.documentDet.itemMarginAmount = this.documentDet.itemMarginAmount ? parseFloat(this.documentDet.itemMarginAmount) : 0
      this.documentDet.itemMaxDiscount = this.documentDet.itemMaxDiscount ? parseFloat(this.documentDet.itemMaxDiscount) : 0
      this.documentDet.itemPrice = this.documentDet.itemPrice ? parseFloat(this.documentDet.itemPrice) : 0
      this.documentDet.itemVAT1Percetage = this.documentDet.itemVAT1Percetage ? parseFloat(this.documentDet.itemVAT1Percetage) : 0
      this.documentDet.itemVAT1Amount = this.documentDet.itemVAT1Amount ? parseFloat(this.documentDet.itemVAT1Amount) : 0
      this.documentDet.itemVAT2Percentge =this.documentDet.itemVAT2Percentge ? parseFloat(this.documentDet.itemVAT2Percentge) : 0
      this.documentDet.itemVAT2Amount = this.documentDet.itemVAT2Amount ? parseFloat(this.documentDet.itemVAT2Amount) : 0
      this.documentDet.itemDirectDuty1 = this.documentDet.itemDirectDuty1 ? parseFloat(this.documentDet.itemDirectDuty1) : 0
      this.documentDet.itemDirectDuty2 = this.documentDet.itemDirectDuty2 ? parseFloat(this.documentDet.itemDirectDuty2) : 0
      this.documentDet.itemPriceWithVAT = this.documentDet.itemPriceWithVAT ? parseFloat(this.documentDet.itemPriceWithVAT) : 0
      this.documentDet.itemForePrice = this.documentDet.itemForePrice ? parseFloat(this.documentDet.itemForePrice) : 0
      this.documentDet.itemForeVAT1Percentage = this.documentDet.itemForeVAT1Percentage ? parseFloat(this.documentDet.itemForeVAT1Percentage) : 0
      this.documentDet.itemForeVAT1Amount = this.documentDet.itemForeVAT1Amount ? parseFloat(this.documentDet.itemForeVAT1Amount) : 0
      this.documentDet.itemForeVAT2Percentage = this.documentDet.itemForeVAT2Percentage ? parseFloat(this.documentDet.itemForeVAT2Percentage) : 0
      this.documentDet.itemForeVAT2Amount = this.documentDet.itemForeVAT2Amount ? parseFloat(this.documentDet.itemForeVAT2Amount) : 0
      this.documentDet.itemForeDirectDuty1 = this.documentDet.itemForeDirectDuty1 ? parseFloat(this.documentDet.itemForeDirectDuty1) : 0
      this.documentDet.itemForeDirectDuty2 = this.documentDet.itemForeDirectDuty2 ? parseFloat(this.documentDet.itemForeDirectDuty2) : 0
      this.documentDet.itemForePriceWithVAT = this.documentDet.itemForePriceWithVAT ? parseFloat(this.documentDet.itemForePriceWithVAT) : 0
      this.documentDet.itemPcsNeutral = this.documentDet.itemPcsNeutral ? parseFloat(this.documentDet.itemPcsNeutral) : 0
      this.documentDet.itemPcsMinus = this.documentDet.itemPcsMinus ? parseFloat(this.documentDet.itemPcsMinus) : 0
      this.documentDet.itemFullTotal = this.documentDet.itemFullTotal ? parseFloat(this.documentDet.itemFullTotal) : 0
      this.documentDet.itemFullTotalFore = this.documentDet.itemFullTotalFore ? parseFloat(this.documentDet.itemFullTotalFore) : 0
      this.documentDet.itemDirectDuty1Amount = this.documentDet.itemDirectDuty1Amount ? parseFloat(this.documentDet.itemDirectDuty1Amount) : 0
      this.documentDet.itemDirectDuty2Amount = this.documentDet.itemDirectDuty2Amount ? parseFloat(this.documentDet.itemDirectDuty2Amount) : 0
      this.documentDet.itemDirectDuty1AmountFore = this.documentDet.itemDirectDuty1AmountFore ? parseFloat(this.documentDet.itemDirectDuty1AmountFore) : 0
      this.documentDet.itemDirectDuty2AmountFore = this.documentDet.itemDirectDuty2AmountFore ? parseFloat(this.documentDet.itemDirectDuty2AmountFore) : 0
      this.documentDet.FreeF1 = this.documentDet.FreeF1 ? parseFloat(this.documentDet.FreeF1) : 0
      this.documentDet.FreeF2 = this.documentDet.FreeF2 ? parseFloat(this.documentDet.FreeF2) : 0
      this.documentDet.FreeF3 = this.documentDet.FreeF3 ? parseFloat(this.documentDet.FreeF3) : 0
      this.documentDet.FreeF4 = this.documentDet.FreeF4 ? parseFloat(this.documentDet.FreeF4) : 0
      this.documentDet.FreeF5 = this.documentDet.FreeF5 ? parseFloat(this.documentDet.FreeF5) : 0
      this.documentDet.itemPriceOriginal = this.documentDet.itemPriceOriginal ? parseFloat(this.documentDet.itemPriceOriginal) : 0
      this.documentDet.itemFullPriceOriginal = this.documentDet.itemFullPriceOriginal ? parseFloat(this.documentDet.itemFullPriceOriginal) : 0
    },
    async register () {
      try {
        this.localError = false

        await this.unassignIt()

        if (this.$store.state.editItem === 1) {
          const activItems = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
          activItems.splice(this.itemIndex, 1, this.documentDet)
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          await this.$store.dispatch('seteditItem', 0)
          this.haveNotice = this.lang.itemUpdated
        } else {
          const newItem = this.documentDet
          this.activItems = this.$store.state.documentActivItems
          this.activItems.push(newItem)
          if (this.activItems) {
            this.$store.dispatch('setDocumentActivItems', this.activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          this.haveNotice = this.lang.itemAdded
        }
        this.makeSum()
        this.$router.push({
          name: 'documentview'
        })
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error
        this.typeError = 'error'
      }
    },
    close () {
      this.show = false
    },
    async updateItemName() {
      if (this.haveItemSelected) {
        const updateItem = {}
        updateItem.itemID = this.documentDet.itemID
        updateItem.itemName = this.documentDet.itemName
        updateItem.itemDescription = this.documentDet.itemDescription
        updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
        const updateItems = []
        updateItems.push(updateItem)
        await ItemService.updateprices(updateItems)
        .then(async(res) => {
          console.log(res.data)
          this.text = this.lang.UpdateItemNameUpdated
          this.color = 'green'
          this.snackbar = true
          await newUpdates(1)
          await testLastUpdate()
          // this.mainQuery.CompanyId = this.$store.state.companyid
          // this.itemsLoc = (await ItemService.index(this.mainQuery)).data.items
          // if (this.itemsLoc) {
          //   this.$store.dispatch('setActivItems', this.itemsLoc)
          // }
        })
        .catch(err => {
          console.log(' Error items ', err)
          this.text = this.lang.UpdateItemNameUpdatedError
          this.color = 'red'
          this.snackbar = true
        })
      }
    },
    async deleteDD2 () {
      try {
        if (this.$store.state.documentdetailid) {
          const activItems = this.$store.state.documentActivItems.filter(item => {
            return item.id !== this.$store.state.documentdetailid
          })
          if (activItems) {
            this.$store.dispatch('setDocumentActivItems', activItems)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          // make sum local head
          this.makeSum()
          if (this.$store.state.language === 'EN') {
            this.error = 'Item is deleted.'
            this.typeError = 'success'
          }
          if (this.$store.state.language === 'HR') {
            this.error = 'Obrisana je stavka sa dokumenta'
            this.typeError = 'success'
          }
          
          await this.$router.push({
            name: 'documentview'
          })
        }
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentdetaillist () {
      try {
        await this.$router.push({
          name: 'documentview'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    initPcs() {
      this.pcsLoc = 0

      if (this.$store.state.documentSide === 1) {
        this.pcsLoc = this.iPcsPlus ? this.iPcsPlus : 0
      }
      if (this.$store.state.documentSide === 2) {
        this.pcsLoc = this.iPcsMinus ? this.iPcsMinus : 0
      }
      if (this.$store.state.documentSide === 3) {
        this.pcsLoc = this.iPcsNeutral ? this.iPcsNeutral : 0
      }
      this.pcsLoc = this.round(this.pcsLoc, 2)
      this.iFreeF4 = this.iSize && this.iSize !== 0 ? this.pcsLoc / this.iSize : 0
    },
    // initCalc() {
    //   if (this.fullpriceChange === true) {
    //     if (this.$store.state.documentSide === 1) {
    //       if (this.$store.state.documentActiv === 'ReceiptPurchase') {
    //         this.calculateSupp()
    //       }
    //     }
    //     if (this.exportDoc === false) {
    //       this.calculateDiscount()
    //     } else {
    //       this.calculateDiscountFore()
    //     } 
    //   }
    //   if (!this.fullpriceChange === true) {
    //     if (this.$store.state.documentSide === 1) {
    //       if (this.$store.state.documentActiv === 'ReceiptPurchase') {
    //         this.initSupp()
    //       }
    //     }
    //     if (this.exportDoc === false) {
    //       this.calculatePrice()
    //     } else {
    //       this.calculatePriceFore()
    //     } 
    //   }
    // },
    // ----------------------------------------------------------  >>>>>
    // ----------------------------------------------------------  >>>>>
    // --------------------------------------------------------------------------------  >>>>>
    inputDocCalc() {
      if (this.$store.state.documentSide === 1) {
        if (!this.doRetail) {
          this.initPcs()
          this.initSupp()
          if (this.fullpriceChange) {
            this.marginFromLeft()
            this.finalFromLeft()
          }
          if (!this.fullpriceChange) {
            // calculate margin = iPrice - iSupp
            this.finalFromLeft()
            this.marginFromRight()
          }
        }
        if (this.doRetail) {
          this.initPcs()
          this.initSupp()
          if (this.fullpriceChange) {
            // go normal from left to right
            this.marginFromLeft()
            this.finalFromLeft()
          }
          if (!this.fullpriceChange) {
            // calculate margin = iPrice - iSupp
            this.finalFromRight()
            this.marginFromRight()
          }
        }
      }
      if (this.$store.state.documentSide === 2 || this.$store.state.documentSide === 3) {
        if (!this.doRetail) {
          this.initPcs()
          this.initBaseSupp()
          if (this.fullpriceChange) {
            // go normal from left to right
            this.finalFromLeft()
            this.profitSales()
          }
          if (!this.fullpriceChange) {
            // from right to left
            this.finalFromRight()
            this.profitSales()
          }
        }
        if (this.doRetail) {
          this.initPcs()
          this.initBaseSupp()
          if (this.fullpriceChange) {
            // go normal from left to right
            this.finalFromLeft()
            this.profitSales()
          }
          if (!this.fullpriceChange) {
            // from right to left
            this.finalFromRight()
            this.profitSales()
          }
        }
      }

    },
    initSupp() {
      this.initPcs()
      if (this.exportDoc) {
        if (this.$store.state.documentActiv === 'ReceiptPurchase') {
          const supp = (this.iSuppBaseForePrice ? this.iSuppBaseForePrice : 0) * (this.ExRate  ? this.ExRate  : 0)
          const deppendables = supp * (this.iSuppBasePercentageExp ? this.iSuppBasePercentageExp / 100 : 0)
          if ((supp + deppendables) !== 0) {
            this.iSuppBasePrice = supp + deppendables
            this.iDependablesAmount = deppendables * this.pcsLoc
          }
        }
        
        this.iSuppDiscount = this.iSuppDiscount ? this.iSuppDiscount : 0
        this.iSuppBasePriceAmount  = (this.iSuppBasePrice * this.pcsLoc) ? this.round((this.iSuppBasePrice * this.pcsLoc),2) : 0
        this.iSuppPriceAmount = (this.iSuppBasePriceAmount - (this.iSuppBasePriceAmount * (this.iSuppDiscount / 100))) ? this.round((this.iSuppBasePriceAmount - (this.iSuppBasePriceAmount * (this.iSuppDiscount / 100))),2) : 0
        this.iSuppDiscountAmount = (this.iSuppBasePriceAmount - this.iSuppPriceAmount) ? (this.iSuppBasePriceAmount - this.iSuppPriceAmount) : 0
      }
      if (!this.exportDoc) {
        this.iSuppBasePrice = this.iSuppBasePrice ? this.iSuppBasePrice : 0
        this.iSuppDiscount = this.iSuppDiscount ? this.iSuppDiscount : 0
        this.iSuppBasePriceAmount  = (this.iSuppBasePrice * this.pcsLoc) ? this.round((this.iSuppBasePrice * this.pcsLoc),2) : 0
        this.iSuppPriceAmount = (this.iSuppBasePriceAmount - (this.iSuppBasePriceAmount * (this.iSuppDiscount / 100))) ? this.round((this.iSuppBasePriceAmount - (this.iSuppBasePriceAmount * (this.iSuppDiscount / 100))),2) : 0
        this.iSuppDiscountAmount = (this.iSuppBasePriceAmount - this.iSuppPriceAmount) ? (this.iSuppBasePriceAmount - this.iSuppPriceAmount) : 0
      }
      if (this.pcsLoc !== 0) {
        this.iSuppPrice = (this.iSuppPriceAmount / this.pcsLoc) ? this.round((this.iSuppPriceAmount / this.pcsLoc),3) : 0
      }

    },
    initBaseSupp() {
      // ----------------------------------------------------------  >>>>>
      this.iSuppBasePrice = parseNum(this.iSuppPrice, 2)
      this.iSuppPrice = parseNum(this.iSuppPrice, 2)
      this.iSuppDiscount = 0
      this.iSuppDiscountAmount = 0
      this.iSuppBasePriceAmount  = (this.iSuppBasePrice * this.pcsLoc) ? this.round((this.iSuppBasePrice * this.pcsLoc),2) : 0
      this.iSuppPriceAmount  = this.iSuppBasePriceAmount
    },
    marginFromLeft() {
      this.iMarginAmount = 0
      if (this.iMargin !== 0) {
        this.iMarginAmount = this.round((this.iSuppPriceAmount * (this.iMargin / 100)),2)
      }
      this.iBaseAmount = this.round((this.iSuppPriceAmount  + this.iMarginAmount),2)
      this.iPrice = this.pcsLoc !== 0 ? this.round(this.iBaseAmount / this.pcsLoc, 2) : 0
      this.iFreeF2 = this.pcsLoc !== 0 ? this.round((this.iMarginAmount / this.pcsLoc),2) : 0
    },
    marginFromRight() {
      this.iMarginAmount = (this.iBaseAmount - this.iSuppPriceAmount) ? this.round((this.iBaseAmount - this.iSuppPriceAmount),2) : 0
      this.iMargin = 0
      if (this.iSuppPriceAmount !== 0) {
        this.iMargin = (this.iMarginAmount / (this.iSuppPriceAmount / 100)) ? this.round((this.iMarginAmount / (this.iSuppPriceAmount / 100)),2) : 0
      }
      this.iFreeF2 = (this.iMarginAmount / this.pcsLoc) ? this.round((this.iMarginAmount / this.pcsLoc),2) : 0
    },
    finalFromLeft() {
      if (!this.exportDoc || this.$store.state.documentActiv === 'ReceiptPurchase') {
        this.iDirectDuty1 = this.iDirectDuty1 ? this.round(this.iDirectDuty1, 2) : 0
        this.iVAT1Percentage = this.iVAT1Percentage ? this.round(this.iVAT1Percentage, 2) : 0
        if (this.ppo) {
          this.iVAT1Percentage = 0
        }
        this.iPrice = this.iPrice ? this.round((this.iPrice), 2) : 0

        this.iPriceOriginal = this.iPrice
        this.iFullPriceOriginal = this.round((this.iPrice * (1 + (this.iVAT1Percentage / 100))), 2)

        this.iBaseAmount = (this.iPrice * this.pcsLoc) ? (this.iPrice * this.pcsLoc) : 0
        this.iBaseAmount = this.round(this.iBaseAmount, 2)

        this.iDiscountAmount1 = this.iBaseAmount * (this.iDiscountPercentage1 / 100) ? this.iBaseAmount * (this.iDiscountPercentage1 / 100) : 0
        this.iDiscountAmount1 = this.round(this.iDiscountAmount1, 2)
        this.iBaseAmountNoVAT = this.iBaseAmount  - this.iDiscountAmount1
        this.iBaseAmountNoVAT = this.round(this.iBaseAmountNoVAT, 2)
        this.iDiscountPrice = this.iBaseAmountNoVAT && this.round(this.pcsLoc, 2) !== 0  ? this.round(this.iBaseAmountNoVAT / this.pcsLoc, 2)  : 0
        
        this.iAmountWithVAT = this.iBaseAmountNoVAT * (1 + (this.iVAT1Percentage / 100)) ? this.iBaseAmountNoVAT * (1 + (this.iVAT1Percentage / 100)) : 0
        this.iAmountWithVAT = this.round(this.iAmountWithVAT, 2)
        this.iVAT1Amount = this.iAmountWithVAT - this.iBaseAmountNoVAT
        this.iPriceWithVAT = this.iAmountWithVAT && this.round(this.pcsLoc, 2) !== 0  ? this.round(this.iAmountWithVAT / this.pcsLoc, 2)  : 0
        this.iDirectDuty1Amount = this.round((this.iDirectDuty1 * this.pcsLoc), 2)
        this.iFullTotal = (this.iAmountWithVAT + this.iDirectDuty1Amount)
        this.iFullTotal = this.round(this.iFullTotal, 2)
        this.updateForeWithLocal()
      }
      if (this.exportDoc && this.$store.state.documentActiv !== 'ReceiptPurchase') {
        this.iForeDirectDuty1 = this.iForeDirectDuty1 ? this.round(this.iForeDirectDuty1, 2) : 0
        this.iForeVAT1Percentage = this.iForeVAT1Percentage ? this.round(this.iForeVAT1Percentage, 2) : 0

        this.iForePrice = this.iForePrice ? this.round(this.iForePrice, 3) : 0
        this.iBaseForeAmount = this.round((this.iForePrice * this.pcsLoc), 2)
        this.iBaseForeAmountNoVAT = (this.iBaseForeAmount - (this.iBaseForeAmount * (this.iDiscountPercentage1 / 100)))
        this.iBaseForeAmountNoVAT = this.round(this.iBaseForeAmountNoVAT, 2)
        if (this.pcsLoc !== 0) {
          this.iDiscountForePrice = (this.iBaseForeAmountNoVAT / this.pcsLoc)
          this.iDiscountForePrice = this.round(this.iDiscountForePrice, 2)
        }
        this.iForeVAT1Amount = ((this.iBaseForeAmountNoVAT * (this.iForeVAT1Percentage / 100)))
        this.iForeVAT1Amount = this.round(this.iForeVAT1Amount, 2)
        this.iForeAmountWithVAT = (parseFloat(this.iBaseForeAmountNoVAT) + parseFloat(this.iForeVAT1Amount))
        this.iForeAmountWithVAT = this.round(this.iForeAmountWithVAT, 2)
        this.maxDiscPer = (this.iForeAmountWithVAT / 100) ? this.round((this.iForeAmountWithVAT / 100),3) : 0
      // this.iMaxDiscount = ((this.iMarginAmount / this.maxDiscPer)) ? this.round(((this.iMarginAmount / this.maxDiscPer)),2) : 0
        if (this.pcsLoc !== 0) {
          this.iForePriceWithVAT = ((this.iForeAmountWithVAT / this.pcsLoc))
          this.iForePriceWithVAT = this.round(this.iForePriceWithVAT, 2)
        }
        this.iDirectDuty1AmountFore = this.round((this.iForeDirectDuty1 * this.pcsLoc), 2)
        this.iFullTotalFore = (this.iBaseForeAmountNoVAT + this.iForeVAT1Amount + this.iDirectDuty1AmountFore)
        this.iFullTotalFore = this.round(this.iFullTotalFore, 2)
        this.updateLocalWithFore()
      }
    },
    finalFromRight() {
      if (!this.exportDoc) {
        this.iDiscountAmount1 = 0
        this.homeDiscount = 0
        this.iDiscountPercentage1 = this.homeDiscount
        
        this.iDirectDuty1 = this.iDirectDuty1 ? this.round(this.iDirectDuty1, 2) : 0
        this.iVAT1Percentage = this.iVAT1Percentage ? this.round(this.iVAT1Percentage, 2) : 0
        if (this.ppo) {
          this.iVAT1Percentage = 0
        }
        this.iFullPriceOriginal = this.iPriceWithVAT ? this.round(this.iPriceWithVAT, 2) : 0

        this.iAmountWithVAT = this.iFullPriceOriginal * this.pcsLoc ? this.iFullPriceOriginal * this.pcsLoc : 0
        this.iAmountWithVAT = this.round(this.iAmountWithVAT, 2)
        this.iDirectDuty1Amount = this.round((this.iDirectDuty1 * this.pcsLoc), 2)
        this.iFullTotal = (this.iAmountWithVAT + this.iDirectDuty1Amount)
        this.iFullTotal = this.round(this.iFullTotal, 2)

        this.iBaseAmountNoVAT= this.iAmountWithVAT / (1 + (this.iVAT1Percentage / 100))
        this.iBaseAmountNoVAT = this.round((this.iBaseAmountNoVAT), 2)
        this.iBaseAmount = this.iBaseAmountNoVAT
        this.iVAT1Amount = this.iAmountWithVAT - this.iBaseAmountNoVAT
        this.iPrice = this.round((this.iBaseAmountNoVAT /this.pcsLoc) ,2)
        this.iDiscountPrice = this.iPrice

        this.updateForeWithLocal()
      }
      if (this.exportDoc) {
        this.iDiscountAmount1 = 0
        this.homeDiscount = 0
        this.iDiscountPercentage1 = this.homeDiscount
        this.iBaseAmount = this.iBaseAmountNoVAT

        this.iForeDirectDuty1 = this.iForeDirectDuty1 ? this.round(this.iForeDirectDuty1, 2) : 0
        this.iForeVAT1Percentage = this.iForeVAT1Percentage ? this.round(this.iForeVAT1Percentage, 2) : 0
        this.iForePriceWithVAT = this.round(this.iForePriceWithVAT, 2)

        this.iForeAmountWithVAT = this.iForePriceWithVAT * this.pcsLoc ? this.iForePriceWithVAT * this.pcsLoc : 0
        this.iForeAmountWithVAT = this.round(this.iForeAmountWithVAT, 2)

        this.iForeVAT1Amount = this.iForeAmountWithVAT - ((this.iForeAmountWithVAT / (1 + (this.iForeVAT1Percentage / 100))))
        this.iForeVAT1Amount = this.round(this.iForeVAT1Amount, 2)
        
        this.iBaseForeAmountNoVAT =  this.iForeAmountWithVAT - this.iForeVAT1Amount
        this.iBaseForeAmountNoVAT = this.round(this.iBaseForeAmountNoVAT, 2)
        this.iBaseForeAmount = this.iBaseForeAmountNoVAT
        
        if (this.pcsLoc !== 0) {
          this.iForePrice = (this.iBaseForeAmountNoVAT / this.pcsLoc)
          this.iDiscountForePrice = this.round(this.iForePrice, 2)
        }

        this.maxDiscPer = (this.iForeAmountWithVAT / 100) ? this.round((this.iForeAmountWithVAT / 100),3) : 0
        // this.iMaxDiscount = ((this.iMarginAmount / this.maxDiscPer)) ? this.round(((this.iMarginAmount / this.maxDiscPer)),2) : 0

        this.iDirectDuty1AmountFore = this.round((this.iForeDirectDuty1 * this.pcsLoc), 2)
        this.iFullTotalFore = (this.iBaseForeAmountNoVAT + this.iForeVAT1Amount + this.iDirectDuty1AmountFore)
        this.iFullTotalFore = this.round(this.iFullTotalFore, 2)
        //this.updateLocalWithFore()
      }

    },

    profitSales() {
      if (this.exportDoc) {
        this.iBaseAmountNoVAT = this.round(this.iBaseForeAmountNoVAT * this.ExRate, 2)
      }
      this.iMarginAmount = (this.iBaseAmountNoVAT - this.iSuppPriceAmount) ? this.round((this.iBaseAmountNoVAT - this.iSuppPriceAmount),2) : 0
      this.iMargin = 0
      if (this.iSuppPriceAmount !== 0) {
        this.iMargin = (this.iMarginAmount / (this.iSuppPriceAmount / 100)) ? this.round((this.iMarginAmount / (this.iSuppPriceAmount / 100)),2) : 0
      }  
      this.FreeF2 = (this.iMarginAmount / this.pcsLoc) ? this.round((this.iMarginAmount / this.pcsLoc),2) : 0 
    },
    calculateMarginSale() {
      this.initPcs()
      this.iSuppPriceAmount  = (this.iSuppPrice * this.pcsLoc) ? this.round((this.iSuppPrice * this.pcsLoc),2) : 0
      if (this.$store.state.companyActiv.CompanyUseRetailPrice) {
        this.iBaseAmount = this.round((this.iPrice * this.pcsLoc), 2)

        this.iMarginAmount = (this.iBaseAmount - this.iSuppPriceAmount) ? this.round((this.iBaseAmount - this.iSuppPriceAmount),2) : 0
        this.iMargin = 0
        if (this.iSuppPriceAmount !== 0) {
          this.iMargin = (this.iMarginAmount / (this.iSuppPriceAmount / 100)) ? this.round((this.iMarginAmount / (this.iSuppPriceAmount / 100)),2) : 0
        }
        
        this.FreeF2 = (this.iMarginAmount / this.pcsLoc) ? this.round((this.iMarginAmount / this.pcsLoc),2) : 0 
      }
    },
    updateLocalWithFore() {
      this.iPrice = this.iForePrice ? this.round(this.iForePrice * this.ExRate, 3) : 0
      this.iBaseAmount = this.round((this.iBaseForeAmount * this.ExRate), 2)
      this.iBaseAmountNoVAT = this.round(this.iBaseForeAmountNoVAT * this.ExRate, 2)
      this.iDiscountPrice = this.round(this.iDiscountForePrice * this.ExRate, 2)
      this.iVAT1Amount = this.round(this.iForeVAT1Amount * this.ExRate, 2)
      this.iAmountWithVAT = this.round(this.iForeAmountWithVAT * this.ExRate, 2)
      this.iPriceWithVAT = this.round(this.iForePriceWithVAT * this.ExRate, 2)
      this.iDirectDuty1Amount = this.round((this.iDirectDuty1Amount * this.ExRate), 2)
      this.iFullTotal = this.round(this.iFullTotalFore * this.ExRate, 2) 
    },
    updateForeWithLocal(){
      this.iForePrice = this.round(this.iPrice / this.ExRate, 2)
      this.iForeVAT1Percentage = this.iVAT1Percentage
      this.iForeVAT1Amount = this.round(this.iVAT1Amount / this.ExRate, 2)
      this.iForeVAT2Percentage = this.iVAT2Percentge
      this.iForeVAT2Amount = this.round(this.iVAT2Amount / this.ExRate, 2)
      this.iForeDirectDuty1 = this.round(this.iDirectDuty1 / this.ExRate, 2)
      this.iForeDirectDuty2 = this.round(this.iDirectDuty2 / this.ExRate, 2)
      this.iForePriceWithVAT = this.round(this.iPriceWithVAT / this.ExRate, 2)
    },
    makeItNew(){
      this.clearIt()
      this.documentDet = defDocumentDetail()
      this.documentDet = {...this.documentDet, ...defNewDocumentDetail()}
      const newId = this.getRndInteger(1000000000, 9000000000)
      this.documentDet.id = newId
      this.$store.dispatch('setDocumentDetailid', newId)
      if (this.$store.state.documentSide === 1) {
        this.iPcsPlus = 1
      }
      if (this.$store.state.documentSide === 2) {
        this.iPcsMinus = 1
      }
      if (this.$store.state.documentSide === 3) {
        this.iPcsNeutral = 1
      }
      this.initPcs()
    },
    findItem () {
       if (this.newItem) {
        this.mainQuery2 = {...this.emptyObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.selectIL.itemID ? this.selectIL.itemID : null
        // const myObjStr = JSON.stringify(this.mainQuery2)
        this.findIt()
       }
    },
    clearIt() {
      this.iID = ''
      this.iName = ''
      this.iNameShort = ''
      this.iCode = ''
      this.iEANCode = ''
      this.iGroup = ''
      this.iGroupName = ''
      this.iForceNewPrintPage = false
      this.iDescription = ''
      this.iUnit = ''
      this.iSize = ''
      this.iWeight = 0
      this.iWeightMeasure = ''
      this.iDimMeasure = ''
      this.iDimLenght = 0
      this.iDimHeight = 0
      this.iDimDepth = 0
      this.iWarehouse = ''
      this.iWarehouseRow = ''
      this.iWarehouseShelf = ''
      this.iWahrehouseFloor = ''
      this.iPicture1 = ''
      this.iPicture2 = ''
      this.iPicture3 = ''
      this.iPicture4 = ''
      this.iPicture5 = ''
      this.iPicture6 = ''
      this.iPicture7 = ''
      this.iBelongsToId = 0
      this.iService = false
      this.iWeb = false
      this.iNotActiv = false
      this.iHideForever = false
      this.iHomeCurrency = ''
      this.iForeignCurrency = ''
      this.iExRate = 0
      this.iTransport = 0
      this.iTransportAmount = 0
      this.iCustom = 0
      this.iCustomAmount = 0
      this.iDependables = 0
      this.iDependablesAmount = 0
      this.iSuppBaseForePrice = 0
      this.iSuppBasePercentageExp = 0
      this.iSuppBaseForePrice2 = 0
      this.iSuppBaseForeAmount = 0
      this.iSuppBasePrice = 0
      this.iSuppBasePriceAmount = 0
      this.iSuppDiscount = 0
      this.iSuppDiscountAmount = 0
      this.iSuppPrice = 0
      this.iSuppPriceAmount = 0
      this.iSuppCode = ''
      this.iMargin = 0
      this.iMarginAmount = 0
      this.iMaxDiscount = 0
      this.iPrice = 0
      this.iVAT1Percentage = 0
      this.iVAT1Amount = 0
      this.iVAT2Percentge = 0
      this.iVAT2Amount = 0
      this.iDirectDuty1 = 0
      this.iDirectDuty2 = 0
      this.iPriceWithVAT = 0
      this.iForePrice = 0
      this.iForeVAT1Percentage = 0
      this.iForeVAT1Amount = 0
      this.iForeVAT2Percentage = 0
      this.iForeVAT2Amount = 0
      this.iForeDirectDuty1 = 0
      this.iForeDirectDuty2 = 0
      this.iForePriceWithVAT = 0
      this.iBaseAmount = 0
      this.iBaseForeAmount = 0
      this.iDiscountAmount1 = 0
      this.iDiscountForeAmount1 = 0
      this.iDiscountPercentage1 = 0
      this.iDiscountAmount2 = 0
      this.iDiscountPercentage2 = 0
      this.iDiscountAmount3 = 0
      this.iDiscountPercentage4 = 0
      this.iDiscountPrice = 0
      this.iDiscountForePrice = 0
      this.iSerialNr = ''
      this.iPcsPlus = 0
      this.iPcsMinus = 0
      this.iPcsNeutral = 0
      this.iBaseAmountNoVAT = 0
      this.iBaseForeAmountNoVAT = 0
      this.iBaseAmountWithVAT = 0
      this.iBaseForeAmountWithVAT = 0
      this.iAmountWithVAT = 0
      this.iFullTotal = 0
      this.iFullTotalFore = 0
      this.iForeAmountWithVAT = 0
      this.iDirectDuty1Amount = 0
      this.iDirectDuty2Amount = 0
      this.iDirectDuty1AmountFore = 0
      this.iDirectDuty2AmountFore = 0
      this.niPrice = 0
      this.niBaseAmount = 0
      this.niVAT1Percentage = 0
      this.niVAT1Amount = 0
      this.niAmountWithVAT = 0
      this.niDirectDuty1 = 0
      this.niFullTotal = 0
      this.iexportDoc = false
      this.iPriceOriginal = 0
      this.iFullPriceOriginal = 0
      this.iFreeF1 = 0
      this.iFreeF2 = 0
      this.iFreeF3 = 0
      this.iFreeF4 = 0
      this.iFreeF5 = 0
      this.iFreeF6 = 0
      this.iTypeID = 0
      this.iTypeName = ''
    },
    unassignIt() {
      this.documentDet.itemID = this.iID
      this.documentDet.itemName = this.iName
      this.documentDet.ItemNameShort = this.iNameShort
      this.documentDet.ItemCode = this.iCode
      this.documentDet.ItemEANCode = this.iEANCode
      this.documentDet.itemGroup = this.iGroup
      this.documentDet.itemGroupName = this.iGroupName
      this.documentDet.forceNewPrintPage = this.iForceNewPrintPage
      this.documentDet.itemDescription = this.iDescription
      this.documentDet.itemUnit = this.iUnit
      this.documentDet.ItemSize = this.iSize
      this.documentDet.ItemWeight = this.iWeight
      this.documentDet.ItemWeightMeasure = this.iWeightMeasure
      this.documentDet.ItemDimMeasure = this.iDimMeasure
      this.documentDet.ItemDimLenght = this.iDimLenght
      this.documentDet.ItemDimHeight = this.iDimHeight
      this.documentDet.ItemDimDepth = this.iDimDepth
      this.documentDet.ItemWarehouse = this.iWarehouse
      this.documentDet.ItemWarehouseRow = this.iWarehouseRow
      this.documentDet.ItemWarehouseShelf = this.iWarehouseShelf
      this.documentDet.ItemWahrehouseFloor = this.iWahrehouseFloor
      this.documentDet.ItemPicture1 = this.iPicture1
      this.documentDet.ItemPicture2 = this.iPicture2
      this.documentDet.ItemPicture3 = this.iPicture3
      this.documentDet.ItemPicture4 = this.iPicture4
      this.documentDet.ItemPicture5 = this.iPicture5
      this.documentDet.ItemPicture6 = this.iPicture6
      this.documentDet.ItemPicture7 = this.iPicture7
      this.documentDet.ItemBelongsToId = this.iBelongsToId
      this.documentDet.itemService = this.iService
      this.documentDet.itemWeb = this.iWeb
      this.documentDet.itemNotActiv = this.iNotActiv
      this.documentDet.itemHideForever = this.iHideForever
      this.documentDet.ItemHomeCurrency = this.iHomeCurrency
      this.documentDet.ItemForeignCurrency = this.iForeignCurrency
      this.documentDet.ItemExRate = this.ExRate
      this.documentDet.itemTransport = this.iTransport
      this.documentDet.itemTransportAmount = this.iTransportAmount
      this.documentDet.itemCustom = this.iCustom
      this.documentDet.itemCustomAmount = this.iCustomAmount
      this.documentDet.itemDependables = this.iDependables
      this.documentDet.itemDependablesAmount = this.iDependablesAmount
      this.documentDet.itemSuppBaseForePrice = this.iSuppBaseForePrice
      this.documentDet.itemSuppBasePercentageExp = this.iSuppBasePercentageExp
      this.documentDet.itemSuppBaseForePrice2 = this.iSuppBaseForePrice2
      this.documentDet.itemSuppBaseForeAmount = this.iSuppBaseForeAmount
      this.documentDet.itemSuppBasePrice = this.iSuppBasePrice
      this.documentDet.itemSuppBasePriceAmount = this.iSuppBasePriceAmount
      this.documentDet.itemSuppDiscount = this.iSuppDiscount
      this.documentDet.itemSuppDiscountAmount = this.iSuppDiscountAmount
      this.documentDet.itemSuppPrice = this.iSuppPrice
      this.documentDet.itemSuppPriceAmount = this.iSuppPriceAmount
      this.documentDet.ItemSuppCode = this.iSuppCode
      this.documentDet.itemMargin = this.iMargin
      this.documentDet.itemMarginAmount = this.iMarginAmount
      this.documentDet.itemMaxDiscount = this.iMaxDiscount
      this.documentDet.itemPrice = this.iPrice
      this.documentDet.itemVAT1Percentage = this.iVAT1Percentage
      this.documentDet.itemVAT1Amount = this.iVAT1Amount
      this.documentDet.itemVAT2Percentge = this.iVAT2Percentge
      this.documentDet.itemVAT2Amount = this.iVAT2Amount
      this.documentDet.itemDirectDuty1 = this.iDirectDuty1
      this.documentDet.itemDirectDuty2 = this.iDirectDuty2
      this.documentDet.itemPriceWithVAT = this.iPriceWithVAT
      this.documentDet.itemForePrice = this.iForePrice
      this.documentDet.itemForeVAT1Percentage = this.iForeVAT1Percentage
      this.documentDet.itemForeVAT1Amount = this.iForeVAT1Amount
      this.documentDet.itemForeVAT2Percentage = this.iForeVAT2Percentage
      this.documentDet.itemForeVAT2Amount = this.iForeVAT2Amount
      this.documentDet.itemForeDirectDuty1 = this.iForeDirectDuty1
      this.documentDet.itemForeDirectDuty2 = this.iForeDirectDuty2 
      this.documentDet.itemForePriceWithVAT = this.iForePriceWithVAT
      this.documentDet.itemBaseAmount = this.iBaseAmount 
      this.documentDet.itemBaseForeAmount = this.iBaseForeAmount
      this.documentDet.itemDiscountAmount1 = this.iDiscountAmount1
      this.documentDet.itemDiscountForeAmount1 = this.iDiscountForeAmount1
      this.documentDet.itemDiscountPercentage1 = this.iDiscountPercentage1
      this.documentDet.itemDiscountAmount2 = this.iDiscountAmount2
      this.documentDet.itemDiscountPercentage2 = this.iDiscountPercentage2
      this.documentDet.itemDiscountAmount3 = this.iDiscountAmount3
      this.documentDet.itemDiscountPercentage4 = this.iDiscountPercentage4
      this.documentDet.itemDiscountPrice = this.iDiscountPrice
      this.documentDet.itemDiscountForePrice = this.iDiscountForePrice
      this.documentDet.itemSerialNr = this.iSerialNr
      this.documentDet.itemPcsPlus = this.iPcsPlus
      this.documentDet.itemPcsMinus = this.iPcsMinus
      this.documentDet.itemPcsNeutral = this.iPcsNeutral
      this.documentDet.itemBaseAmountNoVAT = this.iBaseAmountNoVAT
      this.documentDet.itemBaseForeAmountNoVAT = this.iBaseForeAmountNoVAT
      this.documentDet.itemBaseAmountWithVAT = this.iBaseAmountWithVAT
      this.documentDet.itemBaseForeAmountWithVAT = this.iBaseForeAmountWithVAT
      this.documentDet.itemAmountWithVAT = this.iAmountWithVAT
      this.documentDet.itemFullTotal = this.iFullTotal
      this.documentDet.itemFullTotalFore = this.iFullTotalFore
      this.documentDet.itemForeAmountWithVAT = this.iForeAmountWithVAT
      this.documentDet.itemDirectDuty1Amount = this.iDirectDuty1Amount
      this.documentDet.itemDirectDuty2Amount = this.iDirectDuty2Amount
      this.documentDet.itemDirectDuty1AmountFore = this.iDirectDuty1AmountFore
      this.documentDet.itemDirectDuty2AmountFore = this.iDirectDuty2AmountFore
      this.documentDet.newitemPrice = this.niPrice
      this.documentDet.newitemBaseAmount = this.niBaseAmount
      this.documentDet.newitemVAT1Percentage = this.niVAT1Percentage
      this.documentDet.newitemVAT1Amount = this.niVAT1Amount
      this.documentDet.newitemAmountWithVAT = this.niAmountWithVAT
      this.documentDet.newitemDirectDuty1 = this.niDirectDuty1
      this.documentDet.newitemFullTotal = this.niFullTotal
      this.documentDet.exportDoc = this.exportDoc
      this.documentDet.itemPriceOriginal = this.iPriceOriginal
      this.documentDet.itemFullPriceOriginal = this.iFullPriceOriginal
      this.documentDet.FreeF1 = this.iFreeF1
      this.documentDet.FreeF2 = this.iFreeF2
      this.documentDet.FreeF3 = this.iFreeF3
      this.documentDet.FreeF4 = this.iFreeF4
      this.documentDet.FreeF5 = this.iFreeF5
      this.documentDet.FreeF6 = this.iFreeF6
      this.documentDet.TypeID = this.iTypeID
      this.documentDet.TypeName = this.iTypeName
      this.documentDet.LastUpdateBy = this.$store.state.user.email ? this.$store.state.user.email : ''
    },
    assignIt(itm, type) {
      if (itm) {
        this.iID = itm.itemID
        this.iName = itm.itemName
        this.iNameShort = itm.ItemNameShort
        this.iCode = itm.ItemCode
        this.iEANCode = itm.ItemEANCode
        this.iGroup = itm.itemGroup
        this.iGroupName = itm.GroupName ? itm.GroupName : ''
        this.iForceNewPrintPage = itm.forceNewPrintPage
        this.iDescription = itm.itemDescription
        this.iUnit = itm.itemUnit
        this.iSize = parseNum(itm.ItemSize, 2)
        this.iWeight = itm.ItemWeight
        this.iWeightMeasure = itm.ItemWeightMeasure
        this.iDimMeasure = itm.ItemDimMeasure
        this.iDimLenght = itm.ItemDimLenght
        this.iDimHeight = itm.ItemDimHeight
        this.iDimDepth = itm.ItemDimDepth
        this.iWarehouse = itm.ItemWarehouse
        this.iWarehouseRow = itm.ItemWarehouseRow
        this.iWarehouseShelf = itm.ItemWarehouseShelf
        this.iWahrehouseFloor = itm.ItemWahrehouseFloor
        this.iPicture1 = itm.ItemPicture1
        this.iPicture2 = itm.ItemPicture2
        this.iPicture3 = itm.ItemPicture3
        this.iPicture4 = itm.ItemPicture4
        this.iPicture5 = itm.ItemPicture5
        this.iPicture6 = itm.ItemPicture6
        this.iPicture7 = itm.ItemPicture7
        this.iBelongsToId = itm.ItemBelongsToId
        this.iService = itm.itemService
        this.iWeb = itm.itemWeb
        this.iNotActiv = itm.itemNotActiv
        this.iHideForever = itm.itemHideForever

        this.iHomeCurrency = itm.ItemHomeCurrency
        this.iForeignCurrency = itm.ItemForeignCurrency
        //this.iExRate = parseNum(itm.ItemExRate, 2)
        this.iTransport = parseNum(itm.itemTransport, 2)
        this.iTransportAmount = parseNum(itm.itemTransportAmount, 2)
        this.iCustom = parseNum(itm.itemCustom, 2)
        this.iCustomAmount = parseNum(itm.itemCustomAmount, 2)
        this.iDependables = parseNum(itm.itemDependables, 2)
        this.iDependablesAmount = parseNum(itm.itemDependablesAmount, 2)
        this.iSuppBaseForePrice = parseNum(itm.itemSuppBaseForePrice, 2)
        //this.iSuppBasePercentageExp = parseNum(itm.itemSuppBasePercentageExp, 2)
        this.iSuppBaseForePrice2 = parseNum(itm.itemSuppBaseForePrice2, 2)
        this.iSuppBaseForeAmount = parseNum(itm.itemSuppBaseForeAmount, 2)
        this.iSuppBasePrice = parseNum(itm.itemSuppBasePrice, 2)
        this.iSuppBasePriceAmount = parseNum(itm.itemSuppBasePriceAmount, 2)
        this.iSuppDiscount = parseNum(itm.itemSuppDiscount, 2)
        this.iSuppDiscountAmount = parseNum(itm.itemSuppDiscountAmount, 2)
        this.iSuppPrice = parseNum(itm.itemSuppPrice, 2)
        this.iSuppPriceAmount = parseNum(itm.itemSuppPriceAmount, 2)
        this.iSuppCode = itm.ItemSuppCode
        this.iMargin = parseNum(itm.itemMargin, 2)
        this.iMarginAmount = parseNum(itm.itemMarginAmount, 2)
        this.iMaxDiscount = parseNum(itm.itemMaxDiscount, 2)
        this.iPrice = parseNum(itm.itemPrice, 2)
        this.iVAT1Percentage = parseNum(itm.itemVAT1Percentage, 2)
        this.iVAT1Amount = parseNum(itm.itemVAT1Amount, 2)
        this.iVAT2Percentge = parseNum(itm.itemVAT2Percentge, 2)
        this.iVAT2Amount = parseNum(itm.itemVAT2Amount, 2)
        this.iDirectDuty1 = parseNum(itm.itemDirectDuty1, 2)
        this.iDirectDuty2 = parseNum(itm.itemDirectDuty2, 2)
        this.iPriceWithVAT = parseNum(itm.itemPriceWithVAT, 2)
        this.iForePrice = parseNum(itm.itemForePrice, 2)
        this.iForeVAT1Percentage = parseNum(itm.itemForeVAT1Percentage, 2)
        this.iForeVAT1Amount = parseNum(itm.itemForeVAT1Amount, 2)
        this.iForeVAT2Percentage = parseNum(itm.itemForeVAT2Percentage, 2)
        this.iForeVAT2Amount = parseNum(itm.itemForeVAT2Amount, 2)
        this.iForeDirectDuty1 = parseNum(itm.itemForeDirectDuty1, 2)
        this.iForeDirectDuty2 = parseNum(itm.itemForeDirectDuty2, 2)
        this.iForePriceWithVAT = parseNum(itm.itemForePriceWithVAT, 2)
        this.iBaseAmount = parseNum(itm.itemBaseAmount, 2)
        this.iBaseForeAmount = parseNum(itm.itemBaseForeAmount, 2)
        this.iDiscountAmount1 = parseNum(itm.itemDiscountAmount1, 2)
        this.iDiscountForeAmount1 = parseNum(itm.itemDiscountForeAmount1, 2)
        this.iDiscountPercentage1 = parseNum(itm.itemDiscountPercentage1, 2)
        this.iDiscountAmount2 = parseNum(itm.itemDiscountAmount2, 2)
        this.iDiscountPercentage2 = parseNum(itm.itemDiscountPercentage2, 2)
        this.iDiscountAmount3 = parseNum(itm.itemDiscountAmount3, 2)
        this.iDiscountPercentage4 = parseNum(itm.itemDiscountPercentage4, 2)
        this.iDiscountPrice = parseNum(itm.itemDiscountPrice, 2)
        this.iDiscountForePrice = parseNum(itm.itemDiscountForePrice, 2)
        this.iSerialNr = itm.itemSerialNr
        this.iPcsPlus = parseNum(itm.itemPcsPlus, 2)
        this.iPcsMinus = parseNum(itm.itemPcsMinus, 2)
        this.iPcsNeutral = parseNum(itm.itemPcsNeutral, 2)
        this.iBaseAmountNoVAT = parseNum(itm.itemBaseAmountNoVAT, 2)
        this.iBaseForeAmountNoVAT = parseNum(itm.itemBaseForeAmountNoVAT, 2)
        this.iBaseAmountWithVAT = parseNum(itm.itemBaseAmountWithVAT, 2)
        this.iBaseForeAmountWithVAT = parseNum(itm.itemBaseForeAmountWithVAT, 2)
        this.iAmountWithVAT = parseNum(itm.itemAmountWithVAT, 2)
        this.iFullTotal = parseNum(itm.itemFullTotal, 2)
        this.iFullTotalFore = parseNum(itm.itemFullTotalFore, 2)
        this.iForeAmountWithVAT = parseNum(itm.itemForeAmountWithVAT, 2)
        this.iDirectDuty1Amount = parseNum(itm.itemDirectDuty1Amount, 2)
        this.iDirectDuty2Amount = parseNum(itm.itemDirectDuty2Amount, 2)
        this.iDirectDuty1AmountFore = parseNum(itm.itemDirectDuty1AmountFore, 2)
        this.iDirectDuty2AmountFore = parseNum(itm.itemDirectDuty2AmountFore, 2)
        this.niPrice = parseNum(itm.newitemPrice, 2)
        this.niBaseAmount = parseNum(itm.newitemBaseAmount, 2)
        this.niVAT1Percentage = parseNum(itm.newitemVAT1Percentage, 2)
        this.niVAT1Amount = parseNum(itm.newitemVAT1Amount, 2)
        this.niAmountWithVAT = parseNum(itm.newitemAmountWithVAT, 2)
        this.niDirectDuty1 = parseNum(itm.newitemDirectDuty1, 2)
        this.niFullTotal = parseNum(itm.newitemFullTotal, 2)
        this.iexportDoc = this.exportDoc
        this.iPriceOriginal = parseNum(itm.itemPrice, 2)
        this.iFullPriceOriginal = parseNum(itm.itemPriceWithVAT, 2)
        this.iFreeF1 = parseNum(itm.FreeF1, 2)
        this.iFreeF2 = parseNum(itm.FreeF2, 2)
        this.iFreeF3 = parseNum(itm.FreeF3, 2)
        this.iFreeF4 = parseNum(itm.FreeF4, 2)
        this.iFreeF5 = parseNum(itm.FreeF5, 2)
        this.iFreeF6 = parseNum(itm.FreeF6, 2)
        this.iTypeID = parseNum(itm.TypeID, 2)
        this.iTypeName = itm.TypeName

        if (this.iSuppBasePrice === 0 && this.iPrice !== 0) {
          this.iSuppBasePrice = this.iPrice
          this.iSuppPrice = this.iPrice
        }

        if (this.iSuppBaseForePrice === 0 && this.iSuppBasePrice !== 0) {
          this.iSuppBaseForePrice = parseNum((this.iSuppBasePrice / (this.ExRate  ? this.ExRate  : 1)), 2)
        }

        // if (this.$store.state.documentActiv === 'ReceiptPurchase') {
        //   this.infoSuppBaseForePrice = parseNum(itm.itemSuppBaseForePrice, 2)
        //   this.infoSuppBasePrice = parseNum(itm.itemSuppBasePrice, 2)
        //   this.infoSuppDiscount = parseNum(itm.itemSuppDiscount, 2)
        //   this.infoSuppPrice = parseNum(itm.itemSuppPrice, 2)
        //   this.infoMargin = parseNum(itm.itemMargin, 2)
        // }
        // new item, from item database
        if (type === 1) {
          if (this.$store.state.companyActiv.CompanyUseRetailPrice === true) {
           
            this.iPrice = this.iPriceWithVAT / (1 + (this.iVAT1Percentage / 100))
            this.iPrice = this.iPrice ? this.round(this.iPrice, 2) : 0
          }

          // if local doc, then local multiplay fore with exrate, if is fore then (1.if have fore price, use it, or 2. if dont have fore price, use local and divde with exrate)
          if (this.exportDoc === false) {
            
            this.iPriceOriginal = this.iPrice
            this.iFullPriceOriginal = this.iPriceWithVAT
            this.updateForeWithLocal()

          }
          if (this.exportDoc === true) {
            if (this.$store.state.documentActiv !== 'ReceiptPurchase') {
              // if have fore price use it, if dont have fore price use local price and do math with exrate
              if (this.iForePrice && this.iForePrice > 0) {
                this.updateLocalWithFore()

              } else {
                  this.iVAT1Percentage = 0
                  this.iVAT1Amount = 0
                  this.iVAT2Percentge = 0
                  this.iVAT2Amount = 0
                  this.iDirectDuty1 = 0
                  this.iPriceOriginal = this.round(this.iPrice / this.ExRate, 2)
                  this.iFullPriceOriginal = this.round(this.iPrice / this.ExRate, 2)
                  this.iForePrice = this.round(this.iPrice / this.ExRate, 2)
                  this.iForeVAT1Percentage = 0
                  this.iForeVAT1Amount = 0
                  this.iForeVAT2Percentage = 0
                  this.iForeVAT2Amount = 0
                  this.iForeDirectDuty1 = 0
                  this.iForeDirectDuty2 = 0
                  this.iForePriceWithVAT = this.round(this.iPrice / this.ExRate, 2)
              }
            } 
            if (this.$store.state.documentActiv === 'ReceiptPurchase') {
              this.updateForeWithLocal()
            }
            this.iSuppBasePercentageExp = this.$store.state.documentActivHead.expenesPercent && parseFloat(this.$store.state.documentActivHead.expenesPercent) > 0 ? parseFloat(this.$store.state.documentActivHead.expenesPercent) : 0
            this.iExRate = this.ExRate
          }

          const itemMargin = (this.iPrice - this.iSuppPrice)
          if (itemMargin > 0) {
            this.maxDiscPer = (this.iPrice / 100) ? this.round((this.iPrice / 100),3) : 0
            this.iMaxDiscount = ((itemMargin / this.maxDiscPer)) ? this.round(((itemMargin / this.maxDiscPer)),2) : 0
            if (this.$store.state.companyActiv.CompanyUseRetailPrice === true) {
              this.maxDiscPer = (this.iPriceWithVAT / 100) ? this.round((this.iPriceWithVAT / 100),3) : 0
              this.iMaxDiscount = ((itemMargin / this.maxDiscPer)) ? this.round(((itemMargin / this.maxDiscPer)),2) : 0
            }
          }
          
          this.iDiscountPercentage1 = 0
        }

      }
    },
    async findIt () {
      try {
        const {data} = await ItemService.choose(this.mainQuery2)
        if (data && data.item !== undefined) {
          this.$store.dispatch('setItemid', data.item.id)
          this.assignIt(data.item, 1)
          this.inputDocCalc()
          // this.calculatePrice()
        }
      } catch (error) {
        this.error = ' Greska Ne mogu naci artikla'
        this.typeError = 'error'
        // eslint-disable-next-line
        console.log(' greska Ne mogu naci artikla', error)
      }
    },
    makeSum() {
      const updateSumObject = {
        documentDescription9: 0,
        documentDescription10: 0,
        documentTotalVAT1Per: 0,
        documentTotalVAT1Base: 0,
        documentTotalVAT1Amount: 0,
        documentTotalVAT2Per: 0,
        documentTotalVAT2Base: 0,
        documentTotalVAT2Amount: 0,
        documentTotalVAT3Per: 0,
        documentTotalVAT3Base: 0,
        documentTotalVAT3Amount: 0,
        documentTotalVAT4Per: 0,
        documentTotalVAT4Base: 0,
        documentTotalVAT4Amount: 0,
        documentTotalVAT5Per: 0,
        documentTotalVAT5Base: 0,
        documentTotalVAT5Amount: 0,
        documentTotalAmountNetto: 0,
        documentTotalAmountVAT: 0,
        documentTotalAmountBrutto: 0,
        documentTotalAmountPayInAdvance: this.$store.state.documentActivHead.documentTotalAmountPayInAdvance ? parseFloat(this.$store.state.documentActivHead.documentTotalAmountPayInAdvance) : 0,
        documentTotalAmountToPay: 0,
        documentTotalAmountNettoForeign: 0,
        documentTotalAmountVATForeign: 0,
        documentTotalAmountBruttoForeign: 0,
        documentTotalAmountPayInAdvanceForeign: 0,
        documentTotalAmountToPayForeign: 0,
        documentTotalAmountDirectD1: 0,
        documentTotalAmountDirectD2: 0,
        documentTotalAmountDirectD1Fore: 0,
        documentTotalAmountDirectD2Fore: 0,
        supplierAmount: 0,
        supplierNetoAmount: 0,
        supplierDiscount: 0,
        supplierMargin: 0,
        FreeF1: 0,
        FreeF2: 0,
        FreeF3: 0,
        FreeF4: 0,
        FreeF5: 0,
        FreeF6: 0,
      }
      let dSide = 3
      if (this.$store.state.documentSide) {
        dSide = this.$store.state.documentSide
      }

      const hrVat1 = 25
      const hrVat2 = 13
      const hrVat3 = 5
      const documentsDet = this.$store.state.documentActivItems ? this.$store.state.documentActivItems : []
      if (documentsDet) {
        documentsDet.map(documentDet => {

          this.pcsLoc = 0
          if (dSide === 1 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsPlus) ? parseFloat(documentDet.itemPcsPlus)  : 0
          }
          if (dSide === 2 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsMinus) ? parseFloat(documentDet.itemPcsMinus)  : 0
          }
          if (dSide === 3 ) {
            this.pcsLoc = parseFloat(documentDet.itemPcsNeutral) ? parseFloat(documentDet.itemPcsNeutral)  : 0
          }
          this.pcsLoc = this.round(this.pcsLoc, 3)     
          
          if ((documentDet.itemSuppBasePriceAmount)) {
            updateSumObject.supplierAmount += this.round((documentDet.itemSuppBasePriceAmount),2)
          }
          if (documentDet.itemSuppDiscountAmount) {
            updateSumObject.supplierDiscount += this.round(documentDet.itemSuppDiscountAmount,2)
          }
          if (documentDet.itemSuppPriceAmount) {
            updateSumObject.supplierNetoAmount += this.round(documentDet.itemSuppPriceAmount,2)
          }
          if (documentDet.itemBaseAmount) {
            updateSumObject.documentDescription9 += parseFloat(documentDet.itemBaseAmount)
          }
          if (documentDet.itemBaseAmountNoVAT) {
            updateSumObject.documentTotalAmountNetto += parseFloat(documentDet.itemBaseAmountNoVAT)
          }
          // if (documentDet.itemVAT1Amount) {
          //   updateSumObject.documentTotalAmountVAT += parseFloat(documentDet.itemVAT1Amount)
          // }
          //const dD1 = documentDet.itemDirectDuty1Amount ? parseFloat(documentDet.itemDirectDuty1Amount) : 0
          // if (documentDet.itemAmountWithVAT) {
          //   updateSumObject.documentTotalAmountBrutto += parseFloat(documentDet.itemAmountWithVAT) + dD1
          // }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat1) {
            updateSumObject.documentTotalVAT1Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT1Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT1Base += parseFloat((documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat2) {
            updateSumObject.documentTotalVAT2Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT2Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT2Base += parseFloat((documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (this.round(documentDet.itemVAT1Percentage, 2) === hrVat3) {
            updateSumObject.documentTotalVAT3Base += parseFloat(documentDet.itemBaseAmountNoVAT)
            // updateSumObject.documentTotalVAT3Amount += parseFloat(documentDet.itemVAT1Amount)
            // updateSumObject.documentTotalVAT3Base += (parseFloat(documentDet.itemAmountWithVAT) - parseFloat(documentDet.itemVAT1Amount))
          }
          if (documentDet.itemBaseAmount) {
            if (parseInt(documentDet.FreeF1) === 1) {
              updateSumObject.FreeF1 += parseFloat(documentDet.itemBaseAmount)
            }
            if (parseInt(documentDet.FreeF1) === 2) {
              updateSumObject.FreeF2 += parseFloat(documentDet.itemBaseAmount)
              updateSumObject.FreeF5 += parseFloat(documentDet.empWrkHrs)
            }
            if (parseInt(documentDet.FreeF1) === 3) {
              updateSumObject.FreeF3 += parseFloat(documentDet.itemBaseAmount)
            }
          }
          if (documentDet.itemBaseAmountNoVAT) {
            if (this.round(parseFloat(documentDet.itemVAT1Amount),2) === 0) {
              updateSumObject.FreeF4 += parseFloat(documentDet.itemBaseAmountNoVAT)
            }
          }
          if (documentDet.itemDirectDuty1Amount) {
            updateSumObject.documentTotalAmountDirectD1+= parseFloat(documentDet.itemDirectDuty1Amount)
          }
        })
      }

      updateSumObject.documentTotalAmountNetto = updateSumObject.documentTotalAmountNetto ? this.round(updateSumObject.documentTotalAmountNetto, 2) : 0
      updateSumObject.documentTotalVAT1Amount  = this.round((updateSumObject.documentTotalVAT1Base * ( (hrVat1/100))),2)
      updateSumObject.documentTotalVAT2Amount  = this.round((updateSumObject.documentTotalVAT2Base * ( (hrVat2/100))),2)
      updateSumObject.documentTotalVAT3Amount  = this.round((updateSumObject.documentTotalVAT3Base * ( (hrVat2/100))),2)
      updateSumObject.documentDescription9 = updateSumObject.documentDescription9 ? this.round(updateSumObject.documentDescription9, 2) : 0
      updateSumObject.supplierNetoAmount = updateSumObject.supplierNetoAmount ? this.round(updateSumObject.supplierNetoAmount, 2) : 0
      updateSumObject.supplierMargin = this.round(updateSumObject.documentTotalAmountNetto - updateSumObject.supplierNetoAmount, 2)
      updateSumObject.documentTotalAmountVAT = updateSumObject.documentTotalVAT1Amount + updateSumObject.documentTotalVAT2Amount + updateSumObject.documentTotalVAT3Amount
      updateSumObject.documentTotalAmountBrutto = updateSumObject.documentTotalAmountNetto + updateSumObject.documentTotalAmountVAT
      updateSumObject.documentTotalVAT1Per = hrVat1 ? this.round(hrVat1, 2) : 0
      updateSumObject.documentTotalVAT2Per = hrVat2 ? this.round(hrVat2, 2) : 0
      updateSumObject.documentTotalVAT3Per = hrVat3 ? this.round(hrVat3, 2) : 0

      // updateSumObject.documentTotalVAT1Base = hrVat1 && updateSumObject.documentTotalVAT1Amount ? this.round(updateSumObject.documentTotalVAT1Amount / (hrVat1/100), 2) : 0
      // updateSumObject.documentTotalVAT2Base = hrVat2 && updateSumObject.documentTotalVAT2Amount ? this.round(updateSumObject.documentTotalVAT2Amount / (hrVat2/100), 2) : 0
      // updateSumObject.documentTotalVAT3Base = hrVat3 && updateSumObject.documentTotalVAT3Amount ? this.round(updateSumObject.documentTotalVAT3Amount / (hrVat3/100), 2) : 0
      updateSumObject.FreeF1 = updateSumObject.FreeF1 ? this.round(updateSumObject.FreeF1, 2) : 0
      updateSumObject.FreeF2 = updateSumObject.FreeF2 ? this.round(updateSumObject.FreeF2, 2) : 0
      updateSumObject.FreeF3 = updateSumObject.FreeF3 ? this.round(updateSumObject.FreeF3, 2) : 0
      updateSumObject.FreeF4 = updateSumObject.FreeF4 ? this.round(updateSumObject.FreeF4, 2) : 0
      updateSumObject.FreeF5 = updateSumObject.FreeF5 ? this.round(updateSumObject.FreeF5, 2) : 0
      updateSumObject.documentDescription10 = updateSumObject.documentDescription9 - updateSumObject.documentTotalAmountNetto
      updateSumObject.documentTotalAmountPayInAdvance = updateSumObject.documentTotalAmountPayInAdvance ? this.round(updateSumObject.documentTotalAmountPayInAdvance, 2) : 0
      updateSumObject.documentTotalAmountToPay = updateSumObject.documentTotalAmountBrutto ? (this.round(updateSumObject.documentTotalAmountBrutto, 2) - (updateSumObject.documentTotalAmountPayInAdvance )): 0

      updateSumObject.documentTotalAmountNettoForeign = updateSumObject.documentTotalAmountNetto ? this.round((updateSumObject.documentTotalAmountNetto / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountVATForeign = updateSumObject.documentTotalAmountVAT ? this.round((updateSumObject.documentTotalAmountVAT / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountBruttoForeign = updateSumObject.documentTotalAmountBrutto ? this.round((updateSumObject.documentTotalAmountBrutto / this.ExRate), 2) : 0
      updateSumObject.documentTotalAmountPayInAdvanceForeign = this.round((updateSumObject.documentTotalAmountPayInAdvance  / this.ExRate), 2)
      updateSumObject.documentTotalAmountToPayForeign = updateSumObject.documentTotalAmountBrutto ? (this.round((updateSumObject.documentTotalAmountBrutto/ this.ExRate), 2) - (updateSumObject.documentTotalAmountPayInAdvance  / this.ExRate) ) : 0
      updateSumObject.documentTotalAmountDirectD1Fore = updateSumObject.documentTotalAmountDirectD1 ? this.round((updateSumObject.documentTotalAmountDirectD1 / this.ExRate), 2) : 0

      const newHead = {...this.$store.state.documentActivHead, ...updateSumObject}
      if (newHead) {
          this.$store.dispatch('setDocumentActivHead', newHead)
        } else {
          this.$store.dispatch('setDocumentActivHead', {})
        }
    }
  },
  watch: {
    'mySelectedItemId' () {      
      if (this.$store.state.selectedItemId) {
        const emObj = {}
        this.mainQuery2 = {...emObj}
        this.mainQuery2.CompanyId = this.$store.state.companyid
        this.mainQuery2.itemID = this.$store.state.selectedItemId
        if (this.$store.state.selectedItemId) {
          this.selectIL =
          {
            itemByID: (this.$store.state.selectedItemId),
            itemID: this.$store.state.selectedItemId
          }
        }
        if (this.$store.state.newItemAround) {
          this.itemsLoc = this.$store.state.itemStore ? this.$store.state.itemStore : []
        }
        this.findIt()
        //this.warehouseState()
        this.statewhs = false
        this.newItemCreate = false
      }
     // this.partner()
    },
    'iPrice' () {
      if (this.workOn === true) {
      
        if (!this.iPrice) {
          this.iPrice = 0
        }        
        if (!this.doRetail) {
          this.iPrice = this.round(this.iPrice,2)
          this.inputDocCalc()
        }
        // if (!this.fullpriceChange && this.$store.state.documentActiv === 'ReceiptPurchase') {
        //   this.iPrice = this.round(this.iPrice,2)
        //   this.calculateDiscount()
        //   console.log('iPrice 2 ')
        // }
      }
    },
    'iForePrice' () {
      if (this.workOn === true) {        
        if (!this.iForePrice) {
          this.iForePrice = 0
        }             
        if (!this.doRetail && this.exportDoc && this.$store.state.documentActiv !== 'ReceiptPurchase') {
          this.iForePrice = this.round(this.iForePrice,2)
          this.inputDocCalc()
        }
      }
    },
    'iDiscountPercentage1' () {
      if (this.workOn === true) {
        if (!this.iDiscountPercentage1) {
          this.iDiscountPercentage1 = 0
        }
          
          if (this.iDiscountPercentage1 > 99.99){
            this.iDiscountPercentage1 = 0
          }
          if (this.iDiscountPercentage1 < 0){
            this.iDiscountPercentage1 = 0
          }
          this.inputDocCalc()
          // if (this.fullpriceChange) {
          //    if (this.$store.state.companyActiv.CompanyUseRetailPrice === true) {
          //     if (this.exportDoc === false) {
          //       this.calculateDiscount()
          //     }
          //     if (this.exportDoc === true) {
          //         this.calculateDiscountFore()
          //     }
          //    }
          //    if (this.$store.state.companyActiv.CompanyUseRetailPrice === false) {
          //       if (this.exportDoc === false) {
          //         this.calculatePrice()
          //       }
          //       if (this.exportDoc === true) {
          //         this.calculateDiscountFore()
          //       }
          //    }
          // }
      }
    },
    'iPcsPlus' () {
      if (!this.iPcsPlus) {
        this.iPcsPlus = 1
      }
      this.iPcsPlus = this.round(this.iPcsPlus, 3)
      this.inputDocCalc()
      // this.initPcs()
      // this.initCalc()
    },

    'iPcsMinus' () {
      if (!this.iPcsMinus) {
        this.iPcsMinus = 1
      }
      this.iPcsMinus = this.round(this.iPcsMinus, 3)
      this.inputDocCalc()
      // this.initPcs()
      // this.initCalc()
    },

    'iPcsNeutral' () {
      if (!this.iPcsNeutral) {
        this.iPcsNeutral = 1
      }
      this.iPcsNeutral = this.round(this.iPcsNeutral, 3)
      this.inputDocCalc()
      // this.initPcs()
      // this.initCalc()
    },

    'iVAT1Percentage' () {
      if (this.workOn === true) {
        if (!this.iVAT1Percentage) {
          this.iVAT1Percentage = 25
        }
        this.inputDocCalc()
        // if (this.fullpriceChange === true && !this.exportDoc) {
        //   this.calculateDiscount()
        // }
      }
    },
    'iDirectDuty1' () {
      if (this.workOn === true) {
        if (!this.iDirectDuty1) {
          this.iDirectDuty1 = 0
        }
        this.inputDocCalc()
        // if (this.fullpriceChange === true && !this.exportDoc) {
        //   this.calculateDiscount()
        // }
      }
    },
    'iSuppBasePrice' () {
      if (!this.iSuppBasePrice) {
        this.iSuppBasePrice = 0
      }
      if (this.workOn === true) {
       // this.inputDocCalc()
        if (this.fullpriceChange === true) {
           this.initPcs()
          this.initSupp()
          this.marginFromRight()
          // this.initSupp()
          // this.calculateSupp()
          // this.calculatePrice()
          // this.calculateDiscount()
        }
      }
    },
    'iSuppBaseForePrice' () {
      if (!this.iSuppBaseForePrice) {
        this.iSuppBaseForePrice = 0
      }
      if (this.workOn === true) {
       // this.inputDocCalc()
        if (this.fullpriceChange === true) {
          this.initPcs()
          this.initSupp()
          this.marginFromRight()
          // this.initSupp()
          // this.calculateSupp()
          // this.calculatePrice()
          // this.calculateDiscount()
        }
      }
    },
    'iSuppDiscount' () {
      if (this.workOn === true) {
        if (!this.iSuppDiscount) {
          this.iSuppDiscount = 0
        }
        if (this.iSuppDiscount > 99.99){
            this.iSuppDiscount = 0
        }
       // this.inputDocCalc()
        if (this.fullpriceChange === true) {
          this.initPcs()
          this.initSupp()
          this.marginFromRight()
          // this.calculateSupp()
          // this.calculatePrice()
          // this.calculateDiscount()
        }
      }
    },
    'iMargin' () {
      if (this.workOn === true) {
        if (!this.iMargin){
          this.iMargin = 0
        }
        if (parseNum(this.iMargin) === 0) {
          this.marginIsZero = true
        }
        this.inputDocCalc()
      }
      // if (this.fullpriceChange) {
      //  if (this.$store.state.documentSide === 1) {
      //     if (this.$store.state.documentActiv === 'ReceiptPurchase') {
      //       this.initSupp()
      //       this.calculateSupp()
      //       this.calculatePrice()
      //       this.calculateDiscount()
      //       this.maxDiscPer = (this.iAmountWithVAT / 100) ? this.round((this.iAmountWithVAT / 100),3) : 0
      //       this.iMaxDiscount = ((this.iMarginAmount / this.maxDiscPer)) ? this.round(((this.iMarginAmount / this.maxDiscPer)),2) : 0
      //     }
      //   }
      // }
    },
    'iPriceWithVAT' () {
      if (!this.iPriceWithVAT){
        this.iPriceWithVAT = 0
      }
       if (!this.fullpriceChange && !this.exportDoc) {
         this.inputDocCalc()
         //this.calculatePrice()
       }
       if (this.fullpriceChange && this.doRetail) {
         this.iFullPriceOriginal = this.iPriceWithVAT
         this.inputDocCalc()
       }
      
    //  if (this.workOn === true) {
        // this.documentDet.itemMarginAmount = this.documentDet.itemBaseAmountNoVAT - this.itemSuppPriceAmount
        // if (this.pcsLoc !== 0) {
        //   this.maxDiscPer = (this.documentDet.itemPriceWithVAT / 100) ? this.round((this.documentDet.itemPriceWithVAT / 100),3) : 0
        //   this.documentDet.itemMaxDiscount = (100 - (this.documentDet.itemMarginAmount / this.maxDiscPer)) ? this.round((100 - (this.documentDet.itemMarginAmount / this.maxDiscPer)),2) : 0
        // }
        // this.documentDet.itemMargin = 0
        // this.documentDet.FreeF2 = 0
        // if (this.documentDet.itemSuppPriceAmount !== 0) {
        //   this.documentDet.itemMargin = (this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)) ? this.round((this.documentDet.itemMarginAmount / (this.documentDet.itemSuppPriceAmount / 100)),2) : 0
        // }
        // if (this.pcsLoc !== 0) {
        //   this.documentDet.FreeF2 = (this.documentDet.itemMarginAmount / this.pcsLoc) ? this.round((this.documentDet.itemMarginAmount / this.pcsLoc),2) : 0
        // }
        
     // }
    },
    'iForePriceWithVAT' () {
      if (!this.iForePriceWithVAT){
        this.iForePriceWithVAT = 0
      }
       if (!this.fullpriceChange && this.exportDoc ) {
         this.inputDocCalc()
         //this.calculatePrice()
       }
       if (this.fullpriceChange && this.doRetail) {
         this.iFullPriceOriginal = this.iPriceWithVAT
         this.inputDocCalc()
       }

    },
    'selectIL.itemID' () {
      if (this.workOn === true) {
        if (!this.selectIL) {
          this.haveItemSelected = false
          this.newItem = true
          this.firstUpdate = 3
          this.clearIt()
        } else {
          if (this.selectIL && this.selectIL.itemID) {
            this.haveItemSelected = true
            this.findItem()
          } else {
            this.clearIt()
          }
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >
.v-text-field {
        margin: 0;
        padding: 0;
    }
</style>


