<template>
  <v-container fluid>

        <v-row no-gutters>
          <v-col sm="2">
            <h1>{{documentNameLoc}}</h1>
          </v-col>
          <v-col sm="6">
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="documentEdit" class="mr-1">
                  {{langC.Edit}}
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 5">
                <v-btn color="primary" @click="documentCopy" class="mr-1">
                  {{langC.Copy}}
                </v-btn>
            </span>

            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="documentPrevious" class="mr-1">
                  {{langC.Previous}}
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel <= 3">
                <v-btn color="primary" @click="warehouseState" class="mr-1">
                  {{langC.Warehouse2}}
                </v-btn>
            </span>
            <span v-if="this.$store.state.user.AccessLevel == 1">
                <v-btn text color="red" @click="documentDelete" class="mr-1">
                  {{langC.Delete}}
                </v-btn>
            </span>
          </v-col>
          <v-col sm="1"> 

          </v-col>

          <v-col sm="3"> 
              <v-btn color="green" @click="documentlist" class="mr-1">
                  {{langC.Back}}
              </v-btn>           
              <span v-if="this.$store.state.user.AccessLevel <= 5">
                <v-btn color="green" @click="documentSave" class="mr-1">
                   {{langC.Save}}
                </v-btn>
                <v-progress-circular v-if="this.saving"
                  indeterminate
                  color="green"
                ></v-progress-circular>
            </span>

          </v-col>
          
        </v-row>
        <v-row>
         <v-col
            cols="12"
            sm="1"
            md="1"
          >
            <v-checkbox
                dense 
                v-model="showMat"
                label="Materijal"
              ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            sm="1"
            md="1"
          >
            <v-checkbox
                dense 
                v-model="showWork"
                label="Radnici"
              ></v-checkbox>
          </v-col>
          <v-col
              cols="12"
              sm="1"
              md="1"
            >
              <v-checkbox
                dense 
                v-model="showWork"
                label="Radnici"
              ></v-checkbox>
            </v-col>
            <v-col
                cols="12"
                sm="1"
                md="1"
              >
              <v-checkbox
                dense 
                v-model="showOther"
                label="Ostalo"
              ></v-checkbox>
            </v-col>
            <v-col
                cols="12"
                sm="2"
                md="2"
              >
               <v-checkbox
                dense 
                v-model="showProduct"
                label="Proizvedeno"
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="2"
              md="2"
            >
              <v-checkbox
                dense 
                v-model="hidePrice"
                :label="langC.HidePrice"
              ></v-checkbox>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              md="2"
            >
               <v-btn text color="green" @click="documentprint" class="mr-1">
                {{langC.Print}}
              </v-btn>
            </v-col>


        </v-row>

       <v-row no-gutters>
            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
              <appDocumentViewCard1></appDocumentViewCard1>
              </v-card>
            </v-col>

            <v-col sm="4">
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <appDocumentViewCard2></appDocumentViewCard2>
              </v-card>
              <DigitalDocShortList />
            </v-col>

            <v-col
              sm="4"
            >
              <v-card
                class="pa-2"
                outlined
                tile
              >
                <appDocumentViewCard4></appDocumentViewCard4>
              </v-card>
            </v-col>
          </v-row>
          
        <v-row>
          <v-flex>

          </v-flex>
        </v-row>

        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <DocumentDetailList3 :key="componentKey"></DocumentDetailList3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <DocumentDetailList31 :key="componentKey"></DocumentDetailList31>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <DocumentDetailList32 :key="componentKey"></DocumentDetailList32>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <DocumentDetailList33 :key="componentKey"></DocumentDetailList33>
          </v-col>
        </v-row>

        <DocumentProductionPrint ref="prnt" :showMat="showMat" :showWork="showWork" :showOther="showOther" :showProduct="showProduct" /> 
        <DocumentProductionPrintNoPrice ref="prntNo" :showMat="showMat" :showWork="showWork" :showOther="showOther" :showProduct="showProduct" />

          <v-snackbar
          v-model="snackbar"
          :timeout="timeout"
          bottom
          :color="color"
          >
          {{ text }}
        </v-snackbar> 

  </v-container>
</template>

<script>
import DocumentService from '@/services/DocumentService'
import DocumentDetailService from '@/services/DocumentDetailService'
import ItemService from '@/services/ItemService'
import EmployeeService from '@/services/EmployeeService'
import DocumentViewCard1 from './DocumentViewCard1'
import DocumentViewCard2 from './DocumentViewCard2'
import DocumentViewCard4 from './DocumentViewCard4'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import DocumentDetailList3 from './DocumentDetailList3'
import DocumentDetailList31 from './DocumentDetailList31'
import DocumentDetailList32 from './DocumentDetailList32'
import DocumentDetailList33 from './DocumentDetailList33'
import DocumentProductionPrint from './DocumentProductionPrint'
import DocumentProductionPrintNoPrice from './DocumentProductionPrintNoPrice'
import DigitalDocShortList from '../DigitalDoc/DigitalDocShortList.vue'
import { newUpdates, testLastUpdate } from '../../services/TestLastUpdate'
import dayjs from 'dayjs'

export default {
  name: 'documentview3',
  data () {
    return {
      lang: {},
      langC: {},
      saving: false,
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      msg: '',
      document: {
      },
      loctaskOpenDate: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      loctaskForDate: '',
      loctaskClosedDate: '',
      loctaskAlarmDate: '',
      haveRight: 4,
      documentActivData: {},
      documentNameLoc: '',
      itemsLoc: [],
      employes: [],
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      profitLoss: 0,
      profitLossP: '',
      hidePrice: false,
      showMat: true,
      showWork: true,
      showOther: true,
      showProduct: true,
      componentKey: 0,
    }
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    if (this.$store.state.documentid) {
      try {
        const dbDocument = await DocumentService.show(this.$store.state.documentid)
        // console.log(dbDocument.data.document)
        this.document = dbDocument.data.document
        this.documentNameLoc = this.$store.state.documentActivLoc
        if (this.document.documentDate) {
          this.locdocumentDate = dayjs(this.document.documentDate).format('DD.MM.YYYY')
        }
        if (this.document.documentInvoiceStartDate) {
          this.locdocumentInvoiceStartDate = dayjs(this.document.documentInvoiceStartDate).format('DD.MM.YYYY')
        }
        if (this.document.documentInvoiceEndDate) {
          this.locdocumentInvoiceEndDate = dayjs(this.document.documentInvoiceEndDate).format('DD.MM.YYYY')
        }
        if (this.document.documentLockedDate) {
          this.locdocumentLockedDate = dayjs(this.document.documentLockedDate).format('DD.MM.YYYY')
        }
        this.locCreatedAt = dayjs(this.document.createdAt).format('DD.MM.YYYY HH:MM')
        this.locUpdatedAt = dayjs(this.document.updatedAt).format('DD.MM.YYYY HH:MM')
        this.profitLoss = this.document.FreeF4 - this.document.FreeF1 - this.document.FreeF2 - this.document.FreeF3
        this.profitLossP = this.profitLoss.toFixed(2)
      } catch (error) {
        // eslint-disable-next-line
        console.log('error read from datebase')
      }
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;  
    },
    documentPrevious() {
      this.previous = !this.previous
    },
    warehouseState() {
      this.statewhs = !this.statewhs
    },
    async documentlist () {
      try {
        this.$store.dispatch('settypePOS', 0)
        await this.$router.push({
          name: 'documentproduction'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentDelete () {
      try {
        await this.$router.push({
          name: 'documentdelete'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentEdit () {
      try {
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'documentcreate'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentprint() {
      if (this.hidePrice) {
        await this.$store.dispatch('setprintNoPrice', true)
        this.$refs.prntNo.print()
      } else {
        await this.$store.dispatch('setprintNoPrice', false)
        this.$refs.prnt.print()
      }
      
    },
    async documentCopy () {
      try {
        await this.$router.push({
          name: 'documentcopy'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    async documentDetails () {
      try {
        if (this.$store.state.companyid) {
          this.mainQuery.CompanyId = this.$store.state.companyid
        }
        if (this.$store.state.businessYear) {
          this.mainQuery.BusinessYear = this.$store.state.businessYear
        }
        this.documentActivData = this.document
        this.itemsLoc = (await ItemService.index()).data.items
        this.$store.dispatch('setActivItems', this.itemsLoc)
        this.employes = (await EmployeeService.choose(this.mainQuery)).data.employes
        this.$store.dispatch('setActivEmployes', this.employes)
        this.$store.dispatch('setDocumentActivData', this.documentActivData)
        this.$store.dispatch('seteditItem', 1)
        await this.$router.push({
          name: 'documentdetail3'
        })
      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    },
    round (value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    },
    async doCalculationAgain () {
      const updatedItems = []
      const activItems = this.$store.state.documentActivItems ? this.$store.state.documentActivItems  : []
      activItems.map(item => {
            const upItem = {...item}
            let pcsLoc = 0
            if (item.documentSide === 1) {
              this.pcsLoc = item.itemPcsPlus ? item.itemPcsPlus : 0
            }
            if (item.documentSide === 2) {
              this.pcsLoc = item.itemPcsMinus ? item.itemPcsMinus : 0
            }
            if (item.documentSide === 3) {
              this.pcsLoc = item.itemPcsNeutral ? item.itemPcsNeutral : 0
            }
            pcsLoc = this.round(pcsLoc, 3)
            if (parseInt(item.FreeF6) === 3 || parseInt(item.FreeF6) === 32) {
                // no margin, no discount only supp price 
                upItem.itemSuppBasePrice = upItem.itemSuppBasePrice ? upItem.itemSuppBasePrice : 0
                upItem.itemSuppPrice = upItem.itemSuppBasePrice
                upItem.itemSuppDiscount = 0
                upItem.itemSuppBasePriceAmount  = (upItem.itemSuppBasePrice * pcsLoc) ? this.round((upItem.itemSuppBasePrice * pcsLoc),2) : 0
                upItem.itemSuppDiscountAmount = 0
                upItem.itemSuppPriceAmount = upItem.itemSuppBasePriceAmount
                upItem.itemPrice = upItem.itemSuppPrice
                upItem.itemDiscountPercentage1 = 0
                upItem.itemDirectDuty1 = upItem.itemDirectDuty1 ? this.round(upItem.itemDirectDuty1, 2) : 0
                upItem.itemVAT1Percentage = upItem.itemVAT1Percentage ? this.round(upItem.itemVAT1Percentage, 2) : 0
                upItem.itemPrice = upItem.itemPrice ? this.round(upItem.itemPrice, 3) : 0
                
                upItem.itemMargin = 0
                upItem.itemMarginAmount = 0
                // no discount in any case
                upItem.itemDiscountPercentage1 = 0
                upItem.itemDiscountPrice = upItem.itemPrice
                this.maxDiscPer = 0
                upItem.itemMaxDiscount = 0
            }
            if (parseInt(item.FreeF6) === 33) {
              upItem.itemSuppBasePrice = 0
              upItem.itemSuppDiscount = 0
              upItem.itemSuppBasePriceAmount  =  0
              upItem.itemSuppDiscountAmount = 0
              upItem.itemSuppPriceAmount = 0
              upItem.itemSuppPrice = 0
              upItem.itemDiscountPercentage1 = 0
            // upItem.itemPrice = upItem.itemSuppPrice
            }
            
            if (parseInt(item.FreeF6) === 31) {
              upItem.empWrkExpHrs =  upItem.empWrkExpHrs ? parseFloat(upItem.empWrkExpHrs) : 0
              upItem.itemBaseAmount = upItem.empWrkExpHrs * pcsLoc
              upItem.itemSuppBasePriceAmount  =  upItem.itemBaseAmount
              upItem.itemSuppDiscountAmount = 0
              upItem.itemSuppPriceAmount =  upItem.itemBaseAmount
            }
            if (parseInt(item.FreeF6) === 3 || parseInt(item.FreeF6) === 32) {
              upItem.itemBaseAmount = this.round((upItem.itemPrice * pcsLoc), 2)
            }
            // all cases have these
            upItem.itemBaseAmountNoVAT = this.round(upItem.itemBaseAmount, 2)

            upItem.itemVAT1Amount = ((upItem.itemBaseAmountNoVAT * (upItem.itemVAT1Percentage / 100)))
            upItem.itemVAT1Amount = this.round(upItem.itemVAT1Amount, 2)
            upItem.itemAmountWithVAT = (parseFloat(upItem.itemBaseAmountNoVAT) + parseFloat(upItem.itemVAT1Amount))
            upItem.itemAmountWithVAT = this.round(upItem.itemAmountWithVAT, 2)
            
            if (pcsLoc !== 0) {
              upItem.itemPriceWithVAT = ((upItem.itemAmountWithVAT / pcsLoc))
              upItem.itemPriceWithVAT = this.round(upItem.itemPriceWithVAT, 2)
            }
            upItem.itemDirectDuty1Amount = this.round((upItem.itemDirectDuty1 * pcsLoc), 2)
            upItem.itemFullTotal = (upItem.itemBaseAmountNoVAT + upItem.itemVAT1Amount + upItem.itemDirectDuty1Amount)
            upItem.itemFullTotal = this.round(upItem.itemFullTotal, 2)

            if (parseInt(item.FreeF6) === 33) {       
              upItem.itemMarginAmount = upItem.itemBaseAmount - upItem.itemSuppPriceAmount
              if (upItem.itemMarginAmount < 0) {
                upItem.itemMarginAmount = 0
              }
              const per2 = upItem.itemSuppPriceAmount / 100
              
              upItem.itemMargin = per2 !== 0 ? this.round(upItem.itemMarginAmount / per2, 2) : 0

              const maxDiscPer = (upItem.itemBaseAmountNoVAT / 100) ? this.round((upItem.itemBaseAmountNoVAT / 100),3) : 0
              upItem.itemMaxDiscount = (upItem.itemMargin/ maxDiscPer) ? this.round((upItem.itemMargin/ maxDiscPer),2) : 0
            }

          updatedItems.push(upItem)
      })
      await this.$store.dispatch('setDocumentActivItems', updatedItems)
    },
    async documentSave () {
      try {
 
        if (this.$store.state.documentActivHead) {
          this.saving = true
          const docID = this.$store.state.documentActivHead.id ? this.$store.state.documentActivHead.id : 0
          if (this.$store.state.documentActivHead) {
            await DocumentService.put(this.$store.state.documentActivHead)
          }

         // await this.doCalculationAgain()

          const cost = (this.$store.state.documentActivHead.FreeF1 ? parseFloat(this.$store.state.documentActivHead.FreeF1) : 0) + (this.$store.state.documentActivHead.FreeF2 ? parseFloat(this.$store.state.documentActivHead.FreeF2) : 0) + (this.$store.state.documentActivHead.FreeF3 ? parseFloat(this.$store.state.documentActivHead.FreeF3) : 0)

          let newItems = []
          const itemsUpd = []
          if (this.$store.state.documentActivItems) {
            this.$store.state.documentActivItems.map(item => {
              let newObject = JSON.parse(JSON.stringify(item)) 
              let updateItem = {}
              let suppDesc = ''

              delete newObject.id
              if (parseInt(item.FreeF6) === 33) {
                newObject.itemSuppBasePrice = item.itemPcsPlus && parseFloat(item.itemPcsPlus) !== 0 ? cost / parseFloat(item.itemPcsPlus) : 0
                newObject.itemSuppPrice = item.itemPcsPlus && parseFloat(item.itemPcsPlus) !== 0 ? cost / parseFloat(item.itemPcsPlus) : 0
                newObject.itemSuppDiscount = 0
                newObject.itemSuppBasePriceAmount  = cost
                newObject.itemSuppPriceAmount = cost
                newObject.itemSuppDiscountAmount = 0
                newObject.itemMarginAmount = parseFloat(item.itemBaseAmount) - cost

                updateItem.itemID = item.itemID
                updateItem.ItemSupppartnerName = this.$store.state.documentActivHead.partnerNameDatabase ? this.$store.state.documentActivHead.partnerNameDatabase : 0
                updateItem.ItemSuppDate = this.$store.state.documentActivHead.documentDate ? this.$store.state.documentActivHead.documentDate : 0
                updateItem.ItemSuppDoc = parseInt(this.$store.state.documentActivHead.documentId)? parseInt(this.$store.state.documentActivHead.documentId): 0
                updateItem.ItemSuppDate = this.$store.state.documentActivHead.documentDate ? this.$store.state.documentActivHead.documentDate : null
                updateItem.itemSuppPrice = newObject.itemSuppPrice ? newObject.itemSuppPrice : 0
                updateItem.itemSuppBasePrice = newObject.itemSuppPrice ? newObject.itemSuppPrice : 0
                updateItem.itemSuppDiscount =  0
                updateItem.itemMargin = newObject.itemMarginAmount

                suppDesc = this.$store.state.documentActivHead.documentName ? this.$store.state.documentActivHead.documentName : ''
                suppDesc += ', ' + (parseInt(this.$store.state.documentActivHead.documentId) ?  parseInt(this.$store.state.documentActivHead.documentId) : '')
                suppDesc += ', ' + (this.$store.state.documentActivHead.documentDate ? dayjs(this.$store.state.documentActivHead.documentDate).format('DD.MM.YYYY') : '')
                suppDesc += ', ' + (this.$store.state.documentActivHead.partnerNameDatabase ? this.$store.state.documentActivHead.partnerNameDatabase : '')
                updateItem.ItemSuppCode = suppDesc
                updateItem.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
                console.log('updateItem', updateItem)
                itemsUpd.push(updateItem)
              }
              newItems.push(newObject)
            })
          }
          // if delete all items from document
          if (newItems.length === 0 && docID > 0 ) {
            let newObject = {
              databaseDocID : docID
            }
            newItems.push(newObject)
          }

          if (newItems) {
            await DocumentDetailService.saveitems(newItems).then(res => {
              this.text = this.langC.DocumentSaved + (res.data.message)
              this.color = 'green'
              this.snackbar = true
            }).catch(err => {
              this.text = this.langC.DocumentSaveError + (err.data.message)
              this.color = 'red'
              this.snackbar = true
            })
          }

          if (itemsUpd) {
            await ItemService.updateprices(itemsUpd).then(async(res) => {
              // eslint-disable-next-line
             console.log(' update prices done ', res)
             await newUpdates(1)
             await testLastUpdate()
            }
            )
          }

          this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
          this.mainQuery.businessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
          this.mainQuery.documentName = this.$store.state.documentActiv ? this.$store.state.documentActiv : ''
          this.mainQuery.databaseDocID = this.$store.state.databaseDocID ? this.$store.state.databaseDocID : null
          this.itemsLoc = []
          this.itemsLoc = (await DocumentDetailService.index(this.mainQuery)).data.documentDetails
          if (this.itemsLoc) {
            this.$store.dispatch('setDocumentActivItems', this.itemsLoc)
          } else {
            this.$store.dispatch('setDocumentActivItems', [])
          }
          this.forceRerender()
          this.saving = false
        }

      } catch (err) {
        // eslint-disable-next-line
        console.log(err)
      }
    }
  },
  computed: {
  },
  watch: {

  },
  components: {
    appDocumentViewCard1: DocumentViewCard1,
    appDocumentViewCard2: DocumentViewCard2,
    appDocumentViewCard4: DocumentViewCard4,
    DocumentDetailList3,
    DocumentDetailList31,
    DocumentDetailList32,
    DocumentDetailList33,
    DocumentProductionPrint,
    DocumentProductionPrintNoPrice,
    DigitalDocShortList,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

