<template>
      <div> 
             <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5">
                      <v-autocomplete
                      class="pr-3"
                      :items="partners"
                      v-model="selectPa"
                      :label="lang.SelectPartner"
                      :search-input.sync="search"
                      single-line
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                    <v-text-field class="pr-2"
                      :label="lang.VATID"
                      v-model="document.partnerVATID"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="4" md="4" xs="6" lg="4" xl="4">
                    <p class="text-right">
                      <v-btn text color="primary" class="pt-3" @click="findByVATID()">{{langC.checkVATID}}</v-btn>
                    </p>
                </v-col>
             </v-row>

            <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5" >
                    <v-text-field
                      class="pr-3"
                      :label="lang.PartnerName"
                      v-model="document.partnerName"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="5" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.Street"
                      v-model="document.partnerStreet"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      :label="lang.BuildingNameNumber"
                      v-model="document.partnerBuildingNameNumber"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">

                </v-col>
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-checkbox
                    :label="lang.privatePerson"
                    v-model="document.privatePerson"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="5" md="5" xs="12" lg="5" xl="5">
                    <v-text-field class="pr-2"
                    :label="lang.CompanyEmail"
                    v-model="document.partneremail"
                  ></v-text-field>
                </v-col>
                
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                    <v-text-field class="pr-3"
                      :label="lang.City"
                      v-model="document.partnerCity"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                    <v-text-field
                      :label="lang.Postcode"
                      v-model="document.partnerPostcode"
                    ></v-text-field> 
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3">
                  <v-progress-circular v-if="this.saving"
                    indeterminate
                    color="green"
                  ></v-progress-circular>
                  <p class="text-right">
                     <v-btn text color="primary" class="pt-3" @click="addeditPartner()">{{lang.PartnerSave}}</v-btn>
                     <v-btn text color="primary" @click="updatePartnerReceive()">{{lang.addPOSpartner}}</v-btn>
                  </p>
                </v-col>
              </v-row>

              <v-row no-gutters>

                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-text-field class="pr-2"
                    :label="lang.SecretaryTitle"
                    v-model="document.partnerSecretaryTitle"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryFirst"
                    v-model="document.partnerSecretaryFirst"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryLast"
                    v-model="document.partnerSecretaryLast"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryEmail"
                    v-model="document.partnerSecretaryEmail"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3" md="3" xs="6" lg="3" xl="3">
                  <v-text-field class="pr-3"
                    :label="lang.SecretaryPhone"
                    v-model="document.partnerSecretaryPhone"
                  ></v-text-field>
                </v-col>
 
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1">
                  <v-text-field
                    :label="lang.ContactTime"
                    v-model="document.partnerContactTime"
                  ></v-text-field>
                </v-col>
              </v-row>         
  
          {{error}}
          <v-snackbar
            v-model="snackbar"
            bottom
            :timeout="timeout"
            :color="color"
            >
            {{ text }}
          </v-snackbar>
  </div>
</template>

<script>
import PartnerService from '@/services/PartnerService'
import langEn from './documentDescEn'
import langHr from './documentDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import dateFormat from '@/snippets/dateFormat'
import DocumentService from '@/services/DocumentService'
//import {checkPartners} from '@/snippets/mainledgerHelper'
import {testLastUpdate, newUpdates} from '@/services/TestLastUpdate'

export default {
  name: 'documentPOSpartner',
  $_veeValidate: {
    validator: 'new'
  },
  props: ['newPOS'],
  data () {
    return {
      saving: false,
      valid: true,
      lazy: false,
      rules: {
        required: value => !!value || '*'
      },
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      date1: null,
      date2: null,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      dialog5: false,
      have1: true, 
      have2: true,
      have3: true,
      have4: true,
      have5: false,
      have6: false,
      lang1: '',
      lang2: '',
      lang3: '',
      lang4: '',
      lang5: '',
      msg: '',
      documentName: '',
      documentNameLoc: '',
      document: {
        partnerId: '',
        partnerName: '',
        partnerNameShort: '',
        partnerNameDatabase: '',
        partnerBuildingNameNumber: '',
        partnerStreet: '',
        partnerCity: '',
        partnerPostcode: '',
        partnerCountyRegion: '',
        partnerState: '',
        partnerVATID: '',
        partneremail: '',
        partnerPhones: '',
        partnerBankName: '',
        partnerBankAddress: '',
        partnerBankIBAN: '',
        partnerBankSWIFT: '',
        partnerContactTime: '',
        partnerSecretaryTitle: '',
        partnerSecretaryFirst: '',
        partnerSecretaryLast: '',
        partnerSecretaryFormer: '',
        partnerSecretaryStateResidence: '',
        partnerSecretaryNationality: '',
        partnerSecretaryDateOfBirth: '',
        partnerSecretaryBusinessOccupation: '',
        partnerSecretaryAdressBuildingNameNumber: '',
        partnerSecretaryAdressStreet: '',
        partnerSecretaryCity: '',
        partnerSecretaryPostcode: '',
        partnerSecretaryCountyRegion: '',
        partnerSecretaryEmail: '',
        partnerSecretaryPhone: '',
        partnerSecretaryContactOption: '',
        privatePerson: false
      },
      newpartner: {
        CompanyId: 1,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 1,
        email: '',
        First: '',
        Last: '',
        partnerName: '',
        partnerNameShort: '',
        partnerNameDatabase: '',
        partnerBuildingNameNumber: '',
        partnerStreet: '',
        partnerCity: '',
        partnerPostcode: '',
        partnerCountyRegion: '',
        partnerState: '',
        partnerVATID: null,
        partneremail: '',
        partnerPhones: '',
        partnerBankName: '',
        partnerBankAddress: '',
        partnerBankIBAN: '',
        partnerBankSWIFT: '',
        partnerDirectorTitle: '',
        partnerDirectorFirst: '',
        partnerDirectorLast: '',
        partnerDirectorFormer: '',
        partnerDirectorStateResidence: '',
        partnerDirectorNationality: '',
        partnerDirectorDateOfBirth: '',
        partnerDirectorBusinessOccupation: '',
        partnerDirectorAdressBuildingNameNumber: '',
        partnerDirectorAdressStreet: '',
        partnerDirectorCity: '',
        partnerDirectorPostcode: '',
        partnerDirectorCountyRegion: '',
        partnerDirectorEmail: '',
        partnerDirectorPhone: '',
        partnerDirectorContactOption: '',
        partnerSecretaryTitle: '',
        partnerSecretaryFirst: '',
        partnerSecretaryLast: '',
        partnerSecretaryFormer: '',
        partnerSecretaryStateResidence: '',
        partnerSecretaryNationality: '',
        partnerSecretaryDateOfBirth: '',
        partnerSecretaryBusinessOccupation: '',
        partnerSecretaryAdressBuildingNameNumber: '',
        partnerSecretaryAdressStreet: '',
        partnerSecretaryCity: '',
        partnerSecretaryPostcode: '',
        partnerSecretaryCountyRegion: '',
        partnerSecretaryEmail: '',
        partnerSecretaryPhone: '',
        partnerSecretaryContactOption: '',
        partnerInvoiceMemo: '',
        partnerInvoiceForeginMemo: '',
        partnerDeliveryNoteMemo: '',
        partnerDeliveryNoteForeginMemo: '',
        partnerInvoiceDefDays: 15,
        partnerInvoicePlace: '',
        partnerInvoiceType: '',
        partnerInvoiceHint: '',
        partnerInvoiceSpeed: 1,
        partnerUseRetailPrice: false,
        partnerShowLastSaleBuyer: false,
        partnerShowWarehouseState: false,
        partnerShowInputPrice: false,
        partnerVATonPay: false,
        partnerRetail: false,
        partnerDefaultWarehouse: 1,
        partnerPrinterSize: 1,
        partnerInputFilesPath: '',
        partnerOutputFilesPath: '',
        partnerTransferDataFilesPath: '',
        partnerStartInvoiceNumber: 1,
        partnerStartBillNumber: 1,
        partnerType: 1,
        privatePerson: false
      },
      error: null,
      localError: false,
      newDocument: {},
      confpassword: '',
      size: 'sm',
      required: (value) => !!value || 'Required.',
      partners: [],
      employes: [],
      banks: [],
      currnecy: [],
      users: [],
      CompanyId: 0,
      selectPa: {},
      firstTimePa: true,
      selectId: 0,
      search: null,
      selectBank: {},
      selectBankId: 0,
      firstTimeBank: true,
      selectCR: {},
      selectUs: {},
      documentDate: null,
      invoiceStartDate: null,
      invoiceEndDate: null,
      supplierDocumentDate: null,
      mainQuery: {
        CompanyId: 1,
        BusinessYear: 0,
        documentName: ''
      },
      mainQuery2 : {},
      currnecyType: [
        { text: 'Kn', value: 'Kn' },
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' },
        { text: 'GBP', value: 'GBP' },
        { text: 'CHF', value: 'CHF' }
      ],
      statusType: [
        { text: 'Prijavljeno', value: 'Reported' },
        { text: 'Zaprimljeno', value: 'Received' },
        { text: 'U tijeku', value: 'InProgress' },
        { text: 'Završeno', value: 'Done' },
        { text: 'Preuzeto', value: 'Delivered' }
      ],
      money: {
        prefix: '= ',
        suffix: ' ',
        precision: 3
      },
      price: 7.500,
      docName: '',
      lang: {},
      langC: {},
      mount: true,
      reqVATID: false
    }
  },
  components: {
  
  },
  async mounted () {
    if (this.$store.state.language === 'EN') {
      this.lang = langEn
      this.langC = commEn
    }
    if (this.$store.state.language === 'HR') {
      this.lang = langHr
      this.langC = commHr
    }
    this.saving = true
    //await checkPartners(0)
    await testLastUpdate()
    if (this.$store.state.partnerStore) {
      this.partners = this.$store.state.partnerStore
    }
    //this.partners = (await PartnerService.index()).data.partners
    if (this.$store.state.documentActivHead && this.newPOS === true) {
      const clearHead = this.$store.state.documentActivHead
      clearHead.documentConnId = clearHead.documentId ? clearHead.documentId : null
      clearHead.documentConnName = clearHead.documentName ? clearHead.documentName : null
      clearHead.documentConnDate = clearHead.documentDate ? clearHead.documentDate : null
      clearHead.documentConnTime = clearHead.documentTime ? clearHead.documentTime : null
      clearHead.id = null
      clearHead.UserId = null
      clearHead.email = null
      clearHead.First = null
      clearHead.Last = null
      clearHead.uservatID = null
      clearHead.CompanyId = null
      clearHead.CompanyNameDatabase = null
      clearHead.BusinessYear = null
      clearHead.documentId = null
      clearHead.documentIdGeneral = null
      clearHead.documentName = null
      clearHead.documentAnalysis = null
      clearHead.documentDescription1 = null
      clearHead.documentSide = null
      clearHead.documentDate = null
      clearHead.documentInvoiceStartDate = null
      clearHead.documentInvoiceEndDate = null
      await this.$store.dispatch('setDocumentActivHead', clearHead)
      this.document = {...clearHead}
      this.selectPa = parseInt(clearHead.partnerId)
    }
    if (this.$store.state.documentActivHead && this.newPOS === false) {
      this.document = this.$store.state.documentActivHead
      this.selectPa = parseInt(this.document.partnerId)
    }
    this.saving = false
    this.mount = false
  },
  computed: {

  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async updatePartnerReceive(){
      await DocumentService.put(this.document)
          .then(response => {
            this.text = this.lang.DocumentSavedOK + response.statusText
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err)
            this.text = 'Error put!' + err
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          })
    },
    async findByVATID() {
      if (this.document.partnerVATID) {
        this.mainQuery.partnerVATID = this.document.partnerVATID
        const {data} = await PartnerService.check(this.mainQuery)
        const id = data && data.partner && data.partner.id ? data.partner.id : null
        if (id) {
          this.selectPa =  this.partners.find(partner => (partner.id === parseInt(id)))
          await this.findPartner(id)
        }
      }
    },
    async findPartner (partnerVATID) {
      if (partnerVATID) {
        // in partners array I dont have all required data, for faster serach 
        // const locPartner = this.partners.find(partner => (partner.id === partnerVATID)) and then need to contact API
        if ((partnerVATID) !== (this.document.partnerVATID)) {

          this.saving = true
          const locPartner = (await PartnerService.show(partnerVATID)).data.partner
          this.saving = false

            this.document.partnerId = locPartner.id
            this.document.partnerName = locPartner.partnerName
            this.document.partnerNameShort = locPartner.partnerNameShort
            this.document.partnerNameDatabase = locPartner.partnerNameDatabase
            this.document.partnerBuildingNameNumber = locPartner.partnerBuildingNameNumber
            this.document.partnerStreet = locPartner.partnerStreet
            this.document.partnerCity = locPartner.partnerCity
            this.document.partnerPostcode = locPartner.partnerPostcode
            this.document.partnerCountyRegion = locPartner.partnerCountyRegion
            this.document.partnerState = locPartner.partnerState
            this.document.partnerVATID = locPartner.partnerVATID
            this.document.partneremail = locPartner.partneremail
            this.document.partnerPhones = locPartner.partnerPhones
            this.document.partnerBankName = locPartner.partnerBankName
            this.document.partnerBankAddress = locPartner.partnerBankAddress
            this.document.partnerBankIBAN = locPartner.partnerBankIBAN
            this.document.partnerBankSWIFT = locPartner.partnerBankSWIFT
            this.document.partnerContactTime = locPartner.partnerContactTime
            this.document.partnerSecretaryTitle = locPartner.partnerSecretaryTitle
            this.document.partnerSecretaryFirst = locPartner.partnerSecretaryFirst
            this.document.partnerSecretaryLast = locPartner.partnerSecretaryLast
            this.document.partnerSecretaryEmail = locPartner.partnerSecretaryEmail
            this.document.partnerSecretaryPhone = locPartner.partnerSecretaryPhone
            this.document.partnerSecretaryContactOption = locPartner.partnerSecretaryContactOption
            this.document.privatePerson = locPartner.privatePerson
            const newHead = {...this.$store.state.documentActivHead, ...this.document}
            if (newHead) {
              this.$store.dispatch('setDocumentActivHead', newHead)
            }
          
        }
      }
    },
    async addeditPartner () {
        const addeditPartner = {...this.newpartner}
        addeditPartner.UserId = this.$store.state.user.id ? this.$store.state.user.id : ''
        addeditPartner.email = this.$store.state.user.email ? this.$store.state.user.email : 'test@cegrupa.com'
        addeditPartner.First = this.$store.state.user.First ? this.$store.state.user.First : 'NoFirst'
        addeditPartner.Last = this.$store.state.user.Last ? this.$store.state.user.Last : 'NoLast'
        addeditPartner.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
        addeditPartner.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : ''
        addeditPartner.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2020
        addeditPartner.partnerName = this.document.partnerName
        addeditPartner.partnerNameShort = this.document.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerNameDatabase = this.document.partnerName.replace(/[\W_]/g, '')
        addeditPartner.partnerBuildingNameNumber = this.document.partnerBuildingNameNumber
        addeditPartner.partnerStreet = this.document.partnerStreet
        addeditPartner.partnerCity = this.document.partnerCity
        addeditPartner.partnerPostcode = this.document.partnerPostcode
        addeditPartner.partnerCountyRegion = this.document.partnerCountyRegion
        addeditPartner.partnerState = this.document.partnerState
        addeditPartner.partnerVATID = this.document.partnerVATID
        addeditPartner.partneremail = this.document.partneremail
        addeditPartner.partnerPhones = this.document.partnerPhones
        addeditPartner.partnerBankName = this.document.partnerBankName
        addeditPartner.partnerBankAddress = this.document.partnerBankAddress
        addeditPartner.partnerBankIBAN = this.document.partnerBankIBAN
        addeditPartner.partnerBankSWIFT = this.document.partnerBankSWIFT
        addeditPartner.partnerContactTime = this.document.partnerContactTime
        addeditPartner.partnerSecretaryTitle = this.document.partnerSecretaryTitle
        addeditPartner.partnerSecretaryFirst = this.document.partnerSecretaryFirst
        addeditPartner.partnerSecretaryLast = this.document.partnerSecretaryLast
        addeditPartner.partnerSecretaryEmail = this.document.partnerSecretaryEmail
        addeditPartner.partnerSecretaryPhone = this.document.partnerSecretaryPhone
        addeditPartner.partnerSecretaryContactOption = this.document.partnerSecretaryContactOption
        addeditPartner.privatePerson = this.document.privatePerson
        this.saving = true
        if (this.document.partnerId) {
            addeditPartner.id = this.document.partnerId
            this.saving = true
            await PartnerService.put(addeditPartner)

            this.saving = false
            this.text = 'Partner updated!'
            this.color = 'green'
            this.snackbar = true
            this.timeout = 2000
            await newUpdates(2)
            await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
            const newHead = {...this.$store.state.documentActivHead, ...this.document}
            if (newHead) {
              this.$store.dispatch('setDocumentActivHead', newHead)
            }
        } else {
          if (addeditPartner.partnerName) {
            this.localError = null
            this.mainQuery2 = {}
            // if is private person then dont check
            if (addeditPartner.privatePerson === false) {
              // check if vatid exist and if exist then do not add partner
              if (!addeditPartner.partnerVATID) {
                this.localError =  this.localError + ', Error partner dot have VAT ID or mark for private person '
              }
              if (addeditPartner.partnerVATID) {
                this.mainQuery2.partnerVATID = addeditPartner.partnerVATID
              }
              if (addeditPartner.partneremail) {
                this.mainQuery2.partneremail = addeditPartner.partneremail
              }
              if (!this.localError) {
                await PartnerService.check(this.mainQuery2)
                  .then(res => {
                    if (res.data.partner) {
                      this.haveItem = res.data.partner
                      this.localError = this.lang.PartnerErrorVAT + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }
                    if (res.data.partner2) {
                      this.haveItem = res.data.partner2
                      this.localError = this.lang.PartnerErroremail + (this.haveItem.partnerName ? this.haveItem.partnerName : '')
                    }                
                  })
                  .catch(err => {
                    this.error = err.data
                    this.localError = false
                  })
              }
            }
            // can save as private person ONLY in document ServiceNotes and QuoteSales
            if (!this.localError) {
              this.saving = true
              const newPartner = await PartnerService.post(addeditPartner)
              this.saving = false
              this.text = this.lang.addedPartner
              this.color = 'green'
              this.snackbar = true
              this.timeout = 2000
              //await checkPartners(0)
              await newUpdates(2)
              await testLastUpdate()
              if (this.$store.state.partnerStore) {
                this.partners = this.$store.state.partnerStore
              }
              //this.partners = (await PartnerService.index()).data.partners
              this.document.partnerId = newPartner.data.partner.id ? newPartner.data.partner.id : null
              this.selectId = this.document.partnerId ? parseInt(this.document.partnerId) : null
              this.selectPa = this.selectId ? this.partners.find(partner => (partner.id === this.selectId)) : null
              const newHead = {...this.$store.state.documentActivHead, ...this.document}
              if (newHead) {
                this.$store.dispatch('setDocumentActivHead', newHead)
              }
            } else {
              this.text = this.localError
              this.color = 'red'
              this.snackbar = true
            }
            
          } else {
            // not enough data to save partner 
            this.text = 'Error! Partner name is empty. Can not save partner.'
            this.color = 'red'
            this.snackbar = true
            this.timeout = 4000
          } 
        }
        this.saving = false
    },
  },
  watch: {
    'selectPa' (val) {
      if (val) {
        val.id && this.findPartner(val.id)
      }
      if (!val) {
        console.log('Nemam partnera!')
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
