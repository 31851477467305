import { defNewpartner } from './mainledgerHelper'
import store from '../store/store'
import PartnerService from '../services/PartnerService'
import PartnerCompanyService from '../services/PartnerCompanyService'

const addUpdatePartner = async (upData, partnerId) => {

  const addeditPartner = {...defNewpartner()}
  addeditPartner.CompanyId = upData.CompanyId ? upData.CompanyId : ''
  addeditPartner.CompanyNameDatabase = upData.CompanyNameDatabase
  addeditPartner.BusinessYear = upData.BusinessYear
  addeditPartner.UserId = upData.UserId
  addeditPartner.email = upData.email
  addeditPartner.First = upData.First
  addeditPartner.Last = upData.Last
  addeditPartner.partnerName = upData.partnerName
  addeditPartner.partnerNameShort = upData.partnerName.replace(/[\W_]/g, '')
  addeditPartner.partnerNameDatabase = upData.partnerName.replace(/[\W_]/g, '')
  addeditPartner.partnerBuildingNameNumber = upData.partnerBuildingNameNumber
  addeditPartner.partnerStreet = upData.partnerStreet
  addeditPartner.partnerCity = upData.partnerCity
  addeditPartner.partnerPostcode = upData.partnerPostcode
  addeditPartner.partnerCountyRegion = upData.partnerCountyRegion
  addeditPartner.partnerState = upData.partnerState
  addeditPartner.partnerVATID = upData.partnerVATID
  addeditPartner.partneremail = upData.partneremail
  addeditPartner.partnerPhones = upData.partnerPhones
  addeditPartner.partnerBankName = upData.partnerBankName
  addeditPartner.partnerBankAddress = upData.partnerBankAddress
  addeditPartner.partnerBankIBAN = upData.partnerBankIBAN
  addeditPartner.partnerBankSWIFT = upData.partnerBankSWIFT
  addeditPartner.partnerContactTime = upData.partnerContactTime
  addeditPartner.partnerSecretaryTitle = upData.partnerSecretaryTitle
  addeditPartner.partnerSecretaryFirst = upData.partnerSecretaryFirst
  addeditPartner.partnerSecretaryLast = upData.partnerSecretaryLast
  addeditPartner.partnerSecretaryEmail = upData.partnerSecretaryEmail
  addeditPartner.partnerSecretaryPhone = upData.partnerSecretaryPhone
  addeditPartner.partnerSecretaryContactOption = upData.partnerSecretaryContactOption
  addeditPartner.privatePerson = upData.privatePerson

  const newPrivatePartner = {}
  newPrivatePartner.CompanyId = store.state.companyid
  newPrivatePartner.CompanyNameDatabase = store.state.companyName
  newPrivatePartner.BusinessYear = store.state.businessYear
  newPrivatePartner.UserId = store.state.user.id
  newPrivatePartner.email = upData.email ? upData.email : ''
  newPrivatePartner.partnerNameDatabase = upData.partnerName.replace(/[\W_]/g, '')
  newPrivatePartner.partnerVATID = upData.partnerVATID

  let havePartner = {}
  havePartner.inDB = false
  havePartner.partner = false
  const privateP = store.state.user ? store.state.user.privatePartner : true

  if (addeditPartner.id) {
    delete addeditPartner.id
  }

  // old partner just check 
  if (partnerId) {
      addeditPartner.id = partnerId
      const res = await PartnerService.put(addeditPartner)
      havePartner.partner = res && res.data && res.data.partner ? res.data.partner: {}
      newPrivatePartner.partnerId = havePartner.partner.id ? havePartner.partner.id : 0
      newPrivatePartner.partnerNameDatabase = havePartner.partner.partnerName ? havePartner.partner.partnerName.replace(/[\W_]/g, '') : ''
      newPrivatePartner.partnerVATID = havePartner.partner.partnerVATID ? havePartner.partner.partnerVATID : ''
      // imam javnog provjeri jos privatnog
      if (privateP) {
        const statusPrivateP = await checkPrivatePartner(newPrivatePartner)
        if (!statusPrivateP) {
          await postPrivatePartner(newPrivatePartner)
        }
      }
    return havePartner.partner
  }


  // novi partner ili nije odabran partner
  if (!partnerId) {
    const query = {}
    query.CompanyId = store.state.companyid
    query.partnerVATID = addeditPartner.partnerVATID


    // check if partner with these VATID exist in database, whatever if private or not check so partnerVATID can not duplicate
    const res = await PartnerService.check(query)
    if (res && res.data && res.data.partner) {
      havePartner.inDB = true
      console.log('provjeravam da li ga imam OIB havePartner.inDB', havePartner.inDB)
      addeditPartner.id = res.data.partner.id
      havePartner.partner = res.data.partner
      newPrivatePartner.partnerId = havePartner.partner.id ? havePartner.partner.id : 0
      newPrivatePartner.partnerNameDatabase = havePartner.partner.partnerName ? havePartner.partner.partnerName.replace(/[\W_]/g, '') : ''
      newPrivatePartner.partnerVATID = havePartner.partner.partnerVATID ? havePartner.partner.partnerVATID : ''
      // imam javnog provjeri jos privatnog
      if (privateP) {
        const statusPrivateP = await checkPrivatePartner(newPrivatePartner)
        if (!statusPrivateP) {
          await postPrivatePartner(newPrivatePartner)
        }
      }
    }
    // check basic partner failed
    if (havePartner.inDB === false) {
      console.log('Dodajm novog partnera')
      const newPartner = await PartnerService.post(addeditPartner)
      havePartner.inDB = true
      havePartner.partner = newPartner.data.partner
      newPrivatePartner.partnerId = havePartner.partner.id ? havePartner.partner.id : 0
      newPrivatePartner.partnerNameDatabase = havePartner.partner.partnerName ? havePartner.partner.partnerName.replace(/[\W_]/g, '') : ''
      newPrivatePartner.partnerVATID = havePartner.partner.partnerVATID ? havePartner.partner.partnerVATID : ''
      if (privateP) {
        await postPrivatePartner(newPrivatePartner)
      }
    } 
    return havePartner.partner
  }
}

const checkPrivatePartner = async (newPrivatePartner) => {
  try {
    if (newPrivatePartner) {
      const resPP = await PartnerCompanyService.check(newPrivatePartner)
      if (resPP && resPP.data && resPP.data.partnercompanys){
        return true
      }
    }
  }
  catch(err) {
    console.log('err', err)
    // nemam privatnog partnera onda ga zapisi
    await postPrivatePartner(newPrivatePartner)
    return false
  }

}
const postPrivatePartner = async (newPrivatePartner) => {
  console.log('4 Snimam privatnog partnera ', newPrivatePartner)
  if (newPrivatePartner) {
    await PartnerCompanyService.post(newPrivatePartner)
  }
}


export {addUpdatePartner}