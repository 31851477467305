const docEN = {
  BasicData: 'Basic data',
  FinancialData: 'Financial Data',
  CreateNew: ' Create New Document - ',
  CompanyName: 'Company',
  BusinessYear: 'Business Year',
  User: 'User',
  DocumentDate: 'Issue date',
  StartDate: 'Delivery date',
  DeadLine: 'Currency date',
  Partner: 'Partner',
  SelectPartner: 'Select Partner',
  VATID: 'VAT ID:',
  VATIDBuyer: 'VAT buyer',
  PartnerName: 'Partner name *',
  PartnerEmail: 'Partner email',
  PartnerPhones: 'Partner phones',
  PartnerBankName: 'Primary bank',
  PartnerBankIBAN: 'Primary IBAN',
  PartnerSave: 'Check/Save',
  IBAN: 'IBAN',
  Bank: 'Bank',
  BankName: 'Bank name',
  Street: 'Street',
  BuildingNameNumber: 'Nr',
  City: 'City',
  CityName: 'City name or partname',
  County: 'County ',
  Postcode: 'Postcode ',
  ContactPerson: 'Contact',
  SecretaryTitle: 'Title',
  SecretaryFirst: 'First Name',
  SecretaryLast: 'Last Name',
  ContactTime: 'Contact time',
  ContactChannel: 'Contact chanell',
  CompanyEmail: 'Partner email',
  SecretaryEmail: 'Email',
  SecretaryPhone: 'Phone',
  SecretaryContactOption: 'Contact options',
  SecretaryBusinessOccupation: 'Business position',
  BankAccount: 'IBAN',
  Description4: 'Description',
  Description1: 'Note',
  Description5: 'Note',
  Description3: 'Internat note',
  Description4show: 'Show Description',
  Description1show: 'Show Note',
  Description5show: 'Show Note',
  Description3show: 'Show Internal note',
  HomeCurrency: 'Primary currnecy',
  ExchangeRate: 'Ex.rate',
  SecodnaryCurrency: 'Sec.currency',
  DocumentSavedOK: 'New document is saved.',
  DocumentSaveError: 'Error! Cannot save new document.',
  documentId: 'Doc. id:',
  documentDate1: 'Date',
  documentTime: 'Time',
  documentInvoiceEndDate: 'Payment deadline.',
  documentHomeCurrency: 'Home currnecy',
  documentTotalVAT1Amount: 'VAT am.1',
  documentTotalVAT2Amount: 'VAT am.2',
  documentTotalVAT3Amount: 'VAT am.3',
  documentTotalAmountDirectD1: 'Direct duty',
  documentTotalAmountDirectD1Fore: 'Total direct duty',
  documentTotalAmountNetto: 'Am.netto',
  documentTotalAmountVAT: 'VAT amount',
  documentTotalAmountBrutto: 'Am.brutto',
  documentTotalAmountPayInAdvance: 'Pay in advance',
  documentTotalAmountToPay: 'TOTAL TO PAY',
  documentForeignCurrency: 'Fore.Curr.',
  documentTotalAmountNettoForeign: 'Fore.Total netto',
  documentTotalAmountVATForeign: 'Fore.VAT amount',
  documentTotalAmountBruttoForeign: 'Fore Brutto amount',
  documentTotalAmountPayInAdvanceForeign: 'Pay in advance',
  documentTotalAmountToPayForeign: 'FORE AMOUNT TO PAY',
  newdocumentTotalVAT1Amount: 'New total VAT',
  newdocumentTotalAmountDirectD1: 'New total direct duty',
  newdocumentTotalAmountNetto: 'New total netto amount',
  newdocumentTotalAmountVAT: 'New VAT amounut',
  newdocumentTotalAmountBrutto: 'New total brutto amount',
  bankAccountName: 'Bank name',
  email: 'Made by:',
  documentConnName: 'Conn.to',
  documentConnNameFrom: 'Conn.From',
  documentConnId: 'Conn.on Doc Id',
  documentDate: 'Conn on Doc Date',
  TravelOrder: 'Travel Order',
  TravelOrders: 'Travel Orders',
  partnerId : ' ',
  partnerName: 'Partner ',
  partnerNameShort: 'Partner',
  WorkingOrder: 'Work order',
  TravelOrderId : '',
  OrderDate: 'Order Date',
  eId : 'Id',
  eemail: 'email',
  phone: 'Phone',
  eFirst: 'First name',
  eLast: 'Last name',
  eVatID: 'VAT',
  eJOPP: 'Broj JOPPa',
  WorkPlace: 'Workplace',
  TravelTo: 'Travel to',
  TravelDate: 'Travel Date',
  TravelTask: 'Travel Task',
  TravelLastDays: 'Last days',
  TravelLastDaysLett: 'letters ',
  TravelVeichle: 'For transport can use',
  TravelVeichleBrand: 'Brand',
  TravelVeichleReg: 'Registration',
  ExpensesForwardHomeC: 'Advance kn',
  ExpensesForwardForeC: 'Advance EUR',
  ReportInDays: 'Report in days',
  ApprovedBy: 'Approved',
  ApprovedByFirst: 'First name',
  ApprovedByLast: 'Last name',
  ApprovedByemail: 'email',
  TravelApproved: 'Approved',
  TravelCosting: 'Travel costing',
  CostingDays: 'Day costing',
  FinTravel1: 'For travel to',
  FinTravel2: 'Start travel',
  Travel2Date: 'Start date',
  FinTravel3: 'End travel ',
  Travel3Date: 'End date',
  FinStartDate: 'Departure date ',
  FinStartTime: 'Departure time',
  FinEndDate: 'Arrival date ',
  FinEndTime: 'Arrival time',
  FinHours: 'Nr.hours',
  FinDays: 'Nr.days',
  FinDaysAmount: 'Wage',
  FinDaysTotal: 'Wage amount',
  CostingTravles: 'Travel cost',
  FinTrStart: 'Counter start',
  FinTrEnd: 'Counter end',
  CostByKm: 'Cost/km',
  Relation: 'Relation',
  FinRel1From: 'From',
  FinRel1To: 'To',
  FinRel1Km: 'km',
  FinRel1KmKn: 'kn/km',
  FinRel1KmEur: 'EUR/km',
  FinRel1Total: 'kn',
  FinRel1TotalEur: 'EUR',
  FinRel2From: 'From',
  FinRel2To: 'To',
  FinRel2Km: 'km',
  FinRel2KmKn: 'kn/km',
  FinRel2KmEur: 'EUR/km',
  FinRel2Total: 'kn',
  FinRel2TotalEur: 'EUR',
  FinRel3From: 'From',
  FinRel3To: 'To',
  FinRel3Km: 'km',
  FinRel3KmKn: 'kn/km',
  FinRel3KmEur: 'EUR/km',
  FinRel3Total: 'kn',
  FinRel3TotalEur: 'EUR',
  FinRel4From: 'From',
  FinRel4To: 'To',
  FinRel4Km: 'km',
  FinRel4KmKn: 'kn/km',
  FinRel4KmEur: 'EUR/km',
  FinRel4Total: 'kn',
  FinRel4TotalEur: 'EUR',
  FinRel5From: 'From',
  FinRel5To: 'To',
  FinRel5Km: 'km',
  FinRel5KmKn: 'kn/km',
  FinRel5KmEur: 'EUR/km',
  FinRel5Total: 'kn',
  FinRel5TotalEur: 'EUR',
  OtherExp: 'Other expenses',
  FinOther1: 'Ostalo',
  FinOther1Kn: 'kn',
  FinOther1Eur: 'EUR',
  FinOther2: 'Ostalo',
  FinOther2Kn: 'kn',
  FinOther2Eur: 'EUR',
  FinOther3: 'Ostalo',
  FinOther3Kn: 'kn',
  FinOther3Eur: 'EUR',
  FinOther4: 'Ostalo',
  FinOther4Kn: 'kn',
  FinOther4Eur: 'EUR',
  FinOther5: 'Ostalo',
  FinOther5Kn: 'kn',
  FinOther5Eur: 'EUR',
  FinTotal: 'Ukupno',
  FinTotalKn: 'kn',
  FinTotalEur: 'EUR',
  FinPayReturn: 'Za isplatu/povrat',
  FinPayReturnKn: 'kn',
  FinPayReturnEur: 'EUR',
  FinReport: 'Izvješće sa službenog putovanja',
  PrintMadeBySmall: 'Podnositelj',
  PrintControlBy: 'Kontrolirao:',
  PrintCFO: 'Nalogodavac:',
  Document: 'Document',
  documentTypes: 'Document types',
  documentType: 'Document type',
  documentColor: 'Color type',
  docStatus: 'Type',
  documentTypesHeader: 'Types',
  OpenStatement: 'Open',
  exportDoc: 'Export',
  PPO: 'NO VAT',
  createSerials: 'Create Serials',
  serialsCreatedOK: 'serials Created OK',
  ProfitCard: 'Profit Card',
  itemUpdated: 'Document item is updated.',
  itemAdded: 'Document item has added',
  itemFullPriceOriginal: 'Orig.pr.with VAT',
  showSerials: 'showSerials',
  Inventory: 'Inventory',
  MainLedgerProjectType: 'Project',
  MainLedgerProjectTypes: 'Projects',
  MainLedgerProjectTypeName: 'Project Name',
  MainLedgerProjectTypeEmpty: 'No projects',
  UpdateItemName: 'Update name',
  UpdateItemNameUpdated: 'Name is update in database',
  UpdateItemNameUpdatedError: 'Error!! Name is NOT updated',
  returnPOS : 'Return to POS',
  TotalDiscount: 'Discount',
  TotalFullDiscount: 'Total discount',
  TotalServices: 'Services',
  TotalFullServices: 'Total services',
  ServiceDoneBy: 'Service done by',
  AddZero: 'Show and 0',
  OnlyZero: 'Show only 0',
  GroupsTran: 'Groups tran',
  diffDD: 'Diff.',
  diffNeto: 'Diff Netto',
  diffVAT: 'Diff VAT',
  diffBrutto: 'DIff Brutto',
  pricechangeCreatedOK: 'Price Change doc is made.',
  TotalDiff: 'Margin on items',
  Service: 'Service',
  DocPrintPlace: 'Place:',
  DocPrintOrder: 'Nr.',
  DocPrintName: 'Name',
  DocPrintUnit: 'Unit',
  DocPrintPcs: 'Peaces',
  DocPrintPcsSmall: 'Pcs.',
  DocPrintPrice: 'Price',
  DocPrintAmount: 'Amount',
  DocPrintDiscount: 'Disc.%',
  DocPrintDiscountSmall: 'Dis.%',
  DocPrintAmount2: 'Amonut without VAT',
  DocPrintVAT: 'VAT%',
  DocPrintAmount3: 'Amount with VAT',
  DocPrintAmount3Small: 'Am.with VAT',
  DocPrintVAT1: 'VAT 25%:',
  DocPrintVAT2: 'VAT 13%:',
  DocPrintVAT3: 'VAT 5%:',
  DocPrintVAT4: 'No tax:',
  DocPrintBase: 'Base am.:',
  DocPrintTotal00: 'Amount ',
  DocPrintTotal01: 'Discount amount',
  DocPrintTotal1: 'Amount without VAT',
  DocPrintTotal2: 'VAT amount',
  DocPrintTotal3: 'Amount with VAT ',
  DocPrintTotal1Small: 'Base',
  DocPrintTotal2Small: 'VAT',
  DocPrintTotal3Small: 'Total',
  DocPrintTotal4: 'Pay in advance',
  DocPrintTotal5: 'AMOUNT TO PAY',
  DocPrintPayType: 'Payment type: bank account',
  DocPrintPayTypeSmall1: 'Payment type: cash',
  DocPrintPayTypeSmall2: 'Payment type: card',
  DocPrintIdUser: 'Made by: ',
  DocPrintPayUseNr: 'Bank call: 02',
  DocPrintComment: 'Comment:',
  DocPrintMadeBy: 'Made by:',
  DocPrintMadeBySmall: 'Made by:',
  DocPrintControlBy: 'Controlled by:',
  DocPrintCFO: 'CEO:',
  DocPrintPOS: 'Invoice',
  DocPrintDocumentDate: 'Date,time',
  DocPrintRetail: 'Branch:',
  DocPrintZKI: 'Proc.code',
  DocPrintJIR: 'ID',
  DocPrintoldPrice: 'Old Price',
  DocPrintnewPrice: 'New Price',
  DocPrintoldAmount: 'Old Amount',
  DocPrintnewAmount: 'New Amount',
  DocPrintoldVAT: 'VAT%',
  DocPrintnewVAT: 'VAT%',
  DocPrintAmountoldVAT: 'Old VAT am.',
  DocPrintAmountnewVAT: 'New VAT am.',
  DocPrintoldPriceVAT: 'Old Full Price',
  DocPrintnewPriceVAT: 'New Full Price',
  DocPrintoldTotal: 'Old total',
  DocPrintnewTotal: 'New total',
  UCDnumber: 'UCD nr',
  UCDdate: 'UCD date',
  importCurrency: 'Currency',
  importAmount: 'Import amount',
  exRate: 'Ex.rate',
  domecilAmount: 'Home amount',
  customAmount: 'Custom',
  bankExp: 'Bank exp.',
  forwarderExp: 'Forw.exp.',
  transportExp: 'Trasport exp.',
  otherExp: 'Other exp.',
  totalExp: 'Total exp.',
  expenesPercent: 'Exp percent%',
  itemSuppBaseForePrice: 'Price orig.',
  itemSuppBasePercentageExp: 'Exp.%',
  itemDependablesAmount: 'Exp.amount',
  GroupName: 'Group Name',
  FreeF3: 'In pack',
  FreeF4: 'Packages',
  FreeF4WO: 'Production NO VAT',
  PrintCustom: 'Custom',
  addDescription: 'Add Description',
  printItemID: 'Print ID',
  PcsHome: 'Pcs dom.',
  PcsExport: 'Pcs Export',
  SuppAmountHome: 'Supp.am.dom.',
  NetoSalesAmountHome: 'Neto sales dom.',
  SuppAmountExport: 'Supp.am.exp.',
  NetoSalesAmountExport: 'Neto sales exp.',
  ProfitHome: 'Profit.dom.',
  ProfitExport: 'Profit.Export',
  ProfitTotal: 'Profit.Total',
  ruc: 'Profit',
  suppPrice: 'Supp.Price',
  wrongBussinesYear: 'Document year is on wrong business year!!',
  errorSuppAvgPrice: 'Error in average supplier price',
  errorWorkingOrder: 'Error in working order, finished production',
  PartnerErrorName: 'ERROR!! Partner name is not entered!',
  PartnerErrorVAT3: 'Number of letters in VATID is not regular!',
  lastPurchase: 'Last Purchase',
  showItemCardd: 'Details',
  noData: 'No data ',
  noDataList: 'No data for list ',
  DigitalAr: 'Digital Archive',
  VehiclesShow: 'Show Vehicles',
  VehicleNew: 'New vehicle',
  VehicleEdit: 'Edit vehicle',
  dontHaveVehicle: 'Do not have vehicle',
  vehicleDesc: 'Vehicle',
  vehicleHands: 'Labor amount',
  vehicleParts: 'Part amount',
  vehicleTotalService: 'Total amount',
  searchEAN: 'Serch by EAN',
  itemNotExist: 'Error! Item Not Exist',
  noTotal: 'NO total',
  forceNewPrintPage: 'Print on new page',
  createInv:'Create Inv',
  createInv2:'Create invoices from contract',
  validContract: 'Cont.is on'
}

export default docEN