import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('betonobjekat', {
      params: query 
    })
  },
  show (betonobjekatId) {
    return Api().get(`betonobjekat/${betonobjekatId}`)
  },
  post (newbetonobjekat) {
    return Api().post('betonobjekat', newbetonobjekat)
  },
  put (betonobjekat) {
    return Api().put(`betonobjekat/${betonobjekat.id}`, betonobjekat)
  },
  delete (betonobjekatId) {
    return Api().delete(`betonobjekat/delete/${betonobjekatId}`)
  }
}