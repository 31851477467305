import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Crm from '@/components/crm'
import Home from '@/components/Home'
import CompIndex from '@/components/Companies/CompIndex'
import CompCreate from '@/components/Companies/CompCreate'
import CompCreateUser from '@/components/Companies/CompCreateUser'
import CompDelete from '@/components/Companies/CompDelete'
import CompEdit from '@/components/Companies/CompEdit'
import CompView from '@/components/Companies/CompView'
import CompSelect from '@/components/Companies/CompSelect'
import LanguageSelect from '@/components/Companies/LanguageSelect'
import CompUserCRUD from '@/components/Companies/CompUserCRUD'
import CompBusinessYearCRUD from '@/components/Companies/CompBusinessYearCRUD'
import CompBusinessYearSelect from '@/components/Companies/CompBusinessYearSelect'
import CalendarCRUD from '@/components/Calendar/CalendarCRUD'
import UserCRUD from '@/components/Users/UserCRUD'
import UserRegister from '@/components/Users/UserRegister'

import GroupIndex from '@/components/Group/GroupIndex'
import GroupCreate from '@/components/Group/GroupCreate'
import GroupDelete from '@/components/Group/GroupDelete'
import GroupEdit from '@/components/Group/GroupEdit'
import GroupView from '@/components/Group/GroupView'
import ItemIndex from '@/components/Item/ItemIndex'
import ItemCreate from '@/components/Item/ItemCreate'
import ItemDelete from '@/components/Item/ItemDelete'
import ItemEdit from '@/components/Item/ItemEdit'
import ItemView from '@/components/Item/ItemView'
import PartnerIndex from '@/components/Partner/PartnerIndex'
import PartnerCreate from '@/components/Partner/PartnerCreate'
import PartnerCreate2 from '@/components/Partner/PartnerCreate2'
import PartnerDelete from '@/components/Partner/PartnerDelete'
import PartnerView from '@/components/Partner/PartnerView'
import WareHouseMarkCRUD from '@/components/WareHouse/WareHouseMarkCRUD'
import WareHouseUserCRUD from '@/components/WareHouse/WareHouseUserCRUD'
import TaskIndex from '@/components/Task/TaskIndex'
import TaskCreate from '@/components/Task/TaskCreate'
import TaskCreateEmployee from '@/components/Task/TaskCreateEmployee'
import TaskDelete from '@/components/Task/TaskDelete'
import TaskAnalysis from '@/components/Task/TaskAnalysis'
import TaskTypeCRUD from '@/components/Task/TaskTypeCRUD'
import TaskDepartmentCRUD from '@/components/Task/TaskDepartmentCRUD'
import BankAccountIndex from '@/components/BankAccount/BankAccountIndex'
import BankAccountCreateEdit from '@/components/BankAccount/BankAccountCreateEdit'
import BankAccountDelete from '@/components/BankAccount/BankAccountDelete'
import BankAccountView from '@/components/BankAccount/BankAccountView'
import DocumentIndex from '@/components/Document/DocumentIndex'
import DocumentCreate from '@/components/Document/DocumentCreate'
import DocumentCreateService from '@/components/Document/DocumentCreateService'
import DocumentCopy from '@/components/Document/DocumentCopy'
import DocumentDelete from '@/components/Document/DocumentDelete'
import DocumentView from '@/components/Document/DocumentView'
import DocumentView3 from '@/components/Document/DocumentView3'
import DocumentPrint from '@/components/Document/DocumentPrint3'
import DocumentPrint4 from '@/components/Document/DocumentPrint4'
import DocumentPrint5 from '@/components/Document/DocumentPrint5'
import DocumentPdf from '@/components/Document/DocumentPdf'
import DocumentDetailCreate from '@/components/Document/DocumentDetailCreate'
import DocumentDetailCreate2 from '@/components/Document/DocumentDetailCreate2'
import DocumentDetailCreateEdit3 from '@/components/Document/DocumentDetailCreateEdit3'
import DocumentDetailUpload from '@/components/Document/DocumentDetailUpload'
import DocumentPOS from '@/components/Document/DocumentPOS'
import DocumentPOSAgain from '@/components/Document/DocumentPOSAgain'
import DocumentPOSList from '@/components/Document/DocumentPOSList'
import DocumentPurchaseAnalysis from '@/components/Document/DocumentPurchaseAnalysis'
import DocumentSalesAnalysis from '@/components/Document/DocumentSalesAnalysis'
import DocumentProductionIndex from '@/components/Document/DocumentProductionIndex'
import DocumentStatusCRUD from '@/components/Document/DocumentStatusCRUD'
import SerialsIndex from '@/components/Serials/SerialsIndex'
import CardTypeIndex from '@/components/CardType/CardTypeIndex'
import BankOrderIndex from '@/components/BankOrder/BankOrderIndex'
import CashDeskIndex from '@/components/CashDesk/CashDeskIndex'
import POSobjekatIndex from '@/components/POS/POSobjekatIndex'
import AppSettings from '@/components/AppSettings'
import AppInProgress from '@/components/AppInProgress'

Vue.use(Router)

export default new Router({
  routes: [{
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/crm',
      name: 'crm',
      component: Crm,
      children: [{
          path: 'home',
          name: 'home',
          component: Home
        },
        {
          path: 'user',
          name: 'user',
          component: UserCRUD
        },
        {
          path: 'calendar',
          name: 'calendar',
          component: CalendarCRUD
        },
        {
          path: 'user/register',
          name: 'userregister',
          component: UserRegister
        },
        {
          path: 'company',
          name: 'company',
          component: CompIndex
        },
        {
          path: 'company/new',
          name: 'companycreate',
          component: CompCreate
        },
        {
          path: 'company/user',
          name: 'companycreateuser',
          component: CompCreateUser
        },
        {
          path: 'company/delete',
          name: 'companydelete',
          component: CompDelete
        },
        {
          path: 'company/view',
          name: 'companyview',
          component: CompView
        },
        {
          path: 'company/edit',
          name: 'companyedit',
          component: CompEdit
        },
        {
          path: 'company/select',
          name: 'companyselect',
          component: CompSelect
        },
        {
          path: 'company/langselect',
          name: 'languageselect',
          component: LanguageSelect
        },
        {
          path: 'companyuser',
          name: 'companyuser',
          component: CompUserCRUD
        },
        {
          path: 'companybusinessyear',
          name: 'companybusinessyear',
          component: CompBusinessYearCRUD
        },
        {
          path: 'company/companybusinessyearselect',
          name: 'companybusinessyearselect',
          component: CompBusinessYearSelect
        },
        {
          path: 'cardtype',
          name: 'cardtype',
          component: CardTypeIndex
        },
        {
          path: 'bankorder',
          name: 'bankorder',
          component: BankOrderIndex
        },

        {
          path: 'group',
          name: 'group',
          component: GroupIndex
        },
        {
          path: 'group/new',
          name: 'groupcreate',
          component: GroupCreate
        },
        {
          path: 'group/delete',
          name: 'groupdelete',
          component: GroupDelete
        },
        {
          path: 'group/view',
          name: 'groupview',
          component: GroupView
        },
        {
          path: 'group/edit',
          name: 'groupedit',
          component: GroupEdit
        },
        {
          path: 'item',
          name: 'item',
          component: ItemIndex
        },
        {
          path: 'item/new',
          name: 'itemcreate',
          component: ItemCreate
        },
        {
          path: 'item/delete',
          name: 'itemdelete',
          component: ItemDelete
        },
        {
          path: 'item/view',
          name: 'itemview',
          component: ItemView
        },
        {
          path: 'item/edit',
          name: 'itemedit',
          component: ItemEdit
        },
        {
          path: 'partner',
          name: 'partner',
          component: PartnerIndex
        },
        {
          path: 'partner/new',
          name: 'partnercreate',
          component: PartnerCreate
        },
        {
          path: 'partner/new2',
          name: 'partnercreate2',
          component: PartnerCreate2
        },
        {
          path: 'partner/delete',
          name: 'partnerdelete',
          component: PartnerDelete
        },
        {
          path: 'partner/view',
          name: 'partnerview',
          component: PartnerView
        },
        {
          path: 'warehousemark',
          name: 'warehousemark',
          component: WareHouseMarkCRUD
        },
        {
          path: 'warehouseuser',
          name: 'warehouseuser',
          component: WareHouseUserCRUD
        },
        {
          path: 'task',
          name: 'task',
          component: TaskIndex
        },
        {
          path: 'task/new',
          name: 'taskcreate',
          component: TaskCreate
        },
        {
          path: 'task/newemployee',
          name: 'taskcreateemployee',
          component: TaskCreateEmployee
        },
        {
          path: 'task/delete',
          name: 'taskdelete',
          component: TaskDelete
        },
        {
          path: 'task/analysis',
          name: 'taskanalysis',
          component: TaskAnalysis
        },
        {
          path: 'tasktype',
          name: 'tasktype',
          component: TaskTypeCRUD
        },
        {
          path: 'taskdepartment',
          name: 'taskdepartment',
          component: TaskDepartmentCRUD
        },
        {
          path: 'bankaccount',
          name: 'bankaccount',
          component: BankAccountIndex
        },
        {
          path: 'bankaccount/new',
          name: 'bankaccountcreateedit',
          component: BankAccountCreateEdit
        },
        {
          path: 'bankaccount/delete',
          name: 'bankaccountdelete',
          component: BankAccountDelete
        },
        {
          path: 'bankaccount/view',
          name: 'bankaccountview',
          component: BankAccountView
        },
        {
          path: 'document',
          name: 'document',
          component: DocumentIndex
        },
        {
          path: 'document/new',
          name: 'documentcreate',
          component: DocumentCreate
        },
        {
          path: 'document/newservicevehicle',
          name: 'documentcreateservice',
          component: DocumentCreateService
        },
        {
          path: 'document/copy',
          name: 'documentcopy',
          component: DocumentCopy
        },
        {
          path: 'document/delete',
          name: 'documentdelete',
          component: DocumentDelete
        },
        {
          path: 'document/view',
          name: 'documentview',
          component: DocumentView
        },
        {
          path: 'document/view3',
          name: 'documentview3',
          component: DocumentView3
        },
        {
          path: 'document/print',
          name: 'documentprint',
          component: DocumentPrint
        },
        {
          path: 'document/print4',
          name: 'documentprint4',
          component: DocumentPrint4
        },
        {
          path: 'document/print5',
          name: 'documentprint5',
          component: DocumentPrint5
        },
        {
          path: 'document/purchaseanalysis',
          name: 'documentpurchaseanalysis',
          component: DocumentPurchaseAnalysis
        },
        {
          path: 'document/salesanalysis',
          name: 'documentsalesanalysis',
          component: DocumentSalesAnalysis
        },
        {
          path: 'document/pdf',
          name: 'documentpdf',
          component: DocumentPdf
        },
        {
          path: 'document/production',
          name: 'documentproduction',
          component: DocumentProductionIndex
        },
        {
          path: 'document/pos',
          name: 'documentpos',
          component: DocumentPOS
        },
        {
          path: 'document/poslist',
          name: 'documentposlist',
          component: DocumentPOSList
        },
        {
          path: 'document/posagain',
          name: 'documentposagain',
          component: DocumentPOSAgain
        },
        {
          path: 'documentstatus',
          name: 'documentstatus',
          component: DocumentStatusCRUD
        },
        {
          path: 'documentdetail/new',
          name: 'documentdetailcreate',
          component: DocumentDetailCreate
        },
        {
          path: 'documentdetail/new2',
          name: 'documentdetailcreate2',
          component: DocumentDetailCreate2
        },
        {
          path: 'documentdetail/new3',
          name: 'documentdetailcreateedit3',
          component: DocumentDetailCreateEdit3
        },
        {
          path: 'documentdetail/upload',
          name: 'documentdetailupload',
          component: DocumentDetailUpload
        },

        {
          path: 'serials',
          name: 'serials',
          component: SerialsIndex
        },
        {
          path: 'cashdesk',
          name: 'cashdesk',
          component: CashDeskIndex
        },
        {
          path: 'posobjekatindex',
          name: 'posobjekatindex',
          component: POSobjekatIndex
        },

        {
          path: 'appsettings',
          name: 'appsettings',
          component: AppSettings
        },
        {
          path: 'appinprogress',
          name: 'appinprogress',
          component: AppInProgress
        }
      ]
    }
  ]
})