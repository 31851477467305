import Api from '@/services/Api'

export default {
  index (query) {
    return Api().get('pos/showall', {
      params: query
    })
  },
  indexlist(search) {
    return Api().get('pos', {
      params: search
    })
  },

  choose (query) {
    return Api().get('pos/choose', {
      params: query
    })
  },
  show (id) {
    return Api().get(`pos/${id}`)
  },
  post (newpos) {
    return Api().post('pos', newpos)
  },
  put (pos) {
    return Api().put(`pos/${pos.id}`, pos)
  },
  delete (id) {
    return Api().delete(`pos/delete/${id}`)
  },
  saveitems (newitems) {
    return Api().post('pos/saveitems', newitems)
  },
}