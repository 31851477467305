<template>
    <div class="blue-grey darken-2 rounded">
      <v-row>
        <v-col cols="7">
          <span class="white--text text-h5 ml-2">Ukupno - sve kase:</span>
        </v-col>
        <v-col cols="5" class="text-right">
          <span class="white--text text-h5  mr-2">{{localMoney(total)}} €</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <span class="white--text text-h6 ml-2">Za period: {{localDate(odDatuma)}} - {{localDate(doDatuma)}}</span>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import dateFormat from '@/snippets/dateFormat'
import moneyFormat from '@/snippets/moneyFormat'


export default {
  name: 'postotal',
  props:['total', 'odDatuma', 'doDatuma'],
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      saving: false,
      postNew: false
    }
  },
  mounted () {

  },
  methods: {
    localMoney(moneyToFormat) {
      return moneyFormat(moneyToFormat)
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },

  },
  computed: {

  },
  watch: {

  },
  components: {

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
