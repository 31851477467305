import store from '@/store/store'
import dayjs from 'dayjs'
import PartnerService from '@/services/PartnerService'

const checkPartners = async (refreshIt) => {
    const mainQuery = {}
    let haveItInStore = 0
    let haveItInDB = 0
    mainQuery.CompanyId = store.state.companyid ? store.state.companyid : 0
    mainQuery.noPageing = 0
    const privateP = "privatePartner" in store.state.user ? store.state.user.privatePartner : true
    // 0 chech it, 1 force refresh it
    if (refreshIt === 0) {
      const response = privateP ? (await PartnerService.index2total(mainQuery)) : (await PartnerService.indextotal(mainQuery))
      if (response && response.data && response.data.total) {
        haveItInDB = response.data.total
      }
      if (store.state.partnerStore) {
        haveItInStore = store.state.partnerStore.length
      }
      if (haveItInDB !== haveItInStore) {
        refreshIt = 1
      }
    }

    if (refreshIt === 1) {
      const {data} = privateP ? (await PartnerService.index2(mainQuery)) : (await PartnerService.index(mainQuery))
      if (data && data.partners && data.partners.rows.length > 0) {
        store.dispatch("setPartners", data.partners.rows)
      } else {
        store.dispatch("setPartners", [])
      }   
    }
}

const findPartnerVATID = async (partnerVATID) => {
  const mainQuery = {}
  let partner = {}
  mainQuery.CompanyId = store.state.companyid ? store.state.companyid : 0
  mainQuery.partnerVATID = partnerVATID
    const response = await PartnerService.check(mainQuery) 
    if (response && response.data && response.data.partner) {
      partner = response.data.partner
      return partner
    } else {
      return partner
    }
}

const checkBooks = async(show) => {
  const bookTypeSelected = {}
  if (!store.state.bookTypeSelected ) {
    if (show === 1) {
      bookTypeSelected.text = 'URA_TU'
      bookTypeSelected.value = 1
    }
    if (show === 2) {
      bookTypeSelected.text = 'IRA_TU'
      bookTypeSelected.value = 10
    }
    if (show === 3) {
      bookTypeSelected.text = 'TEM'
      bookTypeSelected.value = 20
    }
    if (show === 4) {
      bookTypeSelected.text = 'BS'
      bookTypeSelected.value = 100
    }
    await store.dispatch('setbookTypeSelected', bookTypeSelected)
  }
   else {
    if (show === 1 || show === 2) {
      if (store.state.bookTypeSelected.value > 19 ) {
        if (show === 1) {
          bookTypeSelected.text = 'URA_TU'
          bookTypeSelected.value = 1
        }
        if (show === 2) {
          bookTypeSelected.text = 'IRA_TU'
          bookTypeSelected.value = 10
        }
        await store.dispatch('setbookTypeSelected', bookTypeSelected)
      }
    }
    if (show === 3) {
      if (store.state.bookTypeSelected.value < 19 || store.state.bookTypeSelected.value > 30  ) {
        bookTypeSelected.text = 'TEM'
        bookTypeSelected.value = 20
        await store.dispatch('setbookTypeSelected', bookTypeSelected)
      }
    }
    if (show === 4) {
      bookTypeSelected.text = 'BS'
      bookTypeSelected.value = 100
      await store.dispatch('setbookTypeSelected', bookTypeSelected)
    }

    if (store.state.bookTypeSelected.value > 19 ) {
      if (show === 1) {
        bookTypeSelected.text = 'URA_TU'
        bookTypeSelected.value = 1
      }
      if (show === 2) {
        bookTypeSelected.text = 'IRA_TU'
        bookTypeSelected.value = 10
      }
      await store.dispatch('setbookTypeSelected', bookTypeSelected)
    } else {
      if (show === 1 && store.state.bookTypeSelected.value > 9) {
        bookTypeSelected.text = 'URA_TU'
        bookTypeSelected.value = 1
        store.dispatch('setbookTypeSelected', bookTypeSelected)

      }
      if (show === 2 && store.state.bookTypeSelected.value < 10) {
        bookTypeSelected.text = 'IRA_TU'
        bookTypeSelected.value = 10
        store.dispatch('setbookTypeSelected', bookTypeSelected)
      }
    }
  }
}

const defNew = () => {
  const defaultMainLedger = {}
  defaultMainLedger.UserId = store.state.user.id ? store.state.user.id : ''
  defaultMainLedger.email = store.state.user.email ? store.state.user.email : ''
  defaultMainLedger.First = store.state.user.First ? store.state.user.First : ''
  defaultMainLedger.Last = store.state.user.Last ? store.state.user.Last : ''
  defaultMainLedger.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultMainLedger.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultMainLedger.BusinessYear = store.state.businessYear ? store.state.businessYear : 2021
  defaultMainLedger.documentName = store.state.documentActiv ? store.state.documentActiv : ''
  defaultMainLedger.MLType = store.state.documentSide ? store.state.documentSide : 1
  if (store.state.bookTypeSelected) {
    defaultMainLedger.BookTypeID = store.state.bookTypeSelected.value
    defaultMainLedger.BookName = store.state.bookTypeSelected.text
  }
  if (defaultMainLedger.MLType === 7) {
    const typeS = store.state.booktype.filter(book => { return book.text === 'OS'})
    defaultMainLedger.BookID = 7
    defaultMainLedger.BookTypeID = parseInt(typeS[0].value)
    defaultMainLedger.BookName = typeS[0].text
  }
  if (defaultMainLedger.MLType === 8) {
    const typeS = store.state.booktype.filter(book => { return book.text === 'Sitni'})
    defaultMainLedger.BookID = 8
    defaultMainLedger.BookTypeID = parseInt(typeS[0].value)
    defaultMainLedger.BookName = typeS[0].text
  }
  let locDate1 = new Date()
  locDate1.getDate()
  locDate1.getMonth() + 1
  locDate1.getFullYear()
  defaultMainLedger.BookingDate = dayjs(new Date()).format('YYYY-MM-DD')
  defaultMainLedger.InvoiceDate = dayjs(new Date()).format('YYYY-MM-DD')
  defaultMainLedger.InvoicePaymentDeadlineDate = dayjs(new Date()).add(15, 'days').format('YYYY-MM-DD')
  if (store.state.businessYear <= 2022) {
    defaultMainLedger.HomeCurrency = 'Kn'
    defaultMainLedger.HomeCurrencyPrimary = true
    defaultMainLedger.ForeignCurrency = 'EUR'
    defaultMainLedger.ExRate = Number('7.5345')
  }
  if (store.state.businessYear >= 2023) {
    defaultMainLedger.HomeCurrency = 'EUR'
    defaultMainLedger.HomeCurrencyPrimary = true
    defaultMainLedger.ForeignCurrency = ''
    defaultMainLedger.ExRate = Number('1')
  }
  defaultMainLedger.ForeignCurrencyPrimary = false

  return defaultMainLedger
}

const defMainQuery = () => {
  const mainQuery = {}
  mainQuery.CompanyId = store.state.companyid ? store.state.companyid : 1
  mainQuery.BusinessYear = store.state.businessYear ? store.state.businessYear : 2021
  mainQuery.documentName = store.state.documentActiv ? store.state.documentActiv : ''
  mainQuery.MLType = store.state.documentSide ? store.state.documentSide : 1
  mainQuery.bookTypeSelected = null
  if (store.state.bookTypeSelected) {
    mainQuery.BookTypeID = store.state.bookTypeSelected.value ? store.state.bookTypeSelected.value : ''
  }
  mainQuery.MainLedgerName = store.state.MainLedgerActiv ? store.state.MainLedgerActiv : ''
  mainQuery.MainLedgerNameLoc = store.state.MainLedgerActivLoc ? store.state.MainLedgerActivLoc : ''
  return mainQuery
}

const defMainLedgerDetail = () => {
  const defObj = {}
  defObj.databaseMLID = 0
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.documentName = null
  defObj.documentIdGeneral = null
  defObj.UserId = 0
  defObj.EmployeeId = 0
  defObj.email = false
  defObj.First = false
  defObj.Last = false
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.account1 = ''
  defObj.account1id = 0
  defObj.account2 = ''
  defObj.account2id = 0
  defObj.account3 = ''
  defObj.account3id = 0
  defObj.account4 = ''
  defObj.account4Description = ''
  defObj.account4Description2 = ''
  defObj.Description = ''
  defObj.Debit = 0
  defObj.Credit = 0
  defObj.DebitForegin = 0
  defObj.CreditForegin = 0
  defObj.ClosedAccount = false
  defObj.partnerId = ''
  defObj.partnerName = ''
  defObj.partnerVATID = ''
  defObj.partnerIBAN = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partneremail = ''
  defObj.BookID = 0
  defObj.BookTypeID = 0
  defObj.BookName = ''
  defObj.Status = ''
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = ''
  defObj.UpdateDate = null
  defObj.UpdateTime = null
  defObj.UpdateBy = ''
  defObj.invoiceInID = 0
  defObj.invoiceOutID = 0
  defObj.invoiceInNumber = 0
  defObj.invoiceOutNumber = 0
  defObj.BusinessYearInvoice = 0
  defObj.InvoiceNumber = ''
  defObj.InvoiceDate = null
  defObj.InvoicePaymentDeadlineDate = null
  defObj.BookingDate = null
  defObj.InvoiceAmount = 0
  defObj.InvoiceForeginAmount = 0
  if (store.state.businessYear <= 2022) {
    defObj.HomeCurrency = 'Kn'
    defObj.HomeCurrencyPrimary = true
    defObj.ForeignCurrency = 'EUR'
  }
  if (store.state.businessYear >= 2023) {
    defObj.HomeCurrency = 'EUR'
    defObj.HomeCurrencyPrimary = true
    defObj.ForeignCurrency = ''
  }
  defObj.ForeignCurrencyPrimary = false
  defObj.ExRate = 7.5345
  defObj.WareHouseId = 0
  defObj.ConnName = ''
  defObj.ConnId = 0
  defObj.VATPer = 0
  defObj.VATBase = 0
  defObj.VATAmount = 0
  defObj.VATPerCanUse = 0
  defObj.VATCanUse = 0
  defObj.VATCanTUse = 0
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.MLdFree1 = ''
  defObj.MLdFree2 = ''
  defObj.MLdFree3 = ''
  defObj.MLdFree4 = ''
  defObj.MLdFree5 = ''
  defObj.MLdBool1 = false
  defObj.MLdBool2 = false
  defObj.MLdBool3 = false
  defObj.MLdBool4 = false
  defObj.MLdBool5 = false
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  defObj.TypeID = 0
  defObj.TypeName = ''
  return defObj
}

const defMainLedgerDetailFill = () => {
  const defObj = {}
  if (store.state.mainledgerActiv) {

    defObj.databaseMLID = store.state.mainledgerActiv.id ? store.state.mainledgerActiv.id : 0
    defObj.CompanyId = store.state.mainledgerActiv.CompanyId ? store.state.mainledgerActiv.CompanyId : (store.state.companyid ? store.state.companyid : 1)
    defObj.CompanyNameDatabase = store.state.mainledgerActiv.CompanyNameDatabase ? store.state.mainledgerActiv.CompanyNameDatabase : (store.state.companyName ? store.state.companyName : '')
    defObj.BusinessYear = store.state.mainledgerActiv.businessYear ? store.state.mainledgerActiv.businessYear : (store.state.businessYear ? store.state.businessYear : 2021)
    defObj.documentName = store.state.mainledgerActiv.documentName ? store.state.mainledgerActiv.documentName : ''
    defObj.documentIdGeneral = store.state.mainledgerActiv.documentIdGeneral
    defObj.UserId = store.state.mainledgerActiv.UserId ? store.state.mainledgerActiv.UserId : 0
    defObj.email = store.state.mainledgerActiv.email ? store.state.mainledgerActiv.email : ''
    defObj.First = store.state.mainledgerActiv.First ? store.state.mainledgerActiv.First : ''
    defObj.Last = store.state.mainledgerActiv.Last ? store.state.mainledgerActiv.Last : ''
    defObj.MLType = store.state.mainledgerActiv.documentSide ? store.state.mainledgerActiv.documentSide : 1
    defObj.BookID = store.state.mainledgerActiv.BookID ? store.state.mainledgerActiv.BookID : 0
    defObj.BookTypeID = store.state.mainledgerActiv.BookTypeID ? store.state.mainledgerActiv.BookTypeID : 0
    defObj.BookName = store.state.mainledgerActiv.BookName ? store.state.mainledgerActiv.BookName : ''
    defObj.BookIdGeneral = store.state.mainledgerActiv.BookIdGeneral ? store.state.mainledgerActiv.BookIdGeneral : ''
  
    defObj.InvoiceNumber = store.state.mainledgerActiv.InvoiceNumber ? store.state.mainledgerActiv.InvoiceNumber : ''
    defObj.InvoiceDate = store.state.mainledgerActiv.InvoiceDate ? store.state.mainledgerActiv.InvoiceDate : null
    defObj.InvoicePaymentDeadlineDate = store.state.mainledgerActiv.InvoicePaymentDeadlineDate ? store.state.mainledgerActiv.InvoicePaymentDeadlineDate : null
    if (store.state.mainledgerActiv.BookName === "BS") {
      defObj.InvoiceDate = store.state.mainledgerActiv.bankStatmentDate ? store.state.mainledgerActiv.bankStatmentDate : null
      defObj.InvoicePaymentDeadlineDate = store.state.mainledgerActiv.bankStatmentDate ? store.state.mainledgerActiv.bankStatmentDate : null
      defObj.BookingDate = store.state.mainledgerActiv.bankStatmentDate ? store.state.mainledgerActiv.bankStatmentDate : null
    } else {
      defObj.InvoiceDate = store.state.mainledgerActiv.InvoiceDate ? store.state.mainledgerActiv.InvoiceDate : null
      defObj.InvoicePaymentDeadlineDate = store.state.mainledgerActiv.InvoicePaymentDeadlineDate ? store.state.mainledgerActiv.InvoicePaymentDeadlineDate : null
      defObj.BookingDate = store.state.mainledgerActiv.BookingDate ? store.state.mainledgerActiv.BookingDate : null
    }
    defObj.InvoiceAmount = store.state.mainledgerActiv.InvoiceAmount ? parseFloat(store.state.mainledgerActiv.InvoiceAmount) : 0
    defObj.InvoiceForeginAmount = store.state.mainledgerActiv.InvoiceForeginAmount ? parseFloat(store.state.mainledgerActiv.InvoiceForeginAmount) : 0
    defObj.WareHouseId = store.state.mainledgerActiv.WareHouseId ? parseInt(store.state.mainledgerActiv.WareHouseId) : 0

    defObj.partnerId = store.state.mainledgerActiv.partnerId ? parseInt(store.state.mainledgerActiv.partnerId) : null
    defObj.partnerName = store.state.mainledgerActiv.partnerNameDatabase ? store.state.mainledgerActiv.partnerNameDatabase : null
    defObj.partnerVATID = store.state.mainledgerActiv.partnerVATID ? store.state.mainledgerActiv.partnerVATID : null
    defObj.partnerIBAN = store.state.mainledgerActiv.partnerBankIBAN ? store.state.mainledgerActiv.partnerBankIBAN : null
    defObj.partneremail = store.state.mainledgerActiv.partneremail ? store.state.mainledgerActiv.partneremail : null
    defObj.partnerStreet = store.state.mainledgerActiv.partnerStreet ? store.state.mainledgerActiv.partnerStreet : null
    defObj.partnerBuildingNameNumber = store.state.mainledgerActiv.partnerBuildingNameNumber ? store.state.mainledgerActiv.partnerBuildingNameNumber : null
    defObj.partnerCity = store.state.mainledgerActiv.partnerCity ? store.state.mainledgerActiv.partnerCity : null
    defObj.partnerPostcode = store.state.mainledgerActiv.partnerPostcode ? store.state.mainledgerActiv.partnerPostcode : null
    
    defObj.HomeCurrency = store.state.mainledgerActiv.HomeCurrency ? store.state.mainledgerActiv.HomeCurrency : 'EUR'
    defObj.HomeCurrencyPrimary = store.state.mainledgerActiv.HomeCurrencyPrimary ? store.state.mainledgerActiv.HomeCurrencyPrimary : false
    defObj.ForeignCurrency = store.state.mainledgerActiv.ForeignCurrency ? store.state.mainledgerActiv.ForeignCurrency : 'EUR'
    defObj.ForeignCurrencyPrimary = store.state.mainledgerActiv.ForeignCurrencyPrimary ? store.state.mainledgerActiv.ForeignCurrencyPrimary : false
    defObj.ExRate = store.state.mainledgerActiv.ExRate ? parseFloat(store.state.mainledgerActiv.ExRate) : 7.5345

    defObj.ConnName = store.state.connName ? store.state.connName : ''
    defObj.ConnId = store.state.connId ? store.state.connId : 0
    defObj.BusinessYearInvoice = store.state.mainledgerActiv.InvoiceDate ? (new Date(store.state.mainledgerActiv.InvoiceDate)).getFullYear() : null
 
    if (store.state.documentSide === 1) {
      defObj.invoiceInID = store.state.mainledgerActiv.id ? parseInt(store.state.mainledgerActiv.id) : null
      defObj.invoiceInNumber = store.state.mainledgerActiv.BookID ? parseInt(store.state.mainledgerActiv.BookID) : null
      
    }
    if (store.state.documentSide === 2) {
      defObj.invoiceOutID = store.state.mainledgerActiv.id ? parseInt(store.state.mainledgerActiv.id) : null
      defObj.invoiceOutNumber = store.state.mainledgerActiv.BookID ? parseInt(store.state.mainledgerActiv.BookID) : null
    }
  }
  return defObj
}

const defMainLedgerDetailFillUpdate = () => {
  const defObj = {}
  if (store.state.mainledgerActiv) {

    defObj.databaseMLID = store.state.mainledgerActiv.id ? store.state.mainledgerActiv.id : 0
    defObj.CompanyId = store.state.mainledgerActiv.CompanyId ? store.state.mainledgerActiv.CompanyId : (store.state.companyid ? store.state.companyid : 1)
    defObj.CompanyNameDatabase = store.state.mainledgerActiv.CompanyNameDatabase ? store.state.mainledgerActiv.CompanyNameDatabase : (store.state.companyName ? store.state.companyName : '')
    defObj.BusinessYear = store.state.mainledgerActiv.businessYear ? store.state.mainledgerActiv.businessYear : (store.state.businessYear ? store.state.businessYear : 2021)
    defObj.documentName = store.state.mainledgerActiv.documentName ? store.state.mainledgerActiv.documentName : ''
    defObj.documentIdGeneral = store.state.mainledgerActiv.documentIdGeneral
    defObj.UserId = store.state.mainledgerActiv.UserId ? store.state.mainledgerActiv.UserId : 0
    defObj.email = store.state.mainledgerActiv.email ? store.state.mainledgerActiv.email : ''
    defObj.First = store.state.mainledgerActiv.First ? store.state.mainledgerActiv.First : ''
    defObj.Last = store.state.mainledgerActiv.Last ? store.state.mainledgerActiv.Last : ''
    defObj.MLType = store.state.mainledgerActiv.documentSide ? store.state.mainledgerActiv.documentSide : 1
    defObj.BookID = store.state.mainledgerActiv.BookID ? store.state.mainledgerActiv.BookID : 0
    defObj.BookTypeID = store.state.mainledgerActiv.BookTypeID ? store.state.mainledgerActiv.BookTypeID : 0
    defObj.BookName = store.state.mainledgerActiv.BookName ? store.state.mainledgerActiv.BookName : ''
    defObj.BookIdGeneral = store.state.mainledgerActiv.BookIdGeneral ? store.state.mainledgerActiv.BookIdGeneral : ''
  
    if (store.state.mainledgerActiv.BookName === "BS") {
      defObj.BookingDate = store.state.mainledgerActiv.bankStatmentDate ? store.state.mainledgerActiv.bankStatmentDate : null
    } else {
      defObj.BookingDate = store.state.mainledgerActiv.BookingDate ? store.state.mainledgerActiv.BookingDate : null
    }
    console.log('novi booking date ', defObj.BookingDate)
    defObj.HomeCurrency = store.state.mainledgerActiv.HomeCurrency ? store.state.mainledgerActiv.HomeCurrency : 'EUR'
    defObj.HomeCurrencyPrimary = store.state.mainledgerActiv.HomeCurrencyPrimary ? store.state.mainledgerActiv.HomeCurrencyPrimary : false
    defObj.ForeignCurrency = store.state.mainledgerActiv.ForeignCurrency ? store.state.mainledgerActiv.ForeignCurrency : 'EUR'
    defObj.ForeignCurrencyPrimary = store.state.mainledgerActiv.ForeignCurrencyPrimary ? store.state.mainledgerActiv.ForeignCurrencyPrimary : false
    defObj.ExRate = store.state.mainledgerActiv.ExRate ? parseFloat(store.state.mainledgerActiv.ExRate) : 7.5345
  }
  return defObj
}

const defMainLedger = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EmployeeId = 0
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.MLType = 0
  defObj.BookID = 0
  defObj.BookTypeID = 0
  defObj.BookName = ''
  defObj.BookIdGeneral = ''
  defObj.Status = ''
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = ''
  defObj.UpdateDate = null
  defObj.UpdateTime = null
  defObj.UpdateBy = ''
  defObj.InvoiceNumber = ''
  defObj.InvoiceDate = null
  defObj.InvoicePaymentDeadlineDate = null
  defObj.BookingDate = null
  defObj.InvoiceAmount = 0
  defObj.InvoiceForeginAmount = 0
  defObj.HomeCurrency = 'EUR'
  defObj.HomeCurrencyPrimary = false
  defObj.ForeignCurrency = 'EUR'
  defObj.ForeignCurrencyPrimary = false
  defObj.ExRate = 0
  defObj.WareHouseId = 0
  defObj.WareHouseEntry = 0
  defObj.WareHouseEntryAmount = 0
  defObj.ConnName = ''
  defObj.ConnId = 0
  defObj.ConnDate = null
  defObj.ConnTime = null
  defObj.ConnAmount = 0
  defObj.ConnAmountVAT = 0
  defObj.ConnAmount2 = 0
  defObj.bankAccountID = 0
  defObj.bankAccountName = ''
  defObj.bankAccountNr = ''
  defObj.bankAccountModel1 = ''
  defObj.bankAccountModel2 = ''
  defObj.partnerId = ''
  defObj.partnerName = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partnerVATID = ''
  defObj.partneremail = null
  defObj.partnerPhones = ''
  defObj.partnerBankName = ''
  defObj.partnerBankAddress = ''
  defObj.partnerBankIBAN = ''
  defObj.partnerBankSWIFT = ''
  defObj.VAT1Per = 25
  defObj.VAT1Base = 0
  defObj.VAT1Amount = 0
  defObj.VAT1PerCanUse = 100
  defObj.VAT1CanUse = 0
  defObj.VAT1CanTUse = 0
  defObj.VAT2Per = 13
  defObj.VAT2Base = 0
  defObj.VAT2Amount = 0
  defObj.VAT2PerCanUse = 100
  defObj.VAT2CanUse = 0
  defObj.VAT2CanTUse = 0
  defObj.VAT3Per = 5
  defObj.VAT3Base = 0
  defObj.VAT3Amount = 0
  defObj.VAT3PerCanUse = 100
  defObj.VAT3CanUse = 0
  defObj.VAT3CanTUse = 0
  defObj.VAT4Per = 0
  defObj.VAT4Base = 0
  defObj.VAT4Amount = 0
  defObj.VAT4PerCanUse = 100
  defObj.VAT4CanUse = 0
  defObj.VAT4CanTUse = 0
  defObj.VAT5Per = 0
  defObj.VAT5Base = 0
  defObj.VAT5Amount = 0
  defObj.VAT5PerCanUse = 100
  defObj.VAT5CanUse = 0
  defObj.VAT5CanTUse = 0
  defObj.AmountNetto = 0
  defObj.AmountVAT = 0
  defObj.AmountBrutto = 0
  defObj.AmountPayInAdvance = 0
  defObj.AmountToPay = 0
  defObj.AmountNettoForeign = 0
  defObj.AmountVATForeign = 0
  defObj.AmountBruttoForeign = 0
  defObj.AmountPayInAdvanceForeign = 0
  defObj.AmountToPayForeign = 0
  defObj.Debit = 0
  defObj.Credit = 0
  defObj.DebitForegin = 0
  defObj.CreditForegin = 0
  defObj.DebitTotal = 0
  defObj.CreditTotal = 0
  defObj.DebitForeginTotal = 0
  defObj.CreditForeginTotal = 0
  defObj.ClosedAccount = false
  defObj.paidHome = 0
  defObj.paidFore = 0
  defObj.paidDate = null
  defObj.paidClosed = false
  defObj.Goods = 0
  defObj.Goods25 = 0
  defObj.Goods13 = 0
  defObj.Goods5 = 0
  defObj.Services = 0
  defObj.Services25 = 0
  defObj.Services13 = 0
  defObj.Services5 = 0
  defObj.GoodsEU = 0
  defObj.ServicesEU = 0
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.LastUpdateBy = ''
  defObj.LastUpdate = null
  defObj.FreeB1 = false
  defObj.MLBool1 = false
  defObj.PayedVAT = false
  defObj.VATZero = 0
  defObj.a2Dcode = ''
  defObj.QRcodeData = '' 
  defObj.QRcodeLink = ''
  defObj.UUID = ''
  defObj.Pay1 = ''
  defObj.Pay2 = ''
  defObj.PayDesc = ''
  defObj.partnerNotInVAT = false
  return defObj
}

const defMainLedgerVAT = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EmployeeId = 0
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.MLType = 0
  defObj.BookID = 0
  defObj.BookTypeID = 0
  defObj.BookName = ''
  defObj.BookIdGeneral = ''
  defObj.Status = ''
  defObj.Locked = false
  defObj.LockedDate = null
  defObj.LockedTime = null
  defObj.LockedBy = ''
  defObj.UpdateDate = null
  defObj.UpdateTime = null
  defObj.UpdateBy = ''
  defObj.fromDate = null
  defObj.toDate = null
  defObj.Branch = ''
  defObj.Podatak000 = 0
  defObj.Podatak100 = 0
  defObj.Podatak101 = 0
  defObj.Podatak102 = 0
  defObj.Podatak103 = 0
  defObj.Podatak104 = 0
  defObj.Podatak105 = 0
  defObj.Podatak106 = 0
  defObj.Podatak107 = 0
  defObj.Podatak108 = 0
  defObj.Podatak109 = 0
  defObj.Podatak110 = 0
  defObj.Podatak111 = 0
  defObj.Podatak200 = 0
  defObj.Porez200 = 0
  defObj.Podatak201 = 0
  defObj.Porez201 = 0
  defObj.Podatak202 = 0
  defObj.Porez202 = 0
  defObj.Podatak203 = 0
  defObj.Porez203 = 0
  defObj.Podatak204 = 0
  defObj.Porez204 = 0
  defObj.Podatak205 = 0
  defObj.Porez205 = 0
  defObj.Podatak206 = 0
  defObj.Porez206 = 0
  defObj.Podatak207 = 0
  defObj.Porez207 = 0
  defObj.Podatak208 = 0
  defObj.Porez208 = 0
  defObj.Podatak209 = 0
  defObj.Porez209 = 0
  defObj.Podatak210 = 0
  defObj.Porez210 = 0
  defObj.Podatak211 = 0
  defObj.Porez211 = 0
  defObj.Podatak212 = 0
  defObj.Porez212 = 0
  defObj.Podatak213 = 0
  defObj.Porez213 = 0
  defObj.Podatak214 = 0
  defObj.Porez214 = 0
  defObj.Podatak215 = 0
  defObj.Porez215 = 0
  defObj.Podatak300 = 0
  defObj.Porez300 = 0
  defObj.Podatak301 = 0
  defObj.Porez301 = 0
  defObj.Podatak302 = 0
  defObj.Porez302 = 0
  defObj.Podatak303 = 0
  defObj.Porez303 = 0
  defObj.Podatak304 = 0
  defObj.Porez304 = 0
  defObj.Podatak305 = 0
  defObj.Porez305 = 0
  defObj.Podatak306 = 0
  defObj.Porez306 = 0
  defObj.Podatak307 = 0
  defObj.Porez307 = 0
  defObj.Podatak308 = 0
  defObj.Porez308 = 0
  defObj.Podatak309 = 0
  defObj.Porez309 = 0
  defObj.Podatak310 = 0
  defObj.Porez310 = 0
  defObj.Podatak311 = 0
  defObj.Porez311 = 0
  defObj.Podatak312 = 0
  defObj.Porez312 = 0
  defObj.Podatak313 = 0
  defObj.Porez313 = 0
  defObj.Podatak314 = 0
  defObj.Porez314 = 0
  defObj.Podatak315 = 0
  defObj.Porez315 = 0
  defObj.Podatak400 = 0
  defObj.Podatak500 = 0
  defObj.Podatak600 = 0

  defObj.Podatak610 = 0
  defObj.Podatak611 = 0
  defObj.Podatak612 = 0
  defObj.Podatak613 = 0
  defObj.Podatak614 = 0  
  defObj.Podatak615 = 0
  defObj.Podatak620 = 0
  defObj.Podatak630 = 0
  defObj.Podatak640 = 0
  defObj.Podatak650 = 0
  defObj.Podatak660 = false

  defObj.Podatak700 = 0
  defObj.Podatak810 = 0
  defObj.Podatak811 = 0
  defObj.Podatak812 = 0
  defObj.Podatak813 = 0
  defObj.Podatak814 = 0
  defObj.Podatak815 = 0
  defObj.Podatak820 = 0
  defObj.Podatak830 = 0
  defObj.Podatak831 = 0
  defObj.Podatak832 = 0
  defObj.Podatak833 = 0
  defObj.Broj833 = 0
  defObj.Podatak840 = 0
  defObj.Podatak850 = 0
  defObj.Podatak860 = 0
  defObj.Podatak871 = 0
  defObj.Podatak872 = 0
  defObj.Podatak873 = 0
  defObj.Povrat1 = false
  defObj.Povrat2 = 0
  defObj.Predujam1 = false
  defObj.Predujam2 = 0
  defObj.UstupPovrata1 = false
  defObj.UstupPovrata2 = 0
  defObj.PovratIBAN = ''
  return defObj
}
const defMainLedgerVATFill = () => {
  const defObj = {}
  defObj.Podatak000 = 0
  defObj.Podatak100 = 0
  defObj.Podatak101 = 0
  defObj.Podatak102 = 0
  defObj.Podatak103 = 0
  defObj.Podatak104 = 0
  defObj.Podatak105 = 0
  defObj.Podatak106 = 0
  defObj.Podatak107 = 0
  defObj.Podatak108 = 0
  defObj.Podatak109 = 0
  defObj.Podatak110 = 0
  defObj.Podatak200 = 0
  defObj.Porez200 = 0
  defObj.Podatak201 = 0
  defObj.Porez201 = 0
  defObj.Podatak202 = 0
  defObj.Porez202 = 0
  defObj.Podatak203 = 0
  defObj.Porez203 = 0
  defObj.Podatak204 = 0
  defObj.Porez204 = 0
  defObj.Podatak205 = 0
  defObj.Porez205 = 0
  defObj.Podatak206 = 0
  defObj.Porez206 = 0
  defObj.Podatak207 = 0
  defObj.Porez207 = 0
  defObj.Podatak208 = 0
  defObj.Porez208 = 0
  defObj.Podatak209 = 0
  defObj.Porez209 = 0
  defObj.Podatak210 = 0
  defObj.Porez210 = 0
  defObj.Podatak211 = 0
  defObj.Porez211 = 0
  defObj.Podatak212 = 0
  defObj.Porez212 = 0
  defObj.Podatak213 = 0
  defObj.Porez213 = 0
  defObj.Podatak214 = 0
  defObj.Porez214 = 0
  defObj.Podatak215 = 0
  defObj.Porez215 = 0
  defObj.Podatak300 = 0
  defObj.Porez300 = 0
  defObj.Podatak301 = 0
  defObj.Porez301 = 0
  defObj.Podatak302 = 0
  defObj.Porez302 = 0
  defObj.Podatak303 = 0
  defObj.Porez303 = 0
  defObj.Podatak304 = 0
  defObj.Porez304 = 0
  defObj.Podatak305 = 0
  defObj.Porez305 = 0
  defObj.Podatak306 = 0
  defObj.Porez306 = 0
  defObj.Podatak307 = 0
  defObj.Porez307 = 0
  defObj.Podatak308 = 0
  defObj.Porez308 = 0
  defObj.Podatak309 = 0
  defObj.Porez309 = 0
  defObj.Podatak310 = 0
  defObj.Porez310 = 0
  defObj.Podatak311 = 0
  defObj.Porez311 = 0
  defObj.Podatak312 = 0
  defObj.Porez312 = 0
  defObj.Podatak313 = 0
  defObj.Porez313 = 0
  defObj.Podatak314 = 0
  defObj.Porez314 = 0
  defObj.Podatak315 = 0
  defObj.Porez315 = 0
  defObj.Podatak400 = 0
  defObj.Podatak500 = 0
  defObj.Podatak600 = 0
  defObj.Podatak700 = 0
  defObj.Podatak810 = 0
  defObj.Podatak811 = 0
  defObj.Podatak812 = 0
  defObj.Podatak813 = 0
  defObj.Podatak814 = 0
  defObj.Podatak815 = 0
  defObj.Podatak820 = 0
  defObj.Podatak830 = 0
  defObj.Podatak831 = 0
  defObj.Podatak832 = 0
  defObj.Podatak833 = 0
  defObj.Broj833 = 0
  defObj.Podatak840 = 0
  defObj.Podatak850 = 0
  defObj.Podatak860 = 0
  defObj.Podatak871 = 0
  defObj.Podatak872 = 0
  defObj.Podatak873 = 0
  defObj.Povrat1 = false
  defObj.Povrat2 = 0
  defObj.Predujam1 = false
  defObj.Predujam2 = 0
  defObj.UstupPovrata1 = false
  defObj.UstupPovrata2 = 0
  return defObj
}

const defNewpartner = () => {
  const defObj = {}
  defObj.CompanyId = 1
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 1
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.partnerName = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partnerVATID = null
  defObj.partneremail = ''
  defObj.partnerPhones = ''
  defObj.partnerBankName = ''
  defObj.partnerBankAddress = ''
  defObj.partnerBankIBAN = ''
  defObj.partnerBankSWIFT = ''
  defObj.partnerDirectorTitle = ''
  defObj.partnerDirectorFirst = ''
  defObj.partnerDirectorLast = ''
  defObj.partnerDirectorFormer = ''
  defObj.partnerDirectorStateResidence = ''
  defObj.partnerDirectorNationality = ''
  defObj.partnerDirectorDateOfBirth = ''
  defObj.partnerDirectorBusinessOccupation = ''
  defObj.partnerDirectorAdressBuildingNameNumber = ''
  defObj.partnerDirectorAdressStreet = ''
  defObj.partnerDirectorCity = ''
  defObj.partnerDirectorPostcode = ''
  defObj.partnerDirectorCountyRegion = ''
  defObj.partnerDirectorEmail = ''
  defObj.partnerDirectorPhone = ''
  defObj.partnerDirectorContactOption = ''
  defObj.partnerSecretaryTitle = ''
  defObj.partnerSecretaryFirst = ''
  defObj.partnerSecretaryLast = ''
  defObj.partnerSecretaryFormer = ''
  defObj.partnerSecretaryStateResidence = ''
  defObj.partnerSecretaryNationality = ''
  defObj.partnerSecretaryDateOfBirth = ''
  defObj.partnerSecretaryBusinessOccupation = ''
  defObj.partnerSecretaryAdressBuildingNameNumber = ''
  defObj.partnerSecretaryAdressStreet = ''
  defObj.partnerSecretaryCity = ''
  defObj.partnerSecretaryPostcode = ''
  defObj.partnerSecretaryCountyRegion = ''
  defObj.partnerSecretaryEmail = ''
  defObj.partnerSecretaryPhone = ''
  defObj.partnerSecretaryContactOption = ''
  defObj.partnerInvoiceMemo = ''
  defObj.partnerInvoiceForeginMemo = ''
  defObj.partnerDeliveryNoteMemo = ''
  defObj.partnerDeliveryNoteForeginMemo = ''
  defObj.partnerInvoiceDefDays = 15
  defObj.partnerInvoicePlace = ''
  defObj.partnerInvoiceType = ''
  defObj.partnerInvoiceHint = ''
  defObj.partnerInvoiceSpeed = 1
  defObj.partnerUseRetailPrice = false
  defObj.partnerShowLastSaleBuyer = false
  defObj.partnerShowWarehouseState = false
  defObj.partnerShowInputPrice = false
  defObj.partnerVATonPay = false
  defObj.partnerRetail = false
  defObj.partnerDefaultWarehouse = 1
  defObj.partnerPrinterSize = 1
  defObj.partnerInputFilesPath = ''
  defObj.partnerOutputFilesPath = ''
  defObj.partnerTransferDataFilesPath = ''
  defObj.partnerStartInvoiceNumber = 1
  defObj.partnerStartBillNumber = 1
  defObj.partnerType = 1
  defObj.StartFrom = null
  defObj.Activ = false
  defObj.lockedCredit = false
  defObj.lockedDebit = false
  defObj.memberOfGroup = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.LastUpdateBy = ''
  defObj.privatePerson = false
  return defObj
}

const defMainBook = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.Skip = false
  defObj.partnerId = 0
  defObj.partnerName = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partnerVATID = ''
  defObj.partneremail = ''
  defObj.partnerPhones = ''
  defObj.partnerBankName = ''
  defObj.partnerBankAddress = ''
  defObj.partnerBankIBAN = ''
  defObj.partnerBankSWIFT = ''
  defObj.BookRecExp = 1 // 1 Rec, 2 Exp
  defObj.BookTypeId = 0
  defObj.BookTypeName = ''
  defObj.BookId = 0
  defObj.BookBusinessYear = 0
  defObj.BookDate = null
  defObj.InvoiceNumber = ""
  defObj.InvoiceDate = null
  defObj.InvoicePaymentDeadlineDate = null
  defObj.BookDesc1 = ''
  defObj.BookDesc2 = ''
  defObj.RecCash = 0
  defObj.RecBank = 0
  defObj.RecFree = 
  defObj.RecVAT = 0
  defObj.RecSpecial = 0
  defObj.RecTotal = 0
  defObj.ExpCash = 0
  defObj.ExpBank = 0
  defObj.ExpFree = 0
  defObj.ExpVAT = 0
  defObj.ExpSpecial = 0
  defObj.ExpTotal = 0
  defObj.documentName = ''
  defObj.documentIdGeneral= ''
  defObj.BookID = 0
  defObj.BookTypeID = 0
  defObj.BookName= ''
  defObj.BookIdGeneral = ''
  defObj.InvoiceAmount = 0
  defObj.VAT1Per = 25
  defObj.VAT1Base = 0
  defObj.VAT1Amount = 0
  defObj.VAT1PerCanUse = 100
  defObj.VAT1CanUse = 0
  defObj.VAT1CanTUse = 0
  defObj.VAT2Per = 13
  defObj.VAT2Base = 0
  defObj.VAT2Amount = 0
  defObj.VAT2PerCanUse = 100
  defObj.VAT2CanUse = 0
  defObj.VAT2CanTUse = 0
  defObj.VAT3Per = 5
  defObj.VAT3Base = 0
  defObj.VAT3Amount = 0
  defObj.VAT3PerCanUse = 100
  defObj.VAT3CanUse = 0
  defObj.VAT3CanTUse = 0
  defObj.VAT4Per = 0
  defObj.VAT4Base = 0
  defObj.VAT4Amount = 0
  defObj.VAT4PerCanUse = 0
  defObj.VAT4CanUse = 0
  defObj.VAT4CanTUse = 0
  defObj.VAT5Per = 0
  defObj.VAT5Base = 0
  defObj.VAT5Amount = 0
  defObj.VAT5PerCanUse = 0
  defObj.VAT5CanUse = 0
  defObj.VAT5CanTUse = 0
  defObj.AmountNetto = 0
  defObj.AmountVAT = 0
  defObj.AmountBrutto = 0
  defObj.bankAccountID = 0
  defObj.bankStatmentNumber = 0
  defObj.bankStatmentDate= null
  defObj.bankStatmentMoneyType = 0
  defObj.bankStatmentMoneyName = ''
  defObj.bankStatmentStart = 0
  defObj.bankStatmentDebit = 0
  defObj.bankStatmentCredit = 0
  defObj.bankStatmentFinish = 0
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  defObj.useBuySupp = false
  return defObj
}

const defNewMainBook = () => {
  const defaultMainBook = {}
  defaultMainBook.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultMainBook.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultMainBook.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defaultMainBook.BookBusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  defaultMainBook.UserId = store.state.user.id ? store.state.user.id : ''
  defaultMainBook.email = store.state.user.email ? store.state.user.email : ''
  defaultMainBook.First = store.state.user.First ? store.state.user.First : ''
  defaultMainBook.Last = store.state.user.Last ? store.state.user.Last : ''
  defaultMainBook.HomeCurrency = 'EUR'
  defaultMainBook.HomeCurrencyPrimary = true
  defaultMainBook.ForeignCurrency = ''
  defaultMainBook.ExRate = store.getters.NeedDefExRate
  defaultMainBook.ForeignCurrencyPrimary = false
  return defaultMainBook
}

const defMainBookType = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.BookRecExp = 1 // 1 Rec, 2 Exp
  defObj.BookTypeName = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  return defObj
}

const defNewMainBookType = () => {
  const defaultMainBookType = {}
  defaultMainBookType.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultMainBookType.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultMainBookType.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defaultMainBookType.UserId = store.state.user.id ? store.state.user.id : ''
  defaultMainBookType.email = store.state.user.email ? store.state.user.email : ''
  defaultMainBookType.First = store.state.user.First ? store.state.user.First : ''
  defaultMainBookType.Last = store.state.user.Last ? store.state.user.Last : ''
  return defaultMainBookType
}

const defMainBookDoc = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.DocID = ''
  defObj.DocName = ''
  defObj.DocVATID = ''
  defObj.DocDesc = ''
  defObj.DocOpenDate = null
  defObj.DocClosecDate = null
  defObj.DocClosed = false
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  return defObj
}

const defNewMainBookDoc = () => {
  const defaultMainBookType = {}
  defaultMainBookType.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultMainBookType.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultMainBookType.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defaultMainBookType.UserId = store.state.user.id ? store.state.user.id : ''
  defaultMainBookType.email = store.state.user.email ? store.state.user.email : ''
  defaultMainBookType.First = store.state.user.First ? store.state.user.First : ''
  defaultMainBookType.Last = store.state.user.Last ? store.state.user.Last : ''
  return defaultMainBookType
}

const defMainLedgerProjectType = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.BookRecExp = 1 // 1 Rec, 2 Exp
  defObj.BookTypeName = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  return defObj
}

const defNewMainLedgerProjectType = () => {
  const defaultObj = {}
  defaultObj.CompanyId = store.state.companyid ? store.state.companyid : 1
  defaultObj.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  defaultObj.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  defaultObj.UserId = store.state.user.id ? store.state.user.id : ''
  defaultObj.email = store.state.user.email ? store.state.user.email : ''
  defaultObj.First = store.state.user.First ? store.state.user.First : ''
  defaultObj.Last = store.state.user.Last ? store.state.user.Last : ''
  return defaultObj
}

const defTravelOrder = () => {
  const defaultTravelOrder = {}
  defaultTravelOrder.CompanyId = 0
  defaultTravelOrder.CompanyNameDatabase = ""
  defaultTravelOrder.BusinessYear = 0
  defaultTravelOrder.UserId = 0
  defaultTravelOrder.email = ""
  defaultTravelOrder.First = ""
  defaultTravelOrder.Last = ""
  defaultTravelOrder.partnerId = 0
  defaultTravelOrder.partnerName = ""
  defaultTravelOrder.partnerNameShort = ""
  defaultTravelOrder.TravelOrderId = 0
  defaultTravelOrder.OrderDate = null
  defaultTravelOrder.eId = 0
  defaultTravelOrder.eemail = ""
  defaultTravelOrder.phone = ""
  defaultTravelOrder.eFirst = ""
  defaultTravelOrder.eLast = ""
  defaultTravelOrder.eVatID = ""
  defaultTravelOrder.eJOPP = ""
  defaultTravelOrder.WorkPlace = ""
  defaultTravelOrder.TravelTo = ""
  defaultTravelOrder.TravelTask = ""
  defaultTravelOrder.TravelDate = null
  defaultTravelOrder.TravelLastDays = 0
  defaultTravelOrder.TravelLastDaysLett = ""
  defaultTravelOrder.TravelVeichle = ""
  defaultTravelOrder.TravelVeichleBrand = ""
  defaultTravelOrder.TravelVeichleReg = ""
  defaultTravelOrder.ExpensesForwardHomeC = 0
  defaultTravelOrder.ExpensesForwardForeC = 0
  defaultTravelOrder.ReportInDays = 0
  defaultTravelOrder.ApprovedBy= 0
  defaultTravelOrder.ApprovedByFirst = ""
  defaultTravelOrder.ApprovedByLast = ""
  defaultTravelOrder.ApprovedByemail= ""
  defaultTravelOrder.TravelApproved = false
  defaultTravelOrder.FinTravel1 = ""
  defaultTravelOrder.FinTravel2 = ""
  defaultTravelOrder.Travel2Date = null
  defaultTravelOrder.FinTravel3 = ""
  defaultTravelOrder.Travel3Date = null
  defaultTravelOrder.FinStartDate = null
  defaultTravelOrder.FinStartTime = null
  defaultTravelOrder.FinEndDate = null
  defaultTravelOrder.FinEndTime = null
  defaultTravelOrder.FinHours = 0
  defaultTravelOrder.FinDays = 0
  defaultTravelOrder.FinDaysAmount = 0
  defaultTravelOrder.FinDaysTotal = 0
  defaultTravelOrder.FinDaysEUR = 0
  defaultTravelOrder.FinDaysAmountEUR = 0
  defaultTravelOrder.FinDaysTotalEUR = 0
  defaultTravelOrder.FinTrStart = 0
  defaultTravelOrder.FinTrEnd = 0
  defaultTravelOrder.CostByKm = 0
  defaultTravelOrder.FinRel1From = ""
  defaultTravelOrder.FinRel1To = ""
  defaultTravelOrder.FinRel1Km = 0
  defaultTravelOrder.FinRel1KmKn = 0
  defaultTravelOrder.FinRel1KmEur = 0
  defaultTravelOrder.FinRel1Total = 0
  defaultTravelOrder.FinRel1TotalEur = 0
  defaultTravelOrder.FinRel2From = ""
  defaultTravelOrder.FinRel2To = ""
  defaultTravelOrder.FinRel2Km = 0
  defaultTravelOrder.FinRel2KmKn = 0
  defaultTravelOrder.FinRel2KmEur = 0
  defaultTravelOrder.FinRel2Total = 0
  defaultTravelOrder.FinRel2TotalEur = 0
  defaultTravelOrder.FinRel3From = ""
  defaultTravelOrder.FinRel3To = ""
  defaultTravelOrder.FinRel3Km = 0
  defaultTravelOrder.FinRel3KmKn = 0
  defaultTravelOrder.FinRel3KmEur = 0
  defaultTravelOrder.FinRel3Total = 0
  defaultTravelOrder.FinRel3TotalEur = 0
  defaultTravelOrder.FinRel4From = ""
  defaultTravelOrder.FinRel4To = ""
  defaultTravelOrder.FinRel4Km = 0
  defaultTravelOrder.FinRel4KmKn = 0
  defaultTravelOrder.FinRel4KmEur = 0
  defaultTravelOrder.FinRel4Total = 0
  defaultTravelOrder.FinRel4TotalEur = 0
  defaultTravelOrder.FinRel5From = ""
  defaultTravelOrder.FinRel5To = ""
  defaultTravelOrder.FinRel5Km = 0
  defaultTravelOrder.FinRel5KmKn = 0
  defaultTravelOrder.FinRel5KmEur = 0
  defaultTravelOrder.FinRel5Total = 0
  defaultTravelOrder.FinRel5TotalEur = 0
  defaultTravelOrder.FinOther1 = ""
  defaultTravelOrder.FinOther1Kn = 0
  defaultTravelOrder.FinOther1Eur = 0
  defaultTravelOrder.FinOther2 = ""
  defaultTravelOrder.FinOther2Kn = 0
  defaultTravelOrder.FinOther2Eur = 0
  defaultTravelOrder.FinOther3 = ""
  defaultTravelOrder.FinOther3Kn = 0
  defaultTravelOrder.FinOther3Eur = 0
  defaultTravelOrder.FinOther4 = ""
  defaultTravelOrder.FinOther4Kn = 0
  defaultTravelOrder.FinOther4Eur = 0
  defaultTravelOrder.FinOther5 = ""
  defaultTravelOrder.FinOther5Kn = 0
  defaultTravelOrder.FinOther5Eur = 0
  defaultTravelOrder.FinTotal = ""
  defaultTravelOrder.FinTotalKn = 0
  defaultTravelOrder.FinTotalEur = 0
  defaultTravelOrder.FinPayReturn = ""
  defaultTravelOrder.FinPayReturnKn = 0
  defaultTravelOrder.FinPayReturnEur = 0
  defaultTravelOrder.FinReport = ""
  defaultTravelOrder.FinEndPlace = ""
  defaultTravelOrder.FinEndDate = null
  defaultTravelOrder.UserId = 0
  defaultTravelOrder.email = ""
  defaultTravelOrder.First = ""
  defaultTravelOrder.Last = ""
  defaultTravelOrder.UserIdControl = 0
  defaultTravelOrder.emailControl = ""
  defaultTravelOrder.FirstControl = ""
  defaultTravelOrder.LastControl = ""
  defaultTravelOrder.UserIdCFO = 0
  defaultTravelOrder.emailCFO = ""
  defaultTravelOrder.FirstCFO = ""
  defaultTravelOrder.LastCFO = ""
  defaultTravelOrder.HomeCurrency = ""
  defaultTravelOrder.HomeCurrencyPrimary = false
  defaultTravelOrder.ForeignCurrency = ""
  defaultTravelOrder.ForeignCurrencyPrimary = false
  defaultTravelOrder.ExRate = 0
  defaultTravelOrder.EnteredInDatabase = null
  defaultTravelOrder.LastUpdateInDatabase = null
  defaultTravelOrder.ReportFinished = false
  defaultTravelOrder.Finished = false
  defaultTravelOrder.FreeF1 = 0
  defaultTravelOrder.FreeF2 = 0
  defaultTravelOrder.FreeF3 = 0
  defaultTravelOrder.FreeF4 = 0
  defaultTravelOrder.FreeF5 = 0
  defaultTravelOrder.FreeB1 = false
  defaultTravelOrder.FreeB2 = false
  defaultTravelOrder.FreeB3 = false
  defaultTravelOrder.FreeB4 = false
  defaultTravelOrder.FreeB5 = false
  defaultTravelOrder.FreeS1 = ""
  defaultTravelOrder.FreeS2 = ""
  defaultTravelOrder.FreeS3 = ""
  defaultTravelOrder.FreeS4 = ""
  defaultTravelOrder.FreeS5 = ""
  return defaultTravelOrder
}
const defNeWTravelOrder = () => {
  const newTravelOrder = {}
  newTravelOrder.CompanyId = store.state.companyid ? store.state.companyid : 1
  newTravelOrder.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  newTravelOrder.BusinessYear = store.state.businessYear ? store.state.businessYear : 2022
  newTravelOrder.UserId = store.state.user.id ? store.state.user.id : ''
  newTravelOrder.email = store.state.user.email ? store.state.user.email : ''
  newTravelOrder.First = store.state.user.First ? store.state.user.First : ''
  newTravelOrder.Last = store.state.user.Last ? store.state.user.Last : ''
  newTravelOrder.HomeCurrency = "EUR"
  newTravelOrder.HomeCurrencyPrimary = true
  newTravelOrder.ForeignCurrency = "EUR"
  return newTravelOrder
}

const defForm = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.formName = ''
  defObj.formSide = 0
  defObj.formAnalysis = 0
  defObj.formId = 0
  defObj.formIdGeneral = ''
  defObj.formDate = null
  defObj.formTime = null
  defObj.tax1 = ''
  defObj.tax2 = ''
  defObj.tax3 = ''
  defObj.tax4 = ''
  defObj.tax5 = ''
  defObj.tax6 = ''
  defObj.month = 0
  defObj.quarter = 0
  defObj.year = 0
  defObj.FreeTotal1 = 0
  defObj.FreeTotal2 = 0
  defObj.FreeTotal3 = 0
  defObj.FreeTotal4 = 0
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = 0
  defObj.FreeF8 = 0
  defObj.FreeF9 = 0
  defObj.FreeF10 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  defObj.FreeS6 = ''
  defObj.FreeS7 = ''
  defObj.FreeS8 = ''
  defObj.FreeS9 = ''
  defObj.FreeS10 = ''
  return defObj
}

const defFormDetail = () => {
  const defObj = {}
  defObj.CompanyId = 0
  defObj.CompanyNameDatabase = ''
  defObj.BusinessYear = 0
  defObj.UserId = 0
  defObj.email = ''
  defObj.First = ''
  defObj.Last = ''
  defObj.EnteredInDatabase = null
  defObj.LastUpdateInDatabase = null
  defObj.formName = ''
  defObj.formSide = 0
  defObj.formAnalysis = 0
  defObj.formId = 0
  defObj.formIdGeneral = ''
  defObj.formDate = null
  defObj.formTime = null
  defObj.month = 0
  defObj.quarter = 0
  defObj.year = 0
  defObj.partnerId = 0
  defObj.partnerName = ''
  defObj.partnerNameShort = ''
  defObj.partnerNameDatabase = ''
  defObj.partnerBuildingNameNumber = ''
  defObj.partnerStreet = ''
  defObj.partnerCity = ''
  defObj.partnerPostcode = ''
  defObj.partnerCountyRegion = ''
  defObj.partnerState = ''
  defObj.partnerVATID = ''
  defObj.partneremail = ''
  defObj.deliveredDate = null
  defObj.deliveredMonth = null
  defObj.FreeF1 = 0
  defObj.FreeF2 = 0
  defObj.FreeF3 = 0
  defObj.FreeF4 = 0
  defObj.FreeF5 = 0
  defObj.FreeF6 = 0
  defObj.FreeF7 = 0
  defObj.FreeF8 = 0
  defObj.FreeF9 = 0
  defObj.FreeF10 = 0
  defObj.FreeB1 = false
  defObj.FreeB2 = false
  defObj.FreeB3 = false
  defObj.FreeB4 = false
  defObj.FreeB5 = false
  defObj.FreeS1 = ''
  defObj.FreeS2 = ''
  defObj.FreeS3 = ''
  defObj.FreeS4 = ''
  defObj.FreeS5 = ''
  defObj.FreeS6 = ''
  defObj.FreeS7 = ''
  defObj.FreeS8 = ''
  defObj.FreeS9 = ''
  defObj.FreeS10 = ''
  return defObj
}

const defNeWForm = () => {
  const newForm = {}
  newForm.CompanyId = store.state.companyid ? store.state.companyid : 1
  newForm.CompanyNameDatabase = store.state.companyName ? store.state.companyName : ''
  newForm.BusinessYear = store.state.businessYear ? store.state.businessYear : 2020
  newForm.UserId = store.state.user.id ? store.state.user.id : ''
  newForm.email = store.state.user.email ? store.state.user.email : ''
  newForm.First = store.state.user.First ? store.state.user.First : ''
  newForm.Last = store.state.user.Last ? store.state.user.Last : ''
  return newForm
}

export {
  defNew,
  defMainQuery,
  defMainLedger,
  defMainLedgerVAT,
  defMainLedgerVATFill,
  defMainLedgerDetail,
  defMainLedgerDetailFill,
  defMainLedgerDetailFillUpdate,
  checkPartners,
  defNewpartner,
  defMainBook,
  defNewMainBook,
  defMainBookType,
  defNewMainBookType,
  defMainBookDoc,
  defNewMainBookDoc,
  defTravelOrder,
  defNeWTravelOrder,
  defMainLedgerProjectType,
  defNewMainLedgerProjectType,
  defForm,
  defNeWForm,
  defFormDetail,
  checkBooks,
  findPartnerVATID
}