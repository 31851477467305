<template>
  <v-container fluid >
    <v-row no-gutters class="pa-0">
      <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
        <POSObjekatCRUD />
      </v-col>
    </v-row>    
  </v-container>
</template>

<script>
import POSObjekatCRUD from './POSobjekatCRUD'

export default {
  name: 'posobjekatindex',
  data () {
    return {
      msg: '',
      lang: {},
      langC: {},
      locMonths: [],
      locYears: [],
      saving: false,
      postNew: false
    }
  },
  mounted () {

  },
  methods: {


  },
  computed: {

  },
  watch: {

  },
  components: {
    POSObjekatCRUD
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
