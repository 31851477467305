import store from '../store/store'
import dayjs from 'dayjs'

const dateFormat = (inputDate) => {
  return dayjs(inputDate).format('DD.MM.YYYY')
  // const year = inputDate ? inputDate.slice(0,4) : ''
  // const month = inputDate ? inputDate.slice(5,7) : ''
  // const day = inputDate ? inputDate.slice(-2) : ''
  // return day + '.' + month + '.' + year 
}

const dateFormatDate = (inputDate) => {
  // const year = inputDate ? inputDate.slice(0,4) : ''
  // const month = inputDate ? inputDate.slice(5,7) : ''
  // const day = inputDate ? inputDate.slice(-2) : ''
  return new Date(inputDate) 
}

const doZeros = (invoiceNumber, BusinessYearInvoice ) => {
  const BYI = BusinessYearInvoice.toString()
  const zeroes = "0"
  const howMany = 6 - String(invoiceNumber).length
  const inNumber = (BYI + zeroes.repeat(howMany) + String(invoiceNumber))
  return inNumber
}

const documentCurrency = () => {

  let localName = ''
  if (store.state.language === 'EN') {
    localName = 'EUR'
  }
  if (store.state.language === 'HR') {
    localName = 'Kn'
  }
  return localName
}

const moneyFormat = (value) => {
  if (isNaN(value)) {
    return 0
  }
  if (store.state.language === 'EN') {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
  if (store.state.language === 'HR') {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
}

const parseNum = (num, dec) => {
  if (!dec) {
    dec = 2
  }
  if (!num) return 0
  
  if (isNaN(num)) return 0
  
  if (typeof(num) === 'boolean') return 0

  if (typeof(num) === 'number') {
    const haveBackOne = round(num, dec)
    return haveBackOne
  }

  if (typeof(num) === 'string') {
    const haveBack = round(parseFloat(num), dec)
    return haveBack
  }

  const newNum = parseFloat(num) 
  return newNum
}

const round = (value, exp) => {
  //return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
 
  if (typeof exp === 'undefined' || +exp === 0)
    return Math.round(value)

    value = +value
    exp = +exp

  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0))
    return 0;

  // Shift
  value = value.toString().split('e')
  value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)))

  // Shift back
  value = value.toString().split('e')
  return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp))
}

const dynamicSort = (property) => {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

function dynamicSortMultiple() {
  /*
   * save the arguments object as it will be overwritten
   * note that arguments object is an array-like object
   * consisting of the names of the properties to sort by
   */
  var props = arguments;
  return function (obj1, obj2) {
      var i = 0, result = 0, numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
       * as long as we have extra properties to compare
       */
      while(result === 0 && i < numberOfProperties) {
          result = dynamicSort(props[i])(obj1, obj2);
          i++;
      }
      return result;
  }
}

const wait = (ms) => {
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
 }
}

// how dynamicSort Works
// var People = [
//   {Name: "Name", Surname: "Surname"},
//   {Name:"AAA", Surname:"ZZZ"},
//   {Name: "Name", Surname: "AAA"}
// ];
// People.sort(dynamicSort("Name"));
// People.sort(dynamicSort("Surname"));
// People.sort(dynamicSort("-Surname"));

const sortByDate = (property, dateName) => {
  const sortedNewProperty = property.slice().sort((a, b) => b[dateName] - a[dateName])
  return sortedNewProperty
}

const sortByDateDesc = (property, dateName) => {
  const sortedNewProperty = property.slice().sort((a, b) =>  b[dateName].getTime() - a[dateName].getTime())
  return sortedNewProperty
}

const months = () => {
  const allMonths = []
  allMonths.push(1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12)
  return allMonths
}

const years = () => {
  const allYears = []
  allYears.push(2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040)
  return allYears
}

const createFloat = (value) => {
  const leftPart = parseInt(value.substring(0,value.length - 2))
  const rightPart = parseFloat("0." + value.substring(value.length -2, value.length))
  const fullNumber = leftPart + rightPart
  return fullNumber
}

export {
  dateFormat,
  documentCurrency,
  moneyFormat,
  parseNum,
  round,
  dynamicSort,
  dynamicSortMultiple,
  sortByDate,
  sortByDateDesc,
  dateFormatDate,
  months,
  years, 
  wait,
  createFloat,
  doZeros
}


